import React, { memo } from "react";
import Chart from "react-apexcharts";

export const ApexBarChart = memo(({
  series,
  categories,
  header,
  stacked = false,
  horizontal = false,
  height = 350,
  monochrome = false,
  className,
}) => (
  <div className={className || ""}>
    <Chart
      type="bar"
      height={height}
      options={{
        chart: {
          type: "bar",
          stacked,
          animations: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal,
          },
        },
        animations: {
          enabled: false,
        },
        theme: { monochrome: { enabled: monochrome } },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: header || "",
        },
        xaxis: {
          categories: categories,
          labels: {
            formatter: function (val) {
              return val ? val : "";
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        // tooltip: {
        //   y: {
        //     formatter: function (val) {
        //       return val + "K";
        //     },
        //   },
        // },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      }}
      series={series}
    />
  </div>
));

export const ApexRangeBarChart = memo(({
  series,
  categories,
  header,
  horizontal = false,
  height = 350,
  monochrome = false,
  className,
}) => (
  <div className={className || ""}>
    <Chart
      type="rangeBar"
      height={height}
      options={{
        chart: {
          type: "bar",
          animations: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal,
          },
        },
        animations: {
          enabled: false,
        },
        theme: { monochrome: { enabled: monochrome } },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: header || "",
        },
        xaxis: {
          categories: categories,
          labels: {
            formatter: function (val) {
              return val ? val : "";
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        // tooltip: {
        //   y: {
        //     formatter: function (val) {
        //       return val + "K";
        //     },
        //   },
        // },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      }}
      series={series}
    />
  </div>
));

export const ApexDonutChart = memo(({ series, labels, header, className }) => (
  <div className={className || ""}>
    <Chart
      type="donut"
      height={350}
      options={{
        chart: {
          type: "donut",
          animations: {
            enabled: false,
          },
        },
        animations: {
          enabled: false,
        },
        labels,
        title: {
          text: header || "",
        },
        theme: { monochrome: { enabled: true } },
        // responsive: [
        //   {
        //     breakpoint: 480,
        //     options: {
        //       chart: {
        //         width: 200,
        //       },
        //       legend: {
        //         position: "bottom",
        //       },
        //     },
        //   },
        // ],
      }}
      series={series}
    />
  </div>
));
