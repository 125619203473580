import { useState, useEffect } from "react";
import {
  Scrollable,
  Card,
  Disclose,
  IconButton,
  LoadingScreen,
  Select,
  CopyToClipBoard,
} from "./UI";
import {
  MonthYearTable,
  LabelValueListTable,
  monthLabelsForYear,
} from "./Table";
import { ApexBarChart } from "./ApexCharts";
import { asEuro } from "./euro";
import ClipboardJS from "clipboard";
import { WorkerSuspense } from "./Worker";

export const TreasuryTab = ({
  isAdmin,
  processed,
  statements,
  // engine,
  engines,
  years,
}) => {
  new ClipboardJS(".bi-clipboard");
  const [selectedYear, setSelectedYear] = useState(years && years[0]);
  const [showChart, setShowChart] = useState(false);
  const selectedYearIndex = years.indexOf(selectedYear);
  const selectedProcessed = processed && processed[selectedYearIndex];
  const selectedStatement = statements && statements[selectedYearIndex];
  const selectedEngine = engines && engines[selectedYearIndex];
  const args = [selectedProcessed, selectedStatement, selectedEngine];
  const toggleChart = (_) => setShowChart(!showChart);

  return (
    <>
      <Card className="my-2">
        <div className="float-end">
          <IconButton
            icon="bar-chart-line-fill"
            className="text-primary"
            onClick={toggleChart}
          ></IconButton>
        </div>
        <h3>
          Tesouraria{" "}
          <small className="text-muted">por principais subcontas</small>
        </h3>
        <WorkerSuspense
          fallback={<LoadingScreen />}
          workerPath="Treasury.worker.js"
          workerFn="cashCounterPartyTransactions"
          workerArgs={args}
        >
          {({ cashCounterPartyTransactions, cashData }) => <>
            <Disclose disclose={showChart}>
              <ApexBarChart
                header="Tesouraria mensal"
                stacked={true}
                monochrome={true}
                height={500}
                categories={cashCounterPartyTransactions.timeAxis.map(
                  (i) => `${i.month}-${i.year}`
                )}
                series={cashCounterPartyTransactions.data.map((i) => ({
                  name: `${i.prefix} - ${i.label}`,
                  data: i._totalForMonthYear.map((t) => asEuro(t || 0).value),
                }))}
              ></ApexBarChart>
            </Disclose>
            <Scrollable>
              <MonthYearTable
                id="treasury"
                topLeft={<div>
                  <Select
                    options={years}
                    selected={selectedYear}
                    onChange={setSelectedYear}
                  ></Select>
                  <CopyToClipBoard target="#treasury"></CopyToClipBoard>
                </div>}
                headers={cashCounterPartyTransactions.timeAxis.map(
                  (i) => `${i.month}-${i.year}`
                )}
                rowHeaders={[
                  "Caixa e depósitos bancários - início",
                  ...cashCounterPartyTransactions.data.map(
                    (i) => `${i.prefix} - ${i.label}`
                  ),
                  "Caixa e depósitos bancários - fim",
                ]}
                data={[
                  cashData.data._startBalanceForMonthYear,
                  ...cashCounterPartyTransactions.data.map(
                    (i) => i._totalForMonthYear
                  ),
                  cashData.data._endBalanceForMonthYear,
                ]}
              ></MonthYearTable>
            </Scrollable>
          </>}
        </WorkerSuspense>
      </Card>



      {isAdmin && <Card className="my-2">
        <h3>
          MOAF mensal{" "}
          <small className="text-muted">por principais items</small>
        </h3>
        <WorkerSuspense
          fallback={<LoadingScreen />}
          workerPath="Treasury.worker.js"
          workerFn="monthlyMoaf"
          workerArgs={args}
        >
          {(monthlyMoaf) =>
            <LabelValueListTable
              id="monthlymoaf"
              className="small"
              headers={[<div className="">
                <Select
                  options={years}
                  selected={selectedYear}
                  onChange={setSelectedYear}
                ></Select>
                <CopyToClipBoard target="#monthlymoaf"></CopyToClipBoard>
              </div>, ...monthLabelsForYear(selectedYear)]}
              data={monthlyMoaf}
              totals={totalsFor(monthlyMoaf)}
            // hideRowsFn={({ values, label, symbol }) =>
            //   !engine.isUncollapsable(symbol) &&
            //   (values || []).every((_val) => !_val || _val === "€ 0.00")
            // }
            ></LabelValueListTable>}
        </WorkerSuspense>
      </Card>}

    </>
  );
};

function totalsFor(monthlyMoaf) {
  return monthlyMoaf.map((item) => item.total);
}

