import { asEuro, euroFormat } from "./euro.js";
import { xml2js } from "xml-js";
import {
  getMinMax,
  generateMonthYears,
  isSameMonthYear,
  getMonthYear,
} from "./month-year.js";

export const XMLtoJson = (xml) => xml2js(xml, { compact: true });

const GroupingCategory = {
  GR: "GR",
  GA: "GA",
  GM: "GM",
  AR: "AR",
  AA: "AA",
  AM: "AM",
};

const getHeader = (saft) => saft.AuditFile.Header;

const getCustomers = (saft) =>
  Array.isArray(saft.AuditFile.MasterFiles.Customer)
    ? saft.AuditFile.MasterFiles.Customer
    : [saft.AuditFile.MasterFiles.Customer];

const getSuppliers = (saft) =>
  Array.isArray(saft.AuditFile.MasterFiles.Supplier)
    ? saft.AuditFile.MasterFiles.Supplier
    : [saft.AuditFile.MasterFiles.Supplier];

export const getTaxonomyReference = (saft) =>
  saft.AuditFile.MasterFiles.GeneralLedgerAccounts.TaxonomyReference._text;

const getAccounts = (saft) =>
  (saft.AuditFile.MasterFiles.GeneralLedgerAccounts &&
    saft.AuditFile.MasterFiles.GeneralLedgerAccounts.Account) || // for v1.04
  saft.AuditFile.MasterFiles.GeneralLedger; // for v1.03

const getJournals = (saft) =>
  saft.AuditFile.GeneralLedgerEntries &&
  saft.AuditFile.GeneralLedgerEntries.Journal;

const getHeaderDescription = (header) => ({
  companyId: header.CompanyID._text,
  companyName: header.CompanyName._text,
  fiscalYear: header.FiscalYear._text,
  startDate: header.StartDate._text,
  endDate: header.EndDate._text,
  taxRegistrationNumber: header.TaxRegistrationNumber._text,
  dateCreated: header.DateCreated._text,
  productId: header.ProductID._text,
  auditFileVersion: header.AuditFileVersion._text
});

const camelCase = (str) => {
  return str
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });
};

const getAccountDescription = (account) => ({
  accountId: account.AccountID._text,
  description: account.AccountDescription._text,
  openingDebitBalance: euroFormat(account.OpeningDebitBalance._text),
  openingCreditBalance: euroFormat(account.OpeningCreditBalance._text),
  openingBalance: asEuro(account.OpeningDebitBalance._text)
    .subtract(euroFormat(account.OpeningCreditBalance._text))
    .format(),
  closingDebitBalance: euroFormat(account.ClosingDebitBalance._text),
  closingCreditBalance: euroFormat(account.ClosingCreditBalance._text),
  closingBalance: asEuro(account.ClosingDebitBalance._text)
    .subtract(euroFormat(account.ClosingCreditBalance._text))
    .format(),
  taxonomyCode: account.TaxonomyCode && account.TaxonomyCode._text,
});

// const data = getMainAccountSummary(getGeneralLedger(saft)).map(
//   getAccountDescription
// );

/**
 * Extracts the journal id and description from a journal object
 * @param {object} journal
 */
const getDataFromJournal = (journal) => ({
  id: journal.JournalID._text,
  description: journal.Description._text,
});

/**
 * Extracts the period, description, supplierId or customerId from a specific transaction
 * @param {object} transaction
 */
const getDataFromTransaction = (transaction) => ({
  transactionId: transaction.TransactionID && transaction.TransactionID._text,
  period: parseInt(transaction.Period._text, 10),
  description: transaction.Description._text,
  supplierId: transaction.SupplierID && transaction.SupplierID._text,
  customerId: transaction.CustomerID && transaction.CustomerID._text,
  transactionDate:
    transaction.TransactionDate &&
    transaction.TransactionDate._text &&
    new Date(transaction.TransactionDate._text),
  glPostingDate:
    transaction.GLPostingDate &&
    transaction.GLPostingDate._text &&
    new Date(transaction.GLPostingDate._text),
});

const xml2JSAdapter = (transactionLine) =>
  transactionLine &&
  Object.keys(transactionLine).reduce(
    (obj, key) => ({ ...obj, [camelCase(key)]: transactionLine[key]._text }),
    {}
  );
/**
 * Extracts the accountId, creditAmount or debitAmount and systemEntryDate
 * from a transaction line and associates with the parent transaction and
 * journal
 * @param {object} line
 * @param {object} transaction
 * @param {object} journal
 */
const getDataFromLine = (line, transaction, journal, transactionLines) => ({
  transactionId: transaction.transactionId,
  _uuid:
    transaction.transactionId + " > " + (line.RecordID && line.RecordID._text),
  accountId: line.AccountID && line.AccountID._text,
  recordId: line.RecordID && line.RecordID._text,
  description: line.Description && line.Description._text,
  creditAmount: line.CreditAmount && asEuro(line.CreditAmount._text),
  debitAmount: line.DebitAmount && asEuro(line.DebitAmount._text),
  transactionDate: transaction.transactionDate,
  glPostingDate: transaction.glPostingDate,
  systemEntryDate:
    line.SystemEntryDate &&
    line.SystemEntryDate._text &&
    new Date(line.SystemEntryDate._text),
  transactionLines: transactionLines
    .map(xml2JSAdapter)
    .filter((l) => l)
    .map((line) => ({
      ...line,
      transactionDate: transaction.transactionDate,
      glPostingDate: transaction.glPostingDate,
      transactionId: transaction.transactionId,
      _uuid: transaction.transactionId + " > " + line.recordID,
    })),
  transaction,
  journal,
});

// const EXCLUDING_PERIODS = [13, 14, 15];

/**
 * Extracts the individual lines from within each
 * journal transactions to a single array of "movements"
 * @param {array} journals
 */
const transformJournalLinesIntoAccountMovements = (journals) =>
  journals.flatMap((journal) => {
    const _journal = getDataFromJournal(journal);
    if (Array.isArray(journal.Transaction) === false) {
      const transaction = journal.Transaction;
      if (!transaction) return [];
      const _transaction = getDataFromTransaction(transaction);
      const debitLine = transaction.Lines && transaction.Lines.DebitLine;
      const creditLine = transaction.Lines && transaction.Lines.CreditLine;
      const debitLines = Array.isArray(debitLine) ? debitLine : [debitLine];
      const creditLines = Array.isArray(creditLine) ? creditLine : [creditLine];
      const transactionLines = transaction.Lines
        ? [...debitLines, ...creditLines] // for v1.04
        : transaction.Line; // for v1.03
      return transactionLines.map((line) =>
        getDataFromLine(line, _transaction, _journal, transactionLines)
      );
    } else
      return journal.Transaction.flatMap((transaction) => {
        const _transaction = getDataFromTransaction(transaction);
        if (Array.isArray(transaction.Lines)) {
          console.warn(`Found an array in lines`);
          return [];
        } else {
          const debitLine = transaction.Lines && transaction.Lines.DebitLine;
          const creditLine = transaction.Lines && transaction.Lines.CreditLine;
          const debitLines = Array.isArray(debitLine) ? debitLine : [debitLine];
          const creditLines = Array.isArray(creditLine)
            ? creditLine
            : [creditLine];
          const transactionLines = transaction.Lines
            ? [...debitLines, ...creditLines] // for v1.04
            : transaction.Line; // for v1.03
          return transactionLines
            .filter((l) => l)
            .map((line) =>
              getDataFromLine(line, _transaction, _journal, transactionLines)
            );
        }
      });
  });

// console.log(data.map((description) => description.join("\t")).join("\n"));

const groupAccountMovementsByAccount = (movements) =>
  movements.reduce((index, mov) => {
    if (!index[mov.accountId]) {
      index[mov.accountId] = [mov];
    } else {
      index[mov.accountId].push(mov);
    }
    return index;
  }, {});

const getAccountSummariesFromGroupedAccountMovements = (
  accountIndex,
  excludePeriods = [],
  exceptionPrefixes = []
) =>
  Object.keys(accountIndex).map((accountId) =>
    accountIndex[accountId]
      .filter(
        (mov) =>
          exceptionPrefixes.some((prefix) => accountId.startsWith(prefix)) ||
          !excludePeriods.includes(mov.transaction.period)
      )
      .reduce(
        (summary, mov, _, transactions) => ({
          accountId,
          debitAmount: summary.debitAmount.add(mov.debitAmount || 0),
          creditAmount: summary.creditAmount.add(mov.creditAmount || 0),
          transactions,
        }),
        { accountId, debitAmount: asEuro(0), creditAmount: asEuro(0) }
      )
  );

const getAccountSummaries = (
  accounts,
  prefixes = [],
  category = GroupingCategory.GM
) =>
  accounts
    .filter((account) => account.GroupingCategory._text === category)
    .filter(
      (account) =>
        prefixes.length === 0 ||
        prefixes.some((prefix) => account.AccountID._text.startsWith(prefix))
    )
    .map(getAccountDescription);

export const getCustomerAndSupplierIndex = (saft) => {
  if (!saft) return {};
  // console.log({ saft });
  const customers = getCustomers(saft).map(xml2JSAdapter);
  const suppliers = getSuppliers(saft).map(xml2JSAdapter);
  // console.log({ customers, suppliers });
  const customerIndex = customers.reduce(
    (obj, customer) => ({ ...obj, [customer.customerID]: customer }),
    {}
  );
  const supplierIndex = suppliers.reduce(
    (obj, supplier) => ({ ...obj, [supplier.supplierID]: supplier }),
    {}
  );
  // console.log({ customerIndex, supplierIndex });
  return { customers, customerIndex, suppliers, supplierIndex };
};


const removeDuplicates = (array = [], criteria = 'some' | 'every', properties = []) => array.filter((e, i) => {
  return array.findIndex((x) => {
    return e && x && properties[criteria](property => x[property] === e[property]);
  }) === i;
});

export const getCustomerAndSupplierIndexFromMultiple = (safts) => {
  if (!safts) return {};
  // console.log({ saft });
  const customers = removeDuplicates(safts
    .flatMap((saft) => getCustomers(saft))
    .map(xml2JSAdapter), 'some', ['customerID', 'companyName']);

  const suppliers =
    removeDuplicates(safts
      .flatMap((saft) => getSuppliers(saft))
      .map(xml2JSAdapter), 'some', ['supplierID', 'companyName'])


  // console.log({ customers, suppliers });
  const customerIndex = customers.reduce(
    (obj, customer) => ({ ...obj, [customer.customerID]: customer }),
    {}
  );
  const supplierIndex = suppliers.reduce(
    (obj, supplier) => ({ ...obj, [supplier.supplierID]: supplier }),
    {}
  );
  // console.log({ customerIndex, supplierIndex });
  return { customers, customerIndex, suppliers, supplierIndex };
};

export const parseSAFTJson = (
  saft,
  accountPrefixes = [],
  excludePeriods = [],
  exceptionPrefixes = []
) => {
  const header = getHeaderDescription(getHeader(saft));
  const accounts = getAccounts(saft);
  const journals = getJournals(saft);
  console.log({ header });
  const isSupportedVersion = ["1.04"].some(v => header && header.auditFileVersion && header.auditFileVersion.includes(v));

  const missingCriticalInformation =
    header === undefined || accounts === undefined || journals === undefined || !isSupportedVersion;
  if (missingCriticalInformation)
    return { missingCriticalInformation, header, accounts, journals };

  const accountSummaries = getAccountSummaries(accounts, accountPrefixes);
  const transactionsGroupedByAccounts = groupAccountMovementsByAccount(
    transformJournalLinesIntoAccountMovements(journals)
  );
  const transactionSummaries = getAccountSummariesFromGroupedAccountMovements(
    transactionsGroupedByAccounts,
    excludePeriods,
    exceptionPrefixes
  )
    .filter(
      (account) =>
        accountPrefixes.length === 0 ||
        accountPrefixes.some((prefix) => account.accountId.startsWith(prefix))
    )
    .map(({ accountId, debitAmount, creditAmount, transactions }) => ({
      accountId,
      debitAmount: debitAmount.format(),
      creditAmount: creditAmount.format(),
      balance: debitAmount.subtract(creditAmount).format(),
      transactions,
    }));

  const allData = [...accountSummaries, ...transactionSummaries];

  const uniqueAccountIds = [
    ...new Set(allData.map((account) => account.accountId)),
  ];

  const codeSummaries = uniqueAccountIds.map((accountId) => {
    const accountSummary = accountSummaries.filter(
      (account) => account.accountId === accountId
    )[0];
    const transactionSummary = transactionSummaries.filter(
      (account) => account.accountId === accountId
    )[0];
    const closingCreditBalance = asEuro(
      (accountSummary && accountSummary.openingCreditBalance) || 0
    )
      .add((transactionSummary && transactionSummary.creditAmount) || 0)
      .format();
    const closingDebitBalance = asEuro(
      (accountSummary && accountSummary.openingDebitBalance) || 0
    )
      .add((transactionSummary && transactionSummary.debitAmount) || 0)
      .format();
    const closingBalance = asEuro(closingDebitBalance)
      .subtract(closingCreditBalance)
      .format();
    const openingDebitBalance = asEuro(
      (accountSummary && accountSummary.openingDebitBalance) || 0
    ).format();
    const openingCreditBalance = asEuro(
      (accountSummary && accountSummary.openingCreditBalance) || 0
    ).format();
    const openingBalance = asEuro(
      (accountSummary && accountSummary.openingDebitBalance) || 0
    )
      .subtract((accountSummary && accountSummary.openingCreditBalance) || 0)
      .format();
    return {
      accountId,
      accountSummary,
      transactionSummary,
      calculated: {
        closingDebitBalance,
        closingCreditBalance,
        closingBalance,
        openingDebitBalance,
        openingCreditBalance,
        openingBalance,
      },
    };
  });

  return { header, codeSummaries, transactionsGroupedByAccounts };
};

export const groupItemsByAccountId = (items = []) =>
  items.reduce((obj, item) => ({ ...obj, [item.accountId]: item }), {});
// console.log(parseSAFTJson(saft, ["11", "12"]).codeSummaries);

export const getSummariesForPrefixes = (summaries, prefixes = []) => {
  const _summaries = summaries.filter((s) =>
    prefixes.some((prefix) => s.accountId.startsWith(prefix))
  );
  return _summaries;
};

export const getBalanceSummariesForPrefixes = (summaries, prefixes = []) => {
  const balanceSummaries = summaries
    .filter((s) => prefixes.some((prefix) => s.accountId.startsWith(prefix)))
    .flatMap((s) => s.accountSummary);
  return balanceSummaries;
};

const getTransactionsForPrefixes_Fast = (
  summaries,
  prefixes = [],
  excludePeriods = [14, 15]
) => {
  const transactions = summaries
    .filter((a) => prefixes.some((prefix) => a.accountId.startsWith(prefix)))
    .flatMap((s) => s.transactionSummary)
    .flatMap((i) => i && i.transactions)
    .filter((t) => t)
    .filter((t) => !excludePeriods.includes(t.period));

  return transactions;
};

const getTotalsAndTransactionForPrefixes = (
  summaries,
  prefixes = [],
  excludePeriods = [14, 15]
) => {
  const transactions = getTransactionsForPrefixes_Fast(
    summaries,
    prefixes,
    excludePeriods
  );
  const total = transactions
    .reduce(
      (total, t) =>
        asEuro(total).add(
          asEuro(t.creditAmount || 0).subtract(asEuro(t.debitAmount || 0))
        ),
      asEuro(0)
    )
    .format();
  return { transactions, total };
};

const __unique = (array, prop) => {
  var bag = {};
  array.forEach((e) => {
    if (!bag[e[prop]]) bag[e[prop]] = e;
  });
  return Object.values(bag);
};

const ___unique = (array, propFn) => {
  var bag = {};
  array.forEach((e) => {
    if (!bag[e[propFn(e)]]) bag[e[propFn(e)]] = e;
  });
  return Object.values(bag);
};

export const _unique = (array, propertyName) =>
  array.filter(
    (e, i) => array.findIndex((a) => a[propertyName] === e[propertyName]) === i
  );

const getTransactionForSummaries = (summaries) => {
  return summaries
    .flatMap((s) => s.transactionSummary)
    .flatMap((i) => i && i.transactions);
};

const getTransactionsForPrefixes = (summaries, prefixes = []) => {
  const transactions = getTransactionForSummaries(summaries);

  const transactionsForPrefixes = transactions.filter(
    (t) => prefixes.some((prefix) => t && t.accountId && t.accountId.startsWith(prefix))
  );

  return transactionsForPrefixes;
};

export const getBalanceSummariesAndTransactionsForPrefixes = (
  summaries,
  prefixes = []
) => {
  const summaryInfo =
    summaries &&
    summaries
      .filter((s) => prefixes.some((prefix) => s.accountId.startsWith(prefix)))
      .flatMap((s) => ({
        accountId: s.accountId,
        accountSummary: s.accountSummary,
        transactions:
          (s.transactionSummary && __unique(s.transactionSummary.transactions.filter(t => t.accountId === s.accountId), "_uuid")) || []
        ,
      }));

  return summaryInfo;
};

export const getBalanceSummariesAndTransactionsForSummaries = (
  summaries = []
) => {
  const summaryInfo = summaries.flatMap(
    (s) =>
      s && {
        accountId: s.accountId,
        accountSummary: s.accountSummary,
        transactions:
          (s.transactionSummary && __unique(s.transactionSummary.transactions.filter(t => t.accountId === s.accountId), "_uuid")) || [],
      }
  );

  return summaryInfo;
};

export const getUniqueTransactionLinesForPrefix = (summaries, prefix) => {
  return getTransactionsForPrefixes(summaries, [prefix])
    .flatMap((t) => t.transactionLines)
    .filter((l) => l.accountID.startsWith(prefix))
    .filter(
      (l, i, lines) => lines.findIndex((_l) => _l._uuid === l._uuid) === i
    );
};

export const getSupplierRanking = (
  suppliers,
  summaries,
  prefixes = ["31", "62"]
) => {
  const transactionsForPrefixes = getTransactionsForPrefixes(
    summaries,
    prefixes
  );

  const _suppliers = [
    ...suppliers,
    { customerId: undefined, companyName: 'Bancos e outros não identificados' }
  ];

  const suppliersWithTransactions = _suppliers.map((supplier) => {
    const transactions = transactionsForPrefixes
      .filter((t) => t.transaction.supplierId === supplier.supplierID)

    transactions && transactions.length > 0 && console.log({ transactions });
    const transactionSummary = transactions.reduce(
      (total, t) => ({
        creditAmount: asEuro(total.creditAmount).add(t.creditAmount),
        debitAmount: asEuro(total.debitAmount).add(t.debitAmount),
      }),
      { debitAmount: 0, creditAmount: 0 }
    );
    transactionSummary.balance = asEuro(transactionSummary.debitAmount)
      .subtract(asEuro(transactionSummary.creditAmount))
      .format();
    transactions && transactions.length > 0 && console.log({ transactionSummary });
    return { ...supplier, transactions, transactionSummary };
  });

  const sortedSuppliersWithTransactions = suppliersWithTransactions.sort(
    (a, b) => {
      const _bal_a = asEuro(a.transactionSummary.balance);
      const _bal_b = asEuro(b.transactionSummary.balance);
      return _bal_b.intValue - _bal_a.intValue;
    }
  );
  return sortedSuppliersWithTransactions;
};

export const getCustomerRanking = (
  customers,
  summaries,
  prefixes = ["71", "72"]
) => {
  const transactionsForPrefixes = getTransactionsForPrefixes(
    summaries,
    prefixes
  );

  const _customers = [
    ...customers,
    { customerId: undefined, companyName: 'Outros não identificados' }
  ]

  const customersWithTransactions = customers.map((customer) => {
    const transactions = transactionsForPrefixes
      .filter((t) => t.transaction.customerId === customer.customerID);

    const transactionSummary = transactions.reduce(
      (total, t) => ({
        creditAmount: asEuro(total.creditAmount).add(t.creditAmount),
        debitAmount: asEuro(total.debitAmount).add(t.debitAmount),
      }),
      { debitAmount: 0, creditAmount: 0 }
    );
    transactionSummary.balance = asEuro(transactionSummary.creditAmount)
      .subtract(asEuro(transactionSummary.debitAmount))
      .format();
    return { ...customer, transactions, transactionSummary };
  });

  const sortedCustomersWithTransactions = customersWithTransactions.sort(
    (a, b) => {
      const _bal_a = asEuro(a.transactionSummary.balance);
      const _bal_b = asEuro(b.transactionSummary.balance);
      return _bal_b.intValue - _bal_a.intValue;
    }
  );
  return sortedCustomersWithTransactions;
};

export const getPersonnelCostBySubAccounts = (summaries) => {
  const personneCostAccounts = [
    { prefix: "631", label: "Remunerações dos órgãos sociais" },
    { prefix: "632", label: "Remunerações do pessoal" },
    { prefix: "633", label: "Benefícios pós-emprego" },
    { prefix: "634", label: "Indemnizações" },
    { prefix: "635", label: "Encargos sobre remunerações" },
    {
      prefix: "636",
      label: "Seguros de acidentes no trabalho e doenças profissionais",
    },
    { prefix: "637", label: "Gastos de acção social" },
    { prefix: "638", label: "Outros gastos com o pessoal" },
  ];

  const transactionsForPrefixes = personneCostAccounts.map((account) => ({
    label: account.label,
    prefix: account.prefix,
    ...getTotalsAndTransactionForPrefixes(summaries, [account.prefix]),
  }));
  return transactionsForPrefixes;
};

export const getDepreciationBySubAccounts = (summaries) => {
  const depreciationAccounts = [
    { prefix: "641", label: "Propriedades de investimento" },
    { prefix: "642", label: "Activos fixos tangíveis" },
    { prefix: "643", label: "Activos intangíveis" },
  ];

  const transactionsForPrefixes = depreciationAccounts.map((account) => ({
    label: account.label,
    prefix: account.prefix,
    ...getTotalsAndTransactionForPrefixes(summaries, [account.prefix]),
  }));
  return transactionsForPrefixes;
};

export const getOtherExpenseBySubAccounts = (summaries) => {
  const otherCostAccounts = [
    { prefix: "681", label: "Impostos" },
    { prefix: "682", label: "Descontos PP obtidos" },
    { prefix: "683", label: "Dividas incobráveis" },
    { prefix: "684", label: "Perdas em inventários" },
    { prefix: "685", label: "Gastos e perdas em subsidárias" },
    { prefix: "686", label: "Gastos e perdas em investimentos financeiros" },
    {
      prefix: "687",
      label: "Gastos e perdas em investimentos não financeiros",
    },
    { prefix: "688", label: "Outros" },
  ];

  const transactionsForPrefixes = otherCostAccounts.map((account) => ({
    label: account.label,
    prefix: account.prefix,
    ...getTotalsAndTransactionForPrefixes(summaries, [account.prefix]),
  }));
  return transactionsForPrefixes;
};
export const getFinanceExpenseBySubAccounts = (summaries) => {
  const otherCostAccounts = [
    { prefix: "691", label: "Juros suportados" },
    { prefix: "692", label: "Diferenças de câmbio desfavoráveis" },
    { prefix: "693", label: "Outros gastos de financiamento" },
  ];

  const transactionsForPrefixes = otherCostAccounts.map((account) => ({
    label: account.label,
    prefix: account.prefix,
    ...getTotalsAndTransactionForPrefixes(summaries, [account.prefix]),
  }));
  return transactionsForPrefixes;
};

export const getInventorySubAccounts = (summaries) => {
  const inventoryAccounts = [
    { prefix: "32", label: "Mercadorias" },
    { prefix: "33", label: "Matérias-primas, ..." },
    { prefix: "34", label: "Produtos acabados e intermédios" },
    { prefix: "35", label: "Subprodutos, ..." },
    { prefix: "36", label: "Produtos e trabalhos em curso" },
    { prefix: "37", label: "Activos biológicos" },
  ];

  const transactionsForPrefixes = inventoryAccounts.map((account) => ({
    label: account.label,
    prefix: account.prefix,
    transactions: getTransactionsForPrefixes_Fast(summaries, [account.prefix]),
  }));

  return transactionsForPrefixes;
};

const forPath = (path) => (obj) => {
  const comps = path.split(".");
  return comps.reduce((parent, comp) => parent && parent[comp], obj);
};

const Accounts = {
  11: "Caixa",
  12: "Depósitos à ordem",
  13: "Outros depósitos à ordem",
  14: "Outros instrumentos financeiros",
  21: "Clientes",
  22: "Fornecedores",
  23: "Pessoal",
  24: "Estados e outros entes públicos",
  25: "Financiamentos obtidos",
  26: "Accionistas / Sócios",
  27: "Outras contas a receber e a pagar",
  28: "Diferimentos",
  29: "Provisões",
  31: "Compras",
  32: "Mercadorias",
  33: "Matérias-primas, ...",
  34: "Produtos acabados e intermédios",
  35: "Subprodutos, ...",
  36: "Produtos e trabalhos em curso",
  37: "Activos biológicos",
  38: "Reclassificação e regularização de inventários / activos biológicos",
  39: "Adiantamentos por contas de compras",
  41: "Investimentos financeiros",
  42: "Propriedades de Investimento",
  43: "Activos Fixos Tangíveis",
  44: "Activos intangíveis",
  45: "Investimentos em curso",
  46: "Activos não correntes detidos para venda",
  51: "Capital",
  52: "Acções (quotas) próprias",
  53: "Outros instrumentos de capital próprio",
  54: "Prémios de emissão",
  55: "Reservas",
  56: "Resultados transitados",
  57: "Ajustamentos em activos financeiros",
  58: "Excedentes de revalorização de activos fixos tangíveis e intangíveis",
  59: "Outras variações de capital próprio",
  71: "Vendas",
  72: "Prestações de Serviços",
  73: "Variações nos inventários da produção",
  74: "Trabalhos para a própria entidade",
  75: "Subsídios à exploração",
  61: "CMVMC",
  62: "FSE",
  63: "Gastos com o pessoal",
  64: "Depreciação / Amortização",
  76: "Reversões",
  65: "Perdas por imparidade",
  77: "Ganhos por aumento do justo valor",
  66: "Perdas por reduções do justo valor",
  67: "Provisões",
  78: "Outros rendimentos e ganhos",
  68: "Outros gastos e perdas",
  79: "Juros, dividendos e outros rendimentos similares",
  69: "Gastos e perdas de financiamento",
  81: "Resultado líquido do período",
  89: "Dividendos antecipados",
};

export const getMonthlyProfitAndLoss = (summaries) => {
  const accounts = [
    { prefix: "71", label: "Vendas" },
    { prefix: "72", label: "Prestações de Serviços" },
    { prefix: "73", label: "Variações nos inventários da produção" },
    { prefix: "74", label: "Trabalhos para a própria entidade" },
    { prefix: "75", label: "Subsídios à exploração" },
    {
      prefix: "61",
      label: "CMVMC",
    },
    { prefix: "62", label: "FSE" },
    { prefix: "63", label: "Gastos com o pessoal" },
    { prefix: "64", label: "Depreciação / Amortização" },
    { prefix: "76", label: "Reversões" },
    { prefix: "65", label: "Perdas por imparidade" },
    { prefix: "77", label: "Ganhos por aumento do justo valor" },
    { prefix: "66", label: "Perdas por reduções do justo valor" },
    { prefix: "67", label: "Provisões" },
    { prefix: "78", label: "Outros rendimentos e ganhos" },
    { prefix: "68", label: "Outros gastos e perdas" },
    { prefix: "79", label: "Juros, dividendos e outros rendimentos similares" },
    { prefix: "69", label: "Gastos e perdas de financiamento" },
  ];

  const transactionsForPrefixes = accounts.map((account) => ({
    label: account.label,
    prefix: account.prefix,
    transactions: getTransactionsForPrefixes_Fast(summaries, [account.prefix]),
  }));
  return transactionsForPrefixes;
};

export const getTurnoverBySubaccount = (summaries) => {
  const accounts = [
    { prefix: "71", label: "Vendas" },
    { prefix: "711", label: "Mercadorias" },
    { prefix: "712", label: "Produtos acabados e intermédios" },
    { prefix: "713", label: "Subprodutos, desperdícios, resíduos e refugos" },
    { prefix: "714", label: "Activos biológicos" },
    { prefix: "717", label: "Devoluções" },
    { prefix: "718", label: "Descontos e abatimentos" },
    { prefix: "72", label: "Prestações de serviços" },
    { prefix: "728", label: "Descontos e abatimentos" },
  ];

  const transactionsForPrefixes = accounts.map((account) => ({
    label: account.label,
    prefix: account.prefix,
    ...getTotalsAndTransactionForPrefixes(summaries, [account.prefix]),
  }));
  return transactionsForPrefixes;
};

export const ExternalServiceAccounts = [
  { prefix: "621", label: "Subcontratos" },
  { prefix: "6221", label: "Trabalhos especializados" },
  { prefix: "6222", label: "Publicidade e propaganda" },
  { prefix: "6223", label: "Vigilância e segurança" },
  { prefix: "6224", label: "Honorários" },
  { prefix: "6225", label: "Comissões" },
  { prefix: "6226", label: "Conservação e reparação" },
  { prefix: "6228", label: "Outros" },
  { prefix: "623", label: "Materiais" },
  { prefix: "6241", label: "Electricidade" },
  { prefix: "6242", label: "Combustível" },
  { prefix: "6243", label: "Água" },
  { prefix: "6248", label: "Outros" },
  { prefix: "6251", label: "Deslocações e estadas" },
  { prefix: "6252", label: "Transportes de pessoal" },
  { prefix: "6253", label: "Transportes de mercadorias" },
  { prefix: "6258", label: "Outros" },
  { prefix: "6261", label: "Rendas e Alugueres" },
  { prefix: "6262", label: "Comunicação" },
  { prefix: "6263", label: "Seguros" },
  { prefix: "6264", label: "Royalties" },
  { prefix: "6265", label: "Contencioso e notariado" },
  { prefix: "6266", label: "Despesas de representação" },
  { prefix: "6267", label: "Limpeza, higiene e conforto" },
  { prefix: "6268", label: "Outros serviços" },
];


export const getExternalServicesByNature = (summaries) => {
  const accounts = ExternalServiceAccounts;

  const transactionsForPrefixes = accounts.map((account) => ({
    label: account.label,
    prefix: account.prefix,
    ...getTotalsAndTransactionForPrefixes(summaries, [account.prefix]),
  }));
  return transactionsForPrefixes;
};

export const groupByLabels = (
  arrayOfArrays,
  groupKey = "label",
  valueKey = "total"
) => {
  const groupKeys = [
    ...new Set(arrayOfArrays.flatMap((array) => array.map((i) => i[groupKey]))),
  ];
  console.log({ groupKeys });
  const grouped = groupKeys.map((key) => ({
    [groupKey]: key,
    values: arrayOfArrays.map(
      (array) => array.filter((i) => i[groupKey] === key)[0][valueKey]
    ),
  }));
  return grouped;
};

export const getCashTransactions = (
  summaries,
  cashPrefixes = ["11", "12", "13"]
) => {
  const cashTransactionsLines = getTransactionsForPrefixes_Fast(
    summaries,
    cashPrefixes
  );

  const cashCounterPartLines = cashTransactionsLines
    .filter((t) => t)
    .flatMap((t) => t.transactionLines)
    .filter((l) =>
      cashPrefixes.some((prefix) => !l.accountID.startsWith(prefix))
    );

  const uniquecashCounterPartLines = __unique(cashCounterPartLines, "_uuid");

  const counterPrefixes = [
    ...new Set(
      uniquecashCounterPartLines.map((l) => l.accountID.substring(0, 2))
    ),
  ].sort((a, b) => parseInt(a) - parseInt(b));

  const counterPrefixesExcludingCash = counterPrefixes.filter(
    (prefix) => !cashPrefixes.includes(prefix)
  );

  const transactionsForCounterPrefixes = counterPrefixesExcludingCash.map(
    (prefix) => ({
      prefix,
      label: Accounts[prefix],
      transactions: uniquecashCounterPartLines.filter((l) =>
        l.accountID.startsWith(prefix)
      ),
    })
  );

  return transactionsForCounterPrefixes;
};

const getStartAndEndDatesForYear = (year) => {
  const start = year && new Date(`${year}-01-01T00:00:00`);
  const end = year && new Date(`${year}-12-31T23:59:59`);
  return { start, end };
};

export const transactionsGroupedByYears = (
  transactions,
  years,
  dateField = DateField.TransactionDate
) =>
  years.map((year) =>
    transactions
      .filter(
        (t) =>
          t[dateField.key] &&
          new Date(t[dateField.key]).getFullYear() === parseInt(year, 10)
      )
      .reduce(
        (total, t) =>
          asEuro(total)
            .add(asEuro(t.creditAmount || 0))
            .subtract(asEuro(t.debitAmount || 0)),
        asEuro(0)
      )
      .format()
  );

export const transactionsGroupedByMonthYears = (
  list,
  { forcePositive = false, invertSignal = true, forceYear = undefined },
  dateField = DateField.TransactionDate
) => {
  // forceYear && console.log({ forceYear });
  // console.log({ dateField });
  // console.log({ list });
  const _dateMatrix = list.map((item) =>
    item.transactions.map((t) => t && t[dateField.key]).filter((d) => d)
  );
  const { min, max } = getMinMax(_dateMatrix);
  // console.log(_dateMatrix, min, max);
  const { start, end } = getStartAndEndDatesForYear(forceYear);
  // forceYear && console.log({ start, end, min, max });
  const timeAxis = generateMonthYears(start || min, end || max);
  // console.log(timeAxis);
  if (!timeAxis) {
    return undefined;
  }
  // console.log({ timeAxis });
  const data = list.map((item) => {
    const _totalForMonthYear = timeAxis
      .map((axisMonthYear) =>
        item.transactions
          .filter(
            (t) =>
              t &&
              isSameMonthYear(axisMonthYear, getMonthYear(t[dateField.key]))
          )
          .reduce(
            (total, i) =>
              asEuro(total)
                .add(asEuro(i.debitAmount || 0))
                .subtract(asEuro(i.creditAmount || 0)),
            asEuro(0)
          )
      )
      .map((total) =>
        invertSignal || (forcePositive && total.value < 0)
          ? asEuro(0).subtract(total)
          : total
      )
      .map((total) => total.format());

    return { ...item, _totalForMonthYear };
  });

  return { data, timeAxis };
};

export const startAndEndPositionsForMonthYears = ({ data, timeAxis }) => {
  return {
    timeAxis,
    data: data
      .map((item) => {
        const _openingBalance = item.accountSummary.openingBalance;
        return {
          ...item,
          _startBalanceForMonthYear: item._totalForMonthYear.map(
            (v, o, values) =>
              values
                .slice(0, o)
                .reduce(
                  (p, _v) => asEuro(p).add(asEuro(_v)).format(),
                  _openingBalance
                )
          ),
          _endBalanceForMonthYear: item._totalForMonthYear.map((v, o, values) =>
            values
              .slice(0, o + 1)
              .reduce(
                (p, _v) => asEuro(p).add(asEuro(_v)).format(),
                _openingBalance
              )
          ),
        };
      })
      .map((item) => ({
        ...item,
        _deltaBalanceForMonthYear: asEuro(item._endBalanceForMonthYear)
          .subtract(item._startBalanceForMonthYear)
          .format(),
      })),
  };
};

export const monthYearsTotals = ({ timeAxis, data }) => {
  return {
    timeAxis,
    data: {
      _totalForMonthYear: timeAxis.map((_, o) =>
        data
          .map((i) => i._totalForMonthYear && i._totalForMonthYear[o])
          .reduce((p, v) => asEuro(p).add(v).format(), 0)
      ),
      _startBalanceForMonthYear: timeAxis.map((_, o) =>
        data
          .map(
            (i) => i._startBalanceForMonthYear && i._startBalanceForMonthYear[o]
          )
          .reduce((p, v) => asEuro(p).add(v).format(), 0)
      ),
      _endBalanceForMonthYear: timeAxis.map((_, o) =>
        data
          .map((i) => i._endBalanceForMonthYear && i._endBalanceForMonthYear[o])
          .reduce((p, v) => asEuro(p).add(v).format(), 0)
      ),
      _deltaBalanceForMonthYear: timeAxis.map((_, o) =>
        data
          .map(
            (i) => i._deltaBalanceForMonthYear && i._deltaBalanceForMonthYear[o]
          )
          .reduce((p, v) => asEuro(p).add(v).format(), 0)
      ),
    },
  };
};

export const getConcentrationList = ({
  data,
  labelKey,
  valueKey,
  transform,
}) => {
  // build simple list
  const getLabel = forPath(labelKey);
  const getValue = forPath(valueKey);
  const _transformValue = (_value) => (transform ? transform(_value) : _value);
  const list = data.map((item) => ({
    label: getLabel(item),
    value: _transformValue(getValue(item)),
  }));
  // add running totals
  list.forEach((item, offset, _list) => {
    item.runningTotal =
      offset === 0 ? item.value : _list[offset - 1].runningTotal + item.value;
  });
  // add percent of total and percent of running total
  const grandTotal = list[list.length - 1].runningTotal;
  list.forEach((item) => {
    item.percent = item.value / grandTotal;
    item.accumPercent = item.runningTotal / grandTotal;
  });
  return list;
};

export const getCommentsForConcentrationList = ({
  list,
  singular,
  plural,
  label,
}) => {
  const pct = (x) => `${(x * 100).toFixed(0)}%`;
  const totalItems = list.length;
  const nonZeroItems = list.filter((i) => i.value > 0);
  const top = nonZeroItems.slice(0, 3);
  const listSub50 = nonZeroItems.findIndex((i) => i.accumPercent >= 0.5);
  const listSub80 = nonZeroItems.findIndex((i) => i.accumPercent >= 0.8);

  var comments = [];

  comments.push(
    `Top ${top.length} (${pct(
      top.length / nonZeroItems.length
    )}) ${plural} representam ${pct(
      top[top.length - 1].accumPercent
    )} dos ${label}`
  );

  comments.push(
    `${listSub50 + 1} (${pct(
      (listSub50 + 1) / nonZeroItems.length
    )}) dos ${plural} representam ~50% dos ${label}`
  );
  comments.push(
    `${listSub80 + 1} (${pct(
      (listSub80 + 1) / nonZeroItems.length
    )}) dos ${plural} representam ~80% dos ${label}`
  );

  comments.push(`${totalItems} ${plural} analisados`);
  comments.push(
    `${nonZeroItems.length} (${pct(
      nonZeroItems.length / totalItems
    )})dos ${plural} tem 0 valor`
  );

  return comments;
};

export const DateField = {
  TransactionDate: { key: "transactionDate", label: "Transacção" },
  GLPostingDate: { key: "glPostingDate", label: "Posting" },
  SystemEntryDate: { key: "systemEntryDate", label: "Entrada em sistema" },
};

const summariesByMonth = (
  summaries,
  year,
  dateField = DateField.TransactionDate
) => {
  const { start, end } = getStartAndEndDatesForYear(year);
  const monthYears = generateMonthYears(start, end);
  console.log({ start, end, monthYears, year });
  return (
    summaries &&
    summaries.map((summary) => {
      const isProfitAndLoss = ["6", "7", "8"].some(
        (prefix) => summary && summary.accountId.startsWith(prefix)
      );
      const _transactions =
        summary &&
        summary.transactionSummary &&
        summary.transactionSummary.transactions;
      const _monthlyTransactions =
        _transactions &&
        monthYears &&
        monthYears.map((monthYear) =>
          _transactions.filter((t) =>
            isSameMonthYear(monthYear, getMonthYear(t[dateField.key]))
          )
        );
      const _monthlyTransactionSums =
        _monthlyTransactions &&
        _monthlyTransactions.map(
          (mts) =>
            mts &&
            mts.reduce(
              (total, mt) => ({
                debitAmount: asEuro(total.debitAmount)
                  .add(asEuro(mt.debitAmount))
                  .format(),
                creditAmount: asEuro(total.creditAmount)
                  .add(asEuro(mt.creditAmount))
                  .format(),
              }),
              { creditAmount: asEuro(0), debitAmount: asEuro(0) }
            )
        );
      const _monthlySummaries =
        (_monthlyTransactionSums &&
          _monthlyTransactionSums.map((mts, offset, all = []) => {
            const startDebitAmount = all
              .slice(0, offset)
              .reduce(
                (partial, _mts) =>
                  asEuro(partial).add(asEuro(_mts.debitAmount)),
                asEuro(0)
              )
              .format();
            const endDebitAmount = asEuro(startDebitAmount)
              .add(mts.debitAmount)
              .format();
            const startCreditAmount = all
              .slice(0, offset)
              .reduce(
                (partial, _mts) =>
                  asEuro(partial).add(asEuro(_mts.creditAmount)),
                asEuro(0)
              )
              .format();
            const endCreditAmount = asEuro(startCreditAmount)
              .add(mts.creditAmount)
              .format();
            const openingDebitBalance =
              summary &&
              summary.accountSummary &&
              asEuro(summary.accountSummary.openingDebitBalance)
                .add(startDebitAmount)
                .format();
            const openingCreditBalance =
              summary &&
              summary.accountSummary &&
              asEuro(summary.accountSummary.openingCreditBalance)
                .add(startCreditAmount)
                .format();
            const openingBalance = asEuro(openingDebitBalance)
              .subtract(asEuro(openingCreditBalance))
              .format();
            const closingDebitBalance =
              summary &&
              summary.accountSummary &&
              asEuro(summary.accountSummary.openingDebitBalance)
                .add(endDebitAmount)
                .format();
            const closingCreditBalance =
              summary &&
              summary.accountSummary &&
              asEuro(summary.accountSummary.openingCreditBalance)
                .add(endCreditAmount)
                .format();
            const closingBalance = asEuro(closingDebitBalance)
              .subtract(asEuro(closingCreditBalance))
              .format();
            const monthIncrementalBalance = asEuro(mts.debitAmount)
              .subtract(asEuro(mts.creditAmount))
              .format();
            return {
              accountId: summary.accountId,
              accountSummary: {
                ...summary.accountSummary,
                startDebitAmount,
                endDebitAmount,
                startCreditAmount,
                endCreditAmount,
                openingDebitBalance,
                openingCreditBalance,
                openingBalance,
                closingDebitBalance,
                closingCreditBalance,
                closingBalance,
              },
              calculated: {
                openingBalance: isProfitAndLoss ? 0 : openingBalance,
                closingBalance: isProfitAndLoss
                  ? monthIncrementalBalance
                  : closingBalance,
              },
            };
          })) ||
        monthYears.map((_) => ({
          accountSummary: summary.accountSummary,
          calculated: summary.calculated,
        }));
      return {
        ...summary,
        _monthlyTransactions,
        _monthlyTransactionSums,
        _monthlySummaries,
      };
    })
  );
};

const matchArraysInArrayByOffsets = (arrayOfArrays) => {
  const firstArray = arrayOfArrays && arrayOfArrays[0];
  const zipped =
    firstArray &&
    firstArray.map((_, o) => arrayOfArrays.map((_arr) => _arr && _arr[o]));
  return zipped;
};

export const summariesByMonth2 = (
  summaries,
  year,
  dateField = DateField.TransactionDate
) => {
  const _pre = summariesByMonth(summaries, year, dateField);
  const _justMonthly = _pre.map((s) => s && s._monthlySummaries);
  const matched = matchArraysInArrayByOffsets(_justMonthly);
  return matched;
};

const isDecember31st = (date) => {
  const dayOfMonth = date && date.getDate();
  const month = date && date.getMonth() + 1;
  return dayOfMonth === 31 && month === 12;
};

export const isNotFullYear = (processed) =>
  !isDecember31st(new Date(processed.header.endDate));
