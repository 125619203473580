import React, { useState, useEffect } from "react";
import firebase, { firestore } from "firebase";

firebase.initializeApp({
  apiKey: "AIzaSyBFZCLTtXXdzUj8pd41qosg92TqBx8stsI",
  authDomain: "reporting-9a91d.firebaseapp.com",
  databaseURL: "https://reporting-9a91d.firebaseio.com",
  projectId: "reporting-9a91d",
  storageBucket: "reporting-9a91d.appspot.com",
  messagingSenderId: "88302622698",
  appId: "1:88302622698:web:cec159e84101a925fc8493",
  measurementId: "G-PKXHJFGJY5",
});

firebase.analytics();

firebase.database.enableLogging(true);

window.___logout = firebase.auth().signOut;

const noop = () => { };

const functions = firebase.functions();

export const useUser = (onLoginCallback = noop) => {
  const [user, setUser] = useState(undefined);
  const [isLoggingIn, setLoggingIn] = useState(false);
  const [errorLoggingIn, setErrorLoggingIn] = useState(false);

  // setup login detection
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      setUser(user);
      onLoginCallback && onLoginCallback(user);
    } else {
      setUser(undefined);
    }
  });

  const login = (email, password) => {
    setLoggingIn(true);
    setErrorLoggingIn(false);
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((...results) => {
        console.log(results);
        setLoggingIn(false);
      })
      .catch((error) => {
        setErrorLoggingIn(true);
        setLoggingIn(false);
      });
  };

  const logout = (_) => firebase.auth().signOut();

  return { user, login, isLoggingIn, errorLoggingIn, logout };
};

export const Login = ({ onSubmit, disabled, error, className }) => {
  const [credentials, setCredentials] = useState({
    email: undefined,
    password: undefined,
  });
  const forwardSubmitEvent = (event) => {
    event.preventDefault();
    onSubmit(credentials);
  };
  const updateEmail = (event) => setCredentials({ ...credentials, email: event.target.value });
  const updatePassword = (event) => setCredentials({ ...credentials, password: event.target.value });

  return (
    <div
      className={`d-flex flex-column text-center justify-content-center ${className || ""
        }`}
    >
      <div>
        <form
          onSubmit={forwardSubmitEvent}
        >
          {error && (
            <div className="alert alert-danger py-4 ">
              Erro ao tentar entrar. Tente outra vez.
            </div>
          )}
          <div className="form-group">
            <small id="emailHelp" className="form-text text-muted text-center">
              Faça login para utilizar a aplicação
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Email</label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              disabled={disabled}
              onChange={updateEmail}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">Password</label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              disabled={disabled}
              onChange={updatePassword}
            />
          </div>
          <div className="mt-2">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={disabled}
            >
              Entrar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

/**
 * TODO: Unfinnished
 * @param {string} collectionPath
 */
export const useFirestoreCollection = (collectionPath) => {
  const [path] = useState(collectionPath);
  const [ref, setRef] = useState();
  const [items, setItems] = useState();

  useEffect(() => {
    setRef(firestore().collection(path));
  }, [path]);

  // Add a new document with a generated id.
  const add = (data) =>
    ref
      .add({
        ...data,
      })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        return docRef.id;
      });

  const getItemsWhere = (property, condition, value) =>
    ref
      .where(property, condition, value)
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      })
      .then(setItems);

  return { add, getItemsWhere, items, isOn: !!ref };
};

export const useFirestoreDoc = (
  initialCollectionPath,
  initialDocPath,
  initialData
) => {
  const [collectionPath, setCollectionPath] = useState(initialCollectionPath);
  const [docPath, _setDocPath] = useState(initialDocPath);
  const [ref, setRef] = useState();
  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (collectionPath && docPath)
      setRef(firestore().collection(collectionPath).doc(docPath));
  }, [collectionPath, docPath]);

  useEffect(() => {
    if (ref) {
      ref.get().then((doc) => {
        if (doc.exists) {
          setData(doc.data());
        } else {
          setData(undefined);
        }
      });
    }
  }, [ref]);

  const setPath = (collectionPath, docPath) => {
    setCollectionPath(collectionPath);
    _setDocPath(docPath);
    setRef(firestore().collection(collectionPath).doc(docPath));
  };

  const setDocPath = (docPath) => {
    _setDocPath(docPath);
    setRef(firestore().collection(collectionPath).doc(docPath));
  };

  const set = (data) => {
    setData(data);
    ref && ref.set({ ...data });
  };

  const get = () => {
    ref &&
      ref.get().then((doc) => {
        if (doc.exists) {
          setData(doc.data());
        } else {
          setData(undefined);
        }
      });
  };

  return { data, ref, setPath, setDocPath, get, set };
};

export const callCloudFunction = (fnName, args) =>
  functions
    .httpsCallable(fnName)(args)
    .then((result) => result.data);
