import React from "react";

export const PageContainer = ({ children }) => (
  <div className="d-flex flex-column w-100 text-left">{children}</div>
);

export const Scrollable = ({ children }) => (
  <div className="overflow-scroll">{children}</div>
);

export const Badge = ({ children, className, onClick, ...others }) => (
  <span
    className={`badge ${className || "black-accent text-white"} ${onClick ? "clickable" : ""
      }`}
    onClick={onClick}
    {...others}
  >
    {children}
  </span>
);

export const Tabs = ({
  className,
  tabHeaders,
  selectedTab,
  onTabClick,
  right,
}) => (
  <ul className={`nav nav-pills ${className || ""}`}>
    {tabHeaders &&
      tabHeaders.map((tabHeader, o) =>
        typeof tabHeader === "string" ? (
          <li
            className="nav-item"
            key={o}
            onClick={handleTabClick(onTabClick, tabHeader)}
          >
            <a
              className={`nav-link ${tabHeader === selectedTab ? "active" : ""
                }`}
              aria-current="page"
              href="#/"
            >
              {tabHeader}
            </a>
          </li>
        ) : (
          <li className="nav-item">{tabHeader}</li>
        )
      )}
    {right && <li className="nav-item">{right}</li>}
  </ul>
);

export const Card = ({ header, children, className }) => (
  <div className={`w-100 ${className || ""}`}>
    <div className="card">
      {header && <div className="card-header">{header}</div>}
      <div className="card-body">{children}</div>
    </div>
  </div>
);

export const MapSelect = ({ fields = {}, selectedField, onFieldChange }) => (
  <Select
    options={Object.entries(fields).map(([key, { label }]) => ({
      label,
      value: key,
    }))}
    selected={
      Object.keys(fields).filter((key) => fields[key] === selectedField)[0]
    }
    onChange={handleFieldChange(onFieldChange, fields)}
  ></Select>
);

export const Select = ({
  options = [],
  selected = undefined,
  onChange,
  ...others
}) => (
  <select
    value={selected}
    onChange={handleSelection(onChange)}
    {...others}
  >
    {options.map((option, offset) => (
      <option key={offset} value={option.value || option.label || option}>
        {option.label || option.value || option}
      </option>
    ))}
  </select>
);

export const Disclose = ({ children, disclose, onClick = () => { } }) => (
  <div className={!disclose && "d-none"} onClick={onClick}>
    {children}
  </div>
);

export const Icon = ({ icon, ...others }) => (
  <i className={"bi bi-" + icon} {...others}></i>
);

export const IconButton = ({ icon, className, onClick }) => (
  <button className={["btn", className].join(" ")} onClick={onClick}>
    <i className={"bi bi-" + icon}></i>
  </button>
);

export const InputGroup = ({
  className,
  type,
  prefix,
  placeholder,
  value,
  onChange,
}) => (
  <div className={`input-group mb-3 ${className || ""}`}>
    {prefix && <span className="input-group-text">{prefix}</span>}
    <input
      type={type || "text"}
      className="form-control"
      placeholder={placeholder}
      value={value}
      onChange={handleChange(onChange)}
    />
  </div>
);

export const Spinner = () => (
  <div className="spinner-border" role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
);

export const LoadingScreen = () => (
  <div className="d-flex flex-column alert alert-info text-center">
    <span className="me-2">
      <Spinner></Spinner>
    </span>{" "}
    <span className="">A processar ...</span>
  </div>
);

// Needs clipboardjs to work
export const CopyToClipBoard = ({ target }) => {
  return (
    <i
      className="bi bi-clipboard clickable text-primary"
      data-clipboard-target={target}
    ></i>
  );
};

export const MailTo = ({
  emailTo,
  emailCc,
  emailBcc,
  subject,
  body,
  children,
  className,
  tooltip,
}) => (
  <a
    className={`${className || ""}`}
    title={tooltip}
    href={`mailto:${emailTo}?cc=${emailCc}&subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`}
  >
    {children}
  </a>
);
function handleChange(onChange) {
  return (e) => onChange(e.target.value);
}

function handleSelection(onChange) {
  return (event) => onChange(event.target.value);
}

function handleFieldChange(onFieldChange, fields) {
  return (v) => onFieldChange(fields[v]);
}

function handleTabClick(onTabClick, tabHeader) {
  return (_) => onTabClick(tabHeader);
}

