import { asEuro, euroFormat, addAll, addAllAndChangeSignal } from "./euro";
import {
  StatementTree,
  TaxonomyRules,
  StatementOrdering,
} from "./microentities-rules";

/**
 * Builds accounting statements (income statement and balance statement)
 * for a micro entity based on the summaries of its accounts
 * @param {array} summaries
 * @param {array} rules
 * @returns
 */
export const buildStatementsFromSummaries = (
  summaries, // saft summaries
  rules = TaxonomyRules // default to micro entities
) => {
  const report = organizeSummariesWithRules(
    rules,
    summaries,
    BalanceType.Closing
  );

  const previousReport = organizeSummariesWithRules(
    rules,
    summaries,
    BalanceType.Opening
  );

  addTotalsToReport(report, BalanceType.Closing);
  addTotalsToReport(previousReport, BalanceType.Opening);

  const balanceSheetStatement = buildBalanceSheetStatement(
    report,
    BalanceType.Closing
  );

  const previousBalanceSheetStatement = buildBalanceSheetStatement(
    previousReport,
    BalanceType.Opening
  );

  const incomeStatement = buildIncomeStatement(report);
  return {
    balanceSheetStatement,
    previousBalanceSheetStatement,
    incomeStatement,
    report,
    previousReport,
  };
};

const organizeSummariesWithRules = (
  rules,
  summaries,
  balanceType = BalanceType.Closing
) => {
  const ruleIndex = rules.reduce(
    (index, rule) => ({ ...index, [rule.taxonomyCode]: rule }),
    {}
  );
  const report = {};
  // init index with credit and debit fields;
  rules.forEach((rule) => {
    if (rule.creditField) report[rule.creditField] = {};
    if (rule.debitField) report[rule.debitField] = {};
  });
  summaries.forEach((summary) => {
    const taxonomyCode = summary.accountSummary.taxonomyCode;
    const _rule = ruleIndex[taxonomyCode];
    const isSingleField =
      [_rule.debitField, _rule.creditField].filter((_) => _).length === 1;
    const canBeTwoFields =
      [_rule.debitField, _rule.creditField].filter((_) => _).length === 2;
    if (isSingleField) {
      const _field = _rule.debitField || _rule.creditField;
      report[_field] = {
        ...report[_field],
        [summary.accountSummary.accountId]: summary,
      };
    } else if (canBeTwoFields) {
      const balanceValue = asEuro(
        summary.calculated[balanceType.description]
      ).value;
      if (balanceValue >= 0) {
        report[_rule.debitField] = {
          ...report[_rule.debitField],
          [summary.accountSummary.accountId]: summary,
        };
      } else if (balanceValue < 0) {
        report[_rule.creditField] = {
          ...report[_rule.creditField],
          [summary.accountSummary.accountId]: summary,
        };
      } else {
        console.warn("strange...", summary);
      }
    } else {
      console.warn("this is also strange...", summary);
    }
  });
  return report;
};

const addTotalsToReport = (report, balanceType = BalanceType.Closing) => {
  const assets = [
    ...Object.values(StatementTree.Assets.NonCurrent),
    ...Object.values(StatementTree.Assets.Current),
  ];

  const liabilities = [
    ...Object.values(StatementTree.Liabilities.NonCurrent),
    ...Object.values(StatementTree.Liabilities.Current),
  ];

  const capital = [...Object.values(StatementTree.Capital)];

  const income = [...Object.values(StatementTree.ProfitAndLoss)];

  assets.forEach((assetKey) => {
    if (report[assetKey])
      report[assetKey].__summary = totalForAssetKeys(
        [assetKey],
        report,
        balanceType
      );
  });

  liabilities.forEach((assetKey) => {
    if (report[assetKey])
      report[assetKey].__summary = totalsForLiabilityKeys(
        [assetKey],
        report,
        balanceType
      );
  });

  capital.forEach((assetKey) => {
    if (report[assetKey])
      report[assetKey].__summary = totalsForCapitalKeys(
        [assetKey],
        report,
        balanceType
      );
  });

  income.forEach((assetKey) => {
    if (report[assetKey])
      report[assetKey].__summary = totalsForIncomeKeys([assetKey], report);
  });

  addGroupTotal(
    StatementTree.Assets.NonCurrent.Total,
    Object.values(StatementTree.Assets.NonCurrent),
    report,
    balanceType
  );

  addGroupTotal(
    StatementTree.Assets.Current.Total,
    Object.values(StatementTree.Assets.Current),
    report,
    balanceType
  );

  addGroupTotal(
    StatementTree.Assets.Total,
    [StatementTree.Assets.NonCurrent.Total, StatementTree.Assets.Current.Total],
    report,
    balanceType
  );

  addGroupTotal(
    StatementTree.Liabilities.NonCurrent.Total,
    Object.values(StatementTree.Liabilities.NonCurrent),
    report,
    balanceType
  );

  addGroupTotal(
    StatementTree.Liabilities.Current.Total,
    Object.values(StatementTree.Liabilities.Current),
    report,
    balanceType
  );

  addGroupTotal(
    StatementTree.Liabilities.Total,
    [
      StatementTree.Liabilities.NonCurrent.Total,
      StatementTree.Liabilities.Current.Total,
    ],
    report,
    balanceType
  );

  addGroupTotal(
    StatementTree.Capital.Total,
    Object.values(StatementTree.Capital),
    report,
    balanceType
  );

  addGroupTotal(
    StatementTree.LiabilitiesAndCapital.Total,
    [StatementTree.Liabilities.Total, StatementTree.Capital.Total],
    report,
    balanceType
  );

  addGroupTotal(
    StatementTree.ProfitAndLoss.EBITDA,
    [
      StatementTree.ProfitAndLoss.SalesAndServicesRendered,
      StatementTree.ProfitAndLoss.FinishedGoodsChanges,
      StatementTree.ProfitAndLoss.OwnWork,
      StatementTree.ProfitAndLoss.Subsidies,
      StatementTree.ProfitAndLoss.Materials,
      StatementTree.ProfitAndLoss.ExternalServices,
      StatementTree.ProfitAndLoss.Personnel,
      StatementTree.ProfitAndLoss.Impairment,
      StatementTree.ProfitAndLoss.Provisions,
      StatementTree.ProfitAndLoss.OtherExpenses,
      StatementTree.ProfitAndLoss.OtherGains,
    ],
    report,
    balanceType
  );

  addGroupTotal(
    StatementTree.ProfitAndLoss.OperatingIncome,
    [
      StatementTree.ProfitAndLoss.SalesAndServicesRendered,
      StatementTree.ProfitAndLoss.FinishedGoodsChanges,
      StatementTree.ProfitAndLoss.OwnWork,
      StatementTree.ProfitAndLoss.Subsidies,
      StatementTree.ProfitAndLoss.Materials,
      StatementTree.ProfitAndLoss.ExternalServices,
      StatementTree.ProfitAndLoss.Personnel,
      StatementTree.ProfitAndLoss.Impairment,
      StatementTree.ProfitAndLoss.Provisions,
      StatementTree.ProfitAndLoss.OtherExpenses,
      StatementTree.ProfitAndLoss.OtherGains,
      StatementTree.ProfitAndLoss.DandA,
    ],
    report,
    balanceType
  );

  addGroupTotal(
    StatementTree.ProfitAndLoss.EarningsBeforeTaxes,
    [
      StatementTree.ProfitAndLoss.SalesAndServicesRendered,
      StatementTree.ProfitAndLoss.FinishedGoodsChanges,
      StatementTree.ProfitAndLoss.OwnWork,
      StatementTree.ProfitAndLoss.Subsidies,
      StatementTree.ProfitAndLoss.Materials,
      StatementTree.ProfitAndLoss.ExternalServices,
      StatementTree.ProfitAndLoss.Personnel,
      StatementTree.ProfitAndLoss.Impairment,
      StatementTree.ProfitAndLoss.Provisions,
      StatementTree.ProfitAndLoss.OtherExpenses,
      StatementTree.ProfitAndLoss.OtherGains,
      StatementTree.ProfitAndLoss.DandA,
      StatementTree.ProfitAndLoss.InterestIncome,
      StatementTree.ProfitAndLoss.InterestExpense,
    ],
    report,
    balanceType
  );
};

const BalanceType = {
  Opening: Symbol("openingBalance"),
  Closing: Symbol("closingBalance"),
};

const totalForAssetKeys = (
  assetKeys,
  balanceSheet,
  balanceType = BalanceType.Closing
) => {
  const summariesForAssetEntries = assetKeys.flatMap((assetKey) =>
    Object.values(balanceSheet[assetKey] || {})
  );
  const balance = addAll(
    summariesForAssetEntries.map(
      (summary) =>
        summary &&
        summary.accountSummary &&
        summary.accountSummary[balanceType.description]
    )
  ).format();
  return { [balanceType.description]: balance };
};

const totalsForLiabilityKeys = (
  liabilityKeys,
  balanceSheet,
  balanceType = BalanceType.Closing
) => {
  const summariesForLiabilityEntries = liabilityKeys.flatMap((liabilityKey) =>
    Object.values(balanceSheet[liabilityKey] || {})
  );
  const balance = addAllAndChangeSignal(
    summariesForLiabilityEntries.map(
      (summary) =>
        summary &&
        summary.accountSummary &&
        summary.accountSummary[balanceType.description]
    )
  ).format();
  return { [balanceType.description]: balance };
};

const totalsForCapitalKeys = (
  capitalKeys,
  balanceSheet,
  balanceType = BalanceType.Closing
) => {
  const summariesForCapitalEntries = capitalKeys.flatMap((capitalKey) =>
    Object.values(balanceSheet[capitalKey] || {})
  );
  const balance = addAllAndChangeSignal(
    summariesForCapitalEntries.map(
      (summary) =>
        summary &&
        summary.accountSummary &&
        summary.accountSummary[balanceType.description]
    )
  ).format();
  return { [balanceType.description]: balance };
};

const totalsForIncomeKeys = (incomeKeys, profitAndLoss) => {
  const summariesForIncomeEntries = incomeKeys.flatMap((incomeKey) =>
    Object.values(profitAndLoss[incomeKey] || {})
  );
  // console.log({ summariesForIncomeEntries });
  const closingBalance = addAllAndChangeSignal(
    summariesForIncomeEntries.map(
      (summary) =>
        summary && summary.calculated && summary.calculated.closingBalance
    )
  ).format();
  return { closingBalance };
};

const buildBalanceSheetStatement = (
  report,
  balanceType = BalanceType.Closing
) => {
  const assets = StatementOrdering.Assets.map((orderKey) => {
    if (orderKey === StatementTree.Assets.Total) {
      const assetKeys = [
        ...Object.values(StatementTree.Assets.Current),
        ...Object.values(StatementTree.Assets.NonCurrent),
      ];

      return {
        orderKey,
        ...totalForAssetKeys(assetKeys, report, balanceType),
        total: true,
      };
    } else if (orderKey === StatementTree.Assets.NonCurrent.Total) {
      const assetKeys = Object.values(StatementTree.Assets.NonCurrent);

      return {
        orderKey,
        ...totalForAssetKeys(assetKeys, report, balanceType),
        subTotal: true,
      };
    } else if (orderKey === StatementTree.Assets.Current.Total) {
      const assetKeys = Object.values(StatementTree.Assets.Current);
      return {
        orderKey,
        ...totalForAssetKeys(assetKeys, report, balanceType),
        subTotal: true,
      };
    } else {
      const assetKeys = [orderKey];
      return {
        orderKey,
        ...totalForAssetKeys(assetKeys, report, balanceType),
      };
    }
  });

  const liabilitiesAndCapital = StatementOrdering.LiabilitiesAndCapital.map(
    (orderKey) => {
      if (orderKey === StatementTree.LiabilitiesAndCapital.Total) {
        const assetKeys = [
          ...Object.values(StatementTree.Liabilities.Current),
          ...Object.values(StatementTree.Liabilities.NonCurrent),
          ...Object.values(StatementTree.Capital),
        ];
        return {
          orderKey,
          ...totalsForLiabilityKeys(assetKeys, report, balanceType),
          total: true,
        };
      } else {
        return {};
      }
    }
  );

  const liabilities = StatementOrdering.Liabilities.map((orderKey) => {
    if (orderKey === StatementTree.Liabilities.Total) {
      const assetKeys = [
        ...Object.values(StatementTree.Liabilities.Current),
        ...Object.values(StatementTree.Liabilities.NonCurrent),
      ];
      return {
        orderKey,
        ...totalsForLiabilityKeys(assetKeys, report, balanceType),
        total: true,
      };
    } else if (orderKey === StatementTree.Liabilities.NonCurrent.Total) {
      const assetKeys = Object.values(StatementTree.Liabilities.NonCurrent);
      return {
        orderKey,
        ...totalsForLiabilityKeys(assetKeys, report, balanceType),
        subTotal: true,
      };
    } else if (orderKey === StatementTree.Liabilities.Current.Total) {
      const assetKeys = Object.values(StatementTree.Liabilities.Current);
      return {
        orderKey,
        ...totalsForLiabilityKeys(assetKeys, report, balanceType),
        subTotal: true,
      };
    } else {
      const assetKeys = [orderKey];
      return {
        orderKey,
        ...totalsForLiabilityKeys(assetKeys, report, balanceType),
      };
    }
  });

  const capital = StatementOrdering.Capital.map((orderKey) => {
    if (orderKey === StatementTree.Capital.Total) {
      const assetKeys = Object.values(StatementTree.Capital);
      return {
        orderKey,
        ...totalsForLiabilityKeys(assetKeys, report, balanceType),
        subTotal: true,
      };
    } else {
      const assetKeys = [orderKey];
      return {
        orderKey,
        ...totalsForLiabilityKeys(assetKeys, report, balanceType),
      };
    }
  });
  return { assets, liabilitiesAndCapital, liabilities, capital };
};

const buildIncomeStatement = (report) => {
  return StatementOrdering.ProfitAndLoss.map((orderKey) => {
    // TODO: Include calculated fields

    if (orderKey === StatementTree.ProfitAndLoss.EBITDA) {
      const assetKeys = [
        StatementTree.ProfitAndLoss.SalesAndServicesRendered,
        StatementTree.ProfitAndLoss.FinishedGoodsChanges,
        StatementTree.ProfitAndLoss.OwnWork,
        StatementTree.ProfitAndLoss.Subsidies,
        StatementTree.ProfitAndLoss.Materials,
        StatementTree.ProfitAndLoss.ExternalServices,
        StatementTree.ProfitAndLoss.Personnel,
        StatementTree.ProfitAndLoss.Impairment,
        StatementTree.ProfitAndLoss.Provisions,
        StatementTree.ProfitAndLoss.OtherExpenses,
        StatementTree.ProfitAndLoss.OtherGains,
      ];
      return {
        orderKey,
        ...totalsForIncomeKeys(assetKeys, report),
        total: true,
      };
    } else if (orderKey === StatementTree.ProfitAndLoss.OperatingIncome) {
      const assetKeys = [
        StatementTree.ProfitAndLoss.SalesAndServicesRendered,
        StatementTree.ProfitAndLoss.FinishedGoodsChanges,
        StatementTree.ProfitAndLoss.OwnWork,
        StatementTree.ProfitAndLoss.Subsidies,
        StatementTree.ProfitAndLoss.Materials,
        StatementTree.ProfitAndLoss.ExternalServices,
        StatementTree.ProfitAndLoss.Personnel,
        StatementTree.ProfitAndLoss.Impairment,
        StatementTree.ProfitAndLoss.Provisions,
        StatementTree.ProfitAndLoss.OtherExpenses,
        StatementTree.ProfitAndLoss.OtherGains,
        StatementTree.ProfitAndLoss.DandA,
      ];
      return {
        orderKey,
        ...totalsForIncomeKeys(assetKeys, report),
        total: true,
      };
    } else if (orderKey === StatementTree.ProfitAndLoss.EarningsBeforeTaxes) {
      const assetKeys = [
        StatementTree.ProfitAndLoss.SalesAndServicesRendered,
        StatementTree.ProfitAndLoss.FinishedGoodsChanges,
        StatementTree.ProfitAndLoss.OwnWork,
        StatementTree.ProfitAndLoss.Subsidies,
        StatementTree.ProfitAndLoss.Materials,
        StatementTree.ProfitAndLoss.ExternalServices,
        StatementTree.ProfitAndLoss.Personnel,
        StatementTree.ProfitAndLoss.Impairment,
        StatementTree.ProfitAndLoss.Provisions,
        StatementTree.ProfitAndLoss.OtherExpenses,
        StatementTree.ProfitAndLoss.OtherGains,
        StatementTree.ProfitAndLoss.DandA,
        StatementTree.ProfitAndLoss.InterestIncome,
        StatementTree.ProfitAndLoss.InterestExpense,
      ];
      return {
        orderKey,
        ...totalsForIncomeKeys(assetKeys, report),
        total: true,
      };
    } else if (orderKey === StatementTree.Capital.NetProfit) {
      const assetKeys = [StatementTree.Capital.NetProfit];
      return {
        orderKey,
        ...totalsForLiabilityKeys(assetKeys, report),
        total: true,
      };
    } else {
      const assetKeys = [orderKey];
      return {
        orderKey,
        ...totalsForIncomeKeys(assetKeys, report),
        total: [StatementTree.ProfitAndLoss.SalesAndServicesRendered].includes(
          orderKey
        ),
      };
    }
  });
};
const addGroupTotal = (target, sources = [], report, balanceType) => {
  report[target] = {
    ...report[target],
    __summary: {
      [balanceType.description]: sources
        .filter((key) => report[key]) // avoid missing report items
        .reduce((total, key) => {
          return (
            (report[key] &&
              report[key].__summary &&
              asEuro(report[key].__summary[balanceType.description])
                .add(total)
                .format()) ||
            euroFormat(0)
          );
        }, euroFormat(0)),
    },
  };
};
