import React, { memo, useState } from "react";
import { Badge, Select } from "./UI";

export const joinArrays = (aa = [], bb = [], foreignKey, valueKey, calcFn) => {
  const uniques = [
    ...new Set([
      ...aa.map((_a) => _a[foreignKey]),
      ...bb.map((_b) => _b[foreignKey]),
    ]),
  ];
  return uniques.map((u) => {
    const a = aa && aa.filter((_a) => u === _a[foreignKey])[0];
    const b = bb && bb.filter((_b) => u === _b[foreignKey])[0];
    const aValue = a && a[valueKey];
    const bValue = b && b[valueKey];
    const values = !calcFn
      ? [aValue, bValue]
      : [aValue, bValue, calcFn(aValue, bValue)];
    return {
      [foreignKey]: u,
      values,
    };
  });
};

/**
 * Draws are simple 2-column table.
 * Each item of the data array has
 * to `label` and `value` keys
 * @param {array} data
 * @param {array} headers
 * @param {text} className
 * @returns
 */
export const LabelValueTable = memo(({ data, anonymous, headers, className }) => (
  <table className={`table ${className || ""}`}>
    <thead>
      <tr>
        {headers &&
          headers.slice(0, 2).map((i, o) => (
            <th
              key={o}
              className={`${o === headers.length - 1 ? "text-end" : ""}`}
            >
              {i}
            </th>
          ))}
      </tr>
    </thead>
    <tbody>
      {data &&
        data.map((i, o) => (
          <tr key={o}>
            <td className={i.total && "fw-bold"}><span style={{ filter: anonymous && "blur(4px)" }}>
              {i.label}
            </span></td>
            <td className={`text-end ${i.total ? "fw-bold" : ""}`}>
              <span className="text-nowrap">{i.value}</span>
            </td>
          </tr>
        ))}
    </tbody>
  </table>
));

/**
 * Draws are n-column table.
 * Each item of the data array has
 * to `label` and `values` keys.
 * The `values` key needs to be
 * an array.
 * @param {array} data
 * @param {array} headers
 * @param {text} className
 * @returns
 */
export const LabelValueListTable2 = memo(({
  anonymous,
  data,
  headers,
  className,
  totals,
  subtotals,
  hideRowsFn,
  ...others
}) => {
  const [pageSize, setPageSize] = useState(25);
  const pageSizes = Array(Math.ceil(data.length / 25)).fill(0).map((i, o) => (o + 1) * 25);
  const sliceStart = 0;
  const sliceEnd = pageSize;
  const _headers = [<span><span className="me-2">{headers[0]}</span><Select options={pageSizes} selected={pageSize} onChange={setPageSize}></Select></span>, ...headers.slice(1)];
  return <LabelValueListTable anonymous={anonymous}
    data={data}
    headers={_headers}
    className={className}
    totals={totals}
    subtotals={subtotals}
    hideRowsFn={hideRowsFn}
    sliceStart={sliceStart}
    sliceEnd={sliceEnd}
    {...others}></LabelValueListTable>
});

/**
 * Draws are n-column table.
 * Each item of the data array has
 * to `label` and `values` keys.
 * The `values` key needs to be
 * an array.
 * @param {array} data
 * @param {array} headers
 * @param {text} className
 * @returns
 */
export const LabelValueListTable = memo(({
  anonymous,
  data,
  headers,
  className,
  totals,
  subtotals,
  hideRowsFn,
  sliceStart = 0,
  sliceEnd = 25,
  ...others
}) => (
  <table className={`table table-hover ${className || ""}`} {...others}>
    <thead>
      <tr>
        {headers &&
          headers.map((i, o) => (
            <th
              key={o}
              className={`text-nowrap ${o > 0 ? "text-end" : ""} ${headers.length > 5 ? "small" : ""
                }`}
            >
              {i}
              {o === 0 && data && data.length > sliceEnd - sliceStart && <span className="ms-2 float-end"><Badge className="bg-light text-secondary">{sliceEnd - sliceStart} / {data.length}</Badge></span>}
            </th>
          ))}
      </tr>
    </thead>
    <tbody>
      {data &&
        data.slice(sliceStart, sliceEnd).map((i, o) => (
          <tr
            key={o}
            className={[
              totals && totals[o] && "fw-bold",
              hideRowsFn && hideRowsFn(i) && "d-none",
            ]
              .filter((_) => _)
              .join(" ")}
          >
            <td>
              <span style={{ filter: anonymous && "blur(4px)" }}>
                {i.label}
              </span>
            </td>
            {(i.values || []).map((v, o) => (
              <td
                className={`text-nowrap text-end ${v === "€ 0.00" && "text-muted"
                  } ${headers.length > 5 ? "small" : ""}`}
                key={o}
              >
                {v}
              </td>
            ))}
          </tr>
        ))}
    </tbody>
  </table>
));



export const MonthYearTable2 = ({ anonymous,
  data,
  topLeft,
  headers,
  rowHeaders,
  className,
  ...others }) => {
  const [pageSize, setPageSize] = useState(25);
  const pageSizes = Array(Math.ceil(data.length / 25)).fill(0).map((i, o) => (o + 1) * 25);
  const sliceStart = 0;
  const sliceEnd = pageSize;
  const _topLeft = <span>{topLeft} <Select options={pageSizes} selected={pageSize} onChange={setPageSize}></Select></span>
  return <MonthYearTable data={data} headers={headers} rowHeaders={rowHeaders} topLeft={_topLeft} sliceStart={sliceStart} sliceEnd={sliceEnd} {...others}></MonthYearTable>
}

export const MonthYearTable = memo(({
  anonymous,
  data,
  topLeft,
  headers,
  rowHeaders,
  className,
  sliceStart = 0,
  sliceEnd = 25,
  ...others
}) => {
  return (
    <table className={`table table-hover ${className || ""}`} {...others}>
      <thead>
        <tr>
          <th>
            {topLeft}

          </th>
          {headers &&
            headers.map((item, o) => (
              <th key={o} className="text-end">
                <small className="text-nowrap small">{item}</small>
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.slice(sliceStart, sliceEnd).map((row, _o) => (
            <tr key={row.label}>
              <td>
                <small style={{ filter: anonymous && "blur(4px)" }}>
                  {rowHeaders[_o]}
                </small>
              </td>
              {row &&
                row.map((value, offset) => (
                  <td className="text-end small" key={offset}>
                    <span
                      className={`text-nowrap small ${value === "€ 0.00" ? "text-muted" : ""
                        }`}
                    >
                      {value}
                    </span>
                  </td>
                ))}
            </tr>
          ))}
      </tbody>
    </table>
  )
});

export const monthLabelsForYear = (year) =>
  year &&
  Array(12)
    .fill(0)
    .map((_, o) => `${o + 1}-${year}`);

export const monthLabelsForYearWithPre = (year) =>
  year && [`12-${year - 1}`, ...monthLabelsForYear(year)];
