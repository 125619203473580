import currency from "currency.js";

const Config = {
  symbol: "€",
  decimal: ".",
  separator: " ",
  pattern: "! #",
};

const PreciseConfig = {
  symbol: "€",
  decimal: ".",
  separator: " ",
  pattern: "! #",
  precision: 4,
};

export const asEuro = (value) =>
  value !== currency(Infinity, Config).format()
    ? currency(value, Config)
    : currency(Infinity, Config);

export const asPreciseEuro = (value) =>
  value !== currency(Infinity, PreciseConfig).format()
    ? currency(value, PreciseConfig)
    : currency(Infinity, PreciseConfig);

export const euroFormat = (value) =>
  value !== currency(Infinity, Config).format()
    ? currency(value, Config).format()
    : currency(Infinity, Config).format();

export const addAll = (values) =>
  (values || []).reduce((sub, value) => sub.add(value || asEuro(0)), asEuro(0));

export const abs = (value) =>
  asEuro(value).value >= 0
    ? asEuro(value)
    : asEuro(0).subtract(asEuro(value));

export const absFormat = (value) => abs(value).format();

export const addAllAndChangeSignal = (values) =>
  asEuro(0).subtract(
    (values || []).reduce(
      (sub, value) => sub.add(value || asEuro(0)),
      asEuro(0)
    )
  );
