import CAEs from "./bdp-cae.json";
import DIM from "./bdp-dim.json";

const caeEntryForCae = (cae) => CAEs.filter((_cae) => _cae.CAE === cae)[0];

const dimForCode = (code) =>
  DIM.filter((dim) => dim && dim.CodDimensao === code)[0];

// const dataDBForCAE = (cae = "0", dim = 1) => {
//   const caeId = CAEs.filter((_cae) => _cae.CAE === cae).map(
//     (_cae) => _cae.IDBP_CAE
//   )[0];
//   const db = DB.db[caeId] && DB.db[caeId][dim];
//   return db;
// };

// const getFieldForCAE = (cae = "47784", dim = 1, key = Fields.AllIn) => {
//   const fieldIndex = DB.labels.indexOf(key);
//   const db = dataDBForCAE(cae, dim);
//   const value = db.filter((item) => item && item.l === fieldIndex)[0];
//   return value && value.v;
// };

const Fields = {
  AllIn: "Custo dos financiamentos obtidos" /** */,
  ROA: "Rendibilidade do ativo" /** */,
  DebtOverAssets: "Financiamentos obtidos (% ativo)",
  FinancialAutonomy: "Autonomia financeira" /** */,
  PercentageEBITDABelowZero: "% de empresas com EBITDA < 0",
  PercentageInterestExpenseAboveEBITDA:
    "% de empresas com gastos de financiamento > EBITDA",
  PercentageNegativeNetWorth: "% de empresas com capital próprio < 0",
  PercentageNegativeNetIncome: "% de empresas com resultado líquido < 0",
  OtherExpenseAndIncome: "Outros gastos e rendimentos",
  CommercialNetFinancing: "Financiamento líquido por dívida comercial",
  Tax: "Imposto s/ rendimento",
  BanksOverRevenue: "Bancos (% rendimentos)",
  FinancingExpense: "Gastos de financiamento",
  StateOverRevenue: "Estado (% rendimentos)",
  OtherOverRevenue: "Outros (% rendimentos)",
  NetMargin: "Margem líquida" /** */,
  Depreciations: "Depreciações",
  Effect1: "Efeito da atividade exploração e financeira",
  NetMarginBeforeTaxes: "Margem EBT",
  OperatingMargin: "Margem EBIT" /** */,
  OtherLoans: "Outros empréstimos",
  ROE: "Rendibilidade dos capitais próprios",
  CashAndEquivalente: "Caixa e depósitos",
  AutoFinancingOverAssets: "Autofinanciamento (% rendimentos)",
  DebtTitles: "Títulos de dívida",
  EBITDAMargin: "Margem EBITDA" /** */,
  Suppliers: "Fornecedores",
  ROCI: "Rendibilidade dos capitais investidos" /** */,
  EBITDAMargin2: "EBITDA (% volume de negócios )",
  Clients: "Clientes",
  Inventory: "Inventários",
  FinancialPressure: "Pressão financeira",
  PersonnelExpenseOverRevenue: "Pessoal (% rendimentos)",
  PersonnelExpenses: "Gastos com o pessoal",
  FinancialInvestments: "Investimentos financeiros",
  OtherAssets: "Restantes ativos",
  OtherLiabilities: "Restantes passivos",
  ExportsOverRevenue: "Exportações (% vendas e serviços)",
  ExtendedGrossMargin: "Margem bruta" /** Includes external services */,
  CurrentLoansPercentage: "Financiamentos obtidos correntes (%)",
  ImportOverPurchase: "Importações (% compras)",
  TotalFinancing: "Financiamentos obtidos",
  NonFinancialInvestments: "Investimentos não financeiros",
  GrossValueAddedOverProduction: "VAB / Produção",
  NetWorth: "Capital próprio",
  Loans: "Empréstimos bancários",
  GroupLoans: "Empréstimos do grupo",
  FinancialLeverage: "Alavancagem financeira",
  TotalFinancingOverAssets: "Financiamentos obtidos (% passivo)",
  SuppliersOverRevenue: "Fornecedores (% rendimentos)",
  TotalFinancingOverEBITDA: "Financiamentos obtidos / EBITDA",
  FTEs: "N.º de pessoas ao serviço",
  DPOExternal: "Prazo médio de pagamentos face ao exterior",
  DSO: "Prazo médio de recebimentos" /** */,
  DSOExternal: "Prazo médio de recebimentos face ao exterior",
  DPO: "Prazo médio de pagamentos" /** */,
  CashCycle: "Duração do ciclo de exploração",
  DII: "Prazo de rotação de inventários" /** */,
  COGS: "CMVMC" /** For standard gross margin calc */,
  Revenue: "Vendas e serviços prestados" /** For standard gross margin calc */,
  CommercialNetFinancingOverRevenue:
    "Financiamento dívida comercial (% vol. negócios)",
  RawLiquidity: "Liquidez reduzida",
  Benefits: "Benefícios pós-emprego",
  NonCurrentNetAssetsHeldForSale:
    "Ativos líquidos não correntes detidos p/ venda",
  GeneralLiquidity: "Liquidez geral",
  NonFinancialAssetImpairments: "Imparidade de investimentos não financeiros",
  ExchangeRateEffects: "Efeito das diferenças de câmbio",
  NetFinancialInstruments: "Instrumentos financeiros líquidos",
  FinancialAssetImpairments: "Imparidades em ativos financeiros",
  Provisions: "Provisões",
  ProvisionChanges: "Provisões (aumentos/reduções)",
  NonFinancingInterestExpense: "Gastos financeiros, exceto financiamento",
  DeferredTaxAssets: "Ativos por impostos diferidos",
  FinancingActivityEffect: "Efeito da atividade financiamento",
  DeferredTaxLiabilities: "Passivos impostos diferidos",
  IntagibleAssetsAndGoodwill: "Ativos intangíveis e goodwill",
  AdjustmentsInFinancialAndOtherAssets:
    "Ajustamentos em ativos financeiros e excedentes de revalorização",
  CurrentFinancing: "Financiamentos obtidos correntes",
  DeferralLiabilities: "Diferimentos (passivo)",
  OtherNonCurrentAssstes: "Restantes ativos não correntes",
  NonFinancialInvestmentPurchases: "Aquisição de investimentos não financeiros",
  ServicesRendered: "Serviços prestados",
  Exports: "Exportações",
  Purchases: "Compras",
  Imports: "Importações",
  InterestIncome: "Rendimentos financeiros",
  ReceivableAndInventoryImpairments: "Imparidades de inventários e clientes",
  InternalMarket: "Mercado interno",
  CAPEX: "Fluxos de caixa de investimento",
  OtherNonCurrentLiabilities: "Restantes passivos não correntes",
  EBITDAOverInterestExpense: "Cobertura dos gastos de financiamento",
  Production: "Produção",
  ClientReceiveds: "Recebimentos de clientes",
  NonCurrentFinancing: "Financiamentos obtidos não correntes",
  StateAssets: "Estado (ativo)",
  DeferralAssets: "Diferimentos (ativo)",
  NetIncome: "Resultado líquido",
};
export { CAEs, DIM, Fields, caeEntryForCae, dimForCode };
