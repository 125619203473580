import { StatementTree as SNCTree } from "./normalentities-rules";
import { StatementTree as MicroTree } from "./microentities-rules";

export const isPnLSymbol = (symbol) =>
  [
    ...Object.values(SNCTree.ProfitAndLoss),
    ...Object.values(MicroTree.ProfitAndLoss),
  ].includes(symbol);

export const mapSNCtoMicro = {
  // P&L
  [SNCTree.ProfitAndLoss.SalesAndServicesRendered]:
    MicroTree.ProfitAndLoss.SalesAndServicesRendered,
  [SNCTree.ProfitAndLoss.Subsidies]: MicroTree.ProfitAndLoss.Subsidies,
  [SNCTree.ProfitAndLoss.GainsAndLossesInAssociates]: undefined,
  [SNCTree.ProfitAndLoss.ChangeInProductionInventory]:
    MicroTree.ProfitAndLoss.FinishedGoodsChanges,
  [SNCTree.ProfitAndLoss.OwnWork]: MicroTree.ProfitAndLoss.OwnWork,
  [SNCTree.ProfitAndLoss.Materials]: MicroTree.ProfitAndLoss.Materials,
  [SNCTree.ProfitAndLoss.ExternalServices]:
    MicroTree.ProfitAndLoss.ExternalServices,
  [SNCTree.ProfitAndLoss.Personnel]: MicroTree.ProfitAndLoss.Personnel,
  [SNCTree.ProfitAndLoss.InventoryImpairments]: undefined,
  [SNCTree.ProfitAndLoss.ReceivableImpairments]:
    MicroTree.ProfitAndLoss.Impairment,
  [SNCTree.ProfitAndLoss.Provisions]: MicroTree.ProfitAndLoss.Provisions,
  [SNCTree.ProfitAndLoss.FairValueChanges]: undefined,
  [SNCTree.ProfitAndLoss.NonDandAInvestmentImpairments]: undefined,
  [SNCTree.ProfitAndLoss.OtherGains]: MicroTree.ProfitAndLoss.OtherGains,
  [SNCTree.ProfitAndLoss.OtherExpenses]: MicroTree.ProfitAndLoss.OtherExpenses,
  [SNCTree.ProfitAndLoss.EBITDA]: MicroTree.ProfitAndLoss.EBITDA,
  [SNCTree.ProfitAndLoss.DandA]: MicroTree.ProfitAndLoss.DandA,
  [SNCTree.ProfitAndLoss.DandAInvestmentImpairments]: undefined,
  [SNCTree.ProfitAndLoss.OperatingIncome]:
    MicroTree.ProfitAndLoss.OperatingIncome,
  [SNCTree.ProfitAndLoss.InterestIncome]:
    MicroTree.ProfitAndLoss.InterestIncome,
  [SNCTree.ProfitAndLoss.InterestExpense]:
    MicroTree.ProfitAndLoss.InterestExpense,
  [SNCTree.ProfitAndLoss.EarningsBeforeTaxes]:
    MicroTree.ProfitAndLoss.EarningsBeforeTaxes,
  [SNCTree.ProfitAndLoss.Taxes]: MicroTree.ProfitAndLoss.Taxes,

  // Assets
  [SNCTree.Assets.Total]: MicroTree.Assets.Total,

  [SNCTree.Assets.NonCurrent.Total]: MicroTree.Assets.NonCurrent.Total,
  [SNCTree.Assets.NonCurrent.FixedAssets]:
    MicroTree.Assets.NonCurrent.FixedAssets,
  [SNCTree.Assets.NonCurrent.InvestmentProperties]: undefined,
  [SNCTree.Assets.NonCurrent.IntangibleAssets]:
    MicroTree.Assets.NonCurrent.IntagibleAssets,
  [SNCTree.Assets.NonCurrent.FinancialInvestments]:
    MicroTree.Assets.NonCurrent.FinancialInvestments,
  [SNCTree.Assets.NonCurrent.BiologicalAssets]: undefined,
  [SNCTree.Assets.NonCurrent.FinantialInterests]: undefined,
  [SNCTree.Assets.NonCurrent.Goodwill]: undefined,
  [SNCTree.Assets.NonCurrent.OtherFinancialInvestments]: undefined,
  [SNCTree.Assets.NonCurrent.OtherReceivables]:
    MicroTree.Assets.NonCurrent.CreditsAndOtherNonCurrentAssets,
  [SNCTree.Assets.NonCurrent.OtherReceivables2]: undefined,
  [SNCTree.Assets.NonCurrent.DeferredTaxAssets]: undefined,

  [SNCTree.Assets.Current.Total]: MicroTree.Assets.Current.Total,
  [SNCTree.Assets.Current.TradeableFinantialAssets]: undefined,
  [SNCTree.Assets.Current.OtherFinancialAssets]: undefined,
  [SNCTree.Assets.Current.OtherReceivables2]:
    MicroTree.Assets.Current.OtherCurrentAssets2,
  [SNCTree.Assets.Current.UnrealizedButSubscribedCapital]:
    MicroTree.Assets.Current.UnrealizedButSubscribedCapital,
  [SNCTree.Assets.Current.BiologicalAssets]: undefined,

  [SNCTree.Assets.Current.NonCurrentAssetsHoldForSales]: undefined,
  [SNCTree.Assets.Current.Shareholders]: undefined,
  [SNCTree.Assets.Current.OtherReceivables]:
    MicroTree.Assets.Current.OtherCurrentAssets,
  [SNCTree.Assets.Current.Deferrals]: MicroTree.Assets.Current.Deferrals,
  [SNCTree.Assets.Current.Clients]: MicroTree.Assets.Current.Clients,
  [SNCTree.Assets.Current.Inventory]: MicroTree.Assets.Current.Inventory,
  [SNCTree.Assets.Current.StateAndOtherPublicEntitities]:
    MicroTree.Assets.Current.StateAndOtherPublicEntities,
  [SNCTree.Assets.Current.Cash]: MicroTree.Assets.Current.Cash,

  /// LiabilitiesAndCapital

  [SNCTree.LiabilitiesAndCapital.Total]: MicroTree.LiabilitiesAndCapital.Total,

  /// Liabilities

  [SNCTree.Liabilities.Total]: MicroTree.Liabilities.Total,

  [SNCTree.Liabilities.NonCurrent.Total]:
    MicroTree.Liabilities.NonCurrent.Total,
  [SNCTree.Liabilities.NonCurrent.Provisions]:
    MicroTree.Liabilities.NonCurrent.Provisions,
  [SNCTree.Liabilities.NonCurrent.Loans]:
    MicroTree.Liabilities.NonCurrent.Loans,
  [SNCTree.Liabilities.NonCurrent.OtherPayables]:
    MicroTree.Liabilities.NonCurrent.OtherNonCurrentPayables,
  [SNCTree.Liabilities.NonCurrent.PersonnelBenefits]: undefined,
  [SNCTree.Liabilities.NonCurrent.DeferredTaxLiabilities]: undefined,

  [SNCTree.Liabilities.Current.Total]: MicroTree.Liabilities.Current.Total,
  [SNCTree.Liabilities.Current.TradeableLiabilities]: undefined,
  [SNCTree.Liabilities.Current.OtherFinantialLiabilities]:
    MicroTree.Liabilities.Current.OtherCurrentPayables2,
  [SNCTree.Liabilities.Current.AdvancementsFromClients2]: undefined,
  [SNCTree.Liabilities.Current.AdvancementsFromClients]: undefined,
  [SNCTree.Liabilities.Current.Deferrals]:
    MicroTree.Liabilities.Current.Deferrals,
  [SNCTree.Liabilities.Current.NonCurrentLiabilitiesHeldForSales]: undefined,
  [SNCTree.Liabilities.Current.Providers]:
    MicroTree.Liabilities.Current.Providers,
  [SNCTree.Liabilities.Current.StateAndOtherPublicEntitities]:
    MicroTree.Liabilities.Current.StateAndOtherPublicEntities,
  [SNCTree.Liabilities.Current.Shareholders]: undefined,
  [SNCTree.Liabilities.Current.Loans]: MicroTree.Liabilities.Current.Loans,
  [SNCTree.Liabilities.Current.OtherPayables]:
    MicroTree.Liabilities.Current.OtherCurrentPayables,

  /// Capital
  [SNCTree.Capital.Total]: MicroTree.Capital.Total,
  [SNCTree.Capital.SubscribedCapital]: MicroTree.Capital.SubscribedCapital,
  [SNCTree.Capital.OwnShare]: undefined,
  [SNCTree.Capital.OtherCapitalInstruments]:
    MicroTree.Capital.OtherCapitalInstruments,
  [SNCTree.Capital.LegalReserves]: MicroTree.Capital.LegalReserves,
  [SNCTree.Capital.RevaluationSurplus]: undefined,
  [SNCTree.Capital.OtherCapitalChanges]: MicroTree.Capital.CapitalChanges,
  [SNCTree.Capital.IssuingPremia]: undefined,
  [SNCTree.Capital.OtherReserves]: MicroTree.Capital.OtherReserves,
  [SNCTree.Capital.PastResults]: MicroTree.Capital.AntecipatedDividends,
  [SNCTree.Capital.AntecipatedDividends]:
    MicroTree.Capital.AntecipatedDividends,
  [SNCTree.Capital.NetProfit]: MicroTree.Capital.NetProfit,
};
