import React, { useState, useEffect, useMemo } from "react";
import {
  PageContainer,
  Tabs,
  Badge,
  InputGroup,
  Spinner,
  LoadingScreen,
} from "./UI";
import Select from "react-select";
import { FileDrop } from "./FileDrop";
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { getTaxonomyReference } from "./saft";
import { CAEs, DIM, caeEntryForCae, dimForCode } from "./bdp";
import { getBDPDataForCaeAndDim, getInitialBDPDataForNif } from "./api";
import * as SNC from "./normalentitities";
import * as Micro from "./microentities";
import { MimeType } from "./import";
import { StatementsTab } from "./Statements";
import { BalanceAnalysisTab } from "./BalanceAnalysis";
import { FinantialAnalysisTab } from "./FinancialAnalysis";
import { ClientsAndSuppliersTab } from "./CustomersAndSuppliers";
import { MonthlyAnalysisTab } from "./MonthlyAnalysis";
import { PlanningTab } from "./Planning";
import { TreasuryTab } from "./Treasury";
import { Checker } from "./Checker";
import { Recommendations } from "./Recommendations";
import { ExecutiveSummaryTab } from "./ExecutiveSummary";
import { Summary } from "./Summary";
// eslint-disable-next-line import/no-webpack-loader-syntax
// import StatementWorker from "worker-loader!./statements.worker";

export const ImportPage = ({
  isAdmin,
  anonymous,
  status,
  loading,
  loaded,
  processed,
  isError,
  onFilesDrop,
  onResetAllClick,
}) => {
  const tabHeaders = [
    "Sumário",
    "Análise Financeira",
    "Planeamento",
    "Mapas Anuais",
    "Mapas Mensais",
    "Tesouraria",
    "Clientes & Fornecedores",
  ];
  const [selectedTab, setSelectTab] = useState(tabHeaders[0]);
  // const [state, setState] = useState();
  const [sectorData, setSectorData] = useState();
  const suggestedCaeEntry =
    sectorData && sectorData.cae && caeEntryForCae(sectorData.cae);
  const [labels, setLabels] = useState(sectorData && sectorData.labels);
  const _db = sectorData && sectorData.data;
  const [cae, setCae] = useState(suggestedCaeEntry || CAEs[0]);
  const [dim, setDim] = useState(DIM[DIM.length - 1]);
  const [db, setDb] = useState(_db);
  const [fte, setFte] = useState(-1);

  const state = useMemo(() => !isError && computeState(processed, setFte, setDim), [processed, isError])

  useEffect(() => {
    const firstProcessed =
      state && state._sortedProcessed && state._sortedProcessed[0];
    firstProcessed &&
      getInitialBDPDataForNif(
        firstProcessed.header.taxRegistrationNumber,
        1
      ).then((sectorData) => {
        setCae(caeEntryForCae(sectorData.cae) || CAEs[0]);
        setDb(sectorData && sectorData.data);
        setSectorData(sectorData);
      });
  }, [state]);

  useEffect(() => {
    sectorData && console.log({ sectorData });
  }, [sectorData]);



  useEffect(() => {
    //check for existing sector data to avoid double-loading
    if (sectorData && cae && dim && processed) {
      // console.log({ cae, dim });
      const firstProcessed = processed && processed[0];
      const _cae = cae.CAE || cae.value;
      const _dim = dim.value || dim.CodDimensao;
      firstProcessed &&
        getBDPDataForCaeAndDim(_cae, _dim).then((_sectorData) => {
          // console.log({ _sectorData });
          setLabels(_sectorData && _sectorData.labels);
          setDb(_sectorData && _sectorData.data);
        });
    }
  }, [cae, dim, processed, sectorData]);

  const handleCaeSelection = (cae) => setCae(caeEntryForCae(cae));
  return (
    <PageContainer>
      {(!processed || !loaded) && (
        <FileDrop
          onFilesDrop={onFilesDrop}
          status={status}
          loading={loading}
          loaded={loaded}
          loadingScreen={<LoadingScreen />}
        ></FileDrop>
      )}

      {isError && processed && (
        <div className="d-flex flex-column">
          <div className="alert alert-danger text-center">
            Ficheiros XML com erros{" "}
          </div>
          <div className="text-center">
            <button className="btn btn-primary" onClick={onResetAllClick}>
              Reiniciar
            </button>
          </div>
        </div>
      )}

      {!isError && loaded && processed && processed.length > 0 && (
        <Tabs
          className="mx-2 nav-fill small"
          tabHeaders={[
            ...tabHeaders,
            <SectorConfig
              cae={cae}
              dim={dim}
              fte={fte}
              onCaeChange={setCae}
              onDimChange={setDim}
              onFTEChange={setFte}
              companyCaes={sectorData && sectorData.caes}
              onCaeClick={handleCaeSelection}
            />,
          ].filter((i) => i)}
          selectedTab={selectedTab}
          onTabClick={setSelectTab}
        ></Tabs>
      )}

      {state && areValuesDefined(state) && (
        <DisplayIf value={selectedTab === "Mapas Anuais"}>
          <StatementsTab
            isAdmin={isAdmin}
            refEngine={SNC}
            anonymous={anonymous}
            engines={state.engines}
            engine={state.engine}
            years={state.years}
            balanceSheetYears={state.balanceSheetYears}
            processed={state._sortedProcessed}
            statements={state.statements}
          ></StatementsTab>
        </DisplayIf>
      )}

      {state &&
        areValuesDefined(state) &&
        (
          <DisplayIf value={selectedTab === "Análise Financeira"}>
            <FinantialAnalysisTab
              isAdmin={isAdmin}
              engines={state.engines}
              engine={state.engine}
              years={state.years}
              statements={state.statements}
              processed={state._sortedProcessed}
              sectorData={{ cae, dim, labels, db, fte }}
            ></FinantialAnalysisTab>
          </DisplayIf>
        )
      }

      {
        state && areValuesDefined(state) && (
          <DisplayIf value={selectedTab === "Planeamento"}>
            <PlanningTab
              isAdmin={isAdmin}
              engines={state.engines}
              engine={state.engine}
              statements={state.statements}
              processed={state._sortedProcessed}
              sectorData={{ cae, dim, labels, db, fte }}
            ></PlanningTab>
          </DisplayIf>
        )
      }

      {
        state &&
        areValuesDefined(state) &&
        (
          <DisplayIf value={selectedTab === "Clientes & Fornecedores"}>
            <ClientsAndSuppliersTab
              isAdmin={isAdmin}
              anonymous={anonymous}
              processed={state._sortedProcessed}
              statements={state.statements}
              engines={state.engines}
              engine={state.engine}
              years={state.years}
            ></ClientsAndSuppliersTab>
          </DisplayIf>
        )
      }


      {
        state && areValuesDefined(state) && (
          <DisplayIf value={selectedTab === 'Mapas Mensais'}>
            <MonthlyAnalysisTab
              isAdmin={isAdmin}
              anonymous={anonymous}
              engines={state.engines}
              engine={state.engine}
              processed={state._sortedProcessed}
              statements={state.statements}
              years={state.years}
            ></MonthlyAnalysisTab>
          </DisplayIf>
        )
      }

      {
        state && areValuesDefined(state) && (
          <DisplayIf value={selectedTab === "Tesouraria"}>
            <TreasuryTab
              isAdmin={isAdmin}
              engines={state.engines}
              engine={state.engine}
              processed={state._sortedProcessed}
              statements={state.statements}
              years={state.years}
            ></TreasuryTab>
          </DisplayIf>
        )
      }

      {/* // {state &&
      //   areValuesDefined(state) &&
      //   selectedTab === "Sumário Executivo" && (
      //     <ExecutiveSummaryTab
      engines={state.engines}
      //       engine={state.engine}
      //       statements={state.statements}
      //     ></ExecutiveSummaryTab>
      //   )} */}

      {/* {state && areValuesDefined(state) && selectedTab === "Alertas" && (
        <Checker
          processed={state._sortedProcessed}
          engines={state.engines}
          engine={state.engine}
          statements={state.statements}
          years={state.years}
          sectorData={{ cae, dim, labels, db, fte }}
        ></Checker>
      )}

      {state && areValuesDefined(state) && selectedTab === "Recomendações" && (
        <Recommendations
          processed={state._sortedProcessed}
          engines={state.engines}
          engine={state.engine}
          statements={state.statements}
          years={state.years}
          sectorData={{ cae, dim, labels, db, fte }}
        ></Recommendations>
      )} */}

      {
        state && areValuesDefined(state) && (
          <DisplayIf value={selectedTab === "Sumário"}>
            <Summary
              isAdmin={isAdmin}
              processed={state._sortedProcessed}
              engines={state.engines}
              engine={state.engine}
              statements={state.statements}
              years={state.years}
              sectorData={{ cae, dim, labels, db, fte }}
            ></Summary>
          </DisplayIf>
        )
      }
    </PageContainer >
  );
};

const DisplayIf = ({ value, children }) => {
  const [started, setStarted] = useState(false);
  if (started === false && value === true) { setStarted(true) };
  return (started && <div className={value ? 'd-block' : 'd-none'}>{children}</div>)
}

export const SAFTValidationSummary = ({
  className,
  // file,
  data,
  taxonomy,
  // onCloseClick,
}) => {
  return (
    <div
      className={`d-flex flex-row w-100 justify-content-between mt-2 ${className || ""
        }`}
    >
      {data && (
        <>
          {data.file && <div className="me-2">
            <i
              className="bi bi-journal-code"
              title={`${data.file.name}\n${data.header.dateCreated}\n${data.header.productId}`}
            ></i>
            <Badge>{MimeType[data.file.type]}</Badge>
          </div>
          }
          <div className="me-2">
            <i className="bi bi-building"></i>{" "}
            <Badge>{data.header.companyName}</Badge>
          </div>
          <div className="me-2">
            <i className="bi bi-upc"></i>
            <Badge>{data.header.taxRegistrationNumber}</Badge>
          </div>
          <div className="me-2">
            <i className="bi bi-calendar"></i>{" "}
            <Badge>{data.header.fiscalYear}</Badge>
          </div>
          <div className="me-2">
            <i className="bi bi-calendar4-range"></i>{" "}
            <Badge>{data.header.startDate}</Badge>
            &nbsp;a&nbsp;
            <Badge>{data.header.endDate}</Badge>
          </div>
          {taxonomy && (
            <div className="me-2">
              <Badge>{Taxonomy[taxonomy]}</Badge>
            </div>
          )}
          {/*<div className="me-2">
            <button
              type="button"
              className="btn-close"
              onClick={onCloseClick}
            ></button>
          </div>*/}
        </>
      )}
    </div>
  );
};

const Taxonomy = {
  M: "Microentidade",
  S: "SNC Base",
  N: "NIC",
  O: "Outros referenciais",
};

const sortedByDescendingFiscalYear = (processed) => {
  return (
    processed &&
    processed.sort(
      (a, b) => parseInt(b.header.fiscalYear) - parseInt(a.header.fiscalYear)
    )
  );
};

const areValuesDefined = (object) => Object.values(object).every((i) => i);

const SectorConfig = ({
  companyCaes,
  cae,
  dim,
  fte,
  onCaeChange,
  onDimChange,
  onFTEChange,
  onCaeClick,
}) => {
  // console.log(companyCaes, cae, dim, fte);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  return (
    <>
      <div className="d-flex flex-row justify-content-between ms-2">
        <div>
          <Button
            id="Popover1"
            type="button"
            title="Configurar CAE, dimensão e colaboradores"
            size="sm"
          >
            <i className="bi bi-gear-wide-connected"></i>
            <span className="text-white small ms-2 me-2">Sector</span>
            <SmallSpinner show={!(cae && companyCaes)} />
          </Button>
        </div>
      </div>
      <Popover
        placement="bottom"
        isOpen={popoverOpen}
        target="Popover1"
        toggle={toggle}
      >
        <PopoverHeader>Comparativos sectoriais</PopoverHeader>
        <PopoverBody>
          <div className="text-muted small">
            Seleccione <b>CAE</b>, <b>Dimensão</b> e{" "}
            <b>número de colaboradores</b>
          </div>
          <div className="d-flex flex-column w-100 mt-2">
            <Select
              className="mb-2"
              placeholder="Seleccione CAE"
              isSearchable
              options={CAEs.map((cae) => ({
                label: cae.DsgActivEcon,
                value: cae.CAE,
              }))}
              value={{
                label: cae.label || cae.DsgActivEcon,
                value: cae.value || cae.CAE,
              }}
              onChange={onCaeChange}
            />
            <Select
              className="mb-2"
              placeholder="Seleccione Dimensão"
              isSearchable
              options={DIM.map((dim) => ({
                label: dim.DsgDimensao,
                value: dim.CodDimensao,
              }))}
              value={{
                label: dim.label || dim.DsgDimensao,
                value: dim.value || dim.CodDimensao,
              }}
              onChange={onDimChange}
            />
            <div className="mb-2">
              <InputGroup
                type="number"
                prefix="Núm. empregados"
                value={fte}
                onChange={onFTEChange}
              />
            </div>
            {companyCaes && companyCaes.length > 0 && (
              <div className="text-muted">
                <small>CAEs associados à empresa </small>
                <div>
                  {companyCaes.map((_cae) => (
                    <span className="me-2">
                      <Badge
                        key={_cae}
                        onClick={handleCaeClick(onCaeClick, _cae)}
                        title={
                          _cae &&
                          caeEntryForCae(_cae) &&
                          caeEntryForCae(_cae).DsgActivEcon.trim()
                        }
                      >
                        {_cae}
                      </Badge>
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

const SmallSpinner = ({ show }) =>
  show && (
    <div className="spinner-border spinner-border-sm" role="status">
      <span className="visually-hidden smalll">Loading...</span>
    </div>
  );

const companyCategoryFromStatement = (statement, engine) => {
  const revenue =
    statement &&
    engine &&
    engine.calcRawRatio(
      engine.Ratios.Revenue,
      statement.report,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );

  const assets =
    statement &&
    engine &&
    engine.calcRawRatio(
      engine.Ratios.Assets,
      statement.report,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );

  const referenceSalaryExpensePerFTE = 14 * 1000 * 1.2375;

  const personnel =
    statement &&
    engine &&
    0 -
    engine.calcRawRatio(
      engine.Ratios.PersonnelExpense,
      statement.report,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );

  const ftes = personnel && Math.ceil(personnel / referenceSalaryExpensePerFTE);

  return { revenue, assets, personnel, ftes };
};

const dimFromCategory = (revenue, assets, fte) => {
  const microDim = dimForCode(1);
  const smallDim = dimForCode(2);
  const medDim = dimForCode(3);
  const bigDim = dimForCode(4);

  if (
    revenue < microDim.Max.Turnover * 1_000_000 &&
    assets < microDim.Max.Assets * 1_000_000 &&
    fte < microDim.Max.Employees
  )
    return microDim;
  else if (
    revenue < smallDim.Max.Turnover * 1_000_000 &&
    assets < smallDim.Max.Assets * 1_000_000 &&
    fte < smallDim.Max.Employees
  )
    return smallDim;
  else if (
    revenue < medDim.Max.Turnover * 1_000_000 &&
    assets < medDim.Max.Assets * 1_000_000 &&
    fte < medDim.Max.Employees
  )
    return medDim;
  else return bigDim;
};


const computeState = (processed, setFte, setDim, isError) => {

  console.log({ processed });
  const _sortedProcessed = processed && sortedByDescendingFiscalYear(processed);
  _sortedProcessed && console.log({ _sortedProcessed });

  const firstProcessed = _sortedProcessed && _sortedProcessed[0];

  const taxonomyCode = firstProcessed &&
    firstProcessed.xmlJson &&
    getTaxonomyReference(firstProcessed.xmlJson);

  console.log({ taxonomyCode });

  const years = _sortedProcessed &&
    _sortedProcessed.map((statements) => statements.header.fiscalYear);

  const balanceSheetYears = years &&
    years.flatMap((_year, offset) => {
      const isLast = offset === years.length - 1;
      if (!isLast) {
        return [_sortedProcessed[offset].header.fiscalYear];
      } else {
        return [
          _sortedProcessed[offset].header.fiscalYear,
          parseInt(_sortedProcessed[offset].header.fiscalYear) - 1,
        ];
      }
    });

  const engine = taxonomyCode === "M" ? Micro : SNC;

  const engines = _sortedProcessed &&
    _sortedProcessed.map((_sp) => getTaxonomyReference(_sp.xmlJson) === "M" ? Micro : SNC
    );

  const statements = _sortedProcessed &&
    _sortedProcessed.map((item, offset) => engines[offset].buildStatementsFromSummaries(item.summaries)
    );

  console.log({ statements });

  const categories = statements &&
    statements.map((s, o) => companyCategoryFromStatement(s, engines[o]));

  setFte(categories ? categories[0].ftes : -1);

  setDim(
    categories
      ? dimFromCategory(
        categories[0].revenue,
        categories[0].assets,
        categories[0].ftes
      )
      : DIM[DIM.length - 1]
  );

  return {
    _sortedProcessed,
    taxonomyCode,
    years,
    balanceSheetYears,
    engine,
    engines,
    statements,
  };
}

function handleCaeClick(onCaeClick, _cae) {
  return (_) => onCaeClick(_cae);
}

