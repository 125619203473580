import { GreaterThan, LessThan, EqualTo, And, Or, Not, If } from "./math";
import {
  calcMultiEngineConfigurableRatioListMultiYear,
  calcRatioMultiYear,
  metaCalc,
  PreviousBalanceType,
} from "./ratios-engine";
import { asPreciseEuro } from "./euro";

export const CheckType = {
  AccountExists: Symbol("Conta inexistente"),
  IntraStatement: Symbol("Comparação intra-demonstrações"),
  InterStatement: Symbol("Comparação inter-demonstrações"),
  UnexpectAccountBalance: Symbol("Balanço inesperado de conta"),
  RatioBoundBreached: Symbol("Rácio fora do intervalo de normalidade"),
  ExpressionCheck: Symbol("Expressão"),

  AccountEmpty: Symbol("Conta sem movimentos"),
  AccountingLag: Symbol("Contabilização parece estar atrasada"),
  MissingCustomers: Symbol("Clientes não identificados"),
  NoChangeInAccounts: Symbol("Conta tem o mesmo valor do período anterior"),
  NoChangeInStatementItem: Symbol(
    "Item de balanço tem o mesmo valor do período anterior"
  ),
};

export const runChecks = (
  checks,
  processed,
  statements,
  engines,
  refDB,
  refLabels,
  input,
  offset = 0
) =>
  checks.map((check) =>
    runCheck(
      check,
      processed,
      statements,
      engines,
      refDB,
      refLabels,
      input,
      offset
    )
  );

export const runCheck = (
  check,
  processed,
  statements,
  engines,
  refDB,
  refLabels,
  input,
  offset = 0
) => {
  if (check.type === CheckType.AccountExists) {
    return {
      ...check,
      values: checkInexistentAccount(
        [check.accountPrefix],
        processed,
        statements,
        engines[offset]
      ),
    };
  } else if (check.type === CheckType.IntraStatement) {
    return {
      ...check,
      values: checkIntraStatementCondition(
        check,
        processed,
        statements,
        engines[offset]
      ),
    };
  } else if (check.type === CheckType.InterStatement) {
    return {
      ...check,
      values: checkInterStatementCondition(
        check,
        processed,
        statements,
        engines[offset]
      ),
    };
  } else if (check.type === CheckType.UnexpectAccountBalance) {
    return {
      ...check,
      values: checkUnexpectedAccountBalance(
        check,
        processed,
        statements,
        engines[offset]
      ),
    };
  } else if (check.type === CheckType.RatioBoundBreached) {
    return {
      ...check,
      values: checkRatioBounds(check, processed, statements, engines[offset]),
    };
  } else if (check.type === CheckType.ExpressionCheck) {
    return {
      ...check,
      values: checkExpression(
        check,
        processed,
        statements,
        engines,
        refDB,
        refLabels,
        input
      ),
      benchmark:
        check.benchmarkValue &&
        metaCalc(
          check.benchmarkValue,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            bdp: refDB,
            labels: refLabels,
            input,
          }
        ),
      company:
        check.companyValue &&
        calcRatioMultiYear(check.companyValue, statements, {
          bdp: refDB,
          labels: refLabels,
          input,
        }).rawValues[offset],
      threshold:
        check.thresholdValue &&
        calcRatioMultiYear(check.thresholdValue, statements, {
          bdp: refDB,
          labels: refLabels,
          input,
        }).rawValues[offset],
    };
  } else {
    console.warn(`Check type '${check.type}' not implemented yet`, check);
  }
};

const checkInexistentAccount = (prefixes, processed, statements, engine) => {
  return (
    processed &&
    processed
      .map((p) =>
        p.summaries.filter((s) =>
          prefixes.some((prefix) => s.accountId.startsWith(prefix))
        )
      )
      .map((s) => s && s.length === 0)
  );
};

const checkIntraStatementCondition = (check, processed, statements, engine) =>
  statements &&
  statements.map((statement) => {
    const lhs = renderOperand(statement.report, check.condition.lhs);
    const rhs = renderOperand(statement.report, check.condition.rhs);
    const result = condr[check.condition.operator](lhs, rhs);
    return result;
  });

const renderOperand = (report, operand) =>
  typeof operand === "symbol"
    ? report[operand] && report[operand].__summary.closingBalance
    : operand;

const checkInterStatementCondition = (check, processed, statements, engine) =>
  statements &&
  statements.map((statement) => {
    const current = renderOperand(statement.report, check.condition.current);
    const previous = renderOperand(
      statement.previousReport,
      check.condition.previous
    );
    const result = condr[check.condition.operator](current, previous);
    return result;
  });

const checkUnexpectedAccountBalance = (check, processed, statements, engine) =>
  processed &&
  processed.map(
    (p) =>
      p.summaries
        .filter(
          (s) => s && s.accountId.startsWith(check.prefix) && s.accountSummary
        )
        .filter((s) => {
          return condr[check.operator](
            s.accountSummary.closingBalance,
            check.reference
          );
        }).length > 0
  );

const checkRatioBounds = (check, processed, statements, engine) => {
  const ratio = engine.Ratios[check.ratioKey];
  const ratioResults = engine.calcRatioMultiYear(ratio, statements);
  const ranges = check.condition.ranges;
  const checkResults = ratioResults.rawValues.map((value) =>
    colorForValueInRange(value, ranges)
  );
  return checkResults;
};

const checkExpression = (
  check,
  processed,
  statements,
  engines,
  refDB,
  refLabels,
  input
) => {
  const expression = check && check.expression;
  const checkResults =
    statements &&
    statements.map((statement, offset) => {
      const curr = statement.report;
      const prev = statement.previousReport;
      const previousReport =
        offset < statements.length - 1
          ? statements[offset + 1].report
          : undefined;
      const previousBalanceType =
        offset < statements.length - 1
          ? PreviousBalanceType.Opening
          : PreviousBalanceType.Opening;
      const result = render(
        expression,
        curr,
        prev,
        previousReport,
        previousBalanceType,
        refDB,
        refLabels,
        input
      );
      // console.log({ result });
      return result;
    });
  return checkResults;
};

const colorForValueInRange = (value, range) =>
  Object.keys(range).reduce(
    (finalColor, _color) =>
      !finalColor && condr.inRange(value, range[_color].min, range[_color].max)
        ? _color
        : finalColor,
    undefined
  );

const condr = {
  "===": (lhs, rhs) => lhs === rhs,
  ">": (lhs, rhs) => lhs > rhs,
  ">=": (lhs, rhs) => lhs >= rhs,
  "<": (lhs, rhs) => lhs < rhs,
  "<=": (lhs, rhs) => lhs <= rhs,
  "!==": (lhs, rhs) => lhs !== rhs,
  inRange: (value, min, max) =>
    (min === undefined || min <= value) && (max === undefined || value < max),
};

/**
 * Render a logical test that includes test for money amounts
 * @param {*} exp the test expression
 * @param {*} curr the current context (closing balance)
 * @param {*} prev the previous context (previous closing balance or current opening balance)
 * @returns
 */
export const render = (
  exp,
  curr,
  prev,
  prevReport,
  previousBalanceType,
  refDB,
  refLabels,
  input
) =>
  metaCalc(exp, curr, prev, prevReport, previousBalanceType, {
    bdp: refDB,
    labels: refLabels,
    input,
  });
