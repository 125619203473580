import { useEffect, useState } from "react";
import { Card, LoadingScreen, Select } from "./UI";
import { runChecks } from "./check-engine";
import { sectionChecksForEngine } from "./check-defs";
import { Fields } from "./bdp";
import { asEuro } from "./euro";
import { formatValue } from "./ratios-engine";
import { isNotFullYear } from "./saft";

export const Checker = ({
  processed,
  statements,
  engines,
  engine,
  years,
  sectorData,
}) => {
  const [processing, setProcessing] = useState(true);
  const [state, setState] = useState();
  const [selectedYear, setSelectedYear] = useState(years && years[0]);

  useEffect(() => {
    setProcessing(true);
    const selectedIndex = years.indexOf(selectedYear);
    const selectedProcessed = processed && processed[selectedIndex];
    const sectionChecks = sectionChecksForEngine(engine, {
      partial: isNotFullYear(selectedProcessed) || statementHasNoTaxOrNetIncome(statements[selectedIndex], engines[selectedIndex]),
    });
    setState({ sectionChecks });
    setProcessing(false);
  }, [engine, years, selectedYear, processed]);

  return (
    <>
      {!state && processing && (
        <Card className="my-2">
          <LoadingScreen></LoadingScreen>
        </Card>
      )}
      {state && !processing && (
        <>
          <Card className="my-2">
            <div className="float-end">
              <Select
                options={years}
                selected={selectedYear}
                onChange={setSelectedYear}
              ></Select>
            </div>
            <h5>Demostrações</h5>
            <>
              <CheckListSummary
                className="my-3"
                header={<b>Demonstração de resultados</b>}
                checks={state.sectionChecks.profitAndLossStatement}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
              ></CheckListSummary>
              <CheckListSummary
                className="my-3"
                header={<b>Balanço</b>}
                checks={state.sectionChecks.balanceSheetStatement}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
              ></CheckListSummary>
            </>
          </Card>
          <Card className="my-2">
            <div className="float-end">
              <Select
                options={years}
                selected={selectedYear}
                onChange={setSelectedYear}
              ></Select>
            </div>
            <h5>Análise Financeira</h5>
            <>
              <CheckListSummary
                className="my-3"
                header={<b>MOAF</b>}
                checks={state.sectionChecks.moaf}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
              ></CheckListSummary>
              <CheckListSummary
                className="my-3"
                header={<b>Fluxo de resultados</b>}
                checks={state.sectionChecks.cashFromResults}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
              ></CheckListSummary>
              <CheckListSummary
                className="my-3"
                header={<b>Lucrabilidade - sumário</b>}
                checks={state.sectionChecks.profitability}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
              ></CheckListSummary>
              <CheckListSummary
                className="my-3"
                header={<b>Lucrabilidade - detalhe</b>}
                checks={state.sectionChecks.profitabilityDetails}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
              ></CheckListSummary>
              <CheckListSummary
                className="my-3"
                header={<b>Fundo de maneio - sumário</b>}
                checks={state.sectionChecks.workingCapital}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
              ></CheckListSummary>
              <CheckListSummary
                className="my-3"
                header={<b>Fundo de maneio - simples</b>}
                checks={state.sectionChecks.simpleWorkingCapital}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
              ></CheckListSummary>
              <CheckListSummary
                className="my-3"
                header={<b>Fundo de maneio - outro</b>}
                checks={state.sectionChecks.otherWorkingCapital}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
              ></CheckListSummary>
              <CheckListSummary
                className="my-3"
                header={<b>PMR, Dias de stocks e PMP</b>}
                checks={state.sectionChecks.workingCapitalDays}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
              ></CheckListSummary>
              <CheckListSummary
                className="my-3"
                header={<b>Variação de Fundo de Maneio</b>}
                checks={state.sectionChecks.workingCapitalChanges}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
              ></CheckListSummary>
              <CheckListSummary
                className="my-3"
                header={
                  <b>
                    Variação de Fundo de Maneio — clientes, inventários e
                    fornecedores
                  </b>
                }
                checks={state.sectionChecks.simpleWorkingCapitalChangeDetails}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
              ></CheckListSummary>
              <CheckListSummary
                className="my-3"
                header={<b>Variação de Fundo de Maneio - Estados e outros</b>}
                checks={state.sectionChecks.otherWorkingCapitalChangeDetails}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
              ></CheckListSummary>
              <CheckListSummary
                className="my-3"
                header={<b>Serviço da Dívida</b>}
                checks={state.sectionChecks.debtService}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
              ></CheckListSummary>
              <CheckListSummary
                className="my-3"
                header={<b>Taxa all-in e sobrecustos financeiros</b>}
                checks={state.sectionChecks.financeEfficiency}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
              ></CheckListSummary>
              <CheckListSummary
                className="my-3"
                header={
                  <b>Robustez financeira e capacidade de servir dívida</b>
                }
                checks={state.sectionChecks.robustness}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
              ></CheckListSummary>
              <CheckListSummary
                className="my-3"
                header={<b>Rentabilidade</b>}
                checks={state.sectionChecks.returns}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
              ></CheckListSummary>
            </>
          </Card>
        </>
      )}
    </>
  );
};

const CheckListSummary = ({
  checks,
  processed,
  statements,
  engine,
  header,
  className,
  db,
  labels,
  company,
}) => {
  const checksReadyToBePerformed =
    checks && checks.filter((check) => !check.benchmarkValue || (db && labels));
  const results =
    checksReadyToBePerformed &&
    runChecks(
      checksReadyToBePerformed,
      processed,
      statements,
      engine,
      db,
      labels,
      company
    );
  console.log({ results });
  const trueResults =
    results &&
    results.filter(
      (result) => result && result.values && result.values[0] === true
    );
  return (
    <div
      className={[
        "bg-warning bg-opacity-25 rounded px-2 py-2",
        className || "",
      ].join(" ")}
    >
      {header || <b>Alertas</b>}
      <div className="d-flex flex-column">
        {trueResults &&
          trueResults.length > 0 &&
          trueResults.map((result, offset) => (
            <CheckResult key={offset} result={result} />
          ))}
        {trueResults && trueResults.length === 0 && (
          <div className="text-muted">Sem alertas relevantes</div>
        )}
      </div>
    </div>
  );
};

const CheckResult = ({ result }) => (
  <div>
    <i className="bi bi-exclamation-triangle-fill text-danger me-2"></i>
    {result.description}{" "}
    {result.benchmark && (
      <small className="text-muted">
        Empresa com {format(asEuro(result.company).value, result.format)} vs{" "}
        {format(asEuro(result.benchmark).value, result.format)} do sector{" "}
      </small>
    )}
    {result.threshold && (
      <small className="text-muted">
        Empresa com {format(asEuro(result.company).value, result.format)} vs{" "}
        {format(asEuro(result.threshold).value, result.format)} como referência
      </small>
    )}
  </div>
);

const format = (rawValue, format) =>
  format ? formatValue(rawValue, format) : rawValue;

const statementHasNoTaxOrNetIncome = (statement, engine) => {
  const symbols = [engine.StatementTree.ProfitAndLoss.Taxes, engine.StatementTree.Capital.NetIncome];
  const noTaxOrNetIncome = symbols.some(symbol => symbolHasNoSummaries(statement[symbol]));
  return noTaxOrNetIncome
}

const symbolHasNoSummaries = (symbol) => Object.keys(symbol || {}).filter(key => key && !key.includes('__summary')).length === 0;