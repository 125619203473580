import { useFirestoreCollection } from "./firebase";

const InternalDestinations = {
  Logger: {
    email: "logger.reporting@racios.consulting",
    uid: "rA48Bx5sKjW7FZWz8IG6lqmeTL62", // changes every app
  },
  Support: {
    email: "apoio.reporting@racios.consulting",
    uid: "vg6KVOGnj0blOnketPQhnWPylu93", // changes every app
  },
};

export const useMessages = () => {
  const MESSAGE_COLLECTION_PATH = "messages";
  const {
    add,
    getItemsWhere,
    items: userMessages,
    isOn,
  } = useFirestoreCollection(MESSAGE_COLLECTION_PATH);

  const sendMessage = ({
    fromEmail,
    fromUid,
    toEmail,
    toUid,
    messageType,
    subject,
    body,
  }) =>
    add({
      created: new Date(),
      fromEmail,
      fromUid,
      toEmail,
      toUid,
      messageType,
      subject,
      body,
      _emails: [fromEmail, toEmail],
      _uids: [fromUid, toUid],
    });

  const getMessagesForUser = (user) =>
    getItemsWhere("_uids", "array-contains", user.uid);

  return { sendMessage, getMessagesForUser, userMessages, isOn };
};

export const useLogger = () => {
  const {
    sendMessage,
    userMessages,
    getMessagesForUser: getLogs,
  } = useMessages();

  const LogEvent = {
    Login: "login",
    FileDrop: "file_drop",
  };

  const logEvent = ({ user, eventType, subject = "", body = "" }) =>
    sendMessage({
      fromEmail: user.email,
      fromUid: user.uid,
      toEmail: InternalDestinations.Logger.email,
      toUid: InternalDestinations.Logger.uid,
      messageType: eventType,
      subject,
      body,
    });

  const isLogMessage = (m) =>
    m && [m.fromUid, m.toUid].includes(InternalDestinations.Logger.uid);

  return {
    logEvent,
    getLogs,
    logs: userMessages && userMessages.filter(isLogMessage),
    LogEvent,
  };
};
