import React, { useState, useEffect } from "react";
import { Card, Select, InputGroup, CopyToClipBoard } from "./UI";
import { LabelValueListTable, LabelValueTable } from "./Table";
import { RecomendationType, sectionChecksForEngine } from "./check-defs";
import { isNotFullYear } from "./saft";
import { asEuro } from "./euro";
import { formatValue } from "./ratios-engine";
import { runChecks } from "./check-engine";
import { Fields } from "./bdp";

export const PlanningTab = ({ statements, engines, engine, processed, sectorData }) => {
  const [improvement, setImprovement] = useState(0.05);
  const { robustnessScenarioInputs, setDecline } = useRevenueScenario(0.25);
  const [exposureInputs, setExposureInputs] = useState({});

  const firstStatement = statements && statements[0];
  const firstProcessed = processed && processed[0];
  const firstEngine = engines && engines[0];

  const revenueDeclineOptions = [
    { value: 0.05, label: "5%" },
    { value: 0.1, label: "10%" },
    { value: 0.25, label: "25%" },
    { value: 0.5, label: "50%" },
    { value: 0.75, label: "75%" },
    { value: 1, label: "100%" },
  ];

  const _robustnessScenarios =
    firstStatement &&
    engine.calcRatioList(
      engine.Ratios.RobustnessScenarios({}),
      firstStatement.report,
      firstStatement.previousReport,
      firstStatement.previousReport,
      undefined /** Balance */,
      { input: robustnessScenarioInputs },
      true /* descending */
    );


  const sectionChecks = sectionChecksForEngine(engine, {
    partial: isNotFullYear(firstProcessed) || statementHasNoTaxOrNetIncome(firstStatement, firstEngine),
  });

  const powerOfOne =
    firstStatement &&
    engine
      .calcRatioList(
        engine.Ratios.PowerOfOneList({}),
        firstStatement.report,
        firstStatement.previousReport,
        firstStatement.previousReport,
        undefined /** Balance */,
        { input: { improvement: improvement } }
      )
      .map(({ label, value, total }) => ({
        label: total ? label : `${label} em ${improvement * 100}%`,
        value,
        total,
      }));

  const power2 =
    firstStatement &&
    engine
      .calcRatioList(
        engine.Ratios.PowerOfOneList2({}),
        firstStatement.report,
        firstStatement.previousReport,
        firstStatement.previousReport,
        undefined /** Balance */,
        { input: { improvement: improvement } }
      )
      .map(({ label, value, total }) => ({
        label: total ? label : `${label} em ${improvement * 100}%`,
        value,
        total,
      }));

  const _bearScenario =
    firstStatement &&
    engine.calcRatioList(
      engine.Ratios.InvestmentScenarioUltraBear({}),
      firstStatement.report,
      firstStatement.previousReport,
      firstStatement.previousReport,
      undefined /** Balance */,
      { input: exposureInputs }
    );

  const setExposureInput = (key, value) =>
    setExposureInputs({
      ...exposureInputs,
      [key]: value,
    });

  useEffect(() => {
    console.log({ exposureInputs });
  }, [exposureInputs]);

  console.log({ _bearScenario });

  const updateInvestment = (v) => setExposureInput("investment", v);
  const updateExecutionMonths = (v) => setExposureInput("executionMonths", v);
  const updateLoanAmount = (v) => setExposureInput("loanAmount", v);
  const updateLoanYears = (v) => setExposureInput("loanYears", v);
  const updateAllIn = (v) => setExposureInput("allIn", v);
  const updateRevenues = (v) => setExposureInput("revenues", v);
  const updateGrossMargin = (v) => setExposureInput("grossMargin", v);
  const updateExternalServices = (v) => setExposureInput("externalServices", v);
  const updatePersonnel = (v) => setExposureInput("personnel", v);
  const updatePMR = (v) => setExposureInput("pmr", v);
  const updateDMI = (v) => setExposureInput("dmi", v);
  const updatePMP = (v) => setExposureInput("pmp", v);
  return (
    <>
      <Card className="my-2">
        <h3>Análise de robustez</h3>
        <SectionIntro>
          Avalie o impacto de uma queda do volume de negócios em indicadores
          críticos de robustez
        </SectionIntro>
        <LabelValueTable
          id="robustnessScenarios"
          headers={[
            <>
              <span className="me-2">
                Cenário de queda do volume de negócios
              </span>
              <Select
                options={revenueDeclineOptions}
                selected={robustnessScenarioInputs.decline}
                onChange={setDecline}
              ></Select>
              <CopyToClipBoard target="#robustnessScenarios"></CopyToClipBoard>
            </>,
            "Valor",
          ]}
          data={_robustnessScenarios}
          totals={_robustnessScenarios.map((i) => i.total)}
        ></LabelValueTable>
        <CheckListSummary
          checks={sectionChecks.robustnessScenarios}
          processed={processed}
          statements={statements}
          engine={engine}
          db={sectorData.db}
          labels={sectorData.labels}
          company={{
            [Fields.FTEs]: sectorData.fte,
            ...robustnessScenarioInputs,
          }}
          years={[firstProcessed.header.fiscalYear]}
          selectedYear={firstProcessed.header.fiscalYear}
          onYearChange={undefined}
        ></CheckListSummary>
      </Card>
      <Card className="my-2">
        <div className="d-flex flex-row justify-content-between">
          <h3>Potencial de optimização</h3>
          <div>
            <span className="me-2">Melhoria</span>
            <ImprovementSelect
              improvement={improvement}
              onChange={setImprovement}
            ></ImprovementSelect>
          </div>
        </div>
        <SectionIntro>
          Pequenas melhorias podem ter grande impacto quando vistas em conjunto.
        </SectionIntro>
        <Comment
          text={
            <span>
              As melhorias de {improvement * 100}% podem{" "}
              <b>aumentar o lucro até {powerOfOne.slice(-2, -1)[0].value}</b>
            </span>
          }
        ></Comment>
        <LabelValueTable
          data={powerOfOne}
          headers={["Acção", "Impacto potencial"]}
        ></LabelValueTable>
        <Comment
          text={
            <span>
              As melhorias de {improvement * 100}% podem{" "}
              <b>
                libertar até {power2.slice(-2, -1)[0].value} em fundo de maneio
              </b>
            </span>
          }
        ></Comment>
        <LabelValueTable
          data={power2}
          headers={["Acção", "Impacto potencial"]}
        ></LabelValueTable>
      </Card>

      {false && (
        <Card className="my-2">
          <h3>
            Estimativa de Imposto{" "}
            <small className="text-warning">em desenvolvimento</small>
          </h3>
          <p className="card-text">Tributações autónomas</p>
          <p className="card-text placeholder-glow">
            <span className="placeholder col-7"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-6"></span>
            <span className="placeholder col-8"></span>
          </p>
          <p className="card-text">Lucro tributável</p>
          <p className="card-text placeholder-glow">
            <span className="placeholder col-7"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-6"></span>
            <span className="placeholder col-8"></span>
          </p>
          <p className="card-text">Cálculo do imposto</p>
          <p className="card-text placeholder-glow">
            <span className="placeholder col-7"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-6"></span>
            <span className="placeholder col-8"></span>
          </p>
        </Card>
      )}

      <Card className="my-2">
        <h3 className="mt-4">
          Análise de investimento{" "}
          <small className="text-warning">em desenvolvimento</small>
        </h3>
        {false && (
          <p className="card-text placeholder-glow">
            <span className="placeholder col-7"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-6"></span>
            <span className="placeholder col-8"></span>
          </p>
        )}

        <div className="mt-4 mb-4">
          <h5 className="">Análise de exposição simples </h5>
          <SectionIntro>
            Veja se sobrevive caso o novo investimento corra mal.
          </SectionIntro>
        </div>
        <div className="d-flex flex-row w-100 mt-2">
          <div className="d-flex flex-column w-50 me-5">
            <div className="mb-2">Investimento &amp; Financiamento</div>
            <InputGroup
              type="number"
              prefix="Investimento"
              onChange={updateInvestment}
            ></InputGroup>
            {false && (
              <InputGroup
                type="number"
                prefix="Meses realização"
                onChange={updateExecutionMonths}
              ></InputGroup>
            )}
            <InputGroup
              type="number"
              prefix="Dívida"
              onChange={updateLoanAmount}
            ></InputGroup>
            {false && (
              <InputGroup
                type="number"
                prefix="Anos amortização"
                onChange={updateLoanYears}
              ></InputGroup>
            )}
            {false && (
              <InputGroup
                type="number"
                prefix="Taxa %"
                onChange={updateAllIn}
              ></InputGroup>
            )}
          </div>
          <div className="d-flex flex-column">
            <div className="mb-2">
              Custos incrementais do negócio novo{" "}
              <small className="text-muted">resultante do investimento</small>
            </div>
            {false && (
              <InputGroup
                type="number"
                prefix="Volume de Negócios incremental"
                onChange={updateRevenues}
              ></InputGroup>
            )}
            {false && (
              <InputGroup
                type="number"
                prefix="Margem esperada %"
                onChange={updateGrossMargin}
              ></InputGroup>
            )}
            <InputGroup
              type="number"
              prefix="FSEs incrementais"
              onChange={updateExternalServices}
            ></InputGroup>
            <InputGroup
              type="number"
              prefix="Gastos de pessoal incrementais"
              onChange={updatePersonnel}
            ></InputGroup>
            {false && (
              <div className="d-flex flex-row">
                <InputGroup
                  className="me-2"
                  type="number"
                  prefix="PMR"
                  onChange={updatePMR}
                ></InputGroup>
                <InputGroup
                  className="me-2"
                  type="number"
                  prefix="DMI"
                  onChange={updateDMI}
                ></InputGroup>
                <InputGroup
                  className="me-2"
                  type="number"
                  prefix="PMP"
                  onChange={updatePMP}
                ></InputGroup>
              </div>
            )}
          </div>
        </div>
        <LabelValueTable
          data={_bearScenario}
          headers={["Indicador", "Valor"]}
        ></LabelValueTable>
        <CheckListSummary
          checks={sectionChecks.investmentScenarios}
          processed={processed}
          statements={statements}
          engine={engine}
          db={sectorData.db}
          labels={sectorData.labels}
          company={{
            [Fields.FTEs]: sectorData.fte,
            ...exposureInputs,
          }}
          years={[firstProcessed.header.fiscalYear]}
          selectedYear={firstProcessed.header.fiscalYear}
          onYearChange={undefined}
        ></CheckListSummary>
        <RecommendationBlock
          checks={sectionChecks.investmentScenarios}
          processed={processed}
          statements={statements}
          engine={engine}
          db={sectorData.db}
          labels={sectorData.labels}
          company={{ [Fields.FTEs]: sectorData.fte, ...exposureInputs }}
          years={[firstProcessed.header.fiscalYear]}
          selectedYear={firstProcessed.header.fiscalYear}
          onYearChange={undefined}
        ></RecommendationBlock>
      </Card>
    </>
  );
};

const improvementOptions = [
  { label: "1%", value: 0.01 },
  { label: "2%", value: 0.02 },
  { label: "5%", value: 0.05 },
  { label: "10%", value: 0.1 },
];

const ImprovementSelect = ({ improvement, onChange }) => (
  <Select
    options={improvementOptions}
    selected={improvement}
    onChange={onChange}
  ></Select>
);

const Comment = ({ text }) => (
  <div className="bg-warning bg-opacity-25 rounded px-2 py-2">
    <i className="bi bi-info-circle-fill text-primary me-2"></i>
    {text}
  </div>
);

const SectionIntro = ({ children, className }) => (
  <div
    className={`bg-light small text-secondary px-2 py-2 rounded mb-4 ${className || ""
      }`}
  >
    {children}
  </div>
);

const useRevenueScenario = (initialDecline = 0.25) => {
  const [robustnessScenarioInputs, setRobustnessScenarioInputs] = useState({
    decline: initialDecline,
    revenueMultiply: 1 - initialDecline,
    cogsMultiply: 1 - initialDecline,
  });

  const setDecline = (decline = 0.25) =>
    setRobustnessScenarioInputs({
      decline,
      revenueMultiply: 1 - decline,
      cogsMultiply: 1 - decline,
    });

  return { robustnessScenarioInputs, setDecline };
};

const CheckListSummary = ({
  checks,
  processed,
  statements,
  engine,
  header,
  className,
  db,
  labels,
  company,
  years,
  selectedYear,
  onYearChange,
}) => {
  const yearOffset = years && years.indexOf(selectedYear);
  const checksReadyToBePerformed =
    checks && checks.filter((check) => !check.benchmarkValue || (db && labels));
  const results =
    checksReadyToBePerformed &&
    runChecks(
      checksReadyToBePerformed,
      processed,
      statements,
      engine,
      db,
      labels,
      company,
      yearOffset
    );
  console.log({ results });
  const trueResults =
    results &&
    results.filter(
      (result) => result && result.values && result.values[yearOffset] === true
    );
  return (
    <div
      className={[
        "bg-warning bg-opacity-25 rounded px-2 py-2",
        className || "",
      ].join(" ")}
    >
      <div className="float-end">
        {onYearChange && (
          <Select
            options={years}
            selected={selectedYear}
            onChange={onYearChange}
          ></Select>
        )}
      </div>
      {header || <b>Alertas</b>}
      <div className="d-flex flex-column">
        {trueResults &&
          trueResults.length > 0 &&
          trueResults.map((result, offset) => (
            <CheckResult key={offset} result={result} />
          ))}
        {trueResults && trueResults.length === 0 && (
          <div className="text-muted">Sem alertas relevantes</div>
        )}
      </div>
    </div>
  );
};

const CheckResult = ({ result }) => (
  <div>
    <i className="bi bi-exclamation-triangle-fill text-danger me-2"></i>
    {result.description}{" "}
    {result.benchmark !== undefined && (
      <small className="text-muted">
        Empresa com {format(asEuro(result.company).value, result.format)} vs{" "}
        {format(asEuro(result.benchmark).value, result.format)} do sector{" "}
      </small>
    )}
    {result.threshold !== undefined && (
      <small className="text-muted">
        Empresa com {format(asEuro(result.company).value, result.format)} vs{" "}
        {format(asEuro(result.threshold).value, result.format)} como referência
      </small>
    )}
  </div>
);

const format = (rawValue, format) =>
  format ? formatValue(rawValue, format) : rawValue;

const RecommendationBlock = ({
  checks,
  processed,
  statements,
  engine,
  db,
  labels,
  company,
  years,
  selectedYear,
  onYearChange,
}) => (
  <>
    <RecomendationSummary
      className="my-3"
      header={<b>Recomendações para a Gestão</b>}
      checks={checks}
      processed={processed}
      statements={statements}
      engine={engine}
      db={db}
      labels={labels}
      company={company}
      type={RecomendationType.Company}
      years={years}
      selectedYear={selectedYear}
      onYearChange={onYearChange}
    ></RecomendationSummary>

    <RecomendationSummary
      className="my-3"
      header={<b>Recomendações para a interacção com a Banca/Investidores</b>}
      checks={checks}
      processed={processed}
      statements={statements}
      engine={engine}
      db={db}
      labels={labels}
      company={company}
      type={RecomendationType.Bank}
      years={years}
      selectedYear={selectedYear}
      onYearChange={onYearChange}
    ></RecomendationSummary>
  </>
);

const RecomendationSummary = ({
  checks,
  processed,
  statements,
  engine,
  header,
  className,
  db,
  labels,
  company,
  type = RecomendationType.Company,
  years,
  selectedYear,
  onYearChange,
}) => {
  const yearOffset = years && years.indexOf(selectedYear);
  const checksReadyToBePerformed =
    checks && checks.filter((check) => !check.benchmarkValue || (db && labels));
  const results =
    checksReadyToBePerformed &&
    runChecks(
      checksReadyToBePerformed,
      processed,
      statements,
      engine,
      db,
      labels,
      company,
      yearOffset
    );
  console.log({ results });
  const trueResults =
    results &&
    results.filter(
      (result) => result && result.values && result.values[yearOffset] === true
    );
  const recommendationDescriptions = trueResults && [
    ...new Set(
      trueResults
        .filter((result) => result.recommendations)
        .flatMap((result) =>
          result.recommendations
            .filter((r) => r.target === type)
            .map((r) => r.description)
        )
    ),
  ];

  return (
    <div
      className={[
        "bg-info bg-opacity-10 rounded px-2 py-2",
        className || "",
      ].join(" ")}
    >
      <div className="float-end">
        {onYearChange && (
          <Select
            options={years}
            selected={selectedYear}
            onChange={onYearChange}
          ></Select>
        )}
      </div>
      {header || <b>Alertas</b>}
      <div className="d-flex flex-column mt-2">
        {recommendationDescriptions &&
          recommendationDescriptions.length > 0 &&
          recommendationDescriptions.map((description, offset) => (
            <RecommendationItem key={offset} description={description} />
          ))}
        {recommendationDescriptions &&
          recommendationDescriptions.length === 0 && (
            <div className="text-muted">Sem recomendações relevantes</div>
          )}
      </div>
    </div>
  );
};

const RecommendationItem = ({ description }) => (
  <div className="d-flex flex-row mb-2">
    <div className="me-2">
      <i className="bi bi-info-square-fill text-primary"></i>
    </div>
    <div>{description} </div>
  </div>
);

const statementHasNoTaxOrNetIncome = (statement, engine) => {
  const symbols = [engine.StatementTree.ProfitAndLoss.Taxes, engine.StatementTree.Capital.NetIncome];
  const noTaxOrNetIncome = symbols.some(symbol => symbolHasNoSummaries(statement[symbol]));
  return noTaxOrNetIncome
}

const symbolHasNoSummaries = (symbol) => Object.keys(symbol || {}).filter(key => key && !key.includes('__summary')).length === 0;