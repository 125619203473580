import React, { useState, useEffect, useMemo, memo } from "react";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";

export const WorkerSuspense = ({
    workerPath,
    workerFn,
    children,
    fallback,
    workerArgs,
    reloadable = false
}) => {
    const createWorker = createWorkerFactory(() => import('./' + workerPath));
    const worker = useWorker(createWorker);
    const [message, setMessage] = useState(null);
    useEffect(() => {
        (reloadable || !message) && (async () => {
            const webWorkerMessage = await worker[workerFn](...workerArgs);
            setMessage(webWorkerMessage);
        })();
    }, [worker, workerArgs, workerFn]);
    return (
        <>
            {!message && <>{fallback}</>}
            {message && children(message)}
        </>
    );
};
