type ArithArg = Operator | Previous | Ref | Symbol | number;

export class Operator {
  lhs: ArithArg;
  rhs: ArithArg;
  constructor(lhs: ArithArg, rhs: ArithArg) {
    this.lhs = lhs;
    this.rhs = rhs;
  }
}

export class Add extends Operator {}
export class Subtract extends Operator {}
export class Multiply extends Operator {}
export class Divide extends Operator {}

export class Previous {
  symbol: Symbol;
  constructor(symbol: Symbol) {
    this.symbol = symbol;
  }
}

export class Within {
  symbol: Symbol;
  prefix: string;
  constructor(symbol: Symbol, prefix: string) {
    this.symbol = symbol;
    this.prefix = prefix;
  }
}

export class PreviousWithin {
  symbol: Symbol;
  prefix: string;
  constructor(symbol: Symbol, prefix: string) {
    this.symbol = symbol;
    this.prefix = prefix;
  }
}

export class Ref {
  key: string;
  source: string;
  constructor(key: string, source: string) {
    this.key = key;
    this.source = source;
  }
}

export const changeIn = (item: Symbol) =>
  new Subtract(item, new Previous(item));

export const percentageChangeIn = (item: Symbol) =>
  new Divide(new Subtract(item, new Previous(item)), new Previous(item));

export const invert = (item: ArithArg) => new Subtract(0, item);

export const averageWithPrevious = (item: Symbol) =>
  new Divide(new Add(item, new Previous(item)), 2);

export const absoluteGrowth = (item: Symbol) =>
  new Subtract(item, new Previous(item));

export const relativeGrowth = (item: Symbol) =>
  new Divide(absoluteGrowth(item), item);

export const costAbsoluteGrowth = (item: Symbol) =>
  new Subtract(invert(item), invert(new Previous(item)));

export const costRelativeGrowth = (item: Symbol) =>
  new Divide(costAbsoluteGrowth(item), invert(item));

export const addAllFormulas = (formulas: ArithArg[] = []) =>
  formulas.slice(1).reduce((t, i) => new Add(t, i), formulas[0]);

export const addList = (...items: ArithArg[]) =>
  items && items.reduce((partial, item) => new Add(partial, item), 0);

export class CompLogOp {
  lhs: ArithArg;
  rhs: ArithArg;
  constructor(lhs: ArithArg, rhs: ArithArg) {
    this.lhs = lhs;
    this.rhs = rhs;
  }
}

export class GreaterThan extends CompLogOp {}

export class LessThan extends CompLogOp {}

export class EqualTo extends CompLogOp {}

export class LogOp {}

export type LogOpArg =
  | Not
  | And
  | Or
  | GreaterThan
  | LessThan
  | EqualTo
  | boolean;

export class MonLogOp extends LogOp {
  arg: LogOpArg;
  constructor(arg: LogOpArg) {
    super();
    this.arg = arg;
  }
}

export class Not extends MonLogOp {}

export class BinLogOp extends LogOp {
  lhs: LogOpArg;
  rhs: LogOpArg;
  constructor(lhs: LogOpArg, rhs: LogOpArg) {
    super();
    this.lhs = lhs;
    this.rhs = rhs;
  }
}

export class And extends BinLogOp {}

export class Or extends BinLogOp {}

export class If {
  condition: LogOpArg;
  ifTrue: ArithArg;
  ifFalse: ArithArg;
  constructor(condition: LogOpArg, ifTrue: ArithArg, ifFalse: ArithArg) {
    this.condition = condition;
    this.ifTrue = ifTrue;
    this.ifFalse = ifFalse;
  }
}

export const max = (a: ArithArg, b: ArithArg) =>
  new If(new GreaterThan(a, b), a, b);

export const min = (a: ArithArg, b: ArithArg) =>
  new If(new LessThan(a, b), a, b);

type StringLogOpArg = StringEqualTo | String | string | Ref;

export class StringEqualTo {
  lhs: StringLogOpArg;
  rhs: StringLogOpArg;
  constructor(lhs: StringLogOpArg, rhs: StringLogOpArg) {
    this.lhs = lhs;
    this.rhs = rhs;
  }
}
