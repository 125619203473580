import React, { useState, useEffect } from "react";
import { Card, CopyToClipBoard, Select } from "./UI";
import { asEuro } from "./euro";
import { getCustomerAndSupplierIndex } from "./saft";
import ClipboardJS from "clipboard";

export const BalanceAnalysisTab = ({ processed, engines }) => {
  new ClipboardJS(".bi-clipboard");
  const years = processed && processed.map((i) => i.header.fiscalYear);
  const [selectedYear, setSelectedYear] = useState(years[0]);
  const [selectedData, setSelectedData] = useState(processed & processed[0]);
  const [selectedStatement, setSelectedStatement] = useState();
  const [selectedEngine, setSelectedEngine] = useState(engines && engines[0]);

  const externalIndex = processed &&
    getCustomerAndSupplierIndex(selectedData.xmlJson);

  const { customerIndex, supplierIndex } = externalIndex;

  console.log({ customerIndex, supplierIndex });

  const symbols = [
    ...new Set([...selectedEngine.BalanceSheetSymbols, ...selectedEngine.ProfitAndLossSymbols]),
  ];

  // console.log(symbols);
  const [selectedSymbol, setSelectedSymbol] = useState(symbols[0]);
  const [symbolAccountIDs, setSymbolAccountIDs] = useState();
  const [selectedAccountID, setSelectedAccountID] = useState();
  const [transactions, setTransactions] = useState();

  useEffect(() => {
    if (!processed) return;
    const _selectedIndex = years.indexOf(selectedYear);
    const _selectedData = processed[_selectedIndex];
    const _selectedEngine = engines[_selectedIndex];
    setSelectedEngine(_selectedEngine);
    setSelectedData(_selectedData);
  }, [selectedYear, processed, years]);

  useEffect(() => {
    const statement =
      selectedData &&
      selectedEngine.buildStatementsFromSummaries(selectedData.summaries);
    setSelectedStatement(statement);
  }, [selectedData]);

  useEffect(() => {
    if (!selectedStatement || !selectedSymbol) return;
    const _symbolData = selectedStatement.report[selectedSymbol];
    console.log({ _symbolData });
    const _accountIDs =
      _symbolData &&
      Object.values(_symbolData)
        .filter((i) => i.accountId)
        // .sort(
        //   (a, b) =>
        //     parseInt(a.accountId.substring(0, 3)) -
        //     parseInt(b.accountId.substring(0, 3))
        // )
        .map(
          (i) =>
            i && {
              ...i.accountSummary,
              transactionCount:
                i.transactionSummary &&
                i.transactionSummary.transactions &&
                i.transactionSummary.transactions.length,
            }
        )
        .filter((i) => i.accountId);

    setSymbolAccountIDs(_accountIDs);
  }, [selectedSymbol, selectedStatement]);

  useEffect(() => {
    setSelectedAccountID(
      symbolAccountIDs && symbolAccountIDs[0] && symbolAccountIDs[0].accountId
    );
  }, [symbolAccountIDs]);

  useEffect(() => {
    const _transactions =
      selectedAccountID &&
      selectedStatement &&
      selectedStatement.report[selectedSymbol][selectedAccountID] &&
      selectedStatement.report[selectedSymbol][selectedAccountID]
        .transactionSummary &&
      selectedStatement.report[selectedSymbol][selectedAccountID]
        .transactionSummary.transactions;
    // console.log({ selectedAccountID, _transactions });
    const dateOrderedTransactions =
      _transactions &&
      [..._transactions].sort(
        (a, b) => a.transactionDate.getTime() - b.transactionDate.getTime()
      );
    console.log({ transactions });
    setTransactions(dateOrderedTransactions);
  }, [selectedAccountID]);

  const handleSymbolSelection = (o) => setSelectedSymbol(symbols[o]);
  return (
    <Card className="my-2">
      <h3>Análise de movimentos</h3>
      <YearSelect
        years={years}
        selectedYear={selectedYear}
        onYearChange={setSelectedYear}
      ></YearSelect>
      <SymbolSelect
        symbols={symbols}
        selectedSymbol={selectedSymbol}
        onSymbolChange={handleSymbolSelection}
      ></SymbolSelect>
      {symbolAccountIDs && symbolAccountIDs.length > 0 && (
        <AccountIDSelect
          accountIDs={symbolAccountIDs.map((a) => ({
            ...a,
            label: `${a.accountId} - ${a.description} (${a.transactionCount || 0
              })`,
            value: a.accountId,
          }))}
          selectedAccountID={selectedAccountID}
          onAccountIDChange={setSelectedAccountID}
        ></AccountIDSelect>
      )}
      <span className="ms-2"><CopyToClipBoard target="#balanceAnalysis"></CopyToClipBoard></span>
      {transactions && (
        <table className="table table-hover small" id="balanceAnalysis">
          <thead>
            <tr>
              <th>ID</th>
              <th>Data</th>
              <th>Período</th>
              <th>Descrição</th>
              <th>Débito</th>
              <th>Crédito</th>
              <th>Cliente/Fornecedor</th>
              <th>Conta</th>
              <th>Diário</th>
              <th>Outras contas</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((t, o) => (
              <tr key={t._uuid}>
                <td>{t.recordId}</td>
                <td>{t.transactionDate.toLocaleDateString()}</td>
                <td>{t.transaction && t.transaction.period}</td>
                <td>{t.transaction && t.transaction.description}</td>
                <td><span className="text-nowrap">{t.debitAmount && asEuro(t.debitAmount).format()}</span></td>
                <td><span className="text-nowrap">{t.creditAmount && asEuro(t.creditAmount).format()}</span></td>
                <td>
                  {(t.transaction.customerId && customerIndex[t.transaction.customerId] && customerIndex[t.transaction.customerId].companyName)
                    ||
                    (t.transaction.supplierId && supplierIndex[t.transaction.supplierId] && supplierIndex[t.transaction.supplierId].companyName)}</td>
                <td>
                  <span class="badge rounded-pill bg-secondary opacity-50 me-1">
                    {t.accountId}
                  </span>
                </td>
                <td>
                  <span className="small">
                    <span className="me-2">{t.journal && t.journal.description}</span>
                    <span className="badge rounded-pill bg-secondary opacity-50 me-1">{t.journal && t.journal.id}</span>
                  </span>
                </td>
                <td>
                  <span className="small">
                    {t.transactionLines &&
                      [
                        ...new Set(
                          t.transactionLines.map((tl) => tl && tl.accountID)
                        ),
                      ]
                        .sort()
                        .map((accountId) => (
                          <span className="badge rounded-pill bg-secondary opacity-50 me-1">
                            {accountId}
                          </span>
                        ))}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Card>
  );
};

const YearSelect = ({ years, selectedYear, onYearChange }) => (
  <Select
    options={years}
    selected={selectedYear}
    onChange={onYearChange}
  ></Select>
);

const AccountIDSelect = ({
  accountIDs,
  selectedAccountID,
  onAccountIDChange,
}) => (
  <Select
    options={accountIDs}
    selected={selectedAccountID}
    onChange={onAccountIDChange}
  ></Select>
);

const SymbolSelect = ({ symbols, selectedSymbol, onSymbolChange }) => (
  <Select
    options={symbols.map((s, o) => ({
      label: s.description,
      value: o,
    }))}
    selected={symbols.indexOf(selectedSymbol)}
    onChange={onSymbolChange}
  ></Select>
);
