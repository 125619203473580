import React, { useState, useEffect, useMemo } from "react";
import { Card, CopyToClipBoard, Scrollable, Select, LoadingScreen } from "./UI";
import { ApexBarChart, ApexRangeBarChart } from "./ApexCharts";
import { LabelValueListTable } from "./Table";
import { asEuro } from "./euro";
import { runChecks } from "./check-engine";
import { RecomendationType, sectionChecksForEngine } from "./check-defs";
import { Fields } from "./bdp";
import { formatValue } from "./ratios-engine";
import ClipboardJS from "clipboard";
import { isNotFullYear } from "./saft";
import { WorkerSuspense } from "./Worker";

export const FinantialAnalysisTab = ({
  statements,
  processed,
  years,
  engine,
  engines,
  refEngine,
  sectorData,
}) => {
  new ClipboardJS(".bi-clipboard");
  const [selectedYear, setSelectedYear] = useState(years && years[0]);
  const [processing, setProcessing] = useState(true);
  // const [state, setState] = useState();
  const { robustnessScenarioInputs, setDecline } = useRevenueScenario(0.25);
  const revenueDeclineOptions = [
    { value: 0.05, label: "5%" },
    { value: 0.1, label: "10%" },
    { value: 0.25, label: "25%" },
    { value: 0.5, label: "50%" },
    { value: 0.75, label: "75%" },
    { value: 1, label: "100%" },
  ];

  const firstStatement = statements && statements[0];

  const { cae, dim, fte, db, labels } = sectorData;

  const selectedYearIndex = years.indexOf(selectedYear);
  const selectedProcessed = processed && processed[selectedYearIndex];
  const selectedStatement = statements && statements[selectedYearIndex];
  const selectedEngine = engines && engines[selectedYearIndex];
  const isPartialArray = processed.map((p, o) => ({ partial: isNotFullYear(p) || statementHasNoTaxOrNetIncome(statements[o], engines[o]) }));

  const yearLabels = years.map((_year, offset) => (
    <span>
      {_year}
      {isNotFullYear(processed[offset]) ? (
        <sup>{monthAcronym(new Date(processed[offset].header.endDate))}</sup>
      ) : (
        ""
      )}
    </span>
  ));

  // const state = useMemo(computeState(setProcessing, statements, sectorData, years, selectedYear, processed, engines, engine, refEngine, robustnessScenarioInputs), [
  //   engine,
  //   sectorData,
  //   statements,
  //   robustnessScenarioInputs,
  //   engines,
  //   processed,
  //   refEngine,
  //   selectedYear,
  //   years,
  // ]);

  const selectedArgs = [selectedProcessed, selectedStatement, selectedEngine]
  const fullArgs = [engine, refEngine, isPartialArray, statements, engines, robustnessScenarioInputs];
  const extendedArgs = [engine, refEngine, isPartialArray, statements, engines, selectedProcessed, selectedStatement, selectedEngine];


  return (

    <WorkerSuspense
      fallback={<LoadingScreen />}
      workerPath="FinancialAnalysis.worker.js"
      workerFn="sectionChecks"
      workerArgs={selectedArgs}
    >
      {(sectionChecks) => <>
        <Card className="my-2">
          <SectionIntro>
            Esta secção permite perceber como são gerados e utilizados os
            fluxos de caixa da empresa.
          </SectionIntro>
          <h3>
            MOAF{" "}
            <small className="text-muted">
              Mapa de origens e aplicações de fundos
            </small>
          </h3>

          <CheckListSummary
            checks={sectionChecks.moaf}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="moafAndCash"
            workerArgs={fullArgs}
          >
            {({ moaf, cash }) => <>
              <Scrollable>
                <LabelValueListTable
                  id="globalmoaf"
                  data={moaf}
                  headers={[
                    <CopyToClipBoard target="#globalmoaf"></CopyToClipBoard>,
                    ...yearLabels,
                  ]}
                  totals={moaf.map((i) => i.total)}
                ></LabelValueListTable>
              </Scrollable>

              <Scrollable>
                <LabelValueListTable
                  id="globalcash"
                  data={cash}
                  headers={[
                    <CopyToClipBoard target="#globalcash"></CopyToClipBoard>,
                    ...yearLabels,
                  ]}
                  totals={cash.map((i) => i.total)}
                ></LabelValueListTable>
              </Scrollable>
            </>}
          </WorkerSuspense>

          <RecommendationBlock
            checks={sectionChecks.moaf}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></RecommendationBlock>
        </Card>

        <Card className="my-2">
          <SectionIntro>
            O Fluxo de resultados mostra os fluxos de caixa operacionais
            excluindo o efeito do fundo de maneio e dos custos de
            financiamento.
          </SectionIntro>
          <h3>Fluxo de Resultados</h3>

          <CheckListSummary
            checks={sectionChecks.cashFromResults}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>

          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="cashFromResults"
            workerArgs={fullArgs}
          >
            {(cashFromResults) => <Scrollable>
              <LabelValueListTable
                id="cashfromresults"
                headers={[
                  <CopyToClipBoard target="#cashfromresults"></CopyToClipBoard>,
                  ...yearLabels,
                ]}
                data={cashFromResults}
                totals={cashFromResults.map((i) => i.total)}
              ></LabelValueListTable>
            </Scrollable>}
          </WorkerSuspense>


          <RecommendationBlock
            checks={sectionChecks.cashFromResults}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></RecommendationBlock>
        </Card>

        <Card className="my-2">
          <SectionIntro>
            A análise de lucrabilidade permite perceber em que "zonas" o lucro
            é mais perdido.
          </SectionIntro>
          <h3>Lucrabilidade</h3>

          <CheckListSummary
            checks={sectionChecks.profitability}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>

          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="profitabilityAndIncomeFunnel"
            workerArgs={extendedArgs}
          >
            {({ profitability, incomeFunnel }) => <><Scrollable>
              <LabelValueListTable
                id="profitability"
                headers={[
                  <>
                    <span className="me-2">Sumário</span>
                    <CopyToClipBoard target="#profitability"></CopyToClipBoard>
                  </>,
                  ...yearLabels,
                ]}
                data={profitability}
              ></LabelValueListTable>
            </Scrollable>

              <ApexRangeBarChart
                header="Funil de lucrabilidade"
                horizontal={true}
                categories={incomeFunnel.map((i) => i.label)}
                series={[
                  {
                    data: incomeFunnel.map((i, o, a) => ({
                      ...i,
                      x: i.label,
                      y: [
                        (asEuro(a[0].value).value - asEuro(i.value).value) / 2,
                        (asEuro(a[0].value).value - asEuro(i.value).value) / 2 +
                        asEuro(i.value).value,
                      ],
                    })),
                  },
                ]}
              ></ApexRangeBarChart>
            </>}
          </WorkerSuspense>
          <RecommendationBlock
            checks={sectionChecks.profitability}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></RecommendationBlock>



          <CheckListSummary
            checks={sectionChecks.profitabilityDetails}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>

          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="pnl"
            workerArgs={fullArgs}
          >
            {(pnl) => <Scrollable>
              <LabelValueListTable
                id="profitabilitydetails"
                headers={[
                  <>
                    <span className="me-2">Detalhe</span>
                    <CopyToClipBoard target="#profitabilitydetails"></CopyToClipBoard>
                  </>,
                  ...yearLabels,
                ]}
                data={pnl}
                totals={pnl.map((i) => i.total)}
              ></LabelValueListTable>
            </Scrollable>}
          </WorkerSuspense>

          <RecommendationBlock
            checks={sectionChecks.profitabilityDetails}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></RecommendationBlock>
        </Card>

        <Card className="my-2">
          <SectionIntro>
            A análise de fundo de maneio permite perceber se há capital
            empatado (em Clientes e/ou Inventários) em demasia e que pode ser
            liberto para se usado para financiar as operações correntes, por
            exemplos, mais compras a fornecedores.
          </SectionIntro>
          <h3>Fundo de Maneio</h3>

          <CheckListSummary
            checks={sectionChecks.workingCapital}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>

          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="wc"
            workerArgs={fullArgs}
          >
            {(wc) => <Scrollable>
              <LabelValueListTable
                id="wc"
                data={wc}
                headers={[
                  <CopyToClipBoard target="#wc"></CopyToClipBoard>,
                  ...yearLabels,
                ]}
                totals={wc.map((i) => i.total)}
              ></LabelValueListTable>
            </Scrollable>}
          </WorkerSuspense>

          <RecommendationBlock
            checks={sectionChecks.workingCapital}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></RecommendationBlock>

          <CheckListSummary
            checks={sectionChecks.workingCapitalDays}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>

          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="wcDays"
            workerArgs={selectedArgs}
          >
            {(wcDays) => <>
              <Scrollable>
                <LabelValueListTable
                  id="wcDays"
                  headers={[
                    <CopyToClipBoard target="#wcDays"></CopyToClipBoard>,
                    ...yearLabels,
                  ]}
                  data={wcDays}
                ></LabelValueListTable>
              </Scrollable>

              <ApexBarChart
                horizontal={true}
                stacked={true}
                header="Dias de Fundo de Maneio"
                categories={["Clientes e Inventários", "Fornecedores"]}
                series={[
                  {
                    name: "Dias de clientes",
                    data: [asEuro(wcDays[0].value).value, 0],
                  },
                  {
                    name: "Dias de inventários",
                    data: [asEuro(wcDays[1].value).value, 0],
                  },
                  {
                    name: "Dias de fornecedores",
                    data: [0, asEuro(wcDays[2].value).value],
                  },
                ]}
              ></ApexBarChart>
            </>
            }
          </WorkerSuspense>

          <RecommendationBlock
            checks={sectionChecks.workingCapitalDays}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></RecommendationBlock>

          <CheckListSummary
            checks={sectionChecks.simpleWorkingCapital}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>

          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="simpleWC"
            workerArgs={fullArgs}
          >
            {(simpleWC) => <Scrollable>
              <LabelValueListTable
                id="simpleWC"
                data={simpleWC}
                headers={[
                  <CopyToClipBoard target="#simpleWC"></CopyToClipBoard>,
                  ...yearLabels,
                ]}
                totals={simpleWC.map((i) => i.total)}
              ></LabelValueListTable>
            </Scrollable>}
          </WorkerSuspense>

          <RecommendationBlock
            checks={sectionChecks.simpleWorkingCapital}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></RecommendationBlock>

          <CheckListSummary
            checks={sectionChecks.otherWorkingCapital}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>

          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="otherWC"
            workerArgs={fullArgs}
          >
            {(otherWC) => <Scrollable>
              <LabelValueListTable
                id="otherWC"
                data={otherWC}
                headers={[
                  <CopyToClipBoard target="#otherWC"></CopyToClipBoard>,
                  ...yearLabels,
                ]}
                totals={otherWC.map((i) => i.total)}
              ></LabelValueListTable>
            </Scrollable>}
          </WorkerSuspense>

          <RecommendationBlock
            checks={sectionChecks.otherWorkingCapital}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></RecommendationBlock>
        </Card>

        <Card className="my-2">
          <SectionIntro>
            A análise de variação de fundo de maneio permite perceber se
            actividade da empresa está a exigir investimento (em Inventários e
            a financiar Clientes) por exemplo devido ao crescimto ou se por
            outro lado a empresa está a desinvestir porque está o nível de
            actividade está a cair.
          </SectionIntro>
          <h3>Variação de Fundo de Maneio</h3>

          <CheckListSummary
            checks={sectionChecks.workingCapitalChanges}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>

          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="wcChange"
            workerArgs={fullArgs}
          >
            {(wcChange) => <Scrollable>
              <LabelValueListTable
                id="wcChange"
                headers={[
                  <CopyToClipBoard target="#wcChange"></CopyToClipBoard>,
                  ...yearLabels,
                ]}
                data={wcChange}
                totals={wcChange.map((i) => i.total)}
              ></LabelValueListTable>
            </Scrollable>}
          </WorkerSuspense>

          <RecommendationBlock
            checks={sectionChecks.workingCapitalChanges}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></RecommendationBlock>

          <CheckListSummary
            checks={sectionChecks.simpleWorkingCapitalChangeDetails}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>

          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="simpleWcChange"
            workerArgs={fullArgs}
          >
            {(simpleWcChange) => <Scrollable>
              <LabelValueListTable
                id="simpleWcChange"
                headers={[
                  <CopyToClipBoard target="#simpleWcChange"></CopyToClipBoard>,
                  ...yearLabels,
                ]}
                data={simpleWcChange}
                totals={simpleWcChange.map((i) => i.total)}
              ></LabelValueListTable>
            </Scrollable>}
          </WorkerSuspense>

          <RecommendationBlock
            checks={sectionChecks.simpleWorkingCapitalChangeDetails}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></RecommendationBlock>

          <CheckListSummary
            checks={sectionChecks.otherWorkingCapitalChangeDetails}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="otherWcChange"
            workerArgs={fullArgs}
          >
            {(otherWcChange) => <Scrollable>
              <LabelValueListTable
                id="otherWcChange"
                headers={[
                  <CopyToClipBoard target="#otherWcChange"></CopyToClipBoard>,
                  ...yearLabels,
                ]}
                data={otherWcChange}
                totals={otherWcChange.map((i) => i.total)}
              ></LabelValueListTable>
            </Scrollable>}
          </WorkerSuspense>
          <RecommendationBlock
            checks={sectionChecks.otherWorkingCapitalChangeDetails}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></RecommendationBlock>
        </Card>

        <Card className="my-2">
          <SectionIntro>
            Esta secção permite resumir a aplicação de fluxos de caixa em
            investimentos em activos fixos tangíveis.
          </SectionIntro>
          <h3>Capex / Alienações</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="capex"
            workerArgs={fullArgs}
          >
            {(capex) => <Scrollable>
              <LabelValueListTable
                id="capex"
                headers={[
                  <CopyToClipBoard target="#capex"></CopyToClipBoard>,
                  ...yearLabels,
                ]}
                data={capex}
                totals={capex.map((i) => i.total)}
              ></LabelValueListTable>
            </Scrollable>}
          </WorkerSuspense>
        </Card>

        <Card className="my-2">
          <SectionIntro>
            Esta secção permite resumir a aplicação de fluxos de caixa em
            investimentos em outros investimentos.
          </SectionIntro>
          <h3>Outros investimentos / desinvestimentos</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="financialInvestments"
            workerArgs={fullArgs}
          >
            {(financialInvestments) => <Scrollable>
              <LabelValueListTable
                id="financialInvestments"
                headers={[
                  <CopyToClipBoard target="#financialInvestments"></CopyToClipBoard>,
                  ...yearLabels,
                ]}
                data={financialInvestments}
                totals={financialInvestments.map((i) => i.total)}
              ></LabelValueListTable>
            </Scrollable>}
          </WorkerSuspense>
        </Card>

        <Card className="my-2">
          <SectionIntro>
            Esta secção permite identificar as várias componentes do serviço
            da dívida: financiamentos correntes, financiamentos não-correntes
            e custos de financiamento.
          </SectionIntro>
          <h3>Serviço da Dívida</h3>

          <CheckListSummary
            checks={sectionChecks.debtService}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>

          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="debtServiceAndDebtChangeDetails"
            workerArgs={fullArgs}
          >
            {({ debtService, debtChangeDetails }) => <>
              <Scrollable>
                <LabelValueListTable
                  id="debtService"
                  headers={[
                    <CopyToClipBoard target="#debtService"></CopyToClipBoard>,
                    ...yearLabels,
                  ]}
                  data={debtService}
                  totals={debtService.map((i) => i.total)}
                ></LabelValueListTable>
              </Scrollable>
              <Scrollable>
                <LabelValueListTable
                  id="debtChangeDetails"
                  headers={[
                    <CopyToClipBoard target="#debtChangeDetails"></CopyToClipBoard>,
                    ...yearLabels,
                  ]}
                  data={debtChangeDetails}
                  totals={debtChangeDetails.map((i) => i.total)}
                ></LabelValueListTable>
              </Scrollable>
            </>}
          </WorkerSuspense>

          <RecommendationBlock
            checks={sectionChecks.debtService}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></RecommendationBlock>
        </Card>

        <Card className="my-2">
          <SectionIntro>
            Esta secção analise potenciais sobrecustos financeiros: (1) juros
            acima do praticado pelo mercado e (2) outros custos financeiros
            (comissões, etc.) que tornem o financeiro mais caro do que olhando
            apenas para os juros.
          </SectionIntro>
          <h3>Custos de Financiamento</h3>
          <CheckListSummary
            checks={sectionChecks.financeEfficiency}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="financingCostsAndExtendedFinancingCosts"
            workerArgs={fullArgs}
          >
            {({ allIn, financingCosts, extendedFinancingCosts }) => <>
              <Scrollable>
                <LabelValueListTable
                  id="allin"
                  headers={[
                    <CopyToClipBoard target="#allin"></CopyToClipBoard>,
                    ...yearLabels,
                  ]}
                  data={allIn}
                  totals={allIn.map((i) => i.total)}
                ></LabelValueListTable>
              </Scrollable>
              <Scrollable>
                <LabelValueListTable
                  id="financingCosts"
                  headers={[
                    <CopyToClipBoard target="#financingCosts"></CopyToClipBoard>,
                    ...yearLabels,
                  ]}
                  data={financingCosts}
                  totals={financingCosts.map((i) => i.total)}
                ></LabelValueListTable>
              </Scrollable>
              <Scrollable>
                <LabelValueListTable
                  id="extendedFinancingCosts"
                  headers={[
                    <CopyToClipBoard target="#extendedFinancingCosts"></CopyToClipBoard>,
                    ...yearLabels,
                  ]}
                  data={extendedFinancingCosts}
                  totals={extendedFinancingCosts.map((i) => i.total)}
                ></LabelValueListTable>
              </Scrollable>
            </>}
          </WorkerSuspense>
          <RecommendationBlock
            checks={sectionChecks.financeEfficiency}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></RecommendationBlock>
        </Card>

        <Card className="my-2">
          <SectionIntro>
            Esta secção identificar potenciais variações do capital próprio da
            empresa, em particular, se houve distribuição de dividendos,
            aumentos de capital, etc.
          </SectionIntro>
          <h3>Dividendos e outras variações de capital</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="dividends"
            workerArgs={fullArgs}
          >
            {(dividends) => <Scrollable>
              <LabelValueListTable
                id="dividends"
                headers={[
                  <CopyToClipBoard target="#dividends"></CopyToClipBoard>,
                  ...yearLabels,
                ]}
                data={dividends}
                totals={dividends.map((i) => i.total)}
              ></LabelValueListTable>
            </Scrollable>}
          </WorkerSuspense>
        </Card>

        <Card className="my-2">
          <SectionIntro>
            Esta secção visa alertar para possíveis fragilidades financeiras
            da empresas, tanto actuais como futuras, permitindo a simulação de
            cenários de queda do volume de negócios.
          </SectionIntro>
          <h3>Robustez Financeira</h3>

          <CheckListSummary
            checks={[
              ...sectionChecks.robustness,
              ...sectionChecks.runways,
            ]}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{
              [Fields.FTEs]: fte,
              ...robustnessScenarioInputs,
            }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="financingRobustnessScenariosAndRunways"
            workerArgs={fullArgs}
          >
            {({ financing, robustnessScenarios, runways }) => <>
              <Scrollable>
                <LabelValueListTable
                  id="financing"
                  headers={[
                    <CopyToClipBoard target="#financing"></CopyToClipBoard>,
                    ...yearLabels,
                  ]}
                  data={financing}
                  totals={financing.map((i) => i.total)}
                ></LabelValueListTable>
              </Scrollable>

              <Scrollable>
                <LabelValueListTable
                  id="robustnessScenarios"
                  headers={[
                    <>
                      <span className="me-2">
                        Cenário de queda do volume de negócios
                      </span>
                      <Select
                        options={revenueDeclineOptions}
                        selected={robustnessScenarioInputs.decline}
                        onChange={setDecline}
                      ></Select>
                      <CopyToClipBoard target="#robustnessScenarios"></CopyToClipBoard>
                    </>,
                    ...yearLabels,
                  ]}
                  data={robustnessScenarios}
                  totals={robustnessScenarios.map((i) => i.total)}
                ></LabelValueListTable>
              </Scrollable>

              <Scrollable>
                <LabelValueListTable
                  id="runways"
                  headers={[
                    <CopyToClipBoard target="#runways"></CopyToClipBoard>,
                    ...yearLabels,
                  ]}
                  data={runways}
                  totals={runways.map((i) => i.total)}
                ></LabelValueListTable>
              </Scrollable>
            </>}
          </WorkerSuspense>

          <RecommendationBlock
            checks={[
              ...sectionChecks.robustness,
              ...sectionChecks.runways,
            ]}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{
              [Fields.FTEs]: fte,
              ...robustnessScenarioInputs,
            }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></RecommendationBlock>
        </Card>

        <Card className="my-2">
          <SectionIntro>
            Esta secção perceber quanto mais rentável é a empresa do que
            outros investimentos alternativos. É típico pensar-se na
            lucrabilidade (ex: resultado operacional sobre volume de
            negócios), mas esta análise foca-se na rentabilidade (ex:
            resultado operacional sobre o capital empatado).
          </SectionIntro>
          <h3>Retorno</h3>
          <CheckListSummary
            checks={sectionChecks.returns}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="FinancialAnalysis.worker.js"
            workerFn="returnability"
            workerArgs={fullArgs}
          >
            {(returnability) => <Scrollable>
              <LabelValueListTable
                id="return"
                headers={[
                  <CopyToClipBoard target="#return"></CopyToClipBoard>,
                  ...yearLabels,
                ]}
                data={returnability}
              ></LabelValueListTable>
            </Scrollable>}
          </WorkerSuspense>
          <RecommendationBlock
            checks={sectionChecks.returns}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            db={db}
            labels={labels}
            company={{ [Fields.FTEs]: fte }}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></RecommendationBlock>
        </Card>
      </>}
    </WorkerSuspense >
  )

};

const CheckListSummary = ({
  checks,
  processed,
  statements,
  engine,
  header,
  className,
  db,
  labels,
  company,
  years,
  selectedYear,
  onYearChange,
}) => {
  const yearOffset = years && years.indexOf(selectedYear);
  console.log(db);
  const checksReadyToBePerformed =
    checks && checks.filter((check) => !check.benchmarkValue || (db && labels && company));
  const results =
    checksReadyToBePerformed &&
    runChecks(
      checksReadyToBePerformed,
      processed,
      statements,
      engine,
      db,
      labels,
      company,
      yearOffset
    );
  console.log({ results });
  const trueResults =
    results &&
    results.filter(
      (result) => result && result.values && result.values[yearOffset] === true
    );
  return (
    <div
      className={[
        "bg-warning bg-opacity-25 rounded px-2 py-2",
        className || "",
      ].join(" ")}
    >
      <div className="float-end">
        <Select
          options={years}
          selected={selectedYear}
          onChange={onYearChange}
        ></Select>
      </div>
      {header || <b>Alertas</b>}
      <div className="d-flex flex-column">
        {trueResults &&
          trueResults.length > 0 &&
          trueResults.map((result, offset) => (
            <CheckResult key={offset} result={result} />
          ))}
        {trueResults && trueResults.length === 0 && (
          <div className="text-muted">Sem alertas relevantes</div>
        )}
      </div>
    </div>
  );
};

const CheckResult = ({ result }) => (
  <div>
    <i className="bi bi-exclamation-triangle-fill text-danger me-2"></i>
    {result.description}{" "}
    {result.benchmark !== undefined && (
      <small className="text-muted">
        Empresa com {format(asEuro(result.company).value, result.format)} vs{" "}
        {format(asEuro(result.benchmark).value, result.format)} do sector{" "}
      </small>
    )}
    {result.threshold !== undefined && (
      <small className="text-muted">
        Empresa com {format(asEuro(result.company).value, result.format)} vs{" "}
        {format(asEuro(result.threshold).value, result.format)} como referência
      </small>
    )}
  </div>
);

const format = (rawValue, format) =>
  format ? formatValue(rawValue, format) : rawValue;

const RecommendationBlock = ({
  checks,
  processed,
  statements,
  engine,
  db,
  labels,
  company,
  years,
  selectedYear,
  onYearChange,
}) => (
  <>
    <RecomendationSummary
      className="my-3"
      header={<b>Recomendações para a Gestão</b>}
      checks={checks}
      processed={processed}
      statements={statements}
      engine={engine}
      db={db}
      labels={labels}
      company={company}
      type={RecomendationType.Company}
      years={years}
      selectedYear={selectedYear}
      onYearChange={onYearChange}
    ></RecomendationSummary>

    <RecomendationSummary
      className="my-3"
      header={<b>Recomendações para a interacção com a Banca/Investidores</b>}
      checks={checks}
      processed={processed}
      statements={statements}
      engine={engine}
      db={db}
      labels={labels}
      company={company}
      type={RecomendationType.Bank}
      years={years}
      selectedYear={selectedYear}
      onYearChange={onYearChange}
    ></RecomendationSummary>
  </>
);

const RecomendationSummary = ({
  checks,
  processed,
  statements,
  engine,
  header,
  className,
  db,
  labels,
  company,
  type = RecomendationType.Company,
  years,
  selectedYear,
  onYearChange,
}) => {
  const yearOffset = years && years.indexOf(selectedYear);
  console.log({ db })
  const checksReadyToBePerformed =
    checks && checks.filter((check) => !check.benchmarkValue || (db && labels && company));
  const results =
    checksReadyToBePerformed &&
    runChecks(
      checksReadyToBePerformed,
      processed,
      statements,
      engine,
      db,
      labels,
      company,
      yearOffset
    );
  console.log({ results });
  const trueResults =
    results &&
    results.filter(
      (result) => result && result.values && result.values[yearOffset] === true
    );
  const recommendationDescriptions = trueResults && [
    ...new Set(
      trueResults
        .filter((result) => result.recommendations)
        .flatMap((result) =>
          result.recommendations
            .filter((r) => r.target === type)
            .map((r) => r.description)
        )
    ),
  ];

  return (
    <div
      className={[
        "bg-info bg-opacity-10 rounded px-2 py-2",
        className || "",
      ].join(" ")}
    >
      <div className="float-end">
        <Select
          options={years}
          selected={selectedYear}
          onChange={onYearChange}
        ></Select>
      </div>
      {header || <b>Alertas</b>}
      <div className="d-flex flex-column mt-2">
        {recommendationDescriptions &&
          recommendationDescriptions.length > 0 &&
          recommendationDescriptions.map((description, offset) => (
            <RecommendationItem key={offset} description={description} />
          ))}
        {recommendationDescriptions &&
          recommendationDescriptions.length === 0 && (
            <div className="text-muted">Sem recomendações relevantes</div>
          )}
      </div>
    </div>
  );
};

const RecommendationItem = ({ description }) => (
  <div className="d-flex flex-row mb-2">
    <div className="me-2">
      <i className="bi bi-info-square-fill text-primary"></i>
    </div>
    <div>{description} </div>
  </div>
);

const useRevenueScenario = (initialDecline = 0.25) => {
  const [robustnessScenarioInputs, setRobustnessScenarioInputs] = useState({
    decline: initialDecline,
    revenueMultiply: 1 - initialDecline,
    cogsMultiply: 1 - initialDecline,
  });

  const setDecline = (decline = 0.25) =>
    setRobustnessScenarioInputs({
      decline,
      revenueMultiply: 1 - decline,
      cogsMultiply: 1 - decline,
    });

  return { robustnessScenarioInputs, setDecline };
};

const monthAcronym = (date) => {
  const month = date && date.getMonth() + 1;
  const Acronynms = {
    undefined: "",
    1: "Jan",
    2: "Fev",
    3: "Mar",
    4: "Abr",
    5: "Mai",
    6: "Jun",
    7: "Jul",
    8: "Ago",
    9: "Set",
    10: "Out",
    11: "Nov",
    12: "Dec",
  };
  return Acronynms[month];
};

const SectionIntro = ({ children, className }) => (
  <div
    className={`bg-light small text-secondary px-2 py-2 rounded mb-4 ${className || ""
      }`}
  >
    {children}
  </div>
);

const statementHasNoTaxOrNetIncome = (statement, engine) => {
  const symbols = [engine.StatementTree.ProfitAndLoss.Taxes, engine.StatementTree.Capital.NetIncome];
  const noTaxOrNetIncome = symbols.some(symbol => symbolHasNoSummaries(statement[symbol]));
  return noTaxOrNetIncome
}

const symbolHasNoSummaries = (symbol) => Object.keys(symbol || {}).filter(key => key && !key.includes('__summary')).length === 0;

function initState(setProcessing, statements, sectorData, years, selectedYear, processed, engines, engine, refEngine, robustnessScenarioInputs, setState) {
  return () => {
    setProcessing(true);

    const firstStatement = statements && statements[0];

    const { cae, dim, fte, db, labels } = sectorData;

    const selectedYearIndex = years.indexOf(selectedYear);
    const selectedProcessed = processed && processed[selectedYearIndex];
    const selectedEngine = engines && engines[selectedYearIndex];

    const yearLabels = years.map((_year, offset) => (
      <span>
        {_year}
        {isNotFullYear(processed[offset]) ? (
          <sup>{monthAcronym(new Date(processed[offset].header.endDate))}</sup>
        ) : (
          ""
        )}
      </span>
    ));

    const isPartialArray = processed.map((p, o) => ({ partial: isNotFullYear(p) || statementHasNoTaxOrNetIncome(statements[o], engines[o]) }));

    const _moaf = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "MOAF",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const _cashFromResults = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "CashFromResultsDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _wc =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.WorkingCapitalDetails,
    //     statements
    //   );
    const _wc = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "WorkingCapitalDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _simpleWC =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.SimpleWorkingCapitalDetails,
    //     statements
    //   );
    const _simpleWC = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "SimpleWorkingCapitalDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _otherWC =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.OtherWorkingCapitalDetails,
    //     statements
    //   );
    const _otherWC = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "OtherWorkingCapitalDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const wcDays = firstStatement &&
      engine.calcRatioList(
        engine.Ratios.WorkingCapitalDaysDetails({}),
        firstStatement.report,
        firstStatement.previousReport
      );

    // const _wcDays =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.WorkingCapitalDaysDetails,
    //     statements
    //   );
    const _wcDays = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "WorkingCapitalDaysDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _wcChange =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.WorkingCapitalChangeDetails,
    //     statements
    //   );
    const _wcChange = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "WorkingCapitalChangeDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _simpleWcChange =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.SimpleWorkingCapitalChangeDetails,
    //     statements
    //   );
    const _simpleWcChange = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "SimpleWorkingCapitalChangeDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _otherWcChange =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.OtherWorkingCapitalChangeDetails,
    //     statements
    //   );
    const _otherWcChange = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "OtherWorkingCapitalChangeDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _capex =
    //   statements &&
    //   engine.calcRatioListMultiYear(engine.Ratios.CAPEXDetails, statements);
    const _capex = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "CAPEXDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _financialInvestments =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.FinancialInvestmentDetails,
    //     statements
    //   );
    const _financialInvestments = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "FinancialInvestmentDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _debtService =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.DebtServiceDetails,
    //     statements
    //   );
    const _debtService = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "DebtServiceDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const _debtChangeDetails = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "DebtChangeDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _dividends =
    //   statements &&
    //   engine.calcRatioListMultiYear(engine.Ratios.DividendDetails, statements);
    const _dividends = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "DividendDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _cash =
    //   statements &&
    //   engine.calcRatioListMultiYear(engine.Ratios.CashDetails, statements);
    const _cash = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "CashDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _profitability =
    //   statements &&
    //   engine.calcRatioListMultiYear(engine.Ratios.Profitability, statements);
    const _profitability = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "Profitability",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const _pnl = engine &&
      (statements && statements.length === 1
        ? engine.calcMultiEngineConfigurableRatioListMultiYear(
          "ProfitAndLossOneYear",
          engines,
          refEngine,
          isPartialArray,
          statements,
          undefined /* no sourcemap */,
          true /* descending */
        )
        : engine.calcMultiEngineConfigurableRatioListMultiYear(
          "ProfitAndLossMultipleYear",
          engines,
          refEngine,
          isPartialArray,
          statements,
          undefined /* no sourcemap */,
          true /* descending */
        ));

    const _operatingLeverage = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "OperatingLeverage",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const _returnability = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "Returnability",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const _financing = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "Financing",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _robustnessScenarios =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.RobustnessScenarios,
    //     statements,
    //     {input: robustnessScenarioInputs }
    //   );
    const _robustnessScenarios = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "RobustnessScenarios",
        engines,
        refEngine,
        isPartialArray,
        statements,
        { input: robustnessScenarioInputs },
        true /* descending */
      );

    const _runways = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "Runways",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const _allIn = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "FinancingAllInDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const _financingCosts = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "FinancingCostDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );
    const _extendedFinancingCosts = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "ExtendedFinancialCostDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const incomeFunnel = selectedEngine &&
      selectedEngine.calcRatioList(
        selectedEngine.Ratios.IncomeFunnel({
          partial: isNotFullYear(processed[0]) || statementHasNoTaxOrNetIncome(statements[0], engines[0]),
        }),
        firstStatement.report,
        firstStatement.previousReport
      );

    const sectionChecks = sectionChecksForEngine(selectedEngine, {
      partial: isNotFullYear(selectedProcessed) || statementHasNoTaxOrNetIncome(statements[selectedYearIndex], selectedEngine),
    });

    setState({
      sectionChecks,
      labels,
      db,
      _moaf,
      _cash,
      _cashFromResults,
      incomeFunnel,
      _wc,
      _wcChange,
      wcDays,
      _wcDays,
      _pnl,
      _profitability,
      _operatingLeverage,
      _otherWC,
      _otherWcChange,
      _simpleWC,
      _simpleWcChange,
      _robustnessScenarios,
      _runways,
      _returnability,
      _financingCosts,
      _extendedFinancingCosts,
      _allIn,
      _financing,
      _dividends,
      _debtService,
      _debtChangeDetails,
      _financialInvestments,
      _capex,
      selectedEngine,
      yearLabels,
    });
    setProcessing(false);
  };
}

function computeState(setProcessing, statements, sectorData, years, selectedYear, processed, engines, engine, refEngine, robustnessScenarioInputs, setState) {
  return () => {
    const firstStatement = statements && statements[0];

    const { cae, dim, fte, db, labels } = sectorData;

    const selectedYearIndex = years.indexOf(selectedYear);
    const selectedProcessed = processed && processed[selectedYearIndex];
    const selectedEngine = engines && engines[selectedYearIndex];

    const yearLabels = years.map((_year, offset) => (
      <span>
        {_year}
        {isNotFullYear(processed[offset]) ? (
          <sup>{monthAcronym(new Date(processed[offset].header.endDate))}</sup>
        ) : (
          ""
        )}
      </span>
    ));

    const isPartialArray = processed.map((p, o) => ({ partial: isNotFullYear(p) || statementHasNoTaxOrNetIncome(statements[o], engines[o]) }));

    const _moaf = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "MOAF",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const _cashFromResults = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "CashFromResultsDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _wc =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.WorkingCapitalDetails,
    //     statements
    //   );
    const _wc = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "WorkingCapitalDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _simpleWC =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.SimpleWorkingCapitalDetails,
    //     statements
    //   );
    const _simpleWC = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "SimpleWorkingCapitalDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _otherWC =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.OtherWorkingCapitalDetails,
    //     statements
    //   );
    const _otherWC = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "OtherWorkingCapitalDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const wcDays = firstStatement &&
      engine.calcRatioList(
        engine.Ratios.WorkingCapitalDaysDetails({}),
        firstStatement.report,
        firstStatement.previousReport
      );

    // const _wcDays =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.WorkingCapitalDaysDetails,
    //     statements
    //   );
    const _wcDays = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "WorkingCapitalDaysDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _wcChange =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.WorkingCapitalChangeDetails,
    //     statements
    //   );
    const _wcChange = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "WorkingCapitalChangeDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _simpleWcChange =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.SimpleWorkingCapitalChangeDetails,
    //     statements
    //   );
    const _simpleWcChange = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "SimpleWorkingCapitalChangeDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _otherWcChange =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.OtherWorkingCapitalChangeDetails,
    //     statements
    //   );
    const _otherWcChange = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "OtherWorkingCapitalChangeDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _capex =
    //   statements &&
    //   engine.calcRatioListMultiYear(engine.Ratios.CAPEXDetails, statements);
    const _capex = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "CAPEXDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _financialInvestments =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.FinancialInvestmentDetails,
    //     statements
    //   );
    const _financialInvestments = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "FinancialInvestmentDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _debtService =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.DebtServiceDetails,
    //     statements
    //   );
    const _debtService = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "DebtServiceDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const _debtChangeDetails = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "DebtChangeDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _dividends =
    //   statements &&
    //   engine.calcRatioListMultiYear(engine.Ratios.DividendDetails, statements);
    const _dividends = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "DividendDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _cash =
    //   statements &&
    //   engine.calcRatioListMultiYear(engine.Ratios.CashDetails, statements);
    const _cash = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "CashDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _profitability =
    //   statements &&
    //   engine.calcRatioListMultiYear(engine.Ratios.Profitability, statements);
    const _profitability = engine &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "Profitability",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const _pnl = engine &&
      (statements && statements.length === 1
        ? engine.calcMultiEngineConfigurableRatioListMultiYear(
          "ProfitAndLossOneYear",
          engines,
          refEngine,
          isPartialArray,
          statements,
          undefined /* no sourcemap */,
          true /* descending */
        )
        : engine.calcMultiEngineConfigurableRatioListMultiYear(
          "ProfitAndLossMultipleYear",
          engines,
          refEngine,
          isPartialArray,
          statements,
          undefined /* no sourcemap */,
          true /* descending */
        ));

    const _operatingLeverage = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "OperatingLeverage",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const _returnability = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "Returnability",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const _financing = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "Financing",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    // const _robustnessScenarios =
    //   statements &&
    //   engine.calcRatioListMultiYear(
    //     engine.Ratios.RobustnessScenarios,
    //     statements,
    //     {input: robustnessScenarioInputs }
    //   );
    const _robustnessScenarios = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "RobustnessScenarios",
        engines,
        refEngine,
        isPartialArray,
        statements,
        { input: robustnessScenarioInputs },
        true /* descending */
      );

    const _runways = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "Runways",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const _allIn = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "FinancingAllInDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const _financingCosts = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "FinancingCostDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );
    const _extendedFinancingCosts = statements &&
      engine.calcMultiEngineConfigurableRatioListMultiYear(
        "ExtendedFinancialCostDetails",
        engines,
        refEngine,
        isPartialArray,
        statements,
        undefined /* no sourcemap */,
        true /* descending */
      );

    const incomeFunnel = selectedEngine &&
      selectedEngine.calcRatioList(
        selectedEngine.Ratios.IncomeFunnel({
          partial: isNotFullYear(processed[0]) || statementHasNoTaxOrNetIncome(statements[0], engines[0]),
        }),
        firstStatement.report,
        firstStatement.previousReport
      );

    const sectionChecks = sectionChecksForEngine(selectedEngine, {
      partial: isNotFullYear(selectedProcessed) || statementHasNoTaxOrNetIncome(statements[selectedYearIndex], selectedEngine),
    });


    setProcessing(false);
    return {
      sectionChecks,
      labels,
      db,
      _moaf,
      _cash,
      _cashFromResults,
      incomeFunnel,
      _wc,
      _wcChange,
      wcDays,
      _wcDays,
      _pnl,
      _profitability,
      _operatingLeverage,
      _otherWC,
      _otherWcChange,
      _simpleWC,
      _simpleWcChange,
      _robustnessScenarios,
      _runways,
      _returnability,
      _financingCosts,
      _extendedFinancingCosts,
      _allIn,
      _financing,
      _dividends,
      _debtService,
      _debtChangeDetails,
      _financialInvestments,
      _capex,
      selectedEngine,
      yearLabels,
    };
  };
}
