export const StatementTree = {
  Assets: {
    Total: Symbol("Total do ativo"),
    NonCurrent: {
      Total: Symbol("Ativo não corrente"),
      FixedAssets: Symbol("Ativos fixos tangíveis"),
      InvestmentProperties: Symbol("Propriedades de investimento"),
      IntangibleAssets: Symbol("Ativos intangíveis"),
      FinancialInvestments: Symbol("Investimentos financeiros"),
      //
      BiologicalAssets: Symbol("Ativos biológicos"),
      FinantialInterests: Symbol("Participações financeiras"),
      Goodwill: Symbol("Goodwill"),
      OtherFinancialInvestments: Symbol("Outros investimentos financeiros"),
      OtherReceivables: Symbol("Outros créditos a receber"),
      OtherReceivables2: Symbol("Outros créditos a receber (A2)"),
      DeferredTaxAssets: Symbol("Ativos por Impostos diferidos"),
    },
    Current: {
      Total: Symbol("Ativo corrente"),
      TradeableFinantialAssets: Symbol(
        "Ativos financeiros detidos para negociação"
      ),
      OtherFinancialAssets: Symbol("Outros ativos financeiros"),
      OtherReceivables2: Symbol("Outras contas a receber (A2)"),
      UnrealizedButSubscribedCapital: Symbol(
        "Capital subscrito e não realizado"
      ),
      BiologicalAssets: Symbol("Ativos biológicos"),

      NonCurrentAssetsHoldForSales: Symbol(
        "Ativos não correntes detidos para venda"
      ),
      Shareholders: Symbol("Accionistas/sócios"),
      OtherReceivables: Symbol("Outras contas a receber"),
      Deferrals: Symbol("Diferimentos"),
      Clients: Symbol("Clientes"),
      Inventory: Symbol("Inventários"),
      StateAndOtherPublicEntitities: Symbol("Estado e Outros Entes Públicos"),
      Cash: Symbol("Caixa e depósitos bancários"),
    },
  },
  LiabilitiesAndCapital: {
    Total: Symbol("Total do passivo e do capital próprio"),
  },
  Liabilities: {
    Total: Symbol("Total do passivo"),
    NonCurrent: {
      Total: Symbol("Passivo não corrente"),
      Provisions: Symbol("Provisões"),
      Loans: Symbol("Financiamentos obtidos"),
      OtherPayables: Symbol("Outras Contas a pagar"),
      PersonnelBenefits: Symbol("Responsabilidades por benefícios pós-emprego"),
      DeferredTaxLiabilities: Symbol("Passivos por impostos diferidos"),
    },
    Current: {
      Total: Symbol("Passivo corrente"),
      TradeableLiabilities: Symbol(
        "Passivos financeiros detidos para negociação"
      ),
      OtherFinantialLiabilities: Symbol("Outros passivos financeiros"),
      AdvancementsFromClients2: Symbol("Adiantamentos de clientes (A2)"),
      AdvancementsFromClients: Symbol("Adiantamentos de clientes"),
      Deferrals: Symbol("Diferimentos"),
      NonCurrentLiabilitiesHeldForSales: Symbol(
        "Passivos não correntes detidos para venda"
      ),
      Providers: Symbol("Fornecedores"),
      StateAndOtherPublicEntitities: Symbol("Estado e Outros Entes Públicos"),
      Shareholders: Symbol("Accionistas/sócios"),
      Loans: Symbol("Financiamentos Obtidos"),
      OtherPayables: Symbol("Outras contas a pagar"),
    },
  },
  Capital: {
    Total: Symbol("Capital Próprio"),
    SubscribedCapital: Symbol("Capital subscrito"),
    OwnShare: Symbol("Acções (quotas próprias)"),
    OtherCapitalInstruments: Symbol("Outros instrumentos de capital próprio"),
    LegalReserves: Symbol("Reservas legais"),
    RevaluationSurplus: Symbol("Excedentes de revalorização"),
    OtherCapitalChanges: Symbol("Outras variações no capital próprio"),
    IssuingPremia: Symbol("Prémios de emissão"),
    OtherReserves: Symbol("Outras reservas"),
    PastResults: Symbol("Resultados transitados"),
    AntecipatedDividends: Symbol("Dividendos antecipados"),
    NetProfit: Symbol("Resultado líquido do período"),
  },
  ProfitAndLoss: {
    SalesAndServicesRendered: Symbol("Vendas e serviços prestados"),
    Subsidies: Symbol("Subsídios à exploração"),
    GainsAndLossesInAssociates: Symbol(
      "Ganhos / perdas imputados de subsidiárias, associadas e empreendimentos conjuntos"
    ),
    ChangeInProductionInventory: Symbol("Variação nos inventários da produção"),
    OwnWork: Symbol("Trabalhos para a própria entidade"),
    Materials: Symbol(
      "Custo das mercadorias vendidas e das matérias consumidas"
    ),
    ExternalServices: Symbol("Fornecimentos e serviços externos"),
    Personnel: Symbol("Gastos com o pessoal"),
    InventoryImpairments: Symbol(
      "Imparidade / Ajustamentos de inventários (perdas/reversões)"
    ),
    ReceivableImpairments: Symbol(
      "Imparidade de dívidas a receber (perdas/reversões)"
    ),
    Provisions: Symbol("Provisões (aumentos/reduções)"),
    FairValueChanges: Symbol("Aumentos / reduções de justo valor"),
    NonDandAInvestmentImpairments: Symbol(
      "Imparidade de investimentos não depreciáveis/amortizáveis (perdas/reversões)"
    ),
    OtherGains: Symbol("Outros rendimentos"),
    OtherExpenses: Symbol("Outros gastos"),
    EBITDA: Symbol("EBITDA"),
    DandA: Symbol("Gastos / reversões de depreciação e de amortização"),
    DandAInvestmentImpairments: Symbol(
      "Imparidade de investimentos depreciáveis / amortizáveis (perdas/reversões)"
    ),
    OperatingIncome: Symbol("Resultado operacional"),
    InterestIncome: Symbol("Juros e rendimentos similares obtidos"),
    InterestExpense: Symbol("Juros e gastos similares suportados"),
    EarningsBeforeTaxes: Symbol("Resultado antes de impostos"),
    Taxes: Symbol("Imposto sobre o rendimento do período"),
  },
};

export const BalanceSheetSymbols = [
  StatementTree.Assets.Total,
  ...Object.values(StatementTree.Assets.NonCurrent),
  ...Object.values(StatementTree.Assets.Current),
  StatementTree.LiabilitiesAndCapital.Total,
  ...Object.values(StatementTree.Capital),
  StatementTree.Liabilities.Total,
  ...Object.values(StatementTree.Liabilities.NonCurrent),
  ...Object.values(StatementTree.Liabilities.Current),
];

export const BalanceSheetTotals = [
  StatementTree.Assets.Total,
  StatementTree.Assets.NonCurrent.Total,
  StatementTree.Assets.Current.Total,
  StatementTree.Liabilities.Total,
  StatementTree.Liabilities.NonCurrent.Total,
  StatementTree.Liabilities.Current.Total,
  StatementTree.LiabilitiesAndCapital.Total,
  StatementTree.Capital.Total,
];

export const UncollapsableBalanceSheetItems = [...BalanceSheetTotals];

export const isBalanceSheetSymbol = (symbol) =>
  BalanceSheetSymbols.includes(symbol);

export const isBalanceSheetTotal = (symbol) => {
  return BalanceSheetTotals.includes(symbol);
};

export const ProfitAndLossSymbols = [
  ...Object.values(StatementTree.ProfitAndLoss),
  StatementTree.Capital.NetProfit,
];

export const ProfitAndLossTotals = [
  StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  StatementTree.ProfitAndLoss.EBITDA,
  StatementTree.ProfitAndLoss.OperatingIncome,
  StatementTree.ProfitAndLoss.EarningsBeforeTaxes,
  StatementTree.Capital.NetProfit,
];

export const UncollapsableProfitAndLossItems = [
  ...ProfitAndLossTotals,
  StatementTree.ProfitAndLoss.DandA,
  StatementTree.ProfitAndLoss.InterestExpense,
  StatementTree.ProfitAndLoss.Taxes,
];

export const isProfitAndLossSymbol = (symbol) =>
  ProfitAndLossSymbols.includes(symbol);

export const isProfitAndLossTotal = (symbol) => {
  return ProfitAndLossTotals.includes(symbol);
};

export const isUncollapsable = (symbol) =>
  [
    ...UncollapsableBalanceSheetItems,
    ...UncollapsableProfitAndLossItems,
  ].includes(symbol);

export const StatementOrdering = {
  Assets: [
    StatementTree.Assets.Total,
    StatementTree.Assets.NonCurrent.Total,
    StatementTree.Assets.NonCurrent.FixedAssets,
    StatementTree.Assets.NonCurrent.InvestmentProperties,
    StatementTree.Assets.NonCurrent.IntangibleAssets,
    StatementTree.Assets.NonCurrent.FinancialInvestments,
    StatementTree.Assets.Current.Total,
    StatementTree.Assets.Current.Inventory,
    StatementTree.Assets.Current.Clients,
    StatementTree.Assets.Current.StateAndOtherPublicEntitities,
    StatementTree.Assets.Current.Shareholders,
    StatementTree.Assets.Current.OtherReceivables,
    StatementTree.Assets.Current.Deferrals,
    StatementTree.Assets.Current.Cash,
  ],
  LiabilitiesAndCapital: [StatementTree.LiabilitiesAndCapital.Total],
  Liabilities: [
    StatementTree.Liabilities.Total,
    StatementTree.Liabilities.NonCurrent.Total,
    StatementTree.Liabilities.NonCurrent.Provisions,
    StatementTree.Liabilities.NonCurrent.Loans,
    StatementTree.Liabilities.NonCurrent.OtherPayables,
    StatementTree.Liabilities.Current.Total,
    StatementTree.Liabilities.Current.Providers,
    StatementTree.Liabilities.Current.StateAndOtherPublicEntitities,
    StatementTree.Liabilities.Current.Shareholders,
    StatementTree.Liabilities.Current.Loans,
    StatementTree.Liabilities.Current.OtherPayables,
  ],
  Capital: [
    StatementTree.Capital.Total,
    StatementTree.Capital.RealizedCapital,
    StatementTree.Capital.OwnShare,
    StatementTree.Capital.OtherCapitalInstruments,
    StatementTree.Capital.Reserves,
    StatementTree.Capital.RevaluationSurplus,
    StatementTree.Capital.OtherCapitalChanges,
    StatementTree.Capital.NetProfit,
  ],
  ProfitAndLoss: [
    StatementTree.ProfitAndLoss.SalesAndServicesRendered,
    StatementTree.ProfitAndLoss.Subsidies,
    StatementTree.ProfitAndLoss.GainsAndLossesInAssociates,
    StatementTree.ProfitAndLoss.ChangeInProductionInventory,
    StatementTree.ProfitAndLoss.OwnWork,
    StatementTree.ProfitAndLoss.Materials,
    StatementTree.ProfitAndLoss.ExternalServices,
    StatementTree.ProfitAndLoss.InventoryImpairments,
    StatementTree.ProfitAndLoss.ReceivableImpairments,
    StatementTree.ProfitAndLoss.FairValueChanges,
    StatementTree.ProfitAndLoss.NonDandAInvestmentImpairments,
    StatementTree.ProfitAndLoss.OtherGains,
    StatementTree.ProfitAndLoss.OtherGains,
    StatementTree.ProfitAndLoss.EBITDA,
    StatementTree.ProfitAndLoss.DandA,
    StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    StatementTree.ProfitAndLoss.OperatingIncome,
    StatementTree.ProfitAndLoss.InterestIncome,
    StatementTree.ProfitAndLoss.InterestExpense,
    StatementTree.ProfitAndLoss.EarningsBeforeTaxes,
    StatementTree.ProfitAndLoss.Taxes,
    StatementTree.Capital.NetProfit,
  ],
};

export const TaxonomyRules = [
  {
    taxonomyCode: "1",
    sncSvatCode: ["11"],
    sncSvatdescription: "Caixa",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Cash,
    creditField: "",
  },
  {
    taxonomyCode: "2",
    sncSvatCode: ["12"],
    sncSvatdescription: "Depósitos à ordem",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Cash,
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "3",
    sncSvatCode: ["13"],
    sncSvatdescription: "Outros depósitos bancários",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Cash,
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "4",
    sncSvatCode: ["1411"],
    sncSvatdescription:
      "Outros instrumentos financeiros - Derivados - Potencialmente favoráveis",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.TradeableFinantialAssets,
    creditField: "",
  },
  {
    taxonomyCode: "5",
    sncSvatCode: ["1412"],
    sncSvatdescription:
      "Outros instrumentos financeiros - Derivados - Potencialmente desfavoráveis",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.TradeableLiabilities,
  },
  {
    taxonomyCode: "6",
    sncSvatCode: ["1421"],
    sncSvatdescription:
      "Outros instrumentos financeiros - Instrumentos financeiros detidos para negociação - Ativos financeiros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.TradeableFinantialAssets,
    creditField: "",
  },
  {
    taxonomyCode: "7",
    sncSvatCode: ["1422"],
    sncSvatdescription:
      "Outros instrumentos financeiros - Instrumentos financeiros detidos para negociação - Passivos financeiros",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.TradeableLiabilities,
  },
  {
    taxonomyCode: "8",
    sncSvatCode: ["1431"],
    sncSvatdescription:
      "Outros instrumentos financeiros - Outros ativos e passivos financeiros - Outros ativos financeiros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.OtherFinancialAssets,
    creditField: "",
  },
  {
    taxonomyCode: "9",
    sncSvatCode: ["1432"],
    sncSvatdescription:
      "Outros instrumentos financeiros - Outros ativos e passivos financeiros - Outros passivos financeiros",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.OtherFinancialLiabilities,
  },
  {
    taxonomyCode: "10",
    sncSvatCode: ["2111"],
    sncSvatdescription: "Clientes - Clientes c/c - Clientes gerais",
    includeAccounts: ["2117", "2118", "2119"],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.AdvancementsFromClients,
  },
  {
    taxonomyCode: "11",
    sncSvatCode: ["2112"],
    sncSvatdescription: "Clientes - Clientes c/c - Clientes — empresa -mãe",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.AdvancementsFromClients,
  },
  {
    taxonomyCode: "12",
    sncSvatCode: ["2113"],
    sncSvatdescription:
      "Clientes - Clientes c/c - Clientes — empresas subsidiárias",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.AdvancementsFromClients,
  },
  {
    taxonomyCode: "13",
    sncSvatCode: ["2114"],
    sncSvatdescription:
      "Clientes - Clientes c/c - Clientes — empresas associadas",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.AdvancementsFromClients,
  },
  {
    taxonomyCode: "14",
    sncSvatCode: ["2115"],
    sncSvatdescription:
      "Clientes - Clientes c/c - Clientes — empreendimentos conjuntos",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.AdvancementsFromClients,
  },
  {
    taxonomyCode: "15",
    sncSvatCode: ["2116"],
    sncSvatdescription:
      "Clientes - Clientes c/c - Clientes — outras partes relacionadas",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.AdvancementsFromClients,
  },
  {
    taxonomyCode: "16",
    sncSvatCode: ["2121"],
    sncSvatdescription:
      "Clientes - Clientes — títulos a receber - Clientes gerais",
    includeAccounts: ["2127", "2128", "2129"],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.AdvancementsFromClients,
  },
  {
    taxonomyCode: "17",
    sncSvatCode: ["2122"],
    sncSvatdescription:
      "Clientes - Clientes — títulos a receber - Clientes — empresa -mãe",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.AdvancementsFromClients,
  },
  {
    taxonomyCode: "18",
    sncSvatCode: ["2123"],
    sncSvatdescription:
      "Clientes - Clientes — títulos a receber - Clientes — empresas subsidiárias",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.AdvancementsFromClients,
  },
  {
    taxonomyCode: "19",
    sncSvatCode: ["2124"],
    sncSvatdescription:
      "Clientes - Clientes — títulos a receber - Clientes — empresas associadas",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.AdvancementsFromClients,
  },
  {
    taxonomyCode: "20",
    sncSvatCode: ["2125"],
    sncSvatdescription:
      "Clientes - Clientes — títulos a receber - Clientes — empreendimentos conjuntos",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.AdvancementsFromClients,
  },
  {
    taxonomyCode: "21",
    sncSvatCode: ["2126"],
    sncSvatdescription:
      "Clientes - Clientes — títulos a receber - Clientes — outras partes relacionadas",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.AdvancementsFromClients,
  },
  {
    taxonomyCode: "22",
    sncSvatCode: ["213", "214", "215", "216", "217"],
    sncSvatdescription: "Clientes - Outros Clientes",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.AdvancementsFromClients,
  },
  {
    taxonomyCode: "23",
    sncSvatCode: ["218"],
    sncSvatdescription: "Clientes - Adiantamentos de clientes",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.AdvancementsFromClients,
  },
  {
    taxonomyCode: "24",
    sncSvatCode: ["219"],
    sncSvatdescription: "Clientes -  Clientes c/c - Clientes gerais",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "25",
    sncSvatCode: ["219"],
    sncSvatdescription: "Clientes -  Clientes c/c - Clientes — empresa -mãe",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "26",
    sncSvatCode: ["219"],
    sncSvatdescription:
      "Clientes -  Clientes c/c - Clientes — empresas subsidiárias",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "27",
    sncSvatCode: ["219"],
    sncSvatdescription:
      "Clientes -  Clientes c/c - Clientes — empresas associadas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "28",
    sncSvatCode: ["219"],
    sncSvatdescription:
      "Clientes -  Clientes c/c - Clientes — empreendimentos conjuntos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "29",
    sncSvatCode: ["219"],
    sncSvatdescription:
      "Clientes -  Clientes c/c - Clientes — outras partes relacionadas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "30",
    sncSvatCode: ["219"],
    sncSvatdescription:
      "Clientes -  Clientes — títulos a receber - Clientes gerais",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "31",
    sncSvatCode: ["219"],
    sncSvatdescription:
      "Clientes -  Clientes — títulos a receber - Clientes — empresa -mãe",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "32",
    sncSvatCode: ["219"],
    sncSvatdescription:
      "Clientes -  Clientes — títulos a receber - Clientes — empresas subsidiárias",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "33",
    sncSvatCode: ["219"],
    sncSvatdescription:
      "Clientes -  Clientes — títulos a receber - Clientes — empresas associadas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "34",
    sncSvatCode: ["219"],
    sncSvatdescription:
      "Clientes -  Clientes — títulos a receber - Clientes — empreendimentos conjuntos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "35",
    sncSvatCode: ["219"],
    sncSvatdescription:
      "Clientes -  Clientes — títulos a receber - Clientes — outras partes relacionadas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "36",
    sncSvatCode: ["219"],
    sncSvatdescription: "Clientes -  Outros Clientes",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "37",
    sncSvatCode: ["2211"],
    sncSvatdescription: "Fornecedores - Fornecedores c/c - Fornecedores gerais",
    includeAccounts: ["2217", "2218", "2219"],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "38",
    sncSvatCode: ["2212"],
    sncSvatdescription:
      "Fornecedores - Fornecedores c/c - Fornecedores — empresa -mãe",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "39",
    sncSvatCode: ["2213"],
    sncSvatdescription:
      "Fornecedores - Fornecedores c/c - Fornecedores — empresas subsidiárias",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "40",
    sncSvatCode: ["2214"],
    sncSvatdescription:
      "Fornecedores - Fornecedores c/c - Fornecedores — empresas associadas",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "41",
    sncSvatCode: ["2215"],
    sncSvatdescription:
      "Fornecedores - Fornecedores c/c - Fornecedores — empreendimentos conjuntos",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "42",
    sncSvatCode: ["2216"],
    sncSvatdescription:
      "Fornecedores - Fornecedores c/c - Fornecedores — outras partes relacionadas",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "43",
    sncSvatCode: ["2221"],
    sncSvatdescription:
      "Fornecedores - Fornecedores — títulos a pagar - Fornecedores gerais",
    includeAccounts: ["2227", "2228", "2229"],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "44",
    sncSvatCode: ["2222"],
    sncSvatdescription:
      "Fornecedores - Fornecedores — títulos a pagar - Fornecedores — empresa -mãe",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "45",
    sncSvatCode: ["2223"],
    sncSvatdescription:
      "Fornecedores - Fornecedores — títulos a pagar - Fornecedores — empresas subsidiárias",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "46",
    sncSvatCode: ["2224"],
    sncSvatdescription:
      "Fornecedores - Fornecedores — títulos a pagar - Fornecedores — empresas associadas",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "47",
    sncSvatCode: ["2225"],
    sncSvatdescription:
      "Fornecedores - Fornecedores — títulos a pagar - Fornecedores — empreendimentos conjuntos",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "48",
    sncSvatCode: ["2226"],
    sncSvatdescription:
      "Fornecedores - Fornecedores — títulos a pagar - Fornecedores — outras partes relacionadas",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "49",
    sncSvatCode: ["223", "224", "226", "227"],
    sncSvatdescription: "Fornecedores - Outros fornecedores",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "50",
    sncSvatCode: ["225"],
    sncSvatdescription: "Fornecedores - Faturas em receção e conferência",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "51",
    sncSvatCode: ["228"],
    sncSvatdescription: "Fornecedores - Adiantamentos a fornecedores",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "52",
    sncSvatCode: ["229"],
    sncSvatdescription: "Fornecedores - Perdas por imparidade acumuladas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "53",
    sncSvatCode: ["2311"],
    sncSvatdescription: "Pessoal - Remunerações a pagar - Aos órgãos sociais",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.OtherPayables,
  },
  {
    taxonomyCode: "54",
    sncSvatCode: ["2312"],
    sncSvatdescription: "Pessoal - Remunerações a pagar - Ao pessoal",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.OtherPayables,
  },
  {
    taxonomyCode: "55",
    sncSvatCode: ["2321"],
    sncSvatdescription: "Pessoal - Adiantamentos - Aos órgãos sociais",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "56",
    sncSvatCode: ["2322"],
    sncSvatdescription: "Pessoal - Adiantamentos - Ao pessoal",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "57",
    sncSvatCode: ["2371"],
    sncSvatdescription: "Pessoal - Cauções - Dos órgãos sociais - (CORRENTE)",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.OtherPayables,
  },
  {
    taxonomyCode: "58",
    sncSvatCode: ["2371"],
    sncSvatdescription:
      "Pessoal - Cauções - Dos órgãos sociais - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.OtherPayables,
  },
  {
    taxonomyCode: "59",
    sncSvatCode: ["2372"],
    sncSvatdescription: "Pessoal - Cauções - Do pessoal - (CORRENTE)",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.OtherPayables,
  },
  {
    taxonomyCode: "60",
    sncSvatCode: ["2372"],
    sncSvatdescription: "Pessoal - Cauções - Do pessoal - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.OtherPayables,
  },
  {
    taxonomyCode: "61",
    sncSvatCode: ["2381"],
    sncSvatdescription:
      "Pessoal - Outras operações - Com os órgãos sociais - (CORRENTE)",
    includeAccounts: ["233", "234", "235", "236"],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.OtherPayables,
  },
  {
    taxonomyCode: "62",
    sncSvatCode: ["2381"],
    sncSvatdescription:
      "Pessoal - Outras operações - Com os órgãos sociais - (NÃO CORRENTE)",
    includeAccounts: ["233", "234", "235", "236"],
    classType: "APNC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.NonCurrent.OtherReceivables,
    creditField: StatementTree.Liabilities.NonCurrent.OtherPayables,
  },
  {
    taxonomyCode: "63",
    sncSvatCode: ["2382"],
    sncSvatdescription:
      "Pessoal - Outras operações - Com o pessoal - (CORRENTE)",
    includeAccounts: ["233", "234", "235", "236"],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.OtherPayables,
  },
  {
    taxonomyCode: "64",
    sncSvatCode: ["2382"],
    sncSvatdescription:
      "Pessoal - Outras operações - Com o pessoal - (NÃO CORRENTE)",
    includeAccounts: ["233", "234", "235", "236"],
    classType: "APNC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.NonCurrent.OtherReceivables,
    creditField: StatementTree.Liabilities.NonCurrent.OtherPayables,
  },
  {
    taxonomyCode: "65",
    sncSvatCode: ["239"],
    sncSvatdescription: "Pessoal - Adiantamentos - Aos órgãos sociais",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "66",
    sncSvatCode: ["239"],
    sncSvatdescription: "Pessoal - Adiantamentos - Ao pessoal",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "67",
    sncSvatCode: ["239"],
    sncSvatdescription:
      "Pessoal - Outras operações - Aos órgãos sociais (CORRENTE)",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "68",
    sncSvatCode: ["239"],
    sncSvatdescription:
      "Pessoal - Outras operações - Aos órgãos sociais (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "69",
    sncSvatCode: ["239"],
    sncSvatdescription: "Pessoal - Outras operações - Ao pessoal (CORRENTE)",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "70",
    sncSvatCode: ["239"],
    sncSvatdescription:
      "Pessoal - Outras operações - Ao pessoal (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "71",
    sncSvatCode: ["241"],
    sncSvatdescription:
      "Estado e outros entes públicos - Imposto sobre o rendimento",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntitities,
    creditField:
      StatementTree.Liabilities.Current.StateAndOtherPublicEntitities,
  },
  {
    taxonomyCode: "72",
    sncSvatCode: ["242"],
    sncSvatdescription:
      "Estado e outros entes públicos - Retenção de impostos sobre rendimentos",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField:
      StatementTree.Liabilities.Current.StateAndOtherPublicEntitities,
  },
  {
    taxonomyCode: "73",
    sncSvatCode: ["2431"],
    sncSvatdescription:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA — Suportado",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntitities,
    creditField: "",
  },
  {
    taxonomyCode: "74",
    sncSvatCode: ["2432"],
    sncSvatdescription:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA — Dedutível",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntitities,
    creditField: "",
  },
  {
    taxonomyCode: "75",
    sncSvatCode: ["2433"],
    sncSvatdescription:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA — Liquidado",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField:
      StatementTree.Liabilities.Current.StateAndOtherPublicEntitities,
  },
  {
    taxonomyCode: "76",
    sncSvatCode: ["2434"],
    sncSvatdescription:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA — Regularizações",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntitities,
    creditField:
      StatementTree.Liabilities.Current.StateAndOtherPublicEntitities,
  },
  {
    taxonomyCode: "77",
    sncSvatCode: ["2435"],
    sncSvatdescription:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA — Apuramento",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntitities,
    creditField:
      StatementTree.Liabilities.Current.StateAndOtherPublicEntitities,
  },
  {
    taxonomyCode: "78",
    sncSvatCode: ["2436"],
    sncSvatdescription:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA — A pagar",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField:
      StatementTree.Liabilities.Current.StateAndOtherPublicEntitities,
  },
  {
    taxonomyCode: "79",
    sncSvatCode: ["2437"],
    sncSvatdescription:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA — A recuperar",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntitities,
    creditField: "",
  },
  {
    taxonomyCode: "80",
    sncSvatCode: ["2438"],
    sncSvatdescription:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA — Reembolsos pedidos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntitities,
    creditField: "",
  },
  {
    taxonomyCode: "81",
    sncSvatCode: ["2439"],
    sncSvatdescription:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA — Liquidações oficiosas",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntitities,
    creditField:
      StatementTree.Liabilities.Current.StateAndOtherPublicEntitities,
  },
  {
    taxonomyCode: "82",
    sncSvatCode: ["244"],
    sncSvatdescription: "Estado e outros entes públicos - Outros impostos",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntitities,
    creditField:
      StatementTree.Liabilities.Current.StateAndOtherPublicEntitities,
  },
  {
    taxonomyCode: "83",
    sncSvatCode: ["245"],
    sncSvatdescription:
      "Estado e outros entes públicos - Contribuições para a Segurança Social",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntitities,
    creditField:
      StatementTree.Liabilities.Current.StateAndOtherPublicEntitities,
  },
  {
    taxonomyCode: "84",
    sncSvatCode: ["246"],
    sncSvatdescription:
      "Estado e outros entes públicos - Tributos das autarquias locais",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntitities,
    creditField:
      StatementTree.Liabilities.Current.StateAndOtherPublicEntitities,
  },
  {
    taxonomyCode: "85",
    sncSvatCode: ["248"],
    sncSvatdescription: "Estado e outros entes públicos - Outras tributações",
    includeAccounts: ["247"],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntitities,
    creditField:
      StatementTree.Liabilities.Current.StateAndOtherPublicEntitities,
  },
  {
    taxonomyCode: "86",
    sncSvatCode: ["2511"],
    sncSvatdescription:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Empréstimos bancários - (CORRENTE)",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "87",
    sncSvatCode: ["2511"],
    sncSvatdescription:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Empréstimos bancários - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Loans,
  },
  {
    taxonomyCode: "88",
    sncSvatCode: ["2512"],
    sncSvatdescription:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Descobertos bancários - (CORRENTE)",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "89",
    sncSvatCode: ["2512"],
    sncSvatdescription:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Descobertos bancários - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Loans,
  },
  {
    taxonomyCode: "90",
    sncSvatCode: ["2513"],
    sncSvatdescription:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Locações financeiras - (CORRENTE)",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "91",
    sncSvatCode: ["2513"],
    sncSvatdescription:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Locações financeiras - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Loans,
  },
  {
    taxonomyCode: "92",
    sncSvatCode: ["2514", "2515", "2516", "2517", "2518", "2519"],
    sncSvatdescription:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Outros financiamentos - (CORRENTE)",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "93",
    sncSvatCode: ["2514", "2515", "2516", "2517", "2518", "2519"],
    sncSvatdescription:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Outros financiamentos - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Loans,
  },
  {
    taxonomyCode: "94",
    sncSvatCode: ["2521"],
    sncSvatdescription:
      "Financiamentos obtidos - Mercado de valores mobiliários - Empréstimos por obrigações - (CORRENTE)",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "95",
    sncSvatCode: ["2521"],
    sncSvatdescription:
      "Financiamentos obtidos - Mercado de valores mobiliários - Empréstimos por obrigações - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Loans,
  },
  {
    taxonomyCode: "96",
    sncSvatCode: [
      "2522",
      "2523",
      "2524",
      "2525",
      "2526",
      "2527",
      "2528",
      "2529",
    ],
    sncSvatdescription:
      "Financiamentos obtidos - Mercado de valores mobiliários - Outros financiamentos - (CORRENTE)",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "97",
    sncSvatCode: [
      "2522",
      "2523",
      "2524",
      "2525",
      "2526",
      "2527",
      "2528",
      "2529",
    ],
    sncSvatdescription:
      "Financiamentos obtidos - Mercado de valores mobiliários - Outros financiamentos - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Loans,
  },
  {
    taxonomyCode: "98",
    sncSvatCode: ["2531"],
    sncSvatdescription:
      "Financiamentos obtidos - Participantes de capital - Empresa -mãe — Suprimentos e outros mútuos - (CORRENTE)",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "99",
    sncSvatCode: ["2531"],
    sncSvatdescription:
      "Financiamentos obtidos - Participantes de capital - Empresa -mãe — Suprimentos e outros mútuos - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Loans,
  },
  {
    taxonomyCode: "100",
    sncSvatCode: ["2532"],
    sncSvatdescription:
      "Financiamentos obtidos - Participantes de capital - Outros participantes — Suprimentos e outros mútuos - (CORRENTE)",
    includeAccounts: ["2533", "2534", "2535", "2536", "2537", "2538", "2539"],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "101",
    sncSvatCode: ["2532"],
    sncSvatdescription:
      "Financiamentos obtidos - Participantes de capital - Outros participantes — Suprimentos e outros mútuos - (NÃO CORRENTE)",
    includeAccounts: ["2533", "2534", "2535", "2536", "2537", "2538", "2539"],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Loans,
  },
  {
    taxonomyCode: "102",
    sncSvatCode: ["254"],
    sncSvatdescription:
      "Financiamentos obtidos - Subsidiárias, associadas e empreendimentos conjuntos - (CORRENTE)",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "103",
    sncSvatCode: ["254"],
    sncSvatdescription:
      "Financiamentos obtidos - Subsidiárias, associadas e empreendimentos conjuntos - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Loans,
  },
  {
    taxonomyCode: "104",
    sncSvatCode: ["258"],
    sncSvatdescription:
      "Financiamentos obtidos - Outros financiadores - (CORRENTE)",
    includeAccounts: ["255", "256", "257"],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "105",
    sncSvatCode: ["258"],
    sncSvatdescription:
      "Financiamentos obtidos - Outros financiadores - (NÃO CORRENTE)",
    includeAccounts: ["255", "256", "257"],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Loans,
  },
  {
    taxonomyCode: "106",
    sncSvatCode: ["261"],
    sncSvatdescription: "Acionistas/sócios - Acionistas c/ subscrição",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.UnrealizedButSubscribedCapital,
    creditField: "",
  },
  {
    taxonomyCode: "107",
    sncSvatCode: ["262"],
    sncSvatdescription: "Acionistas/sócios - Quotas não liberadas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.UnrealizedButSubscribedCapital,
    creditField: "",
  },
  {
    taxonomyCode: "108",
    sncSvatCode: ["263"],
    sncSvatdescription: "Acionistas/sócios - Adiantamentos por conta de lucros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "109",
    sncSvatCode: ["264"],
    sncSvatdescription: "Acionistas/sócios - Resultados atribuídos",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.OtherPayables,
  },
  {
    taxonomyCode: "110",
    sncSvatCode: ["265"],
    sncSvatdescription: "Acionistas/sócios - Lucros disponíveis",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.OtherPayables,
  },
  {
    taxonomyCode: "111",
    sncSvatCode: ["266"],
    sncSvatdescription:
      "Acionistas/sócios - Empréstimos concedidos — empresa-mãe - (CORRENTE)",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "112",
    sncSvatCode: ["266"],
    sncSvatdescription:
      "Acionistas/sócios - Empréstimos concedidos — empresa-mãe - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "113",
    sncSvatCode: ["268"],
    sncSvatdescription: "Acionistas/sócios - Outras operações - (CORRENTE)",
    includeAccounts: ["267"],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.OtherPayables,
  },
  {
    taxonomyCode: "114",
    sncSvatCode: ["268"],
    sncSvatdescription: "Acionistas/sócios - Outras operações - (NÃO CORRENTE)",
    includeAccounts: ["267"],
    classType: "APNC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.NonCurrent.OtherReceivables,
    creditField: StatementTree.Liabilities.NonCurrent.OtherPayables,
  },
  {
    taxonomyCode: "115",
    sncSvatCode: ["269"],
    sncSvatdescription: "Acionistas/sócios -Acionistas c/ subscrição",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.UnrealizedButSubscribedCapital,
    creditField: "",
  },
  {
    taxonomyCode: "116",
    sncSvatCode: ["269"],
    sncSvatdescription: "Acionistas/sócios - Quotas não liberadas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.UnrealizedButSubscribedCapital,
    creditField: "",
  },
  {
    taxonomyCode: "117",
    sncSvatCode: ["269"],
    sncSvatdescription: "Acionistas/sócios - Adiantamentos por conta de lucros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "118",
    sncSvatCode: ["269"],
    sncSvatdescription: "Acionistas/sócios - Resultados atribuídos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "119",
    sncSvatCode: ["269"],
    sncSvatdescription: "Acionistas/sócios - Lucros disponíveis",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "120",
    sncSvatCode: ["269"],
    sncSvatdescription:
      "Acionistas/sócios - Empréstimos concedidos — empresa-mãe (CORRENTE)",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "121",
    sncSvatCode: ["269"],
    sncSvatdescription:
      "Acionistas/sócios - Empréstimos concedidos — empresa-mãe (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "122",
    sncSvatCode: ["269"],
    sncSvatdescription: "Acionistas/sócios - Outras operações (CORRENTE)",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "123",
    sncSvatCode: ["269"],
    sncSvatdescription: "Acionistas/sócios - Outras operações (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "124",
    sncSvatCode: ["2711"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Fornecedores de investimentos - Fornecedores de investimentos — contas gerais - (CORRENTE)",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.OtherPayables,
  },
  {
    taxonomyCode: "125",
    sncSvatCode: ["2711"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Fornecedores de investimentos - Fornecedores de investimentos — contas gerais - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "APNC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.NonCurrent.OtherReceivables,
    creditField: StatementTree.Liabilities.NonCurrent.OtherPayables,
  },
  {
    taxonomyCode: "126",
    sncSvatCode: ["2712"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Fornecedores de investimentos - Facturas em recepção e conferência - (CORRENTE)",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.OtherPayables,
  },
  {
    taxonomyCode: "127",
    sncSvatCode: ["2712"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Fornecedores de investimentos - Facturas em recepção e conferência - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "APNC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.NonCurrent.OtherReceivables,
    creditField: StatementTree.Liabilities.NonCurrent.OtherPayables,
  },
  {
    taxonomyCode: "128",
    sncSvatCode: ["2713"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Fornecedores de investimentos - Adiantamentos a fornecedores de investimentos - (CORRENTE)",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "129",
    sncSvatCode: ["2713"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Fornecedores de investimentos - Adiantamentos a fornecedores de investimentos - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "130",
    sncSvatCode: ["2721"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Devedores e credores por acréscimos (periodização económica)* - Devedores por acréscimos de rendimentos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "131",
    sncSvatCode: ["2722"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Devedores e credores por acréscimos (periodização económica)* - Credores por acréscimos de gastos",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.OtherPayables,
  },
  {
    taxonomyCode: "132",
    sncSvatCode: ["273"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Benefícios pós -emprego",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.PersonnelBenefits,
  },
  {
    taxonomyCode: "133",
    sncSvatCode: ["2741"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Impostos diferidos - Ativos por impostos diferidos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.DeferredTaxAssets,
    creditField: "",
  },
  {
    taxonomyCode: "134",
    sncSvatCode: ["2742"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Impostos diferidos - Passivos por impostos diferidos",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.DeferredTaxLiabilities,
  },
  {
    taxonomyCode: "135",
    sncSvatCode: ["275"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Credores por subscrições não liberadas - (CORRENTE)",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.OtherPayables,
  },
  {
    taxonomyCode: "136",
    sncSvatCode: ["275"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Credores por subscrições não liberadas - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.OtherPayables,
  },
  {
    taxonomyCode: "137",
    sncSvatCode: ["276"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Adiantamentos por conta de vendas",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.AdvancementsFromClients,
  },
  {
    taxonomyCode: "138",
    sncSvatCode: ["278"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Outros devedores e credores - (CORRENTE)",
    includeAccounts: ["277"],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: StatementTree.Liabilities.Current.OtherPayables,
  },
  {
    taxonomyCode: "139",
    sncSvatCode: ["278"],
    sncSvatdescription:
      "Outras contas a receber e a pagar - Outros devedores e credores - (NÃO CORRENTE)",
    includeAccounts: ["277"],
    classType: "APNC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.NonCurrent.OtherReceivables,
    creditField: StatementTree.Liabilities.NonCurrent.OtherPayables,
  },
  {
    taxonomyCode: "140",
    sncSvatCode: ["279"],
    sncSvatdescription:
      "Fornecedores de investimentos - Adiantamentos a fornecedores de investimentos (CORRENTE)",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "141",
    sncSvatCode: ["279"],
    sncSvatdescription:
      "Fornecedores de investimentos - Adiantamentos a fornecedores de investimentos (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "142",
    sncSvatCode: ["279"],
    sncSvatdescription:
      "Devedores e credores por acréscimos (periodização económica)* - Devedores por acréscimos de rendimentos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "143",
    sncSvatCode: ["279"],
    sncSvatdescription: "Impostos diferidos - Ativos por impostos diferidos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.DeferredTaxAssets,
    creditField: "",
  },
  {
    taxonomyCode: "144",
    sncSvatCode: ["279"],
    sncSvatdescription: "Outros devedores e credores - (CORRENTE)",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "145",
    sncSvatCode: ["279"],
    sncSvatdescription: "Outros devedores e credores - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherReceivables,
    creditField: "",
  },
  {
    taxonomyCode: "146",
    sncSvatCode: ["281"],
    sncSvatdescription: "Diferimentos - Gastos a reconhecer",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Deferrals,
    creditField: "",
  },
  {
    taxonomyCode: "147",
    sncSvatCode: ["282"],
    sncSvatdescription: "Diferimentos - Rendimentos a reconhecer",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Deferrals,
  },
  {
    taxonomyCode: "148",
    sncSvatCode: ["291"],
    sncSvatdescription: "Provisões - Impostos",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Provisions,
  },
  {
    taxonomyCode: "149",
    sncSvatCode: ["292"],
    sncSvatdescription: "Provisões - Garantias a clientes",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Provisions,
  },
  {
    taxonomyCode: "150",
    sncSvatCode: ["293"],
    sncSvatdescription: "Provisões - Processos judiciais em curso",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Provisions,
  },
  {
    taxonomyCode: "151",
    sncSvatCode: ["294"],
    sncSvatdescription:
      "Provisões - Acidentes de trabalho e doenças profissionais",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Provisions,
  },
  {
    taxonomyCode: "152",
    sncSvatCode: ["295"],
    sncSvatdescription: "Provisões - Matérias ambientais",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Provisions,
  },
  {
    taxonomyCode: "153",
    sncSvatCode: ["296"],
    sncSvatdescription: "Provisões - Contratos onerosos",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Provisions,
  },
  {
    taxonomyCode: "154",
    sncSvatCode: ["297"],
    sncSvatdescription: "Provisões - Reestruturação",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Provisions,
  },
  {
    taxonomyCode: "155",
    sncSvatCode: ["298"],
    sncSvatdescription: "Provisões - Outras provisões",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Provisions,
  },
  {
    taxonomyCode: "156",
    sncSvatCode: ["311"],
    sncSvatdescription: "Compras - Mercadorias",
    includeAccounts: ["314", "315", "316"],
    classType: "",
    expectedBalance: "Dc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "157",
    sncSvatCode: ["312"],
    sncSvatdescription: "Compras - Matérias -primas, subsidiárias e de consumo",
    includeAccounts: ["314", "315", "317"],
    classType: "",
    expectedBalance: "Dc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "158",
    sncSvatCode: ["313"],
    sncSvatdescription: "Compras - Ativos biológicos",
    includeAccounts: ["314", "315", "318"],
    classType: "",
    expectedBalance: "Dc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "159",
    sncSvatCode: ["317"],
    sncSvatdescription: "Compras - Devoluções de compras - Mercadorias",
    includeAccounts: ["314", "315", "319"],
    classType: "",
    expectedBalance: "Cc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "160",
    sncSvatCode: ["317"],
    sncSvatdescription:
      "Compras - Devoluções de compras - Matérias -primas, subsidiárias e de consumo",
    includeAccounts: ["314", "315", "320"],
    classType: "",
    expectedBalance: "Cc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "161",
    sncSvatCode: ["317"],
    sncSvatdescription: "Compras - Devoluções de compras - Ativos biológicos",
    includeAccounts: ["314", "315", "321"],
    classType: "",
    expectedBalance: "Cc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "162",
    sncSvatCode: ["318"],
    sncSvatdescription:
      "Compras - Descontos e abatimentos em compras - Mercadorias",
    includeAccounts: ["314", "315", "322"],
    classType: "",
    expectedBalance: "Cc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "163",
    sncSvatCode: ["318"],
    sncSvatdescription:
      "Compras - Descontos e abatimentos em compras - Matérias -primas, subsidiárias e de consumo",
    includeAccounts: ["314", "315", "323"],
    classType: "",
    expectedBalance: "Cc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "164",
    sncSvatCode: ["318"],
    sncSvatdescription:
      "Compras - Descontos e abatimentos em compras - Ativos biológicos",
    includeAccounts: ["314", "315", "324"],
    classType: "",
    expectedBalance: "Cc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "165",
    sncSvatCode: ["321", "322", "323", "324", "327", "328"],
    sncSvatdescription: "Mercadorias - Mercadorias",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "166",
    sncSvatCode: ["325"],
    sncSvatdescription: "Mercadorias - Mercadorias em trânsito",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "167",
    sncSvatCode: ["326"],
    sncSvatdescription: "Mercadorias - Mercadorias em poder de terceiros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "168",
    sncSvatCode: ["329"],
    sncSvatdescription: "Mercadorias",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "169",
    sncSvatCode: ["329"],
    sncSvatdescription: "Mercadorias em trânsito",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "170",
    sncSvatCode: ["329"],
    sncSvatdescription: "Mercadorias em poder de terceiros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "171",
    sncSvatCode: ["331"],
    sncSvatdescription:
      "Matérias -primas, subsidiárias e de consumo - Matérias -primas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "172",
    sncSvatCode: ["332"],
    sncSvatdescription:
      "Matérias -primas, subsidiárias e de consumo - Matérias subsidiárias",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "173",
    sncSvatCode: ["333"],
    sncSvatdescription:
      "Matérias -primas, subsidiárias e de consumo - Embalagens",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "174",
    sncSvatCode: ["334"],
    sncSvatdescription:
      "Matérias -primas, subsidiárias e de consumo - Materiais diversos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "175",
    sncSvatCode: ["335"],
    sncSvatdescription:
      "Matérias -primas, subsidiárias e de consumo - Matérias em trânsito",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "176",
    sncSvatCode: ["336", "337", "338"],
    sncSvatdescription:
      "Matérias -primas, subsidiárias e de consumo - Outras matérias -primas, subsidiárias e de consumo",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "177",
    sncSvatCode: ["339"],
    sncSvatdescription: "Matérias -primas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "178",
    sncSvatCode: ["339"],
    sncSvatdescription: "Matérias subsidiárias",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "179",
    sncSvatCode: ["339"],
    sncSvatdescription: "Embalagens",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "180",
    sncSvatCode: ["339"],
    sncSvatdescription: "Materiais diversos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "181",
    sncSvatCode: ["339"],
    sncSvatdescription: "Matérias em trânsito",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "182",
    sncSvatCode: ["339"],
    sncSvatdescription: "Outras matérias -primas, subsidiárias e de consumo",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "183",
    sncSvatCode: ["341", "342", "343", "344", "345", "347", "348"],
    sncSvatdescription:
      "Produtos acabados e intermédios - Produtos acabados e intermédios",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "184",
    sncSvatCode: ["346"],
    sncSvatdescription:
      "Produtos acabados e intermédios - Produtos em poder de terceiros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "185",
    sncSvatCode: ["349"],
    sncSvatdescription:
      "Produtos acabados e intermédios - Perdas por imparidade acumuladas - Produtos acabados e intermédios",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "186",
    sncSvatCode: ["349"],
    sncSvatdescription:
      "Produtos acabados e intermédios - Perdas por imparidade acumuladas - Produtos em poder de terceiros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "187",
    sncSvatCode: ["351"],
    sncSvatdescription:
      "Subprodutos, desperdícios, resíduos e refugos - Subprodutos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "188",
    sncSvatCode: ["352"],
    sncSvatdescription:
      "Subprodutos, desperdícios, resíduos e refugos - Desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "189",
    sncSvatCode: ["353", "354", "355", "356", "357", "358"],
    sncSvatdescription:
      "Subprodutos, desperdícios, resíduos e refugos - Outros subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "190",
    sncSvatCode: ["359"],
    sncSvatdescription: "Perdas por imparidade acumuladas - Subprodutos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "191",
    sncSvatCode: ["359"],
    sncSvatdescription:
      "Perdas por imparidade acumuladas - Desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "192",
    sncSvatCode: ["359"],
    sncSvatdescription:
      "Perdas por imparidade acumuladas - Outros subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "193",
    sncSvatCode: ["361", "362", "363", "364", "365", "366", "367", "368"],
    sncSvatdescription:
      "Produtos e trabalhos em curso - Produtos e trabalhos em curso",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "194",
    sncSvatCode: ["369"],
    sncSvatdescription:
      "Produtos e trabalhos em curso - Perdas por imparidade acumuladas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "195",
    sncSvatCode: ["3711"],
    sncSvatdescription: "Ativos biológicos - Consumíveis - Animais",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.BiologicalAssets,
    creditField: "",
  },
  {
    taxonomyCode: "196",
    sncSvatCode: ["3712"],
    sncSvatdescription: "Ativos biológicos - Consumíveis - Plantas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.BiologicalAssets,
    creditField: "",
  },
  {
    taxonomyCode: "197",
    sncSvatCode: ["3721"],
    sncSvatdescription: "Ativos biológicos - De produção - Animais",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.BiologicalAssets,
    creditField: "",
  },
  {
    taxonomyCode: "198",
    sncSvatCode: ["3722"],
    sncSvatdescription: "Ativos biológicos - De produção - Plantas",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.BiologicalAssets,
    creditField: "",
  },
  {
    taxonomyCode: "199",
    sncSvatCode: ["373", "374", "375", "376", "377", "378", "379"],
    sncSvatdescription:
      "Ativos biológicos - Depreciações acumuladas - Consumíveis",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.BiologicalAssets,
    creditField: "",
  },
  {
    taxonomyCode: "200",
    sncSvatCode: ["373", "374", "375", "376", "377", "378", "379"],
    sncSvatdescription:
      "Ativos biológicos - Depreciações acumuladas - De produção",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.BiologicalAssets,
    creditField: "",
  },
  {
    taxonomyCode: "201",
    sncSvatCode: ["373", "374", "375", "376", "377", "378", "379"],
    sncSvatdescription:
      "Ativos biológicos - Perdas por imparidade acumuladas - Consumíveis",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.BiologicalAssets,
    creditField: "",
  },
  {
    taxonomyCode: "202",
    sncSvatCode: ["373", "374", "375", "376", "377", "378", "379"],
    sncSvatdescription:
      "Ativos biológicos - Perdas por imparidade acumuladas - De produção",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.BiologicalAssets,
    creditField: "",
  },
  {
    taxonomyCode: "203",
    sncSvatCode: ["382"],
    sncSvatdescription:
      "Reclassificação e regularização de inventários e ativos biológicos - Mercadorias",
    includeAccounts: [],
    classType: "",
    expectedBalance: "Sc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "204",
    sncSvatCode: ["383"],
    sncSvatdescription:
      "Reclassificação e regularização de inventários e ativos biológicos - Matérias -primas, subsidiárias e de consumo",
    includeAccounts: [],
    classType: "",
    expectedBalance: "Sc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "205",
    sncSvatCode: ["384"],
    sncSvatdescription:
      "Reclassificação e regularização de inventários e ativos biológicos - Produtos acabados e intermédios",
    includeAccounts: [],
    classType: "",
    expectedBalance: "Sc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "206",
    sncSvatCode: ["385"],
    sncSvatdescription:
      "Reclassificação e regularização de inventários e ativos biológicos - Subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "",
    expectedBalance: "Sc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "207",
    sncSvatCode: ["386"],
    sncSvatdescription:
      "Reclassificação e regularização de inventários e ativos biológicos - Produtos e trabalhos em curso",
    includeAccounts: [],
    classType: "",
    expectedBalance: "Sc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "208",
    sncSvatCode: ["387"],
    sncSvatdescription:
      "Reclassificação e regularização de inventários e ativos biológicos - Ativos biológicos",
    includeAccounts: [],
    classType: "",
    expectedBalance: "Sc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "209",
    sncSvatCode: ["39"],
    sncSvatdescription: "Adiantamentos por conta de compras - Mercadorias",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "210",
    sncSvatCode: ["39"],
    sncSvatdescription:
      "Adiantamentos por conta de compras - Matérias -primas, subsidiárias e de consumo",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "211",
    sncSvatCode: ["39"],
    sncSvatdescription:
      "Adiantamentos por conta de compras - Produtos acabados e intermédios",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "212",
    sncSvatCode: ["39"],
    sncSvatdescription:
      "Adiantamentos por conta de compras - Subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "213",
    sncSvatCode: ["39"],
    sncSvatdescription:
      "Adiantamentos por conta de compras - Produtos e trabalhos em curso",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "214",
    sncSvatCode: ["39"],
    sncSvatdescription:
      "Adiantamentos por conta de compras - Ativos biológicos consumíveis",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.BiologicalAssets,
    creditField: "",
  },
  {
    taxonomyCode: "215",
    sncSvatCode: ["39"],
    sncSvatdescription:
      "Adiantamentos por conta de compras - Ativos biológicos de produção",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.BiologicalAssets,
    creditField: "",
  },
  {
    taxonomyCode: "216",
    sncSvatCode: ["4111"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos em subsidiárias - Participações de capital — método da equivalência patrimonial - Participação de capital",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "217",
    sncSvatCode: ["4111"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos em subsidiárias - Participações de capital — método da equivalência patrimonial - Goodwill",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.Goodwill,
    creditField: "",
  },
  {
    taxonomyCode: "218",
    sncSvatCode: ["4112"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos em subsidiárias - Participações de capital — outros métodos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "219",
    sncSvatCode: ["4113"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos em subsidiárias - Empréstimos concedidos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "220",
    sncSvatCode: ["4114", "4115", "4116", "4117", "4118", "4119"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos em subsidiárias - Outros investimentos financeiros",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "221",
    sncSvatCode: ["4121"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos em associadas - Participações de capital — método da equivalência patrimonial - Participação de capital",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "222",
    sncSvatCode: ["4121"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos em associadas - Participações de capital — método da equivalência patrimonial - Goodwill",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.Goodwill,
    creditField: "",
  },
  {
    taxonomyCode: "223",
    sncSvatCode: ["4122"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos em associadas - Participações de capital — outros métodos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "224",
    sncSvatCode: ["4123"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos em associadas - Empréstimos concedidos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "225",
    sncSvatCode: ["4124", "4125", "4126", "4127", "4128", "4129"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos em associadas - Outros investimentos financeiros",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "226",
    sncSvatCode: ["4131"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos em entidades conjuntamente controladas - Participações de capital — método da equivalência patrimonial - Participação de capital",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "227",
    sncSvatCode: ["4131"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos em entidades conjuntamente controladas - Participações de capital — método da equivalência patrimonial - Goodwill",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.Goodwill,
    creditField: "",
  },
  {
    taxonomyCode: "228",
    sncSvatCode: ["4132"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos em entidades conjuntamente controladas - Participações de capital — outros métodos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "229",
    sncSvatCode: ["4133"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos em entidades conjuntamente controladas - Empréstimos concedidos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "230",
    sncSvatCode: ["4134", "4135", "4136", "4137", "4138", "4139"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos em entidades conjuntamente controladas - Outros investimentos financeiros",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "231",
    sncSvatCode: ["4141"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos noutras empresas - Participações de capital",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "232",
    sncSvatCode: ["4142"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos noutras empresas - Empréstimos concedidos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "233",
    sncSvatCode: ["4143", "4144", "4145", "4146", "4147", "4148", "4149"],
    sncSvatdescription:
      "Investimentos financeiros - Investimentos noutras empresas - Outros investimentos financeiros",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "234",
    sncSvatCode: ["4151"],
    sncSvatdescription:
      "Investimentos financeiros - Outros investimentos financeiros - Detidos até à maturidade",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "235",
    sncSvatCode: ["4157"],
    sncSvatdescription: "",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "235",
    sncSvatCode: ["4158"],
    sncSvatdescription:
      "Investimentos financeiros - Outros investimentos financeiros - Outros",
    includeAccounts: [
      "4152",
      "4153",
      "4154",
      "4155",
      "4156",
      "4157",
      "4158",
      "4159",
      "416",
      "417",
      "418",
    ],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "236",
    sncSvatCode: ["416", "417", "418"],
    sncSvatdescription:
      " Investimentos em subsidiárias - Participações de capital — MEP -Goodwill",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.Goodwill,
    creditField: "",
  },
  {
    taxonomyCode: "237",
    sncSvatCode: ["416", "417", "418"],
    sncSvatdescription:
      " Investimentos em associadas - Participações de capital — MEP -Goodwill",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.Goodwill,
    creditField: "",
  },
  {
    taxonomyCode: "238",
    sncSvatCode: ["416", "417", "418"],
    sncSvatdescription:
      " Investimentos em entidades conjuntamente controladas - Participações de capital — MEP -Goodwill",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.Goodwill,
    creditField: "",
  },
  {
    taxonomyCode: "239",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos em subsidiárias - Participações de capital — método da equivalência patrimonial - Participação de capital",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "240",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos em subsidiárias - Participações de capital — método da equivalência patrimonial - Goodwill",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.Goodwill,
    creditField: "",
  },
  {
    taxonomyCode: "241",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos em subsidiárias - Participações de capital — outros métodos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "242",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos em subsidiárias - Empréstimos concedidos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "243",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos em subsidiárias - Outros investimentos financeiros",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "244",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos em associadas - Participações de capital — método da equivalência patrimonial - Participação de capital",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "245",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos em associadas - Participações de capital — método da equivalência patrimonial - Goodwill",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.Goodwill,
    creditField: "",
  },
  {
    taxonomyCode: "246",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos em associadas - Participações de capital — outros métodos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "247",
    sncSvatCode: ["419"],
    sncSvatdescription: "Investimentos em associadas - Empréstimos concedidos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "248",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos em associadas - Outros investimentos financeiros",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "249",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos em entidades conjuntamente controladas - Participações de capital — método da equivalência patrimonial - Participação de capital",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "250",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos em entidades conjuntamente controladas - Participações de capital — método da equivalência patrimonial - Goodwill",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.Goodwill,
    creditField: "",
  },
  {
    taxonomyCode: "251",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos em entidades conjuntamente controladas - Participações de capital — outros métodos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "252",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos em entidades conjuntamente controladas - Empréstimos concedidos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "253",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos em entidades conjuntamente controladas - Outros investimentos financeiros",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "254",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos noutras empresas - Participações de capital",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "255",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos noutras empresas - Empréstimos concedidos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "256",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Investimentos noutras empresas - Outros investimentos financeiros",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "257",
    sncSvatCode: ["419"],
    sncSvatdescription:
      "Outros investimentos financeiros - Detidos até à maturidade",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "258",
    sncSvatCode: ["419"],
    sncSvatdescription: "Outros investimentos financeiros - Outros",
    includeAccounts: [
      "4152",
      "4153",
      "4154",
      "4155",
      "4156",
      "4157",
      "4158",
      "4159",
      "416",
      "417",
      "418",
    ],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "259",
    sncSvatCode: ["421"],
    sncSvatdescription:
      "Propriedades de investimento - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.InvestmentProperties,
    creditField: "",
  },
  {
    taxonomyCode: "260",
    sncSvatCode: ["422"],
    sncSvatdescription:
      "Propriedades de investimento - Edifícios e outras construções",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.InvestmentProperties,
    creditField: "",
  },
  {
    taxonomyCode: "261",
    sncSvatCode: ["426"],
    sncSvatdescription:
      "Propriedades de investimento - Outras propriedades de investimento",
    includeAccounts: ["423", "424", "425", "427"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.InvestmentProperties,
    creditField: "",
  },
  {
    taxonomyCode: "262",
    sncSvatCode: ["428"],
    sncSvatdescription:
      "Propriedades de investimento - Depreciações acumuladas - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.InvestmentProperties,
    creditField: "",
  },
  {
    taxonomyCode: "263",
    sncSvatCode: ["428"],
    sncSvatdescription:
      "Propriedades de investimento - Depreciações acumuladas - Edifícios e outras construções",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.InvestmentProperties,
    creditField: "",
  },
  {
    taxonomyCode: "264",
    sncSvatCode: ["428"],
    sncSvatdescription:
      "Propriedades de investimento - Depreciações acumuladas - Outras propriedades de investimento",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.InvestmentProperties,
    creditField: "",
  },
  {
    taxonomyCode: "265",
    sncSvatCode: ["429"],
    sncSvatdescription:
      "Propriedades de investimento - Perdas por imparidade acumuladas - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.InvestmentProperties,
    creditField: "",
  },
  {
    taxonomyCode: "266",
    sncSvatCode: ["429"],
    sncSvatdescription:
      "Propriedades de investimento - Perdas por imparidade acumuladas - Edifícios e outras construções",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.InvestmentProperties,
    creditField: "",
  },
  {
    taxonomyCode: "267",
    sncSvatCode: ["429"],
    sncSvatdescription:
      "Propriedades de investimento - Perdas por imparidade acumuladas - Outras propriedades de investimento",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.InvestmentProperties,
    creditField: "",
  },
  {
    taxonomyCode: "268",
    sncSvatCode: ["431"],
    sncSvatdescription: "Ativos fixos tangíveis - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "269",
    sncSvatCode: ["432"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Edifícios e outras construções",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "270",
    sncSvatCode: ["433"],
    sncSvatdescription: "Ativos fixos tangíveis - Equipamento básico",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "271",
    sncSvatCode: ["434"],
    sncSvatdescription: "Ativos fixos tangíveis - Equipamento de transporte",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "272",
    sncSvatCode: ["435"],
    sncSvatdescription: "Ativos fixos tangíveis - Equipamento administrativo",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "273",
    sncSvatCode: ["436"],
    sncSvatdescription: "Ativos fixos tangíveis - Equipamentos biológicos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "274",
    sncSvatCode: ["437"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Outros ativos fixos tangíveis",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "275",
    sncSvatCode: ["438"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Depreciações acumuladas - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "276",
    sncSvatCode: ["438"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Depreciações acumuladas - Edifícios e outras construções",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "277",
    sncSvatCode: ["438"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Depreciações acumuladas - Equipamento básico",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "278",
    sncSvatCode: ["438"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Depreciações acumuladas - Equipamento de transporte",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "279",
    sncSvatCode: ["438"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Depreciações acumuladas - Equipamento administrativo",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "280",
    sncSvatCode: ["438"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Depreciações acumuladas - Equipamentos biológicos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "281",
    sncSvatCode: ["438"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Depreciações acumuladas - Outros ativos fixos tangíveis",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "282",
    sncSvatCode: ["439"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Perdas por imparidade acumuladas - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "283",
    sncSvatCode: ["439"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Perdas por imparidade acumuladas - Edifícios e outras construções",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "284",
    sncSvatCode: ["439"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Perdas por imparidade acumuladas - Equipamento básico",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "285",
    sncSvatCode: ["439"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Perdas por imparidade acumuladas - Equipamento de transporte",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "286",
    sncSvatCode: ["439"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Perdas por imparidade acumuladas - Equipamento administrativo",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "287",
    sncSvatCode: ["439"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Perdas por imparidade acumuladas - Equipamentos biológicos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "288",
    sncSvatCode: ["439"],
    sncSvatdescription:
      "Ativos fixos tangíveis - Perdas por imparidade acumuladas - Outros ativos fixos tangíveis",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "289",
    sncSvatCode: ["441"],
    sncSvatdescription: "Ativos intangíveis - Goodwill",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.Goodwill,
    creditField: "",
  },
  {
    taxonomyCode: "290",
    sncSvatCode: ["442"],
    sncSvatdescription: "Ativos intangíveis - Projetos de desenvolvimento",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "291",
    sncSvatCode: ["443"],
    sncSvatdescription: "Ativos intangíveis - Programas de computador",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "292",
    sncSvatCode: ["444"],
    sncSvatdescription: "Ativos intangíveis - Propriedade industrial",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "293",
    sncSvatCode: ["446"],
    sncSvatdescription: "Ativos intangíveis - Outros ativos intangíveis",
    includeAccounts: ["445", "447"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "294",
    sncSvatCode: ["448"],
    sncSvatdescription:
      "Ativos intangíveis - Amortizações acumuladas - Goodwill",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.Goodwill,
    creditField: "",
  },
  {
    taxonomyCode: "295",
    sncSvatCode: ["448"],
    sncSvatdescription:
      "Ativos intangíveis - Amortizações acumuladas - Projetos de desenvolvimento",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "296",
    sncSvatCode: ["448"],
    sncSvatdescription:
      "Ativos intangíveis - Amortizações acumuladas - Programas de computador",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "297",
    sncSvatCode: ["448"],
    sncSvatdescription:
      "Ativos intangíveis - Amortizações acumuladas - Propriedade industrial",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "298",
    sncSvatCode: ["448"],
    sncSvatdescription:
      "Ativos intangíveis - Amortizações acumuladas - Outros ativos intangíveis",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "299",
    sncSvatCode: ["449"],
    sncSvatdescription:
      "Ativos intangíveis - Perdas por imparidade acumuladas - Goodwill",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.Goodwill,
    creditField: "",
  },
  {
    taxonomyCode: "300",
    sncSvatCode: ["449"],
    sncSvatdescription:
      "Ativos intangíveis - Perdas por imparidade acumuladas - Projetos de desenvolvimento",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "301",
    sncSvatCode: ["449"],
    sncSvatdescription:
      "Ativos intangíveis - Perdas por imparidade acumuladas - Programas de computador",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "302",
    sncSvatCode: ["449"],
    sncSvatdescription:
      "Ativos intangíveis - Perdas por imparidade acumuladas - Propriedade industrial",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "303",
    sncSvatCode: ["449"],
    sncSvatdescription:
      "Ativos intangíveis - Perdas por imparidade acumuladas - Outros ativos intangíveis",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "304",
    sncSvatCode: ["451"],
    sncSvatdescription:
      "Investimentos em curso - Investimentos financeiros em curso",
    includeAccounts: ["456", "457", "458"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "305",
    sncSvatCode: ["452"],
    sncSvatdescription:
      "Investimentos em curso - Propriedades de investimento em curso",
    includeAccounts: ["456", "457", "459"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.InvestmentProperties,
    creditField: "",
  },
  {
    taxonomyCode: "306",
    sncSvatCode: ["453"],
    sncSvatdescription:
      "Investimentos em curso - Ativos fixos tangíveis em curso",
    includeAccounts: ["456", "457", "460"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "307",
    sncSvatCode: ["454"],
    sncSvatdescription: "Investimentos em curso - Ativos intangíveis em curso",
    includeAccounts: ["456", "457", "461"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "308",
    sncSvatCode: ["455"],
    sncSvatdescription:
      "Investimentos em curso - Adiantamentos por conta de investimentos - Investimentos financeiros em curso",
    includeAccounts: ["456", "457", "462"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "309",
    sncSvatCode: ["455"],
    sncSvatdescription:
      "Investimentos em curso - Adiantamentos por conta de investimentos - Propriedades de investimento em curso",
    includeAccounts: ["456", "457", "463"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.InvestmentProperties,
    creditField: "",
  },
  {
    taxonomyCode: "310",
    sncSvatCode: ["455"],
    sncSvatdescription:
      "Investimentos em curso - Adiantamentos por conta de investimentos - Ativos fixos tangíveis em curso",
    includeAccounts: ["456", "457", "464"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "311",
    sncSvatCode: ["455"],
    sncSvatdescription:
      "Investimentos em curso - Adiantamentos por conta de investimentos - Ativos intangíveis em curso",
    includeAccounts: ["456", "457", "465"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "312",
    sncSvatCode: ["459"],
    sncSvatdescription:
      "Investimentos em curso - Perdas por imparidade acumuladas - Investimentos financeiros em curso",
    includeAccounts: ["456", "457", "466"],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "313",
    sncSvatCode: ["459"],
    sncSvatdescription:
      "Investimentos em curso - Perdas por imparidade acumuladas - Propriedades de investimento em curso",
    includeAccounts: ["456", "457", "467"],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.InvestmentProperties,
    creditField: "",
  },
  {
    taxonomyCode: "314",
    sncSvatCode: ["459"],
    sncSvatdescription:
      "Investimentos em curso - Perdas por imparidade acumuladas - Ativos fixos tangíveis em curso",
    includeAccounts: ["456", "457", "468"],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "315",
    sncSvatCode: ["459"],
    sncSvatdescription:
      "Investimentos em curso - Perdas por imparidade acumuladas - Ativos intangíveis em curso",
    includeAccounts: ["456", "457", "469"],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "316",
    sncSvatCode: ["459"],
    sncSvatdescription:
      "Investimentos em curso - Perdas por imparidade acumuladas - Adiantamentos por conta de investimentos - Investimentos financeiros em curso",
    includeAccounts: ["456", "457", "470"],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.OtherFinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "317",
    sncSvatCode: ["459"],
    sncSvatdescription:
      "Investimentos em curso - Perdas por imparidade acumuladas - Adiantamentos por conta de investimentos - Propriedades de investimento em curso",
    includeAccounts: ["456", "457", "471"],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.InvestmentProperties,
    creditField: "",
  },
  {
    taxonomyCode: "318",
    sncSvatCode: ["459"],
    sncSvatdescription:
      "Investimentos em curso - Perdas por imparidade acumuladas - Adiantamentos por conta de investimentos - Ativos fixos tangíveis em curso",
    includeAccounts: ["456", "457", "472"],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "319",
    sncSvatCode: ["459"],
    sncSvatdescription:
      "Investimentos em curso - Perdas por imparidade acumuladas - Adiantamentos por conta de investimentos - Ativos intangíveis em curso",
    includeAccounts: ["456", "457", "473"],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.IntangibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "320",
    sncSvatCode: ["461", "462", "463", "464", "465", "466", "467", "468"],
    sncSvatdescription:
      "Ativos não correntes detidos para venda - Ativos não correntes detidos para venda - Investimentos financeiros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.NonCurrentAssetsHoldForSales,
    creditField: "",
  },
  {
    taxonomyCode: "321",
    sncSvatCode: ["461", "462", "463", "464", "465", "466", "467", "468"],
    sncSvatdescription:
      "Ativos não correntes detidos para venda - Ativos não correntes detidos para venda - Propriedades de investimento",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.NonCurrentAssetsHoldForSales,
    creditField: "",
  },
  {
    taxonomyCode: "322",
    sncSvatCode: ["461", "462", "463", "464", "465", "466", "467", "468"],
    sncSvatdescription:
      "Ativos não correntes detidos para venda - Ativos não correntes detidos para venda - Ativos fixos tangíveis",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.NonCurrentAssetsHoldForSales,
    creditField: "",
  },
  {
    taxonomyCode: "323",
    sncSvatCode: ["461", "462", "463", "464", "465", "466", "467", "468"],
    sncSvatdescription:
      "Ativos não correntes detidos para venda - Ativos não correntes detidos para venda - Ativos intangíveis",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.NonCurrentAssetsHoldForSales,
    creditField: "",
  },
  {
    taxonomyCode: "324",
    sncSvatCode: ["461", "462", "463", "464", "465", "466", "467", "468"],
    sncSvatdescription:
      "Ativos não correntes detidos para venda - Ativos não correntes detidos para venda - Outros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.NonCurrentAssetsHoldForSales,
    creditField: "",
  },
  {
    taxonomyCode: "325",
    sncSvatCode: ["461", "462", "463", "464", "465", "466", "467", "468"],
    sncSvatdescription:
      "Ativos não correntes detidos para venda - Passivos não correntes detidos para venda",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField:
      StatementTree.Liabilities.Current.NonCurrentLiabilitiesHeldForSales,
  },
  {
    taxonomyCode: "326",
    sncSvatCode: ["469"],
    sncSvatdescription:
      "Ativos não correntes detidos para venda - Perdas por imparidade acumuladas - Investimentos financeiros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.NonCurrentAssetsHoldForSales,
    creditField: "",
  },
  {
    taxonomyCode: "327",
    sncSvatCode: ["469"],
    sncSvatdescription:
      "Ativos não correntes detidos para venda - Perdas por imparidade acumuladas - Propriedades de investimento",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.NonCurrentAssetsHoldForSales,
    creditField: "",
  },
  {
    taxonomyCode: "328",
    sncSvatCode: ["469"],
    sncSvatdescription:
      "Ativos não correntes detidos para venda - Perdas por imparidade acumuladas - Ativos fixos tangíveis",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.NonCurrentAssetsHoldForSales,
    creditField: "",
  },
  {
    taxonomyCode: "329",
    sncSvatCode: ["469"],
    sncSvatdescription:
      "Ativos não correntes detidos para venda - Perdas por imparidade acumuladas - Ativos intangíveis",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.NonCurrentAssetsHoldForSales,
    creditField: "",
  },
  {
    taxonomyCode: "330",
    sncSvatCode: ["469"],
    sncSvatdescription:
      "Ativos não correntes detidos para venda - Perdas por imparidade acumuladas - Outros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.NonCurrentAssetsHoldForSales,
    creditField: "",
  },
  {
    taxonomyCode: "331",
    sncSvatCode: ["51"],
    sncSvatdescription: "Capital subscrito",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.SubscribedCapital,
  },
  {
    taxonomyCode: "332",
    sncSvatCode: ["521"],
    sncSvatdescription: "Ações (quotas) próprias - Valor nominal",
    includeAccounts: ["523", "524", "525", "526", "527", "528", "529"],
    classType: "C",
    expectedBalance: "D",
    debitField: "",
    creditField: StatementTree.Capital.OwnShares,
  },
  {
    taxonomyCode: "333",
    sncSvatCode: ["522"],
    sncSvatdescription: "Ações (quotas) próprias - Descontos e prémios",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "S1C",
    debitField: "",
    creditField: StatementTree.Capital.OwnShares,
  },
  {
    taxonomyCode: "334",
    sncSvatCode: ["53"],
    sncSvatdescription: "Outros instrumentos de capital próprio*",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.OtherCapitalInstruments,
  },
  {
    taxonomyCode: "335",
    sncSvatCode: ["54"],
    sncSvatdescription: "Prémios de emissão",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.IssuingPremia,
  },
  {
    taxonomyCode: "336",
    sncSvatCode: ["551"],
    sncSvatdescription: "Reservas - Reservas legais",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.LegalReserves,
  },
  {
    taxonomyCode: "337",
    sncSvatCode: ["552"],
    sncSvatdescription: "Reservas - Outras reservas",
    includeAccounts: ["553", "554", "555", "556", "557", "558", "559"],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.OtherReserves,
  },
  {
    taxonomyCode: "338",
    sncSvatCode: ["56"],
    sncSvatdescription: "Resultados transitados",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "S1C",
    debitField: "",
    creditField: StatementTree.Capital.PastResults,
  },
  {
    taxonomyCode: "339",
    sncSvatCode: ["5711"],
    sncSvatdescription:
      "Ajustamentos em ativos financeiros - Relacionados com o método da equivalência patrimonial - Ajustamentos de transição",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "S1C",
    debitField: "",
    creditField: StatementTree.Capital.OtherCapitalChanges,
  },
  {
    taxonomyCode: "340",
    sncSvatCode: ["5712"],
    sncSvatdescription:
      "Ajustamentos em ativos financeiros - Relacionados com o método da equivalência patrimonial - Lucros não atribuídos",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.OtherCapitalChanges,
  },
  {
    taxonomyCode: "341",
    sncSvatCode: ["5713"],
    sncSvatdescription:
      "Ajustamentos em ativos financeiros - Relacionados com o método da equivalência patrimonial - Decorrentes de outras variações nos capitais próprios das participadas",
    includeAccounts: ["5714", "5715", "5716", "5717", "5718", "5719"],
    classType: "C",
    expectedBalance: "S1C",
    debitField: "",
    creditField: StatementTree.Capital.OtherCapitalChanges,
  },
  {
    taxonomyCode: "342",
    sncSvatCode: ["579"],
    sncSvatdescription: "Ajustamentos em ativos financeiros - Outros",
    includeAccounts: ["572", "573", "574", "575", "576", "577", "578"],
    classType: "C",
    expectedBalance: "S1C",
    debitField: "",
    creditField: StatementTree.Capital.OtherCapitalChanges,
  },
  {
    taxonomyCode: "343",
    sncSvatCode: ["5811"],
    sncSvatdescription:
      "Excedentes de revalorização de ativos fixos tangíveis e intangíveis - Reavaliações decorrentes de diplomas legais - Antes de imposto sobre o rendimento",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.RevaluationSurplus,
  },
  {
    taxonomyCode: "344",
    sncSvatCode: ["5812"],
    sncSvatdescription:
      "Excedentes de revalorização de ativos fixos tangíveis e intangíveis - Reavaliações decorrentes de diplomas legais - Impostos diferidos",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "D",
    debitField: "",
    creditField: StatementTree.Capital.RevaluationSurplus,
  },
  {
    taxonomyCode: "345",
    sncSvatCode: ["5891"],
    sncSvatdescription:
      "Excedentes de revalorização de ativos fixos tangíveis e intangíveis - Outros excedentes - Antes de imposto sobre o rendimento",
    includeAccounts: ["582", "583", "584", "585", "586", "587", "588"],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.RevaluationSurplus,
  },
  {
    taxonomyCode: "346",
    sncSvatCode: ["5892"],
    sncSvatdescription:
      "Excedentes de revalorização de ativos fixos tangíveis e intangíveis - Outros excedentes - Impostos diferidos",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "D",
    debitField: "",
    creditField: StatementTree.Capital.RevaluationSurplus,
  },
  {
    taxonomyCode: "347",
    sncSvatCode: ["591"],
    sncSvatdescription:
      "Outras variações no capital próprio - Diferenças de conversão de demonstrações financeiras",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "S1C",
    debitField: "",
    creditField: StatementTree.Capital.OtherCapitalChanges,
  },
  {
    taxonomyCode: "348",
    sncSvatCode: ["592"],
    sncSvatdescription:
      "Outras variações no capital próprio - Ajustamentos por impostos diferidos",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "S1C",
    debitField: "",
    creditField: StatementTree.Capital.OtherCapitalChanges,
  },
  {
    taxonomyCode: "349",
    sncSvatCode: ["5931"],
    sncSvatdescription:
      "Outras variações no capital próprio - Subsídios - Subsídios atribuídos",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.OtherCapitalChanges,
  },
  {
    taxonomyCode: "350",
    sncSvatCode: ["5932"],
    sncSvatdescription:
      "Outras variações no capital próprio - Subsídios - Ajustamentos em subsídios",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "D",
    debitField: "",
    creditField: StatementTree.Capital.OtherCapitalChanges,
  },
  {
    taxonomyCode: "351",
    sncSvatCode: ["594"],
    sncSvatdescription: "Outras variações no capital próprio - Doações",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.OtherCapitalChanges,
  },
  {
    taxonomyCode: "352",
    sncSvatCode: ["599"],
    sncSvatdescription: "Outras variações no capital próprio - Outras",
    includeAccounts: ["595", "596", "597", "598"],
    classType: "C",
    expectedBalance: "S1C",
    debitField: "",
    creditField: StatementTree.Capital.OtherCapitalChanges,
  },
  {
    taxonomyCode: "353",
    sncSvatCode: ["611"],
    sncSvatdescription:
      "Custo das mercadorias vendidas e das matérias consumidas - Mercadorias",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Sa1C",
    debitField: StatementTree.ProfitAndLoss.Materials,
    creditField: "",
  },
  {
    taxonomyCode: "354",
    sncSvatCode: ["612"],
    sncSvatdescription:
      "Custo das mercadorias vendidas e das matérias consumidas - Matérias -primas, subsidiárias e de consumo",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Sa1C",
    debitField: StatementTree.ProfitAndLoss.Materials,
    creditField: "",
  },
  {
    taxonomyCode: "355",
    sncSvatCode: ["613"],
    sncSvatdescription:
      "Custo das mercadorias vendidas e das matérias consumidas - Ativos biológicos (compras)*",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Sa1C",
    debitField: StatementTree.ProfitAndLoss.Materials,
    creditField: "",
  },
  {
    taxonomyCode: "356",
    sncSvatCode: ["621"],
    sncSvatdescription: "Fornecimentos e serviços externos - Subcontratos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "357",
    sncSvatCode: ["6221"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Serviços especializados - Trabalhos especializados",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "358",
    sncSvatCode: ["6222"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Serviços especializados - Publicidade e propaganda",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "359",
    sncSvatCode: ["6223"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Serviços especializados - Vigilância e segurança",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "360",
    sncSvatCode: ["6224"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Serviços especializados - Honorários",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "361",
    sncSvatCode: ["6225"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Serviços especializados - Comissões",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "362",
    sncSvatCode: ["6226"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Serviços especializados - Conservação e reparação",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "363",
    sncSvatCode: ["6228"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Serviços especializados - Outros",
    includeAccounts: ["6227"],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "364",
    sncSvatCode: ["6231"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Materiais - Ferramentas e utensílios de desgaste rápido",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "365",
    sncSvatCode: ["6232"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Materiais - Livros e documentação técnica",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "366",
    sncSvatCode: ["6233"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Materiais - Material de escritório",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "367",
    sncSvatCode: ["6234"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Materiais - Artigos para oferta",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "368",
    sncSvatCode: ["6238"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Materiais - Outros",
    includeAccounts: ["6235", "6236", "6237"],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "369",
    sncSvatCode: ["6241"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Energia e fluidos - Eletricidade",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "370",
    sncSvatCode: ["6242"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Energia e fluidos - Combustíveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "371",
    sncSvatCode: ["6243"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Energia e fluidos - Água",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "372",
    sncSvatCode: ["6248"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Energia e fluidos - Outros",
    includeAccounts: ["6244", "6245", "6246", "6247"],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "373",
    sncSvatCode: ["6251"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Deslocações, estadas e transportes - Deslocações e estadas",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "374",
    sncSvatCode: ["6252"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Deslocações, estadas e transportes - Transportes de pessoal",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "375",
    sncSvatCode: ["6253"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Deslocações, estadas e transportes - Transportes de mercadorias",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "376",
    sncSvatCode: ["6258"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Deslocações, estadas e transportes - Outros",
    includeAccounts: ["6254", "6255", "6256", "6257"],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "377",
    sncSvatCode: ["6261"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Serviços diversos - Rendas e alugueres",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "378",
    sncSvatCode: ["6262"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Serviços diversos - Comunicação",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "379",
    sncSvatCode: ["6263"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Serviços diversos - Seguros",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "380",
    sncSvatCode: ["6264"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Serviços diversos - Royalties",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "381",
    sncSvatCode: ["6265"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Serviços diversos - Contencioso e notariado",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "382",
    sncSvatCode: ["6266"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Serviços diversos - Despesas de representação",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "383",
    sncSvatCode: ["6267"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Serviços diversos - Limpeza, higiene e conforto",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "384",
    sncSvatCode: ["6268"],
    sncSvatdescription:
      "Fornecimentos e serviços externos - Serviços diversos - Outros serviços",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "385",
    sncSvatCode: ["631"],
    sncSvatdescription:
      "Gastos com o pessoal - Remunerações dos órgãos sociais",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "386",
    sncSvatCode: ["632"],
    sncSvatdescription: "Gastos com o pessoal - Remunerações do pessoal",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "387",
    sncSvatCode: ["6331"],
    sncSvatdescription:
      "Gastos com o pessoal - Benefícios pós -emprego - Prémios para pensões",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Sa1C",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "388",
    sncSvatCode: ["6332"],
    sncSvatdescription:
      "Gastos com o pessoal - Benefícios pós -emprego - Outros benefícios",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Sa1C",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "389",
    sncSvatCode: ["634"],
    sncSvatdescription: "Gastos com o pessoal - Indemnizações",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "390",
    sncSvatCode: ["635"],
    sncSvatdescription: "Gastos com o pessoal - Encargos sobre remunerações",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "391",
    sncSvatCode: ["636"],
    sncSvatdescription:
      "Gastos com o pessoal - Seguros de acidentes no trabalho e doenças profissionais",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "392",
    sncSvatCode: ["637"],
    sncSvatdescription: "Gastos com o pessoal - Gastos de ação social",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "393",
    sncSvatCode: ["638"],
    sncSvatdescription: "Gastos com o pessoal - Outros gastos com o pessoal",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "394",
    sncSvatCode: ["641"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Propriedades de investimento - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "395",
    sncSvatCode: ["641"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Propriedades de investimento - Edifícios e outras construções",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "396",
    sncSvatCode: ["641"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Propriedades de investimento - Outras propriedades de investimento",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "397",
    sncSvatCode: ["642"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Ativos fixos tangíveis - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "398",
    sncSvatCode: ["642"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Ativos fixos tangíveis - Edifícios e outras construções",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "399",
    sncSvatCode: ["642"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Ativos fixos tangíveis - Equipamento básico",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "400",
    sncSvatCode: ["642"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Ativos fixos tangíveis - Equipamento de transporte",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "401",
    sncSvatCode: ["642"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Ativos fixos tangíveis - Equipamento administrativo",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "402",
    sncSvatCode: ["642"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Ativos fixos tangíveis - Equipamentos biológicos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "403",
    sncSvatCode: ["642"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Ativos fixos tangíveis - Outros ativos fixos tangíveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "404",
    sncSvatCode: ["643"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Ativos intangíveis - Goodwill",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "405",
    sncSvatCode: ["643"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Ativos intangíveis - Projetos de desenvolvimento",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "406",
    sncSvatCode: ["643"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Ativos intangíveis - Programas de computador",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "407",
    sncSvatCode: ["643"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Ativos intangíveis - Propriedade industrial",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "408",
    sncSvatCode: ["643"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Ativos intangíveis - Outros ativos intangíveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "409",
    sncSvatCode: ["641", "642", "643"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Ativos biológicos - Consumíveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "410",
    sncSvatCode: ["641", "642", "643"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Ativos biológicos - De produção",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "411",
    sncSvatCode: ["641", "642", "643"],
    sncSvatdescription:
      "Gastos de depreciação e de amortização - Investimentos financeiros - Goodwill",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "412",
    sncSvatCode: [
      "6511",
      "6512",
      "652",
      "653",
      "654",
      "655",
      "656",
      "657",
      "658",
    ],
    sncSvatdescription: "Perdas por imparidade - Instrumentos financeiros",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Sa1C",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "413",
    sncSvatCode: ["6511"],
    sncSvatdescription:
      "Perdas por imparidade - Em dívidas a receber - Clientes",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ReceivableImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "414",
    sncSvatCode: ["6512"],
    sncSvatdescription:
      "Perdas por imparidade - Em dívidas a receber - Outros devedores",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ReceivableImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "415",
    sncSvatCode: ["652"],
    sncSvatdescription: "Perdas por imparidade - Em inventários - Mercadorias",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InventoryImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "416",
    sncSvatCode: ["652"],
    sncSvatdescription:
      "Perdas por imparidade - Em inventários - Matérias -primas, subsidiárias e de consumo",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InventoryImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "417",
    sncSvatCode: ["652"],
    sncSvatdescription:
      "Perdas por imparidade - Em inventários - Produtos acabados e intermédios",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InventoryImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "418",
    sncSvatCode: ["652"],
    sncSvatdescription:
      "Perdas por imparidade - Em inventários - Subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InventoryImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "419",
    sncSvatCode: ["652"],
    sncSvatdescription:
      "Perdas por imparidade - Em inventários - Produtos e trabalhos em curso",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InventoryImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "420",
    sncSvatCode: ["652"],
    sncSvatdescription:
      "Perdas por imparidade - Em inventários - Ativos biológicos consumíveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InventoryImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "421",
    sncSvatCode: ["652"],
    sncSvatdescription:
      "Perdas por imparidade - Em inventários - Ativos biológicos de produção",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InventoryImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "422",
    sncSvatCode: ["653"],
    sncSvatdescription:
      "Perdas por imparidade - Em investimentos financeiros - Participações de capital",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "423",
    sncSvatCode: ["653"],
    sncSvatdescription:
      "Perdas por imparidade - Em investimentos financeiros - Empréstimos concedidos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "424",
    sncSvatCode: ["653"],
    sncSvatdescription:
      "Perdas por imparidade - Em investimentos financeiros - Outros investimentos financeiros",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "425",
    sncSvatCode: ["653"],
    sncSvatdescription:
      "Perdas por imparidade - Em investimentos financeiros - Goodwill",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "426",
    sncSvatCode: ["654"],
    sncSvatdescription:
      "Perdas por imparidade - Em propriedades de investimento - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "427",
    sncSvatCode: ["654"],
    sncSvatdescription:
      "Perdas por imparidade - Em propriedades de investimento - Edifícios e outras construções",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "428",
    sncSvatCode: ["654"],
    sncSvatdescription:
      "Perdas por imparidade - Em propriedades de investimento - Outras propriedades de investimento",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "429",
    sncSvatCode: ["655"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos fixos tangíveis - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "430",
    sncSvatCode: ["655"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos fixos tangíveis - Edifícios e outras construções",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "431",
    sncSvatCode: ["655"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos fixos tangíveis - Equipamento básico",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "432",
    sncSvatCode: ["655"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos fixos tangíveis - Equipamento de transporte",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "433",
    sncSvatCode: ["655"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos fixos tangíveis - Equipamento administrativo",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "434",
    sncSvatCode: ["655"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos fixos tangíveis - Equipamentos biológicos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "435",
    sncSvatCode: ["655"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos fixos tangíveis - Outros ativos fixos tangíveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "436",
    sncSvatCode: ["656"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos intangíveis - Goodwill",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "437",
    sncSvatCode: ["656"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos intangíveis - Projetos de desenvolvimento",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "438",
    sncSvatCode: ["656"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos intangíveis - Programas de computador",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "439",
    sncSvatCode: ["656"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos intangíveis - Propriedade industrial",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "440",
    sncSvatCode: ["656"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos intangíveis - Outros ativos intangíveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "441",
    sncSvatCode: ["657"],
    sncSvatdescription:
      "Perdas por imparidade - Em investimentos em curso - Investimentos financeiros em curso",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "442",
    sncSvatCode: ["657"],
    sncSvatdescription:
      "Perdas por imparidade - Em investimentos em curso - Propriedades de investimento em curso",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "443",
    sncSvatCode: ["657"],
    sncSvatdescription:
      "Perdas por imparidade - Em investimentos em curso - Ativos fixos tangíveis em curso",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "444",
    sncSvatCode: ["657"],
    sncSvatdescription:
      "Perdas por imparidade - Em investimentos em curso - Ativos intangíveis em curso",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "445",
    sncSvatCode: ["657"],
    sncSvatdescription:
      "Perdas por imparidade - Em investimentos em curso - Adiantamentos por conta de investimentos - Investimentos financeiros em curso",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "446",
    sncSvatCode: ["657"],
    sncSvatdescription:
      "Perdas por imparidade - Em investimentos em curso - Adiantamentos por conta de investimentos - Propriedades de investimento em curso",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "447",
    sncSvatCode: ["657"],
    sncSvatdescription:
      "Perdas por imparidade - Em investimentos em curso - Adiantamentos por conta de investimentos - Ativos fixos tangíveis em curso",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "448",
    sncSvatCode: ["657"],
    sncSvatdescription:
      "Perdas por imparidade - Em investimentos em curso - Adiantamentos por conta de investimentos - Ativos intangíveis em curso",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "449",
    sncSvatCode: ["658"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos não correntes detidos para venda - Investimentos financeiros",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "450",
    sncSvatCode: ["658"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos não correntes detidos para venda - Propriedades de investimento",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "451",
    sncSvatCode: ["658"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos não correntes detidos para venda - Ativos fixos tangíveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "452",
    sncSvatCode: ["658"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos não correntes detidos para venda - Ativos intangíveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "453",
    sncSvatCode: ["658"],
    sncSvatdescription:
      "Perdas por imparidade - Em ativos não correntes detidos para venda - Outros",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "454",
    sncSvatCode: ["661"],
    sncSvatdescription:
      "Perdas por reduções de justo valor - Em instrumentos financeiros",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "455",
    sncSvatCode: ["662"],
    sncSvatdescription:
      "Perdas por reduções de justo valor - Em investimentos financeiros - Participações de capital",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "456",
    sncSvatCode: ["662"],
    sncSvatdescription:
      "Perdas por reduções de justo valor - Em investimentos financeiros - Outros investimentos financeiros",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "457",
    sncSvatCode: ["663"],
    sncSvatdescription:
      "Perdas por reduções de justo valor - Em propriedades de investimento - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "458",
    sncSvatCode: ["663"],
    sncSvatdescription:
      "Perdas por reduções de justo valor - Em propriedades de investimento - Edifícios e outras construções",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "459",
    sncSvatCode: ["663"],
    sncSvatdescription:
      "Perdas por reduções de justo valor - Em propriedades de investimento - Outras propriedades de investimento",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "460",
    sncSvatCode: ["663"],
    sncSvatdescription:
      "Perdas por reduções de justo valor - Em propriedades de investimento - Propriedades de investimento em curso",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "461",
    sncSvatCode: ["664"],
    sncSvatdescription:
      "Perdas por reduções de justo valor - Em ativos biológicos - Consumíveis",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "462",
    sncSvatCode: ["664"],
    sncSvatdescription:
      "Perdas por reduções de justo valor - Em ativos biológicos - De produção",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "463",
    sncSvatCode: ["671"],
    sncSvatdescription: "Provisões do período - Impostos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "464",
    sncSvatCode: ["672"],
    sncSvatdescription: "Provisões do período - Garantias a clientes",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "465",
    sncSvatCode: ["673"],
    sncSvatdescription: "Provisões do período - Processos judiciais em curso",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "466",
    sncSvatCode: ["674"],
    sncSvatdescription:
      "Provisões do período - Acidentes no trabalho e doenças profissionais",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "467",
    sncSvatCode: ["675"],
    sncSvatdescription: "Provisões do período - Matérias ambientais",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "468",
    sncSvatCode: ["676"],
    sncSvatdescription: "Provisões do período - Contratos onerosos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "469",
    sncSvatCode: ["677"],
    sncSvatdescription: "Provisões do período - Reestruturação",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "470",
    sncSvatCode: ["678"],
    sncSvatdescription: "Provisões do período - Outras provisões",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "471",
    sncSvatCode: ["6811"],
    sncSvatdescription: "Outros gastos - Impostos - Impostos diretos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "472",
    sncSvatCode: ["6812"],
    sncSvatdescription: "Outros gastos - Impostos - Impostos indiretos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "473",
    sncSvatCode: ["6813"],
    sncSvatdescription: "Outros gastos - Impostos - Taxas",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "474",
    sncSvatCode: ["682"],
    sncSvatdescription:
      "Outros gastos - Descontos de pronto pagamento concedidos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "475",
    sncSvatCode: ["683"],
    sncSvatdescription: "Outros gastos - Dívidas incobráveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "476",
    sncSvatCode: ["6841"],
    sncSvatdescription: "Outros gastos - Perdas em inventários - Sinistros",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "477",
    sncSvatCode: ["6842"],
    sncSvatdescription: "Outros gastos - Perdas em inventários - Quebras",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "478",
    sncSvatCode: ["6848"],
    sncSvatdescription: "Outros gastos - Perdas em inventários - Outras perdas",
    includeAccounts: ["6843", "6844", "6845", "6846", "6847"],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "479",
    sncSvatCode: ["6851"],
    sncSvatdescription:
      "Outros gastos - Gastos em subsidiárias, associadas e empreendimentos conjuntos - Cobertura de prejuízos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.GainsAndLossesInAssociates,
  },
  {
    taxonomyCode: "480",
    sncSvatCode: ["6852"],
    sncSvatdescription:
      "Outros gastos - Gastos em subsidiárias, associadas e empreendimentos conjuntos - Aplicação do método da equivalência patrimonial",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.GainsAndLossesInAssociates,
  },
  {
    taxonomyCode: "481",
    sncSvatCode: ["6853"],
    sncSvatdescription:
      "Outros gastos - Gastos em subsidiárias, associadas e empreendimentos conjuntos - Alienações",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.GainsAndLossesInAssociates,
  },
  {
    taxonomyCode: "482",
    sncSvatCode: ["6858"],
    sncSvatdescription:
      "Outros gastos - Gastos em subsidiárias, associadas e empreendimentos conjuntos - Outros gastos",
    includeAccounts: ["6854", "6855", "6856", "6857"],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.GainsAndLossesInAssociates,
  },
  {
    taxonomyCode: "483",
    sncSvatCode: ["6861"],
    sncSvatdescription:
      "Outros gastos - Gastos nos restantes investimentos financeros - Cobertura de prejuízos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "484",
    sncSvatCode: ["6862"],
    sncSvatdescription:
      "Outros gastos - Gastos nos restantes investimentos financeros - Alienações",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "485",
    sncSvatCode: ["6863"],
    sncSvatdescription:
      "Outros gastos - Gastos nos restantes investimentos financeros - Diferenças de câmbio desfavoráveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "486",
    sncSvatCode: ["6868"],
    sncSvatdescription:
      "Outros gastos - Gastos nos restantes investimentos financeros - Outros gastos",
    includeAccounts: ["6864", "6865", "6866", "6867"],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "487",
    sncSvatCode: ["6871"],
    sncSvatdescription:
      "Outros gastos - Gastos em investimentos não financeiros - Alienações",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "488",
    sncSvatCode: ["6872"],
    sncSvatdescription:
      "Outros gastos - Gastos em investimentos não financeiros - Sinistros",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "489",
    sncSvatCode: ["6873"],
    sncSvatdescription:
      "Outros gastos - Gastos em investimentos não financeiros - Abates",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "490",
    sncSvatCode: ["6874"],
    sncSvatdescription:
      "Outros gastos - Gastos em investimentos não financeiros - Gastos em propriedades de investimento",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "491",
    sncSvatCode: ["6878"],
    sncSvatdescription:
      "Outros gastos - Gastos em investimentos não financeiros - Outras gastos",
    includeAccounts: ["6875", "6876", "6877"],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "492",
    sncSvatCode: ["6881"],
    sncSvatdescription:
      "Outros gastos - Outros - Correções relativas a períodos anteriores",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "493",
    sncSvatCode: ["6882"],
    sncSvatdescription: "Outros gastos - Outros - Donativos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "494",
    sncSvatCode: ["6883"],
    sncSvatdescription: "Outros gastos - Outros - Quotizações",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "495",
    sncSvatCode: ["6884"],
    sncSvatdescription:
      "Outros gastos - Outros - Ofertas e amostras de inventários",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "496",
    sncSvatCode: ["6885"],
    sncSvatdescription:
      "Outros gastos - Outros - Insuficiência da estimativa para impostos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "497",
    sncSvatCode: ["6886"],
    sncSvatdescription:
      "Outros gastos - Outros - Perdas em instrumentos financeiros",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "498",
    sncSvatCode: ["6887"],
    sncSvatdescription:
      "Outros gastos - Outros - Diferenças de câmbio desfavoráveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "499",
    sncSvatCode: ["6888"],
    sncSvatdescription: "Outros gastos - Outros - Outros não especificados",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "500",
    sncSvatCode: ["6911"],
    sncSvatdescription:
      "Gastos de financiamento - Juros suportados - Juros de financiamentos obtidos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InterestExpense,
    creditField: "",
  },
  {
    taxonomyCode: "501",
    sncSvatCode: ["6918"],
    sncSvatdescription:
      "Gastos de financiamento - Juros suportados - Outros juros",
    includeAccounts: ["6912", "6913", "6914", "6915", "6916", "6917"],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InterestExpense,
    creditField: "",
  },
  {
    taxonomyCode: "502",
    sncSvatCode: ["6921"],
    sncSvatdescription:
      "Gastos de financiamento - Diferenças de câmbio desfavoráveis - Relativas a financiamentos obtidos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InterestExpense,
    creditField: "",
  },
  {
    taxonomyCode: "503",
    sncSvatCode: ["6928"],
    sncSvatdescription:
      "Gastos de financiamento - Diferenças de câmbio desfavoráveis - Outras",
    includeAccounts: ["6922", "6923", "6924", "6925", "6926", "6927"],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InterestExpense,
    creditField: "",
  },
  {
    taxonomyCode: "504",
    sncSvatCode: ["6981"],
    sncSvatdescription:
      "Gastos de financiamento - Outros gastos de financiamento - Relativos a financiamentos obtidos",
    includeAccounts: [
      "693",
      "694",
      "695",
      "696",
      "697",
      "6982",
      "6983",
      "6984",
      "6985",
      "6986",
      "6987",
    ],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InterestExpense,
    creditField: "",
  },
  {
    taxonomyCode: "505",
    sncSvatCode: ["6988"],
    sncSvatdescription:
      "Gastos de financiamento - Outros gastos de financiamento - Outros",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InterestExpense,
    creditField: "",
  },
  {
    taxonomyCode: "506",
    sncSvatCode: ["711"],
    sncSvatdescription: "Vendas - Mercadorias",
    includeAccounts: ["715"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "507",
    sncSvatCode: ["712"],
    sncSvatdescription: "Vendas - Produtos acabados e intermédios",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "508",
    sncSvatCode: ["713"],
    sncSvatdescription:
      "Vendas - Subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "509",
    sncSvatCode: ["714"],
    sncSvatdescription: "Vendas - Ativos biológicos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "510",
    sncSvatCode: ["716"],
    sncSvatdescription: "Vendas - IVA das vendas com imposto incluído",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Sa1C",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "511",
    sncSvatCode: ["717"],
    sncSvatdescription: "Vendas - Devoluções de vendas",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "512",
    sncSvatCode: ["718"],
    sncSvatdescription: "Vendas - Descontos e abatimentos em vendas",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "513",
    sncSvatCode: ["721"],
    sncSvatdescription: "Prestações de serviços - Serviço A",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "514",
    sncSvatCode: ["722"],
    sncSvatdescription: "Prestações de serviços - Serviço B",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "515",
    sncSvatCode: ["723", "724", "727"],
    sncSvatdescription: "Prestações de serviços - Outros serviços",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "516",
    sncSvatCode: ["725"],
    sncSvatdescription: "Prestações de serviços - Serviços secundários",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "517",
    sncSvatCode: ["726"],
    sncSvatdescription:
      "Prestações de serviços - IVA dos serviços com imposto incluído",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Sa1C",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "518",
    sncSvatCode: ["728"],
    sncSvatdescription: "Prestações de serviços - Descontos e abatimentos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "519",
    sncSvatCode: ["731"],
    sncSvatdescription:
      "Variações nos inventários da produção - Produtos acabados e intermédios",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Sa1C",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.ChangeInProductionInventory,
  },
  {
    taxonomyCode: "520",
    sncSvatCode: ["732"],
    sncSvatdescription:
      "Variações nos inventários da produção - Subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Sa1C",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.ChangeInProductionInventory,
  },
  {
    taxonomyCode: "521",
    sncSvatCode: ["733"],
    sncSvatdescription:
      "Variações nos inventários da produção - Produtos e trabalhos em curso",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Sa1C",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.ChangeInProductionInventory,
  },
  {
    taxonomyCode: "522",
    sncSvatCode: ["734"],
    sncSvatdescription:
      "Variações nos inventários da produção - Ativos biológicos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Sa1C",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.ChangeInProductionInventory,
  },
  {
    taxonomyCode: "523",
    sncSvatCode: ["741"],
    sncSvatdescription:
      "Trabalhos para a própria entidade - Ativos fixos tangíveis",
    includeAccounts: ["745", "746", "747", "748", "749"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OwnWork,
  },
  {
    taxonomyCode: "524",
    sncSvatCode: ["742"],
    sncSvatdescription:
      "Trabalhos para a própria entidade - Ativos intangíveis",
    includeAccounts: ["745", "746", "747", "748", "749"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OwnWork,
  },
  {
    taxonomyCode: "525",
    sncSvatCode: ["743"],
    sncSvatdescription:
      "Trabalhos para a própria entidade - Propriedades de investimento",
    includeAccounts: ["745", "746", "747", "748", "749"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OwnWork,
  },
  {
    taxonomyCode: "526",
    sncSvatCode: ["744"],
    sncSvatdescription:
      "Trabalhos para a própria entidade - Ativos por gastos diferidos",
    includeAccounts: ["745", "746", "747", "748", "749"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OwnWork,
  },
  {
    taxonomyCode: "527",
    sncSvatCode: ["751"],
    sncSvatdescription:
      "Subsídios à exploração - Subsídios das entidades públicas",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.Subsidies,
  },
  {
    taxonomyCode: "528",
    sncSvatCode: ["752"],
    sncSvatdescription:
      "Subsídios à exploração - Subsídios de outras entidades",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.Subsidies,
  },
  {
    taxonomyCode: "529",
    sncSvatCode: ["7611"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Propriedades de investimento - Terrenos e recursos naturais",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "769"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "530",
    sncSvatCode: ["7611"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Propriedades de investimento - Edifícios e outras construções",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "770"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "531",
    sncSvatCode: ["7611"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Propriedades de investimento - Outras propriedades de investimento",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "771"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "532",
    sncSvatCode: ["7612"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Ativos fixos tangíveis - Terrenos e recursos naturais",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "772"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "533",
    sncSvatCode: ["7612"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Ativos fixos tangíveis - Edifícios e outras construções",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "773"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "534",
    sncSvatCode: ["7612"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Ativos fixos tangíveis - Equipamento básico",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "774"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "535",
    sncSvatCode: ["7612"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Ativos fixos tangíveis - Equipamento de transporte",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "775"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "536",
    sncSvatCode: ["7612"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Ativos fixos tangíveis - Equipamento administrativo",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "776"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "537",
    sncSvatCode: ["7612"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Ativos fixos tangíveis - Equipamentos biológicos",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "777"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "538",
    sncSvatCode: ["7612"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Ativos fixos tangíveis - Outros ativos fixos tangíveis",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "778"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "539",
    sncSvatCode: ["7613"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Ativos intangíveis - Goodwill",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "779"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "540",
    sncSvatCode: ["7613"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Ativos intangíveis - Projetos de desenvolvimento",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "780"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "541",
    sncSvatCode: ["7613"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Ativos intangíveis - Programas de computador",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "781"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "542",
    sncSvatCode: ["7613"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Ativos intangíveis - Propriedade industrial",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "782"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "543",
    sncSvatCode: ["7613"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Ativos intangíveis - Outros ativos intangíveis",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "783"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "544",
    sncSvatCode: ["7614", "7615", "7616", "7617", "7618", "7619"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Ativos biológicos - Consumíveis",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "784"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "545",
    sncSvatCode: ["7614", "7615", "7616", "7617", "7618", "7619"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Ativos biológicos - De produção",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "785"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "546",
    sncSvatCode: ["7614", "7615", "7616", "7617", "7618", "7619"],
    sncSvatdescription:
      "Reversões - De depreciações e de amortizações - Investimentos financeiros - Goodwill",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "786"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "547",
    sncSvatCode: ["76211"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em dívidas a receber - Clientes",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "787"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.ReceivableImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "548",
    sncSvatCode: ["76212"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em dívidas a receber - Outros devedores",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "788"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.ReceivableImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "549",
    sncSvatCode: ["7622"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em inventários - Mercadorias",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "789"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.InventoryImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "550",
    sncSvatCode: ["7622"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em inventários - Matérias -primas, subsidiárias e de consumo",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "790"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.InventoryImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "551",
    sncSvatCode: ["7622"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em inventários - Produtos acabados e intermédios",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "791"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.InventoryImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "552",
    sncSvatCode: ["7622"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em inventários - Subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "792"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.InventoryImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "553",
    sncSvatCode: ["7622"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em inventários - Produtos e trabalhos em curso",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "793"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.InventoryImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "554",
    sncSvatCode: ["7622"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em inventários - Ativos biológicos consumíveis",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "794"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.InventoryImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "555",
    sncSvatCode: ["7622"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em inventários - Ativos biológicos de produção",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "795"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.InventoryImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "556",
    sncSvatCode: ["7623"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em investimentos financeiros - Participações de capital",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "796"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "557",
    sncSvatCode: ["7623"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em investimentos financeiros - Empréstimos concedidos",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "797"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "558",
    sncSvatCode: ["7623"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em investimentos financeiros - Outros investimentos financeiros",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "798"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "559",
    sncSvatCode: ["7624"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em propriedades de investimento - Terrenos e recursos naturais",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "799"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "560",
    sncSvatCode: ["7624"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em propriedades de investimento - Edifícios e outras construções",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "800"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "561",
    sncSvatCode: ["7624"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em propriedades de investimento - Outras propriedades de investimento",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "801"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "562",
    sncSvatCode: ["7625"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos fixos tangíveis - Terrenos e recursos naturais",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "802"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "563",
    sncSvatCode: ["7625"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos fixos tangíveis - Edifícios e outras construções",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "803"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "564",
    sncSvatCode: ["7625"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos fixos tangíveis - Equipamento básico",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "804"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "565",
    sncSvatCode: ["7625"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos fixos tangíveis - Equipamento de transporte",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "805"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "566",
    sncSvatCode: ["7625"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos fixos tangíveis - Equipamento administrativo",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "806"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "567",
    sncSvatCode: ["7625"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos fixos tangíveis - Equipamentos biológicos",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "807"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "568",
    sncSvatCode: ["7625"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos fixos tangíveis - Outros ativos fixos tangíveis",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "808"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "569",
    sncSvatCode: ["7626"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos intangíveis - Projetos de desenvolvimento",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "809"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "570",
    sncSvatCode: ["7626"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos intangíveis - Programas de computador",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "810"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "571",
    sncSvatCode: ["7626"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos intangíveis - Propriedade industrial",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "811"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField:
      "Imparidade de investimentos depreciáveis / amortizáveis (perdas/reversões)",
    creditField: "",
  },
  {
    taxonomyCode: "572",
    sncSvatCode: ["7626"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos intangíveis - Outros ativos intangíveis",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "812"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField:
      "Imparidade de investimentos depreciáveis / amortizáveis (perdas/reversões)",
    creditField: "",
  },
  {
    taxonomyCode: "573",
    sncSvatCode: ["7627"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em investimentos em curso - Investimentos financeiros em curso",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "813"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "574",
    sncSvatCode: ["7627"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em investimentos em curso - Propriedades de investimento em curso",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "814"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "575",
    sncSvatCode: ["7627"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em investimentos em curso - Ativos fixos tangíveis em curso",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "815"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "576",
    sncSvatCode: ["7627"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em investimentos em curso - Ativos intangíveis em curso",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "816"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "577",
    sncSvatCode: ["7627"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em investimentos em curso - Adiantamentos por conta de investimentos - Investimentos financeiros em curso",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "817"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "578",
    sncSvatCode: ["7627"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em investimentos em curso - Adiantamentos por conta de investimentos - Propriedades de investimento em curso",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "818"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "579",
    sncSvatCode: ["7627"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em investimentos em curso - Adiantamentos por conta de investimentos - Ativos fixos tangíveis em curso",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "819"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "580",
    sncSvatCode: ["7627"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em investimentos em curso - Adiantamentos por conta de investimentos - Ativos intangíveis em curso",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "820"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "581",
    sncSvatCode: ["7628"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos não correntes detidos para venda - Investimentos financeiros",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "821"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "582",
    sncSvatCode: ["7628"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos não correntes detidos para venda - Propriedades de investimento",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "822"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "583",
    sncSvatCode: ["7628"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos não correntes detidos para venda - Ativos fixos tangíveis",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "823"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "584",
    sncSvatCode: ["7628"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos não correntes detidos para venda - Ativos intangíveis",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "824"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "585",
    sncSvatCode: ["7628"],
    sncSvatdescription:
      "Reversões - De perdas por imparidade - Em ativos não correntes detidos para venda - Outros",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "825"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandAInvestmentImpairments,
    creditField: "",
  },
  {
    taxonomyCode: "586",
    sncSvatCode: ["7631"],
    sncSvatdescription: "Reversões - De provisões - Impostos",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "826"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "587",
    sncSvatCode: ["7632"],
    sncSvatdescription: "Reversões - De provisões - Garantias a clientes",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "827"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "588",
    sncSvatCode: ["7633"],
    sncSvatdescription:
      "Reversões - De provisões - Processos judiciais em curso",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "828"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "589",
    sncSvatCode: ["7634"],
    sncSvatdescription:
      "Reversões - De provisões - Acidentes no trabalho e doenças profissionais",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "829"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "590",
    sncSvatCode: ["7635"],
    sncSvatdescription: "Reversões - De provisões - Matérias ambientais",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "830"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "591",
    sncSvatCode: ["7636"],
    sncSvatdescription: "Reversões - De provisões - Contratos onerosos",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "831"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "592",
    sncSvatCode: ["7637"],
    sncSvatdescription: "Reversões - De provisões - Reestruturação",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "832"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "593",
    sncSvatCode: ["7638"],
    sncSvatdescription: "Reversões - De provisões - Outras provisões",
    includeAccounts: ["7639", "764", "765", "766", "767", "768", "833"],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "594",
    sncSvatCode: ["771"],
    sncSvatdescription:
      "Ganhos por aumentos de justo valor - Em instrumentos financeiros",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "595",
    sncSvatCode: ["772"],
    sncSvatdescription:
      "Ganhos por aumentos de justo valor - Em investimentos financeiros - Participações de capital",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "596",
    sncSvatCode: ["772"],
    sncSvatdescription:
      "Ganhos por aumentos de justo valor - Em investimentos financeiros - Outros investimentos financeiros",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "597",
    sncSvatCode: ["773"],
    sncSvatdescription:
      "Ganhos por aumentos de justo valor - Em propriedades de investimento - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "598",
    sncSvatCode: ["773"],
    sncSvatdescription:
      "Ganhos por aumentos de justo valor - Em propriedades de investimento - Edifícios e outras construções",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "599",
    sncSvatCode: ["773"],
    sncSvatdescription:
      "Ganhos por aumentos de justo valor - Em propriedades de investimento - Outras propriedades de investimento",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "600",
    sncSvatCode: ["773"],
    sncSvatdescription:
      "Ganhos por aumentos de justo valor - Em propriedades de investimento - Propriedades de investimento em curso",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "601",
    sncSvatCode: ["774"],
    sncSvatdescription:
      "Ganhos por aumentos de justo valor - Em ativos biológicos - Consumíveis",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "602",
    sncSvatCode: ["774"],
    sncSvatdescription:
      "Ganhos por aumentos de justo valor - Em ativos biológicos - De produção",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FairValueChanges,
  },
  {
    taxonomyCode: "603",
    sncSvatCode: ["7811"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos suplementares - Serviços sociais",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "604",
    sncSvatCode: ["7812"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos suplementares - Aluguer de equipamento",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "605",
    sncSvatCode: ["7813"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos suplementares - Estudos, projetos e assistência tecnológica",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "606",
    sncSvatCode: ["7814"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos suplementares - Royalties",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "607",
    sncSvatCode: ["7815"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos suplementares - Desempenho de cargos sociais noutras empresas",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "608",
    sncSvatCode: ["7816"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos suplementares - Outros rendimentos suplementares",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "609",
    sncSvatCode: ["782"],
    sncSvatdescription:
      "Outros rendimentos - Descontos de pronto pagamento obtidos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "610",
    sncSvatCode: ["783"],
    sncSvatdescription: "Outros rendimentos - Recuperação de dívidas a receber",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "611",
    sncSvatCode: ["7841"],
    sncSvatdescription:
      "Outros rendimentos - Ganhos em inventários - Sinistros",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "612",
    sncSvatCode: ["7842"],
    sncSvatdescription: "Outros rendimentos - Ganhos em inventários - Sobras",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "613",
    sncSvatCode: ["7848"],
    sncSvatdescription:
      "Outros rendimentos - Ganhos em inventários - Outros ganhos",
    includeAccounts: ["7843", "7844", "7845", "7846", "7847"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "614",
    sncSvatCode: ["7851"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos em subsidiárias, associadas e empreendimentos conjuntos - Aplicação do método da equivalência patrimonial",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.GainsAndLossesInAssociates,
  },
  {
    taxonomyCode: "615",
    sncSvatCode: ["7852"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos em subsidiárias, associadas e empreendimentos conjuntos - Alienações",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.GainsAndLossesInAssociates,
  },
  {
    taxonomyCode: "616",
    sncSvatCode: ["7858"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos em subsidiárias, associadas e empreendimentos conjuntos - Outros rendimentos",
    includeAccounts: ["7853", "7854", "7855", "7856", "7857"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.GainsAndLossesInAssociates,
  },
  {
    taxonomyCode: "617",
    sncSvatCode: ["7861"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos nos restantes ativos financeiros - Diferenças de câmbio favoráveis",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "618",
    sncSvatCode: ["7862"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos nos restantes ativos financeiros - Alienações",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "619",
    sncSvatCode: ["7868"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos nos restantes ativos financeiros - Outros rendimentos",
    includeAccounts: ["7863", "7864", "7865", "7866", "7867"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "620",
    sncSvatCode: ["7871"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos em investimentos não financeiros - Alienações",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "621",
    sncSvatCode: ["7872"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos em investimentos não financeiros - Sinistros",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "622",
    sncSvatCode: ["7873"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos em investimentos não financeiros - Rendas e outros rendimentos em propriedades de investimento",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "623",
    sncSvatCode: ["7878"],
    sncSvatdescription:
      "Outros rendimentos - Rendimentos em investimentos não financeiros - Outros rendimentos",
    includeAccounts: ["7874", "7875", "7876", "7877"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "624",
    sncSvatCode: ["7881"],
    sncSvatdescription:
      "Outros rendimentos - Outros - Correções relativas a períodos anteriores",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "625",
    sncSvatCode: ["7882"],
    sncSvatdescription:
      "Outros rendimentos - Outros - Excesso da estimativa para impostos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "626",
    sncSvatCode: ["7883"],
    sncSvatdescription:
      "Outros rendimentos - Outros - Imputação de subsídios para investimentos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "627",
    sncSvatCode: ["7884"],
    sncSvatdescription:
      "Outros rendimentos - Outros - Ganhos em outros instrumentos financeiros",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "628",
    sncSvatCode: ["7885"],
    sncSvatdescription: "Outros rendimentos - Outros - Restituição de impostos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "629",
    sncSvatCode: ["7887"],
    sncSvatdescription:
      "Outros rendimentos - Outros - Diferenças de câmbio favoráveis",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "630",
    sncSvatCode: ["7888"],
    sncSvatdescription:
      "Outros rendimentos - Outros - Outros não especificados",
    includeAccounts: ["7886"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "631",
    sncSvatCode: ["7911"],
    sncSvatdescription:
      "Juros, dividendos e outros rendimentos similares - Juros obtidos - De depósitos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "632",
    sncSvatCode: ["7912"],
    sncSvatdescription:
      "Juros, dividendos e outros rendimentos similares - Juros obtidos - De outras aplicações de meios financeiros líquidos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "633",
    sncSvatCode: ["7913"],
    sncSvatdescription:
      "Juros, dividendos e outros rendimentos similares - Juros obtidos - De financiamentos concedidos a associadas e empreendimentos conjuntos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "634",
    sncSvatCode: ["7914"],
    sncSvatdescription:
      "Juros, dividendos e outros rendimentos similares - Juros obtidos - De financiamentos concedidos a subsidiárias",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "635",
    sncSvatCode: ["7915"],
    sncSvatdescription:
      "Juros, dividendos e outros rendimentos similares - Juros obtidos - De financiamentos obtidos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.InterestIncome,
  },
  {
    taxonomyCode: "636",
    sncSvatCode: ["7918"],
    sncSvatdescription:
      "Juros, dividendos e outros rendimentos similares - Juros obtidos - De outros financiamentos concedidos",
    includeAccounts: ["7916", "7917"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "637",
    sncSvatCode: ["7921"],
    sncSvatdescription:
      "Juros, dividendos e outros rendimentos similares - Dividendos obtidos - De aplicações de meios financeiros líquidos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "638",
    sncSvatCode: ["7922"],
    sncSvatdescription:
      "Juros, dividendos e outros rendimentos similares - Dividendos obtidos - De associadas e empreendimentos conjuntos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.GainsAndLossesInAssociates,
  },
  {
    taxonomyCode: "639",
    sncSvatCode: ["7923"],
    sncSvatdescription:
      "Juros, dividendos e outros rendimentos similares - Dividendos obtidos - De subsidiárias",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.GainsAndLossesInAssociates,
  },
  {
    taxonomyCode: "640",
    sncSvatCode: ["7928"],
    sncSvatdescription:
      "Juros, dividendos e outros rendimentos similares - Dividendos obtidos - Outras",
    includeAccounts: ["7924", "7925", "7926", "7927"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "641",
    sncSvatCode: ["793"],
    sncSvatdescription:
      "Juros, dividendos e outros rendimentos similares - Diferenças de câmbio favoráveis",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.InterestIncome,
  },
  {
    taxonomyCode: "642",
    sncSvatCode: ["798"],
    sncSvatdescription:
      "Juros, dividendos e outros rendimentos similares - Outros rendimentos similares",
    includeAccounts: ["794", "795", "796", "797"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "643",
    sncSvatCode: ["811"],
    sncSvatdescription:
      "Resultado líquido do período - Resultado antes de impostos",
    includeAccounts: [
      "8123",
      "8124",
      "8125",
      "8126",
      "8127",
      "8128",
      "8129",
      "813",
      "814",
      "815",
      "816",
      "817",
      "819",
      "82",
      "83",
      "84",
      "85",
      "86",
      "87",
      "88",
    ],
    classType: "RG",
    expectedBalance: "Sa1C",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.EarningsBeforeTaxes,
  },
  {
    taxonomyCode: "644",
    sncSvatCode: ["8121"],
    sncSvatdescription:
      "Resultado líquido do período - Imposto sobre o rendimento do período - Imposto estimado para o período",
    includeAccounts: [
      "8123",
      "8124",
      "8125",
      "8126",
      "8127",
      "8128",
      "8129",
      "813",
      "814",
      "815",
      "816",
      "817",
      "819",
      "82",
      "83",
      "84",
      "85",
      "86",
      "87",
      "89",
    ],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Taxes,
    creditField: "",
  },
  {
    taxonomyCode: "645",
    sncSvatCode: ["8122"],
    sncSvatdescription:
      "Resultado líquido do período - Imposto sobre o rendimento do período - Imposto diferido",
    includeAccounts: [
      "8123",
      "8124",
      "8125",
      "8126",
      "8127",
      "8128",
      "8129",
      "813",
      "814",
      "815",
      "816",
      "817",
      "819",
      "82",
      "83",
      "84",
      "85",
      "86",
      "87",
      "90",
    ],
    classType: "GR",
    expectedBalance: "Sa1C",
    debitField: StatementTree.ProfitAndLoss.Taxes,
    creditField: "",
  },
  {
    taxonomyCode: "646",
    sncSvatCode: ["818"],
    sncSvatdescription: "Resultado líquido do período - Resultado líquido",
    includeAccounts: [
      "8123",
      "8124",
      "8125",
      "8126",
      "8127",
      "8128",
      "8129",
      "813",
      "814",
      "815",
      "816",
      "817",
      "819",
      "82",
      "83",
      "84",
      "85",
      "86",
      "87",
      "91",
    ],
    classType: "C",
    expectedBalance: "S1C",
    debitField: "",
    creditField: StatementTree.Capital.NetProfit,
  },
  {
    taxonomyCode: "647",
    sncSvatCode: ["89"],
    sncSvatdescription: "Dividendos antecipados",
    includeAccounts: [
      "8123",
      "8124",
      "8125",
      "8126",
      "8127",
      "8128",
      "8129",
      "813",
      "814",
      "815",
      "816",
      "817",
      "819",
      "82",
      "83",
      "84",
      "85",
      "86",
      "87",
      "92",
    ],
    classType: "C",
    expectedBalance: "D",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.AntecipatedDividends,
  },
];
