import React, { useState, memo, useMemo, useCallback } from "react";
import {
  Card,
  Scrollable,
  MapSelect,
  IconButton,
  Disclose,
  LoadingScreen,
  Select,
  CopyToClipBoard,
} from "./UI";
import { ApexBarChart } from "./ApexCharts";
import {
  MonthYearTable,
  LabelValueListTable,
  monthLabelsForYear,
  monthLabelsForYearWithPre,
  MonthYearTable2,
  LabelValueListTable2,
} from "./Table";
import { asEuro } from "./euro";
import {
  getPersonnelCostBySubAccounts,
  getExternalServicesByNature,
  getMonthlyProfitAndLoss,
  transactionsGroupedByMonthYears,
  getCustomerAndSupplierIndex,
  getCustomerRanking,
  getSupplierRanking,
  DateField,
  summariesByMonth2,
  isNotFullYear,
} from "./saft";
import ClipboardJS from "clipboard";
import { WorkerSuspense } from "./Worker";


export const MonthlyAnalysisTab = memo(({
  isAdmin,
  anonymous,
  processed,
  statements,
  // engine,
  engines,
  years,
}) => {
  new ClipboardJS(".bi-clipboard");
  const [dateField, setDateField] = useState(DateField.TransactionDate);
  const [selectedYear, setSelectedYear] = useState(years && years[0]);
  const [processing, setProcessing] = useState(true);
  const [state, setState] = useState();

  const [showPnLChart, setShowPnLChart] = useState(false);
  const [showCustomerChart, setShowCustomerChart] = useState(false);
  const [showSupplierChart, setShowSupplierChart] = useState(false);
  const [showServicesChart, setShowServicesChart] = useState(false);
  const [showPersonnelChart, setShowPersonnelChart] = useState(false);

  // useEffect(initState(setProcessing, years, selectedYear, processed, engines, statements, dateField, setState), [engines, processed, dateField, years, selectedYear]);
  const selectedYearIndex = years.indexOf(selectedYear);
  const selectedProcessed = processed && processed[selectedYearIndex];
  const selectedStatement = statements && statements[selectedYearIndex];
  const selectedEngine = engines && engines[selectedYearIndex];
  // const state = useMemo(computeState(years, selectedYear, processed, engines, statements, dateField), [engines, processed, dateField, years, selectedYear]);
  if (processing && state) setProcessing(false);
  const args = [selectedProcessed, dateField];
  const fullArgs = [selectedProcessed, selectedStatement, selectedEngine, selectedYear];
  const toggleCustomerChartShow = (_) => setShowCustomerChart(!showCustomerChart);
  const toggleSupplierChartShow = (_) => setShowSupplierChart(!showSupplierChart);
  const toggleServiceChartShow = (_) => setShowServicesChart(!showServicesChart);
  const togglePersonnelChartShow = (_) => setShowPersonnelChart(!showPersonnelChart);
  const togglePnlChartShow = (_) => setShowPnLChart(!showPnLChart);
  const hideRows = ({ values, label, formula }) => !selectedEngine.isUncollapsable(formula) &&
    (values || []).every((_val) => !_val || _val === "€ 0.00");
  const hideRows2 = ({ values, label, symbol }) => !selectedEngine.isUncollapsable(symbol) &&
    (values || []).every((_val) => !_val || _val === "€ 0.00");
  return (
    <>


      <Card className="my-2">
        <div className="float-end">
          <Select
            options={years}
            selected={selectedYear}
            onChange={setSelectedYear}
          ></Select>
          <IconButton
            icon="bar-chart-line-fill"
            className="text-primary"
            onClick={toggleCustomerChartShow}
          ></IconButton>
        </div>
        <h3>
          Proveitos por cliente por mês{" "}
          <small className="text-muted">
            vendas e serviços prestados
          </small>
        </h3>
        <WorkerSuspense
          fallback={<LoadingScreen />}
          workerPath="MonthlyAnalysis.worker.js"
          workerFn="customersPerMonth"
          workerArgs={args}
        >
          {(customersPerMonth) => (<>
            <Disclose disclose={showCustomerChart}>
              <ApexBarChart
                header="Proveitos por mês"
                stacked={true}
                height={500}
                categories={customersPerMonth.timeAxis.map(
                  (i) => `${i.month}-${i.year}`
                )}
                series={customersPerMonth.data.map((i) => ({
                  name: !anonymous && i.companyName,
                  data: i._totalForMonthYear.map((t) => asEuro(t || 0).value),
                }))}
              ></ApexBarChart>
            </Disclose>
            <Scrollable>
              <MonthYearTable2
                id="monthlyRevenues"
                topLeft={<CopyToClipBoard target="#monthlyRevenues"></CopyToClipBoard>}
                anonymous={anonymous}
                headers={customersPerMonth.timeAxis.map(
                  (i) => `${i.month}-${i.year}`
                )}
                rowHeaders={customersPerMonth.data.map(
                  (i) => i.companyName
                )}
                data={customersPerMonth.data.map(
                  (i) => i._totalForMonthYear
                )}
              ></MonthYearTable2>
            </Scrollable>
          </>)}
        </WorkerSuspense>
      </Card>



      <Card className="my-2">
        <div className="float-end">
          <Select
            options={years}
            selected={selectedYear}
            onChange={setSelectedYear}
          ></Select>
          <IconButton
            icon="bar-chart-line-fill"
            className="text-primary"
            onClick={toggleSupplierChartShow}
          ></IconButton>
        </div>
        <h3>
          Fornecedor por mês{" "}
          <small className="text-muted">
            compras e serviços externos
          </small>
        </h3>
        <WorkerSuspense
          fallback={<LoadingScreen />}
          workerPath="MonthlyAnalysis.worker.js"
          workerFn="suppliersPerMonth"
          workerArgs={args}
        >
          {(suppliersPerMonth) => <>
            <Disclose disclose={showSupplierChart}>
              <ApexBarChart
                header="Gastos por mês"
                stacked={true}
                height={800}
                categories={suppliersPerMonth.timeAxis.map(
                  (i) => `${i.month}-${i.year}`
                )}
                series={suppliersPerMonth.data.map((i) => ({
                  name: !anonymous && i.companyName,
                  data: i._totalForMonthYear.map((t) => asEuro(t || 0).value),
                }))}
              ></ApexBarChart>
            </Disclose>
            <Scrollable>
              <MonthYearTable2
                id="monthlyExpenses"
                topLeft={<CopyToClipBoard target="#monthlyExpenses"></CopyToClipBoard>}
                anonymous={anonymous}
                headers={suppliersPerMonth.timeAxis.map(
                  (i) => `${i.month}-${i.year}`
                )}
                rowHeaders={suppliersPerMonth.data.map(
                  (i) => i.companyName
                )}
                data={suppliersPerMonth.data.map(
                  (i) => i._totalForMonthYear
                )}
              ></MonthYearTable2>
            </Scrollable>
          </>}
        </WorkerSuspense>
      </Card>




      <Card className="my-2">
        <div className="float-end">
          <Select
            options={years}
            selected={selectedYear}
            onChange={setSelectedYear}
          ></Select>
          <IconButton
            icon="bar-chart-line-fill"
            className="text-primary"
            onClick={toggleServiceChartShow}
          ></IconButton>
        </div>
        <h3>
          Fornecimento de Serviços Externos{" "}
          <small className="text-muted">por subcontas</small>
        </h3>
        <WorkerSuspense
          fallback={<LoadingScreen />}
          workerPath="MonthlyAnalysis.worker.js"
          workerFn="externalServiceByNature"
          workerArgs={args}
        >
          {(externalServiceByNature) => <>
            <Disclose disclose={showServicesChart}>
              <ApexBarChart
                header="Serviços externos"
                stacked={true}
                height={500}
                categories={externalServiceByNature.timeAxis.map(
                  (i) => `${i.month}-${i.year}`
                )}
                series={externalServiceByNature.data.map((i) => ({
                  name: `${i.prefix} - ${i.label}`,
                  data: i._totalForMonthYear.map((t) => asEuro(t || 0).value),
                }))}
              ></ApexBarChart>
            </Disclose>
            <Scrollable>
              <MonthYearTable
                id="monthlyFSE"
                topLeft={<CopyToClipBoard target="#monthlyFSE"></CopyToClipBoard>}
                headers={externalServiceByNature.timeAxis.map(
                  (i) => `${i.month}-${i.year}`
                )}
                rowHeaders={externalServiceByNature.data.map(
                  (i) => `${i.prefix} - ${i.label}`
                )}
                data={externalServiceByNature.data.map(
                  (i) => i._totalForMonthYear
                )}
              ></MonthYearTable>
            </Scrollable>
          </>}
        </WorkerSuspense>
      </Card>


      <Card className="my-2">
        <div className="float-end">
          <Select
            options={years}
            selected={selectedYear}
            onChange={setSelectedYear}
          ></Select>
          <IconButton
            icon="bar-chart-line-fill"
            className="text-primary"
            onClick={togglePersonnelChartShow}
          ></IconButton>
        </div>
        <h3>
          Gastos de pessoal por mês{" "}
          <small className="text-muted">por subcontas</small>
        </h3>
        <WorkerSuspense
          fallback={<LoadingScreen />}
          workerPath="MonthlyAnalysis.worker.js"
          workerFn="personnelCostBySubAccounts"
          workerArgs={args}
        >
          {(personnelCostBySubAccounts) => <>
            <Disclose disclose={showPersonnelChart}>
              <ApexBarChart
                header="Gastos de pessoal por mês"
                stacked={true}
                monochrome={true}
                height={500}
                categories={personnelCostBySubAccounts.timeAxis.map(
                  (i) => `${i.month}-${i.year}`
                )}
                series={personnelCostBySubAccounts.data.map((i) => ({
                  name: `${i.prefix} - ${i.label}`,
                  data: i._totalForMonthYear.map((t) => asEuro(t || 0).value),
                }))}
              ></ApexBarChart>
            </Disclose>
            <Scrollable>
              <MonthYearTable
                id="monthlyPersonnel"
                topLeft={<CopyToClipBoard target="#monthlyPersonnel"></CopyToClipBoard>}
                headers={personnelCostBySubAccounts.timeAxis.map(
                  (i) => `${i.month}-${i.year}`
                )}
                rowHeaders={personnelCostBySubAccounts.data.map(
                  (i) => `${i.prefix} - ${i.label}`
                )}
                data={personnelCostBySubAccounts.data.map(
                  (i) => i._totalForMonthYear
                )}
              ></MonthYearTable>
            </Scrollable>
          </>}
        </WorkerSuspense>
      </Card>



      {isAdmin && <Card className="my-2">
        <div className="float-end">
          <Select
            options={years}
            selected={selectedYear}
            onChange={setSelectedYear}
          ></Select>
          <DateFieldSelect
            selectedDateField={dateField}
            onDateFieldChange={setDateField}
          ></DateFieldSelect>
          <IconButton
            icon="bar-chart-line-fill"
            className="text-primary"
            onClick={togglePnlChartShow}
          ></IconButton>
        </div>
        <h3>
          Demonstração de resultados{" "}
          <small className="text-muted">por contas</small>
        </h3>
        <WorkerSuspense
          fallback={<LoadingScreen />}
          workerPath="MonthlyAnalysis.worker.js"
          workerFn="monthlyProfitAndLoss"
          workerArgs={args}
        >
          {(monthlyProfitAndLoss) => <>
            <Disclose disclose={showPnLChart}>
              <ApexBarChart
                header="DR mensal"
                stacked={true}
                monochrome={true}
                height={500}
                categories={monthlyProfitAndLoss.timeAxis.map(
                  (i) => `${i.month}-${i.year}`
                )}
                series={monthlyProfitAndLoss.data.map((i) => ({
                  name: `${i.prefix} - ${i.label}`,
                  data: i._totalForMonthYear.map((t) => asEuro(t || 0).value),
                }))}
              ></ApexBarChart>
            </Disclose>
            <Scrollable>
              <MonthYearTable
                id="monthlyDR"
                topLeft={<CopyToClipBoard target="#monthlyDR"></CopyToClipBoard>}
                headers={monthlyProfitAndLoss.timeAxis.map(
                  (i) => `${i.month}-${i.year}`
                )}
                rowHeaders={monthlyProfitAndLoss.data.map(
                  (i) => `${i.prefix} - ${i.label}`
                )}
                data={monthlyProfitAndLoss.data.map(
                  (i) => i._totalForMonthYear
                )}
              ></MonthYearTable>
            </Scrollable>
          </>}
        </WorkerSuspense>
      </Card>}



      <Card className="my-2">
        <div className="float-end">
          <Select
            options={years}
            selected={selectedYear}
            onChange={setSelectedYear}
          ></Select>
        </div>
        <h3>Demonstração de Resultados</h3>
        <WorkerSuspense
          fallback={<LoadingScreen />}
          workerPath="MonthlyAnalysis.worker.js"
          workerFn="pnl"
          workerArgs={fullArgs}
        >
          {(pnl) =>
            <Scrollable>
              <LabelValueListTable
                id="monthlyDR2"
                className="small"
                headers={[<CopyToClipBoard target="#monthlyDR2"></CopyToClipBoard>, ...monthLabelsForYear(selectedYear)]}
                data={pnl}
                totals={pnl.map((i) => i.total)}
                hideRowsFn={hideRows}
              ></LabelValueListTable>
            </Scrollable>}
        </WorkerSuspense>
      </Card>



      <Card className="my-2">
        <div className="float-end">
          <Select
            options={years}
            selected={selectedYear}
            onChange={setSelectedYear}
          ></Select>
        </div>
        <h3>Balanço</h3>
        <WorkerSuspense
          fallback={<LoadingScreen />}
          workerPath="MonthlyAnalysis.worker.js"
          workerFn="monthlyBalanceSheets"
          workerArgs={fullArgs}
        >
          {(monthlyBalanceSheets) =>
            <Scrollable>
              <LabelValueListTable
                className="small"
                id="monthlyBalance"
                headers={[<CopyToClipBoard target="#monthlyBalance"></CopyToClipBoard>, ...monthLabelsForYearWithPre(selectedYear)]}
                data={monthlyBalanceSheets}
                sliceEnd={monthlyBalanceSheets && monthlyBalanceSheets.length}
                totals={monthlyBalanceSheets.map((item) => item.total)}
                hideRowsFn={hideRows2}
              ></LabelValueListTable>
            </Scrollable>}
        </WorkerSuspense>

      </Card>

      {isAdmin && <Card className="my-2">
        <div className="float-end">
          <Select
            options={years}
            selected={selectedYear}
            onChange={setSelectedYear}
          ></Select>
        </div>
        <h3>Saldos de Clientes</h3>
        <WorkerSuspense
          fallback={<LoadingScreen />}
          workerPath="MonthlyAnalysis.worker.js"
          workerFn="clients"
          workerArgs={fullArgs}
        >
          {(clients) =>
            <Scrollable>
              <LabelValueListTable2
                className="small"
                id="clients"
                headers={[<CopyToClipBoard target="#clients"></CopyToClipBoard>, ...monthLabelsForYearWithPre(selectedYear)]}
                data={clients}
                totals={clients.map((item) => item.total)}
                hideRowsFn={hideRows2}
              ></LabelValueListTable2>
            </Scrollable>}
        </WorkerSuspense>
      </Card>}

      {isAdmin && <Card className="my-2">
        <div className="float-end">
          <Select
            options={years}
            selected={selectedYear}
            onChange={setSelectedYear}
          ></Select>
        </div>
        <h3>Saldos de Fornecedores</h3>
        <WorkerSuspense
          fallback={<LoadingScreen />}
          workerPath="MonthlyAnalysis.worker.js"
          workerFn="providers"
          workerArgs={fullArgs}
        >
          {(providers) =>
            <Scrollable>
              <LabelValueListTable2
                className="small"
                id="providers"
                headers={[<CopyToClipBoard target="#providers"></CopyToClipBoard>, ...monthLabelsForYearWithPre(selectedYear)]}
                data={providers}
                totals={providers.map((item) => item.total)}
                hideRowsFn={hideRows2}
              ></LabelValueListTable2>
            </Scrollable>}
        </WorkerSuspense>
      </Card>}

    </>
  );
});

const DateFieldSelect = ({ selectedDateField, onDateFieldChange }) => (
  <MapSelect
    fields={DateField}
    selectedField={selectedDateField}
    onFieldChange={onDateFieldChange}
  ></MapSelect>
);

const balanceSheetTableDataForStatements = (
  statements,
  engine,
  descending = true
) =>
  statements &&
  engine.BalanceSheetSymbols.map((symbol) => ({
    symbol,
    label: symbol.description,
    values: statements.flatMap((statement, offset) => {
      if (descending) {
        const isLast = offset === statements.length - 1;
        if (!isLast) {
          return [
            statement.report[symbol] &&
            statement.report[symbol].__summary.closingBalance,
          ];
        } else {
          return [
            statements[offset].report[symbol] &&
            statements[offset].report[symbol].__summary.closingBalance,
            statements[offset].previousReport[symbol] &&
            statements[offset].previousReport[symbol].__summary
              .openingBalance,
          ];
        }
      } else {
        // for monthly balancesheets
        const isFirst = offset === 0;
        if (isFirst) {
          return [
            statements[offset].previousReport[symbol] &&
            statements[offset].previousReport[symbol].__summary
              .openingBalance,
            statements[offset].report[symbol] &&
            statements[offset].report[symbol].__summary.closingBalance,
          ];
        } else {
          return [
            statement.report[symbol] &&
            statement.report[symbol].__summary.closingBalance,
          ];
        }
      }
    }),
    total: engine.isBalanceSheetTotal(symbol),
  }));

const incomeStatementTableDataForStatements = (statements, engine) =>
  statements &&
  engine.ProfitAndLossSymbols.map((symbol) => ({
    symbol,
    label: symbol.description,
    values: statements.map(
      (statement) =>
        statement.report[symbol] &&
        statement.report[symbol].__summary.closingBalance
    ),
    total: engine.isProfitAndLossTotal(symbol),
  }));

const statementHasNoTaxOrNetIncome = (statement, engine) => {
  const symbols = [engine.StatementTree.ProfitAndLoss.Taxes, engine.StatementTree.Capital.NetIncome];
  const noTaxOrNetIncome = symbols.some(symbol => symbolHasNoSummaries(statement[symbol]));
  return noTaxOrNetIncome
}

const symbolHasNoSummaries = (symbol) => Object.keys(symbol || {}).filter(key => key && !key.includes('__summary')).length === 0;

