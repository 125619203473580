import React, { useEffect, useMemo, useState } from "react";
import { Card, CopyToClipBoard, LoadingScreen } from "./UI";
import { ApexDonutChart } from "./ApexCharts";
import { joinArrays, LabelValueListTable, LabelValueListTable2 } from "./Table";
import { asEuro } from "./euro";
import {
  getConcentrationList,
  getCommentsForConcentrationList,
  getBalanceSummariesForPrefixes,
  getCustomerAndSupplierIndex,
  getCustomerRanking,
  getSupplierRanking,
  getSummariesForPrefixes,
  isNotFullYear,
} from "./saft";
import { sectionChecksForEngine } from "./check-defs";
import { runChecks } from "./check-engine";
import { WorkerSuspense } from "./Worker";

export const ClientsAndSuppliersTab = ({
  anonymous,
  processed,
  statements,
  // engine,
  engines,
  years,
}) => {
  const [selectedYear, setSelectedYear] = useState(years && years[0]);
  const [processing, setProcessing] = useState(true);
  const [state, setState] = useState();
  const selectedYearIndex = years.indexOf(selectedYear);
  const selectedProcessed = processed && processed[selectedYearIndex];
  const selectedStatement = statements && statements[selectedYearIndex];
  const selectedEngine = engines && engines[selectedYearIndex];
  const args = [selectedProcessed, statements, selectedEngine];

  // const state = useMemo(computeState(years, selectedYear, processed, engines, statements, setProcessing), [engines, selectedYear, years, processed, statements]);

  return (
    <>
      <Card className="my-2">
        <h3>Clientes</h3>
        <WorkerSuspense
          fallback={<LoadingScreen />}
          workerPath="CustomersAndSuppliers.worker.js"
          workerFn="customerData"
          workerArgs={args}
        >
          {({
            customerChecks,
            customerComments,
            customerRanking,
            customerRevenues,
            clientDebts,
          }) =>
            <><CommentList
              negatives={customerChecks
                .filter((c) => c.values[0])
                .map((c) => c.description)}
              positives={customerComments}
            ></CommentList>

              <ApexDonutChart
                className=""
                header="Distribuição de Clientes"
                labels={customerRanking.map(
                  (i) =>
                    (!anonymous &&
                      i.companyName &&
                      i.companyName.substring(0, 10)) ||
                    ""
                )}
                series={customerRanking.map(
                  (i) => asEuro(i.transactionSummary.balance).value
                )}
              ></ApexDonutChart>
              <CopyToClipBoard target="#customerDetailTable"></CopyToClipBoard>
              <LabelValueListTable2
                id="customerDetailTable"
                anonymous={anonymous}
                headers={[
                  "Cliente",
                  "Proveitos",
                  "Valor em dívida",
                  "Dias c/ IVA a 23%",
                ]}
                data={joinArrays(
                  customerRevenues,
                  clientDebts,
                  "label",
                  "value",
                  (a, b) =>
                    asEuro(a).value > 0 && asEuro(b).value > 0
                      ? `${(
                        (asEuro(b).value / (asEuro(a).value * 1.23)) *
                        365
                      ).toFixed(0)} dias`
                      : "--"
                )}
              ></LabelValueListTable2>
            </>}
        </WorkerSuspense>
      </Card>



      <Card className="my-2">
        <h3>
          Fornecedores{" "}
          <small className="text-muted">compras e serviços externos</small>
        </h3>
        <WorkerSuspense
          fallback={<LoadingScreen />}
          workerPath="CustomersAndSuppliers.worker.js"
          workerFn="supplierData"
          workerArgs={args}
        >
          {({ supplierComments, supplierChecks, supplierExpenses, supplierRanking, supplierDebts }) =>
            <><CommentList
              negatives={supplierChecks
                .filter((s) => s.values[0])
                .map((s) => s.description)}
              positives={supplierComments}
            ></CommentList>
              <ApexDonutChart
                className=""
                header="Distribuição de Fornecedores"
                labels={supplierRanking.map(
                  (i) =>
                    (!anonymous &&
                      i.companyName &&
                      i.companyName.substring(0, 10)) ||
                    ""
                )}
                series={supplierRanking.map(
                  (i) => asEuro(i.transactionSummary.balance).value
                )}
              ></ApexDonutChart>
              <CopyToClipBoard target="#supplierDetailTable"></CopyToClipBoard>
              <LabelValueListTable2
                anonymous={anonymous}
                id="supplierDetailTable"
                headers={[
                  "Fornecedor",
                  "Gastos / Compras",
                  "Valor em dívida",
                  "Dias c/ IVA a 23%",
                ]}
                data={joinArrays(
                  supplierExpenses,
                  supplierDebts,
                  "label",
                  "value",
                  (a, b) =>
                    asEuro(a).value > 0 && asEuro(b).value > 0
                      ? `${(
                        (asEuro(b).value / (asEuro(a).value * 1.23)) *
                        365
                      ).toFixed(0)} dias`
                      : "--"
                )}
              ></LabelValueListTable2>
            </>}
        </WorkerSuspense>
      </Card>

    </>
  )
};

const CommentList = ({ className, positives, negatives }) => (
  <div className="d-flex flex-column bg-warning bg-opacity-25 px-2 py-1 rounded">
    {negatives &&
      negatives.map((item, offset) => (
        <div key={offset} className="">
          <i className="bi bi-exclamation-triangle-fill text-danger me-2"></i>
          {item}
        </div>
      ))}
    {positives &&
      positives.map((item, offset) => (
        <div key={offset} className="">
          <i className="bi bi-info-circle-fill text-primary me-2"></i>
          {item}
        </div>
      ))}
  </div>
);