import { CheckType } from "./check-engine";
import {
  GreaterThan,
  LessThan,
  EqualTo,
  And,
  Or,
  Not,
  Multiply,
  Divide,
  Add,
  Subtract,
  Previous,
  Ref,
  invert,
} from "./math";

import { Fields } from "./bdp";

export const RecomendationType = {
  Bank: Symbol("bank"),
  Company: Symbol("company"),
};

export const checkListForEngine = (engine, configs) =>
  Object.values(checksForEngine(engine, configs));

export const checksForEngine = (engine, { partial }) => {
  return {
    CMVMC: {
      group: "Erros no SAFT",
      type: CheckType.AccountExists,
      accountPrefix: "61",
      label: "Custos das mercadorias vendidas",
      description: "CMVMC não existente",
    },
    ZeroAssets: {
      group: "Erros no SAFT",
      type: CheckType.IntraStatement,
      label: "Activo zero",
      description: "O activo está a zero",
      condition: {
        lhs: engine.StatementTree.Assets.Total,
        rhs: "€ 0.00",
        operator: "===",
      },
    },
    ZeroCapitalAndLiabilities: {
      group: "Erros no SAFT",
      type: CheckType.IntraStatement,
      label: "Passivo e Capital Próprio zero",
      description: "Passivo e Capital Próprio estão a zero",
      condition: {
        lhs: engine.StatementTree.LiabilitiesAndCapital.Total,
        rhs: "€ 0.00",
        operator: "===",
      },
    },
    Balanced: {
      group: "Erros no SAFT",
      type: CheckType.IntraStatement,
      label: "Balanço desquadrado",
      description: "Balanço desquadrado",
      condition: {
        lhs: engine.StatementTree.Assets.Total,
        rhs: engine.StatementTree.LiabilitiesAndCapital.Total,
        operator: "!==",
      },
    },
    NãoZeradas8: {
      group: "Erros no SAFT",
      type: CheckType.UnexpectAccountBalance,
      label: "Contas de Resultados não zeradas",
      description: "Contas de Resultados não zeradas",
      prefix: "8",
      operator: "!==",
      reference: "€ 0.00",
    },
    NãoZeradas62: {
      group: "Erros no SAFT",
      type: CheckType.UnexpectAccountBalance,
      label: "Contas de FSE não zeradas",
      description: "Contas de FSE não zeradas",
      prefix: "62",
      operator: "!==",
      reference: "€ 0.00",
    },
    NãoZeradas63: {
      group: "Erros no SAFT",
      type: CheckType.UnexpectAccountBalance,
      label: "Contas de Gastos de Pessoal não zeradas",
      description: "Contas de Gastos de Pessoal não zeradas",
      prefix: "63",
      operator: "!==",
      reference: "€ 0.00",
    },
    DepreciatioCheck: {
      group: "Erros no SAFT",
      type: CheckType.ExpressionCheck,
      label: "Depreciações não lançadas",
      description: "Depreciações não lançadas",
      expression: new EqualTo(engine.StatementTree.ProfitAndLoss.DandA, 0),
    },
    MaterialsCheck: {
      group: "Erros no SAFT",
      type: CheckType.ExpressionCheck,
      label: "Custos das mercadorias",
      description: "CMVMC não apurada",
      expression: new EqualTo(engine.StatementTree.ProfitAndLoss.Materials, 0),
    },
    Clients: {
      group: "Erros no SAFT",
      type: CheckType.InterStatement,
      label: "Clientes diferentes",
      description: "Não há diferença na conta Clientes entre anos consecutivos",
      condition: {
        current: engine.StatementTree.Assets.Current.Clients,
        previous: engine.StatementTree.Assets.Current.Clients,
        operator: "===",
      },
    },
    Inventory: {
      group: "Erros no SAFT",
      type: CheckType.InterStatement,
      label: "Inventários diferentes",
      description: "Não há diferença os Inventários entre anos consecutivos",
      condition: {
        current: engine.StatementTree.Assets.Current.Inventory,
        previous: engine.StatementTree.Assets.Current.Inventory,
        operator: "===",
      },
    },
    CurrentLoans: {
      group: "Erros no SAFT",
      type: CheckType.InterStatement,
      label: "FO corrente diferentes",
      description:
        "Não há diferença os financiamentos obtidos correntes entre anos consecutivos",
      condition: {
        current: engine.StatementTree.Liabilities.Current.Loans,
        previous: engine.StatementTree.Liabilities.Current.Loans,
        operator: "===",
      },
    },
    NonCurrentLoans: {
      group: "Erros no SAFT",
      type: CheckType.InterStatement,
      label: "FO não-correntes diferentes",
      description:
        "Não há diferença os financiamentos obtidos não-correntes entre anos consecutivos",
      condition: {
        current: engine.StatementTree.Liabilities.NonCurrent.Loans,
        previous: engine.StatementTree.Liabilities.NonCurrent.Loans,
        operator: "===",
      },
    },
    DSO: {
      group: "Pagamentos e Recebimentos",
      type: CheckType.RatioBoundBreached,
      label: "Dias de Clientes acima do esperado",
      ratioKey: "DSO",
      condition: {
        ranges: {
          green: { min: 0, max: 90 },
          yellow: { min: 90, max: 180 },
          orange: { min: 180, max: 270 },
          red: { min: 270, max: undefined },
        },
      },
    },
    DII: {
      group: "Inventários",
      type: CheckType.RatioBoundBreached,
      label: "Dias de Inventários acima do esperado",
      ratioKey: "DII",
      condition: {
        ranges: {
          green: { min: 0, max: 30 },
          yellow: { min: 30, max: 60 },
          orange: { min: 60, max: 120 },
          red: { min: 120, max: undefined },
        },
      },
    },
    DPO: {
      group: "Pagamentos e Recebimentos",
      type: CheckType.RatioBoundBreached,
      label: "Dias de Fornecedores abaixo do esperado",
      ratioKey: "DPO",
      condition: {
        ranges: {
          green: { min: 45, max: undefined },
          yellow: { min: 30, max: 45 },
          orange: { min: 15, max: 30 },
          red: { min: 0, max: 15 },
        },
      },
    },
    RedAllOver: {
      group: "Sustentabilidade Financeira",
      type: CheckType.ExpressionCheck,
      label: "Perigo iminente",
      description:
        "Todos os fluxos são negativos: de resultados, operacional, disponível e de caixa",
      expression: new And(
        new And(
          new LessThan(
            partial
              ? engine.Ratios.SynthCashFromResults.formula
              : engine.Ratios.CashFromResults.formula,
            0
          ),
          new LessThan(
            partial
              ? engine.Ratios.SynthOperatingCashflow.formula
              : engine.Ratios.OperatingCashflow.formula,
            0
          )
        ),
        new And(
          new LessThan(
            partial
              ? engine.Ratios.SynthAvailableCashflow.formula
              : engine.Ratios.AvailableCashflow.formula,
            0
          ),
          new LessThan(
            partial
              ? engine.Ratios.SynthCashChangeInMOAF.formula
              : engine.Ratios.CashChangeInMOAF.formula,
            0
          )
        )
      ),
    },
    SilentDeath: {
      group: "Sustentabilidade Financeira",
      type: CheckType.ExpressionCheck,
      label: '"Morte silencionsa"',
      description:
        "Fluxo de caixa operacional só é positivo porque há desinvestimento de fundo de maneio",
      expression: new And(
        new LessThan(engine.Ratios.CashFromResults.formula, 0),
        new GreaterThan(engine.Ratios.OperatingCashflow.formula, 0)
      ),
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Se tem financiamento bancário, convém avaliar em rescalonamento do plano de amortizações para criar uma reserva de caixa. Assim, quando voltar a crescer o volume de negócio, tenha auto-financiamento do investimento em fundo de maneio.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Pode avaliar contratualizar com a banca financiamento ao fundo de maneio (ex: factoring, confirming, conta corrente) para garantir que tem financiamento quando voltar a crescer",
        },
      ],
      importance: 1,
    },
    SilentDeath2: {
      group: "Sustentabilidade Financeira",
      type: CheckType.ExpressionCheck,
      label: '"Morte silenciosa 2"',
      description:
        "Fluxo de resultados não está a ser suficiente para pagar apenas juros ou serviço da dívida",
      expression: new Or(
        new And(
          new GreaterThan(engine.Ratios.DebtService.formula, 0),
          new LessThan(
            engine.Ratios.CashFromResults.formula,
            engine.Ratios.DebtService.formula
          )
        ),
        new And(
          new GreaterThan(invert(engine.Ratios.FinancialIncome.formula), 0),
          new LessThan(
            engine.Ratios.CashFromResults.formula,
            invert(engine.Ratios.FinancialIncome.formula)
          )
        )
      ),
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Se tem financiamento bancário, convém avaliar em rescalonamento do plano de amortizações para criar uma reserva de caixa. Assim, quando voltar a crescer o volume de negócio, tenha auto-financiamento do investimento em fundo de maneio.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Pode avaliar contratualizar com a banca financiamento ao fundo de maneio (ex: factoring, confirming, conta corrente) para garantir que tem financiamento quando voltar a crescer",
        },
      ],
      importance: 1,
    },
    SilentDeath3: {
      group: "Sustentabilidade Financeira",
      type: CheckType.ExpressionCheck,
      label: '"Morte silenciosa 3"',
      description:
        "Fluxo operacional é negativo apesar de haver desinvestimento de fundo de maneio",
      expression: new And(
        new LessThan(engine.Ratios.OperatingCashflow.formula, 0),
        new LessThan(engine.Ratios.ChangeInWorkingCapital.formula, 0)
      ),
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Se tem financiamento bancário, convém avaliar em rescalonamento do plano de amortizações para criar uma reserva de caixa. Assim, quando voltar a crescer o volume de negócio, tenha auto-financiamento do investimento em fundo de maneio.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Pode avaliar contratualizar com a banca financiamento ao fundo de maneio (ex: factoring, confirming, conta corrente) para garantir que tem financiamento quando voltar a crescer",
        },
      ],
      importance: 1,
    },
    GrowingPains: {
      group: "Sustentabilidade Financeira",
      type: CheckType.ExpressionCheck,
      label: "Dores de crescimento",
      description:
        "O investimento em fundo-de-maneio está a absorver o cashflow gerado",
      expression: new And(
        new GreaterThan(engine.Ratios.CashFromResults.formula, 0),
        new LessThan(
          new Divide(
            engine.Ratios.OperatingCashflow.formula,
            engine.Ratios.CashFromResults.formula
          ),
          0.5
        )
      ),
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se há opções de financiamento ao fundo de maneio (ex: factoring, confirming) junto da banca antes que a empresa fique sem tesouraria para operar.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se o crescimento está a ser feito com margens baixas, e se for o caso, considerar abrandar o crescimento nessa parte de negócio para evitar o estrangulamento financeiro.",
        },
      ],
      importance: 2,
    },
    GrowingPains2: {
      group: "Crescimento",
      type: CheckType.ExpressionCheck,
      label: "Dores de crescimento",
      description:
        "O capital a empatar em fundo-de-maneio simples para crescer é superior à margem liberta",
      expression: new GreaterThan(
        new Divide(
          engine.Ratios.SimpleWorkingCapital.formula,
          engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
        ),
        engine.Ratios.GrossMargin.formula
      ),
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se o crescimento está a ser feito com margens, e se for o caso, considerar abrandar o crescimento nessa parte de negócio para evitar o estrangulamento financeiro.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se há formas de encurtar o ciclo de caixa — encurtar PMR, reduzir dias de stocks e esticar PMP — para libertação parcial da tesouraria empatada em fundo de maneio.",
        },
      ],
      importance: 2,
    },
    GrossMargin: {
      group: "Lucrabilidade",
      type: CheckType.ExpressionCheck,
      label: "Lucrabilidade",
      description: "A margem bruta parece ser muito baixa",
      expression: new LessThan(engine.Ratios.GrossMargin.formula, 0.1),
      companyValue: engine.Ratios.GrossMargin,
      thresholdValue: 0.1,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se é possível aumentar preços em alguns produtos e/ou clientes.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se é possível negociar melhor com fornecedores de mercadorias/matérias-primas — concentrando compras, dando melhor previsibilidade de consumos, pagando atempadamente, negociando descontos de volume / rappel",
        },
      ],
    },
    GrossMargin1: {
      group: "Lucrabilidade",
      type: CheckType.ExpressionCheck,
      label: "Lucrabilidade",
      description: "A margem bruta parece ser baixa",
      expression: new And(
        new GreaterThan(engine.Ratios.GrossMargin.formula, 0.1),
        new LessThan(engine.Ratios.GrossMargin.formula, 0.2)
      ),
      companyValue: engine.Ratios.GrossMargin,
      thresholdValue: [0.1, 0.2],
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se é possível aumentar preços em alguns produtos e/ou clientes.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se é possível negociar melhor com fornecedores de mercadorias/matérias-primas — concentrando compras, dando melhor previsibilidade de consumos, pagando atempadamente, negociando descontos de volume / rappel",
        },
      ],
    },
    GrossMargin2: {
      group: "Lucrabilidade",
      type: CheckType.ExpressionCheck,
      label: "Lucrabilidade",
      description: "A margem bruta é inferior à média do sector",
      expression: new LessThan(
        engine.Ratios.GrossMargin.formula,
        new Subtract(
          1,
          new Divide(
            new Ref(Fields.COGS, "bdp"),
            new Ref(Fields.Revenue, "bdp")
          )
        )
      ),
      companyValue: engine.Ratios.GrossMargin,
      benchmarkValue: new Subtract(
        1,
        new Divide(new Ref(Fields.COGS, "bdp"), new Ref(Fields.Revenue, "bdp"))
      ),
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se é possível aumentar preços em alguns produtos e/ou clientes.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se é possível negociar melhor com fornecedores de mercadorias/matérias-primas — concentrando compras, dando melhor previsibilidade de consumos, pagando atempadamente, negociando descontos de volume / rappel",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar que razões podem estar a levar a uma margem baixa vs o sector — diferenças de posicionamento, diferenças de escala, conjuntura, etc.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Convém avaliar que razões podem estar a levar a uma margem baixa vs o sector — diferenças de posicionamento, diferenças de escala, conjuntura, etc.",
        },
      ],
    },
    ExtendedMargin: {
      group: "Lucrabilidade",
      type: CheckType.ExpressionCheck,
      label: "Lucrabilidade",
      description: "A margem bruta alargada é inferior à média do sector",
      expression: new LessThan(
        engine.Ratios.ExtendedGrossMargin.formula,
        new Ref(Fields.ExtendedGrossMargin, "bdp")
      ),
      companyValue: engine.Ratios.ExtendedGrossMargin,
      benchmarkValue: new Ref(Fields.ExtendedGrossMargin, "bdp"),
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar a proporção de FSEs fixos (vs variáveis) e verificar se existe um problema de escala mínima.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar os FSEs fixos da empresa e verificar o potencial de optimização — redução do consumo, renegociação de preços.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar os FSEs variáveis da empresa e verificar o potencial de optimização sobretudo via obtenção de melhores condições dos respectivos fornecedores — concentrando compras, dando melhor previsibilidade de consumos, pagando atempadamente, negociando descontos de volume / rappel",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Convém avaliar que razões podem estar a levar a uma margem baixa vs o sector — diferenças de posicionamento, diferenças de escala, conjuntura, etc.",
        },
      ],
    },
    Margin3: {
      group: "Lucrabilidade",
      type: CheckType.ExpressionCheck,
      label: "Lucrabilidade",
      description: "A margem operacional é inferior à média do sector",
      expression: new LessThan(
        engine.Ratios.OperatingMargin.formula,
        new Ref(Fields.OperatingMargin, "bdp")
      ),
      companyValue: engine.Ratios.OperatingMargin,
      benchmarkValue: new Ref(Fields.OperatingMargin, "bdp"),
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "A empresa terá investimentos recentes de dimensão que estão a ser penalizadores",
        },
        {
          target: RecomendationType.Company,
          description:
            "Se as amortizações se devem a imobilizado, pode valer a pena ver se o valor comercial do imóvel é superior ao valor em balanço e reavaliar a política de amortização.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Convém avaliar que razões podem estar a levar a uma margem baixa vs o sector — diferenças de posicionamento, diferenças de escala, conjuntura, etc.",
        },
      ],
    },
    Margin4: {
      group: "Lucrabilidade",
      type: CheckType.ExpressionCheck,
      label: "Lucrabilidade",
      description: "A margem líquida é inferior à média do sector",
      expression: new LessThan(
        partial
          ? engine.Ratios.SynthNetMargin.formula
          : engine.Ratios.NetMargin.formula,
        new Ref(Fields.NetMargin, "bdp")
      ),
      companyValue: partial
        ? engine.Ratios.SynthNetMargin
        : engine.Ratios.NetMargin,
      benchmarkValue: new Ref(Fields.NetMargin, "bdp"),
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se a está a utilizar mais dívida que outras empresas.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se os custos de financiamento estão acima da média e se os sobrecustos (ex: comissões e outros gastos) representam parte significativa.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Se os custos de financiamento estiverem acima da média, convém avaliar renegociação de condições.",
        },
      ],
    },
    CashFromResults: {
      group: "Sustentabilidade Financeira",
      type: CheckType.ExpressionCheck,
      label: "Lucrabilidade",
      description: "O fluxo de resultados e/ou o EBITDA são negativos",
      expression: new Or(
        new LessThan(engine.Ratios.CashFromResults.formula, 0),
        new LessThan(engine.StatementTree.ProfitAndLoss.EBITDA, 0)
      ),
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Clarifique se o problema resulta de um evento que impacta apenas este ano ou se é de grau regular.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Explique à Banca se as potenciais razões — impacto de evento não repetível, sobrecustos de crescimento, a empresa está tentar reduzir custos de estrutura",
        },
      ],
      importance: 1,
    },
    CashFromResults2: {
      group: "Sustentabilidade Financeira",
      type: CheckType.ExpressionCheck,
      label: "Lucrabilidade",
      description:
        "O fluxo de resultados e/ou o EBITDA não chegam para pagar os juros",
      expression: new Or(
        new LessThan(
          engine.Ratios.CashFromResults.formula,
          invert(engine.StatementTree.ProfitAndLoss.InterestExpense)
        )
      ),
      companyValue: engine.Ratios.CashFromResults,
      thresholdValue: invert(
        engine.StatementTree.ProfitAndLoss.InterestExpense
      ),
      format: "euro",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Clarifique se o problema resulta de um evento que impacta apenas este ano ou se é de grau regular.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Confirme se está a pagar juros ou outros custos de financiamento acima do sector.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Explique à Banca se as potenciais razões — impacto de evento não repetível, sobrecustos de crescimento, a empresa está tentar reduzir custos de estrutura",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Se se confirmarem os custos de estrutura, contacte a Banca para aferir potencial de redução de spreads e/ou de sobrecustos (ex: financiamentos)",
        },
      ],
    },
    RevenueGrowth: {
      group: "Crescimento",
      type: CheckType.ExpressionCheck,
      label: "Crescimento do volume de negócios",
      description: "Os proveitos estão a cair mais de 5%",
      expression: new LessThan(engine.Ratios.RevenueGrowth.formula, -0.05),
      companyValue: engine.Ratios.RevenueGrowth,
      thresholdValue: -0.05,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Avalie que motivos levaram a queda de proveitos: variação natural do volume de encomendas, perda de clientes, competição em preços, aumento de descontos, perda de representadas, etc.",
        },
      ],
    },
    ExternalServices: {
      group: "FSE",
      type: CheckType.ExpressionCheck,
      label: "Lucrabilidade",
      description: "Os FSE em % dos proveitos estão acima do sector",
      expression: new GreaterThan(
        engine.Ratios.ExternalServicesOverRevenue.formula,
        new Subtract(
          new Subtract(
            1,
            new Divide(
              new Ref(Fields.COGS, "bdp"),
              new Ref(Fields.Revenue, "bdp")
            )
          ),
          new Ref(Fields.ExtendedGrossMargin, "bdp")
        )
      ),
      companyValue: engine.Ratios.ExternalServicesOverRevenue,
      benchmarkValue: new Subtract(
        new Subtract(
          1,
          new Divide(
            new Ref(Fields.COGS, "bdp"),
            new Ref(Fields.Revenue, "bdp")
          )
        ),
        new Ref(Fields.ExtendedGrossMargin, "bdp")
      ),
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar a proporção de FSEs fixos (vs variáveis) e verificar se existe um problema de escala mínima.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar os FSEs fixos da empresa e verificar o potencial de optimização — redução do consumo, renegociação de preços.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar os FSEs variáveis da empresa e verificar o potencial de optimização sobretudo via obtenção de melhores condições dos respectivos fornecedores — concentrando compras, dando melhor previsibilidade de consumos, pagando atempadamente, negociando descontos de volume / rappel",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Convém avaliar que razões podem estar a levar a uma margem baixa vs o sector — diferenças de posicionamento, diferenças de escala, conjuntura, etc.",
        },
      ],
    },
    Personnel: {
      group: "Pessoal",
      type: CheckType.ExpressionCheck,
      label: "Lucrabilidade",
      description:
        "Os custos de pessoal em % dos proveitos estão acima do sector",
      expression: new GreaterThan(
        engine.Ratios.PersonnelExpenseOverRevenue.formula,
        new Ref(Fields.PersonnelExpenseOverRevenue, "bdp")
      ),
      companyValue: engine.Ratios.PersonnelExpenseOverRevenue,
      benchmarkValue: new Ref(Fields.PersonnelExpenseOverRevenue, "bdp"),
      format: "percentage",
    },
    Personnel2: {
      group: "Pessoal",
      type: CheckType.ExpressionCheck,
      label: "Produtividade",
      description:
        "Os gastos de pessoal por empregado são superiores à média do sector",
      expression: new And(
        new GreaterThan(new Ref(Fields.FTEs, "input"), 0),
        new GreaterThan(
          new Divide(
            new Subtract(0, engine.StatementTree.ProfitAndLoss.Personnel),
            new Ref(Fields.FTEs, "input")
          ),
          new Divide(
            new Multiply(
              new Ref(Fields.Revenue, "bdp"),
              new Ref(Fields.PersonnelExpenseOverRevenue, "bdp")
            ),
            new Ref(Fields.FTEs, "bdp")
          )
        )
      ),
      companyValue: {
        formula: new Divide(
          new Subtract(0, engine.StatementTree.ProfitAndLoss.Personnel),
          new Ref(Fields.FTEs, "input")
        ),
      },
      benchmarkValue: new Divide(
        new Multiply(
          new Ref(Fields.Revenue, "bdp"),
          new Ref(Fields.PersonnelExpenseOverRevenue, "bdp")
        ),
        new Ref(Fields.FTEs, "bdp")
      ),
      format: "euro",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar a proporção de FSEs fixos (vs variáveis) e verificar se existe um problema de escala mínima.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar os FSEs fixos da empresa e verificar o potencial de optimização — redução do consumo, renegociação de preços.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar os FSEs variáveis da empresa e verificar o potencial de optimização sobretudo via obtenção de melhores condições dos respectivos fornecedores — concentrando compras, dando melhor previsibilidade de consumos, pagando atempadamente, negociando descontos de volume / rappel",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Convém avaliar que razões podem estar a levar a uma margem baixa vs o sector — diferenças de posicionamento, diferenças de escala, conjuntura, etc.",
        },
      ],
    },
    SubScale: {
      group: "Lucrabilidade",
      type: CheckType.ExpressionCheck,
      label: "Sub-scala",
      description:
        "A margem bruta gerada não parece ser suficiente para cobrir os custos fixos",
      expression: new LessThan(engine.Ratios.EbitdaToGrossProfit.formula, 0.2),
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se a empresa está a ter custos fixos em excesso ou se está investir / incorrer em despesas para poder crescer e ter melhor aproveitamento da estrutura de custos.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Se estiver a realizar iniciativas, para melhorar a eficiência de custos fixos deve comunicá-las ou tê-las presente quando falar com a banca.",
        },
      ],
      importance: 1,
    },
    SubScale2: {
      group: "Lucrabilidade",
      type: CheckType.ExpressionCheck,
      label: "Sub-scala",
      description: "A margem EBITDA é inferior à média do sector",
      expression: new LessThan(
        engine.Ratios.EBITDAMargin.formula,
        new Ref(Fields.EBITDAMargin, "bdp")
      ),
      companyValue: engine.Ratios.EBITDAMargin,
      benchmarkValue: new Ref(Fields.EBITDAMargin, "bdp"),
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar que razões podem estar a levar a uma margem baixa vs o sector — diferenças de posicionamento, diferenças de escala, conjuntura, etc.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Convém avaliar que razões podem estar a levar a uma margem baixa vs o sector — diferenças de posicionamento, diferenças de escala, conjuntura, etc.",
        },
      ],
    },
    Productivity1: {
      group: "Produtividade",
      type: CheckType.ExpressionCheck,
      label: "Produtividade",
      description:
        "Os proveitos por empregado são inferiores à média do sector",
      expression: new And(
        new GreaterThan(new Ref(Fields.FTEs, "input"), 0),
        new LessThan(
          new Divide(
            engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered,
            new Ref(Fields.FTEs, "input")
          ),
          new Divide(
            new Ref(Fields.Revenue, "bdp"),
            new Ref(Fields.FTEs, "bdp")
          )
        )
      ),
      format: "euro",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se os actuais colaboradores têm capacidade para captar mais receitas — via venda de mais produtos aos actuais clientes, maior prospecção, menos descontos, melhorias para incrementar capacidade operacional / produção",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar potenciais investimento em ferramentas de produtividade — sistemas de informação, ferramentas, equipamentos, etc.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar aplicabilidade de sistemas de incentivos ao crescimento.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Se a empresa estiver com dificuldades, convém considerar eventual redução de equipa",
        },
      ],
    },
    Productivity2: {
      group: "Produtividade",
      type: CheckType.ExpressionCheck,
      label: "Produtividade",
      description: "O EBITDA por empregado é inferior à média do sector",
      expression: new And(
        new GreaterThan(new Ref(Fields.FTEs, "input"), 0),
        new LessThan(
          new Divide(
            engine.StatementTree.ProfitAndLoss.EBITDA,
            new Ref(Fields.FTEs, "input")
          ),
          new Divide(
            new Multiply(
              new Ref(Fields.Revenue, "bdp"),
              new Ref(Fields.EBITDAMargin, "bdp")
            ),
            new Ref(Fields.FTEs, "bdp")
          )
        )
      ),
      companyValue: {
        formula: new Divide(
          engine.StatementTree.ProfitAndLoss.EBITDA,
          new Ref(Fields.FTEs, "input")
        ),
      },
      benchmarkValue: new Divide(
        new Multiply(
          new Ref(Fields.Revenue, "bdp"),
          new Ref(Fields.EBITDAMargin, "bdp")
        ),
        new Ref(Fields.FTEs, "bdp")
      ),
      format: "euro",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se os actuais colaboradores têm capacidade para captar mais receitas — via venda de mais produtos aos actuais clientes, maior prospecção, menos descontos, melhorias para incrementar capacidade operacional / produção",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar potenciais investimento em ferramentas de produtividade — sistemas de informação, ferramentas, equipamentos, etc.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar aplicabilidade de sistemas de incentivos ao crescimento.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Se a empresa estiver com dificuldades, convém considerar eventual redução de equipa",
        },
      ],
    },
    Clients1: {
      group: "Pagamentos e recebimentos",
      type: CheckType.ExpressionCheck,
      label: "Clientes",
      description: "O crescimento da conta clientes parece ser anormal",
      expression: new GreaterThan(
        new Divide(
          engine.Ratios.ChangeInClients.formula,
          new Previous(engine.StatementTree.Assets.Current.Clients)
        ),
        0.05
      ),
      companyValue: {
        formula: new Divide(
          engine.Ratios.ChangeInClients.formula,
          new Previous(engine.StatementTree.Assets.Current.Clients)
        ),
      },
      thresholdValue: 0.1,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se o aumento de conta clientes é justificado pelo crescimento do volume de negócios, por dar melhores condições de pagamento a clientes ou, por outro lado, o acumular de (potenciais) imparidades de clientes.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "É importante explicar as razões do crescimento da conta de clientes para que a Banca não associe a maior risco.",
        },
      ],
    },
    Clients2: {
      group: "Pagamentos e recebimentos",
      type: CheckType.ExpressionCheck,
      label: "Clientes",
      description:
        "O crescimento da conta clientes é superior ao crescimento dos proveitos",
      expression: new And(
        new GreaterThan(
          new Divide(
            engine.Ratios.ChangeInClients.formula,
            new Previous(engine.StatementTree.Assets.Current.Clients)
          ),
          engine.Ratios.RevenueGrowth.formula
        ),
        new GreaterThan(
          new Previous(
            engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
          ),
          0
        )
      ),
      companyValue: {
        formula: new Divide(
          engine.Ratios.ChangeInClients.formula,
          new Previous(engine.StatementTree.Assets.Current.Clients)
        ),
      },
      thresholdValue: engine.Ratios.RevenueGrowth,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se o aumento de conta clientes é justificado pelo crescimento do volume de negócios, por dar melhores condições de pagamento a clientes ou, por outro lado, o acumular de (potenciais) imparidades de clientes.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "É importante explicar as razões do crescimento da conta de clientes para que a Banca não associe a maior risco.",
        },
      ],
    },
    Inventory1: {
      group: "Inventários",
      type: CheckType.ExpressionCheck,
      label: "Inventários",
      description: "O crescimento de inventários parece ser anormal",
      expression: new GreaterThan(
        new Divide(
          engine.Ratios.ChangeInInventory.formula,
          new Previous(engine.StatementTree.Assets.Current.Inventory)
        ),
        0.05
      ),
      companyValue: {
        formula: new Divide(
          engine.Ratios.ChangeInInventory.formula,
          new Previous(engine.StatementTree.Assets.Current.Inventory)
        ),
      },
      thresholdValue: 0.05,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se o aumento de inventários é justificado pelo crescimento do volume de negócios, pela política de gama/categorias da empresa ou, por outro lado, se deve a imprecisões de contagem.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "É importante explicar as razões do crescimento da conta de inventários para que a Banca não associe a menor capacidade de escoamento dos materiais e/ou a aumento artificial da margem bruta e do EBITDA da empresa.",
        },
      ],
    },
    Inventory2: {
      group: "Inventários",
      label: "Inventário",
      description:
        "O crescimento de inventários é superior ao crescimento dos proveitos",
      expression: new GreaterThan(
        new Divide(
          engine.Ratios.ChangeInInventory.formula,
          new Previous(engine.StatementTree.Assets.Current.Inventory)
        ),
        engine.Ratios.RevenueGrowth.formula
      ),
      companyValue: {
        formula: new Divide(
          engine.Ratios.ChangeInInventory.formula,
          new Previous(engine.StatementTree.Assets.Current.Inventory)
        ),
      },
      thresholdValue: engine.Ratios.RevenueGrowth,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se o aumento de inventários é justificado pela política de gama/categorias da empresa ou, por outro lado, se deve a imprecisões de contagem.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "É importante explicar as razões do crescimento da conta de inventários para que a Banca não associe a menor capacidade de escoamento dos materiais e/ou a aumento artificial da margem bruta e do EBITDA da empresa.",
        },
      ],
    },
    Providers1: {
      group: "Pagamentos e recebimentos",
      type: CheckType.ExpressionCheck,
      label: "Fornecedores",
      description: "O crescimento de fornecedores parece ser anormal",
      expression: new GreaterThan(
        new Divide(
          engine.Ratios.ChangeInInventory.formula,
          new Previous(engine.StatementTree.Assets.Current.Inventory)
        ),
        0.05
      ),
      companyValue: new Divide(
        engine.Ratios.ChangeInInventory.formula,
        new Previous(engine.StatementTree.Assets.Current.Inventory)
      ),
      thresholdValue: 0.05,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se o aumento da conta Fornecedores é justificado pelo crescimento da empresa, obtenção de melhores condições de pagamento ou, por outro lado, a dificuldades de tesouraria.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "É importante explicar as razões do crescimento da conta de Fornecedores para que a Banca não associe a dificuldades inesperadas de tesouraria por parte da empresa.",
        },
      ],
    },
    Providers2: {
      group: "Pagamentos e recebimentos",
      type: CheckType.ExpressionCheck,
      label: "Fornecedores",
      description:
        "O crescimento de inventários é superior ao crescimento dos proveitos",
      expression: new GreaterThan(
        new Divide(
          engine.Ratios.ChangeInInventory.formula,
          new Previous(engine.StatementTree.Assets.Current.Inventory)
        ),
        engine.Ratios.RevenueGrowth.formula
      ),
      companyValue: {
        formula: new Divide(
          engine.Ratios.ChangeInInventory.formula,
          new Previous(engine.StatementTree.Assets.Current.Inventory)
        ),
      },
      thresholdValue: engine.Ratios.RevenueGrowth,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se o aumento da conta Fornecedores é justificado pela obtenção de melhores condições de pagamento ou, por outro lado, a dificuldades de tesouraria.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "É importante explicar as razões do crescimento da conta de Fornecedores para que a Banca não associe a dificuldades inesperadas de tesouraria por parte da empresa.",
        },
      ],
    },
    DSO1: {
      group: "Pagamentos e recebimentos",
      type: CheckType.ExpressionCheck,
      label: "Prazo médio de recebimentos",
      description: "O prazo médio de recebimentos (PMR) parece ser elevado",
      expression: new And(
        new GreaterThan(engine.Ratios.DSO.formula, 120),
        new LessThan(engine.Ratios.DSO.formula, 180)
      ),
      companyValue: engine.Ratios.DSO,
      thresholdValue: [120, 180],
      format: "days",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se PMR tão elevado resulta de exposição a sectores específicos (Estado, grandes multinacionais com poder negocial) ou, por outro lado, a acumulação de imparidades de clientes que ainda não foram a resultados.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Dado o elevado PMR, é importante fazer acompanhar uma nota explicativa das razões para o seu valor elevado (ou a indicação do seu valor 'normal', se forem expurgadas imparidades por reconhecer) sempre que partilhar balancetes ou as contas com a Banca.",
        },
      ],
      importance: 1,
    },
    DSO2: {
      group: "Pagamentos e recebimentos",
      type: CheckType.ExpressionCheck,
      label: "Prazo médio de recebimentos",
      description:
        "O prazo médio de recebimentos (PMR) parece ser extremamente elevado",
      expression: new GreaterThan(engine.Ratios.DSO.formula, 180),
      companyValue: engine.Ratios.DSO,
      thresholdValue: 180,
      format: "days",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se PMR tão elevado resulta de exposição a sectores específicos (Estado, grandes multinacionais com poder negocial) ou, por outro lado, a acumulação de imparidades de clientes que ainda não foram a resultados.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Dado o elevado PMR, é importante fazer acompanhar uma nota explicativa das razões para o seu valor elevado (ou a indicação do seu valor 'normal', se forem expurgadas imparidades por reconhecer) sempre que partilhar balancetes ou as contas com a Banca.",
        },
      ],
      importance: 1,
    },
    DSO3: {
      group: "Pagamentos e recebimentos",
      type: CheckType.ExpressionCheck,
      label: "Prazo médio de recebimentos",
      description:
        "O prazo médio de recebimentos (PMR) parece estar acima da média do sector",
      expression: new GreaterThan(
        engine.Ratios.DSO.formula,
        new Ref(Fields.DSO, "bdp")
      ),
      companyValue: engine.Ratios.DSO,
      benchmarkValue: new Ref(Fields.DSO, "bdp"),
      format: "days",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se PMR acima do sector resulta de exposição a sectores específicos (Estado, grandes multinacionais com poder negocial), a política da empresa (dar prazos mais longos para ganhar negócio) ou, por outro lado, a acumulação de imparidades de clientes que ainda não foram a resultados.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Se a diferença face ao PMR médio sector for grande, é importante fazer acompanhar uma nota explicativa das razões para o seu valor elevado (ou a indicação do seu valor 'normal', se forem expurgadas imparidades por reconhecer) sempre que partilhar balancetes ou as contas com a Banca.",
        },
      ],
    },
    DSO4: {
      group: "Pagamentos e recebimentos",
      type: CheckType.ExpressionCheck,
      label: "Prazo médio de recebimentos",
      description:
        "O prazo médio de recebimentos (PMR) parece estar abaixo da média do sector",
      expression: new LessThan(
        engine.Ratios.DSO.formula,
        new Ref(Fields.DSO, "bdp")
      ),
      companyValue: engine.Ratios.DSO,
      benchmarkValue: new Ref(Fields.DSO, "bdp"),
      format: "days",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se PMR abaixo do sector resulta da política específica da empresa, método de contabilização de produtos financeiros (ex: factoring) e se vale pena oferecer prazos mais longos para ganhar quota.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Caso queira oferecer prazos mais longos para ganhar quota, e os seus clientes forem de dimensão / bom risco, considere falar com a Banca para soluções de factoring.",
        },
      ],
    },
    DII1: {
      group: "Inventários",
      type: CheckType.ExpressionCheck,
      label: "Dias de stocks",
      description: "Os dias de stocks parecem ser elevados",
      expression: new And(
        new GreaterThan(engine.Ratios.DII.formula, 90),
        new LessThan(engine.Ratios.DII.formula, 180)
      ),
      companyValue: engine.Ratios.DII,
      thresholdValue: [90, 180],
      format: "days",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se dias de stocks elevados resultados da política da empresa / factores competitivos ou se resultado do acumulado de imprecisões de contagem.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém ver se há muito capital empatado em stock que não roda ou não prevê rodar nos próximos tempos. Mais vale alienar esse inventário (mesmo que abdicando de margem ou até perdendo ligeiramente) se tiver hipótese de usar o valor para financiar vendas de novo inventário que rode mais.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Prepare-se para responder à questão 'todo o stock existe?' por parte da Banca e para explicar as razões de negócio (ex: ter gama alargada, grande projecto que requer muito stock) para ter tantos dias de stock.",
        },
      ],
      importance: 1,
    },
    DII2: {
      group: "Inventários",
      type: CheckType.ExpressionCheck,
      label: "Dias de stocks",
      description: "Os dias de stocks parecem ser extremamente elevados",
      expression: new GreaterThan(engine.Ratios.DII.formula, 180),
      companyValue: engine.Ratios.DII,
      thresholdValue: 180,
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se dias de stocks elevados resultam da política da empresa / factores competitivos ou se resultado do acumulado de imprecisões de contagem.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém ver se há muito capital empatado em stock que não roda ou não prevê rodar nos próximos tempos. Mais vale alienar esse inventário (mesmo que abdicando de margem ou até perdendo ligeiramente) se tiver hipótese de usar o valor para financiar vendas de novo inventário que rode mais.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Prepare-se para responder à questão 'todo o stock existe?' por parte da Banca e para explicar as razões de negócio (ex: ter gama alargada, grande projecto que requer muito stock) para ter tantos dias de stock.",
        },
      ],
      importance: 1,
    },
    DII3: {
      group: "Inventários",
      type: CheckType.ExpressionCheck,
      label: "Dias de stocks",
      description: "Os dias de stocks parecem estar acima da média do sector",
      expression: new GreaterThan(
        engine.Ratios.DII.formula,
        new Ref(Fields.DII, "bdp")
      ),
      companyValue: engine.Ratios.DII,
      benchmarkValue: new Ref(Fields.DII, "bdp"),
      format: "days",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém avaliar se dias de stocks mais elevados que sector resultam da política da empresa / factores competitivos ou se resultado do acumulado de imprecisões de contagem.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Prepara-se para explicar as razões de negócio (ex: ter gama alargada, grande projecto que requer muito stock) para ter mais dias de stock que a média.",
        },
      ],
    },
    DII4: {
      group: "Inventários",
      type: CheckType.ExpressionCheck,
      label: "Dias de stocks",
      description: "Os dias de stocks parecem estar abaixo da média do sector",
      expression: new LessThan(
        engine.Ratios.DII.formula,
        new Ref(Fields.DII, "bdp")
      ),
      companyValue: engine.Ratios.DII,
      benchmarkValue: new Ref(Fields.DII, "bdp"),
      format: "days",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Se a empresa está de boa saúde financeira, poderá avaliar se aumentar os dias de stocks (via maior gama, maior disponibilidade produtos) podem ter um contributo aumentar vendas ou melhor margens (via compra em bolo, descontos de quantidade).",
        },
        {
          target: RecomendationType.Company,
          description:
            "Convém ver se há muito capital empatado em stock que não roda ou não prevê rodar nos próximos tempos. Mais vale alienar esse inventário (mesmo que abdicando de margem ou até perdendo ligeiramente) se tiver hipótese de usar o valor para financiar vendas de novo inventário que rode mais.",
        },
      ],
    },
    DPO1: {
      group: "Pagamentos e recebimentos",
      type: CheckType.ExpressionCheck,
      label: "Dias de fornecedores",
      description: "O prazo médio de pagamentos parece ser anormalmente baixo",
      expression: new LessThan(engine.Ratios.DPO.formula, 15),
      companyValue: engine.Ratios.DPO,
      thresholdValue: 15,
      format: "days",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém ter presenta a razão para ter PMP curto. Trata-se de aproveitamento de descontos PP, fidelização de fornecedores, incapacidade negocial face a fornecedores ou falta de financiamento específico para compras (ex: confirming)?",
        },
      ],
    },
    DPO2: {
      group: "Pagamentos e recebimentos",
      type: CheckType.ExpressionCheck,
      label: "Dias de fornecedores",
      description: "O prazo médio de pagamentos parece ser baixo",
      expression: new And(
        new GreaterThan(engine.Ratios.DPO.formula, 15),
        new LessThan(engine.Ratios.DPO.formula, 30)
      ),
      companyValue: engine.Ratios.DPO,
      thresholdValue: [15, 30],
      format: "days",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém ter presente a razão para ter PMP curto. Trata-se de aproveitamento de descontos PP, fidelização de fornecedores, incapacidade negocial face a fornecedores ou falta de financiamento específico para compras (ex: confirming)?",
        },
      ],
    },
    DPO3: {
      group: "Pagamentos e recebimentos",
      type: CheckType.ExpressionCheck,
      label: "Dias de fornecedores",
      description: "O prazo médio de pagamentos parece ser elevado",
      expression: new And(
        new GreaterThan(engine.Ratios.DPO.formula, 60),
        new LessThan(engine.Ratios.DPO.formula, 90)
      ),
      companyValue: engine.Ratios.DPO,
      thresholdValue: [60, 90],
      format: "days",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém ter presente a razão para ter um PMP elevado, por exemplo, se trata de poder negocial face a fornecedores, dificuldades de tesouraria ou erro de contabilização de financiamentos (ex: confirming)",
        },
      ],
      importance: 1,
    },
    DPO4: {
      group: "Pagamentos e recebimentos",
      type: CheckType.ExpressionCheck,
      label: "Dias de fornecedores",
      description: "O prazo médio de pagamentos parece ser muito elevado",
      expression: new And(
        new GreaterThan(engine.Ratios.DPO.formula, 90),
        new LessThan(engine.Ratios.DPO.formula, 180)
      ),
      companyValue: engine.Ratios.DPO,
      thresholdValue: [90, 180],
      format: "days",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém ter presente a razão para ter um PMP elevado, por exemplo, se trata de poder negocial face a fornecedores, dificuldades de tesouraria ou erro de contabilização de financiamentos (ex: confirming).",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Prepare-se para explicar à Banca se o PMP é sustentável no tempo e, se for encurtado, se cria dificuldades de tesouraria à empresa.",
        },
      ],
      importance: 1,
    },
    DPO5: {
      group: "Pagamentos e recebimentos",
      type: CheckType.ExpressionCheck,
      label: "Dias de fornecedores",
      description:
        "O prazo médio de pagamentos parece ser extremamente elevado",
      expression: new GreaterThan(engine.Ratios.DPO.formula, 180),
      companyValue: engine.Ratios.DPO,
      thresholdValue: 180,
      format: "days",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém ter presente a razão para ter um PMP elevado, por exemplo, se trata de poder negocial face a fornecedores, dificuldades de tesouraria ou erro de contabilização de financiamentos (ex: confirming)",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Prepare-se para explicar à Banca se o PMP é sustentável no tempo e, se for encurtado, se cria dificuldades de tesouraria à empresa.",
        },
      ],
    },
    DPO6: {
      group: "Pagamentos e recebimentos",
      type: CheckType.ExpressionCheck,
      label: "Dias de fornecedores",
      description:
        "O prazo médio de pagamentos parece acima da média do sector",
      expression: new GreaterThan(
        engine.Ratios.DPO.formula,
        new Ref(Fields.DPO, "bdp")
      ),
      companyValue: engine.Ratios.DPO,
      benchmarkValue: new Ref(Fields.DPO, "bdp"),
      format: "days",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Convém ter presente a razão para ter um PMP acima do sector, por exemplo, se trata de poder negocial face a fornecedores, dificuldades de tesouraria ou erro de contabilização de financiamentos (ex: confirming)",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Se PMP estiver muito acima do sector, prepare-se para explicar à Banca as razões de negócio que a justificação.",
        },
      ],
    },
    DPO7: {
      group: "Pagamentos e recebimentos",
      type: CheckType.ExpressionCheck,
      label: "Dias de fornecedores",
      description:
        "O prazo médio de pagamentos parece abaixo da média do sector",
      expression: new LessThan(
        engine.Ratios.DPO.formula,
        new Ref(Fields.DPO, "bdp")
      ),
      companyValue: engine.Ratios.DPO,
      benchmarkValue: new Ref(Fields.DPO, "bdp"),
      format: "days",
      recommendations: [
        {
          target: RecomendationType.Bank,
          description:
            "Se PMP estiver muito abaixo do sector, prepare-se para explicar à Banca se o prazo é curto por defeito (ex: fornecedores não dão crédito) ou por feitio (ex: política de boas condições de pagamento da empresa).",
        },
      ],
    },
    /**  */
    WC0: {
      group: "Fundo de maneio",
      type: CheckType.ExpressionCheck,
      label: "Fundo de maneio",
      description:
        "O capital empatado em fundo de maneio parece excessivo face ao volume de negócios",
      expression: new GreaterThan(
        new Divide(
          new Add(
            engine.Ratios.OtherWorkingCapital.formula,
            engine.Ratios.SimpleWorkingCapital.formula
          ),
          engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
        ),
        0.3
      ),
      companyValue: {
        formula: new Divide(
          new Add(
            engine.Ratios.OtherWorkingCapital.formula,
            engine.Ratios.SimpleWorkingCapital.formula
          ),
          engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
        ),
      },
      thresholdValue: 0.3,
      format: "percentage",
    },
    WC1: {
      group: "Fundo de maneio",
      type: CheckType.ExpressionCheck,
      label: "Fundo de maneio",
      description:
        "O capital empatado em fundo de maneio simples parece excessivo face ao volume de negócio",
      expression: new GreaterThan(
        new Divide(
          engine.Ratios.SimpleWorkingCapital.formula,
          engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
        ),
        0.3
      ),
      companyValue: {
        formula: new Divide(
          engine.Ratios.SimpleWorkingCapital.formula,
          engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
        ),
      },
      thresholdValue: 0.3,
      format: "percentage",
    },
    WC2: {
      group: "Estado",
      type: CheckType.ExpressionCheck,
      label: "Fundo de maneio — endividamento no Estado e outros",
      description:
        "Há uma boa parte do capital empatado em *outro* fundo de maneio (Estado, Sócios)",
      expression: new GreaterThan(
        new Divide(
          engine.Ratios.OtherWorkingCapital.formula,
          new Add(
            engine.Ratios.OtherWorkingCapital.formula,
            engine.Ratios.SimpleWorkingCapital.formula
          )
        ),
        0.2
      ),
      companyValue: {
        formula: new Divide(
          engine.Ratios.OtherWorkingCapital.formula,
          new Add(
            engine.Ratios.OtherWorkingCapital.formula,
            engine.Ratios.SimpleWorkingCapital.formula
          )
        ),
      },
      thresholdValue: 0.2,
      format: "percentage",
    },
    WCDetails0: {
      grupo: "Fundo de Maneio",
      type: CheckType.ExpressionCheck,
      label: "Fundo de maneio — Clientes",
      description:
        "O empate de capital em Clientes é mais de metade do volume de negócios",
      expression: new GreaterThan(
        new Divide(
          engine.StatementTree.Assets.Current.Clients,
          engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
        ),
        0.5
      ),
      companyValue: {
        formula: new Divide(
          engine.StatementTree.Assets.Current.Clients,
          engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
        ),
      },
      thresholdValue: 0.5,
      format: "percentage",
    },
    WCDetails1: {
      grupo: "Fundo de Maneio",
      type: CheckType.ExpressionCheck,
      label: "Fundo de maneio — Clientes",
      description:
        "O empate de capital em Inventários é mais de metade do volume de negócios",
      expression: new GreaterThan(
        new Divide(
          engine.StatementTree.Assets.Current.Inventory,
          engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
        ),
        0.5
      ),
      format: "percentage",
    },
    WCDetails2: {
      grupo: "Fundo de Maneio",
      type: CheckType.ExpressionCheck,
      label: "Fundo de maneio — Clientes",
      description:
        "O empate de capital em Clientes e Inventários que o volume de negócios",
      expression: new GreaterThan(
        new Divide(
          new Add(
            engine.StatementTree.Assets.Current.Clients,
            engine.StatementTree.Assets.Current.Inventory
          ),
          engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
        ),
        1
      ),
      companyValue: {
        formula: new Divide(
          new Add(
            engine.StatementTree.Assets.Current.Clients,
            engine.StatementTree.Assets.Current.Inventory
          ),
          engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
        ),
      },
      thresholdValue: 1,
      format: "percentage",
    },
    WCDetails3: {
      grupo: "Fundo de Maneio",
      type: CheckType.ExpressionCheck,
      label: "Fundo de maneio — Clientes",
      description: "O financiamento em fornecedores parece ser limitado",
      expression: new LessThan(
        new Divide(
          engine.StatementTree.Liabilities.Current.Providers,
          new Add(
            engine.StatementTree.Assets.Current.Clients,
            engine.StatementTree.Assets.Current.Inventory
          )
        ),
        0.3
      ),
      companyValue: new Divide(
        engine.StatementTree.Liabilities.Current.Providers,
        new Add(
          engine.StatementTree.Assets.Current.Clients,
          engine.StatementTree.Assets.Current.Inventory
        )
      ),
      thresholdValue: 0.3,
      format: "percentage",
    },
    WCDetails4: {
      group: "Estado",
      type: CheckType.ExpressionCheck,
      label: "Fundo de maneio — Estado",
      description:
        "Parece estar dever demasiado à AT / SS — mais de 3 meses de IVA e mais de 1 mês de SS",
      expression: new LessThan(
        new Add(
          new Multiply(engine.Ratios.GrossProfit.formula, 0.23 * (3 / 12)), // VAT at 23%, 3 months
          new Multiply(
            engine.StatementTree.ProfitAndLoss.Personnel,
            (0.2375 / (1 + 0.2375)) * (1 / 12)
          ) // SS at 23.75%, 1 month
        ),
        engine.StatementTree.Liabilities.Current.StateAndOtherPublicEntitities
      ),
      companyValue: {
        formula:
          engine.StatementTree.Liabilities.Current
            .StateAndOtherPublicEntitities,
      },
      thresholdValue: {
        formula: new Add(
          new Multiply(engine.Ratios.GrossProfit.formula, 0.23 * (3 / 12)), // VAT at 23%, 3 months
          new Multiply(
            engine.StatementTree.ProfitAndLoss.Personnel,
            (0.2375 / (1 + 0.2375)) * (1 / 12)
          ) // SS at 23.75%, 1 month
        ),
      },
      format: "euro",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Se as dívidas ao AT / SS estão a acumular-se pode estar penalizar as contas da empresa com multas e incorrer em responsabilidades que podem ser crime. É urgente encontrar um plano para a regularização.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Note que há Bancos que não aceitam reformulações / restruturações das dívidas se a empresa estiver incumprimento face ao Estado.",
        },
      ],
      importance: 1,
    },
    WCDetails5: {
      grupo: "Fundo de Maneio",
      type: CheckType.ExpressionCheck,
      label: "Fundo de maneio — Outras contas a pagar",
      description:
        "Outras contas a pagar parecem ser excessivas — são maiores que Fornecedores",
      expression: new GreaterThan(
        engine.StatementTree.Liabilities.Current.OtherPayables,
        engine.StatementTree.Liabilities.Current.Providers
      ),
      companyValue: {
        formula: engine.StatementTree.Liabilities.Current.OtherPayables,
      },
      thresholdValue: {
        formula: engine.StatementTree.Liabilities.Current.Providers,
      },
      format: "euro",
    },
    WCChange1: {
      group: "Estado",
      type: CheckType.ExpressionCheck,
      label: "Fundo de maneio",
      description:
        "O *outro* fundo de maneio (Estado, Sócios) parece ter um contributo significativo no financiamento/consumo de capital",
      expression: new GreaterThan(
        new Divide(
          engine.Ratios.ChangeInOtherWorkingCapital.formula,
          new Add(
            engine.Ratios.ChangeInSimpleWorkingCapital.formula,
            engine.Ratios.ChangeInOtherWorkingCapital.formula
          )
        ),
        0.2
      ),
      companyValue: {
        formula: new Divide(
          engine.Ratios.ChangeInOtherWorkingCapital.formula,
          new Add(
            engine.Ratios.ChangeInSimpleWorkingCapital.formula,
            engine.Ratios.ChangeInOtherWorkingCapital.formula
          )
        ),
      },
      thresholdValue: 0.2,
      format: "percentage",
    },
    WC3: {
      group: "Estado",
      type: CheckType.ExpressionCheck,
      label: "Fundo de maneio — endividamento no Estado e outros",
      description:
        "O *outro* fundo de maneio (Estado, Sócios) parece ter crescido de forma significativa",
      expression: new GreaterThan(
        new Divide(
          engine.Ratios.ChangeInOtherWorkingCapital.formula,
          new Previous(engine.Ratios.OtherWorkingCapital.formula)
        ),
        0.1
      ),
      companyValue: {
        formula: new Divide(
          engine.Ratios.ChangeInOtherWorkingCapital.formula,
          new Previous(engine.Ratios.OtherWorkingCapital.formula)
        ),
      },
      thresholdValue: 0.1,
      format: "percentage",
    },
    WC4: {
      group: "Fundo de Maneio",
      type: CheckType.ExpressionCheck,
      label: "Variação de fundo de maneio",
      description:
        "Houve algum desinvestimento de fundo de maneio que pode decorrer de queda de vendas",
      expression: new LessThan(
        engine.Ratios.ChangeInSimpleWorkingCapital.formula,
        0
      ),
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "O desinvestimento em fundo-de-maneio pode ser perigoso porque dá ilusão de folga de tesouraria e, quando é preciso voltar a crescer vendas, a empresa não as consegue financiar. É importante acautelar tal não sucede com a sua empresa, por exemplo, através de controlo da aplicação de tesouraria e/ou preparar a obtenção de financiamento (ex: factoring, confirming, conta corrente) ao fundo de maneio junto da Banca",
        },
      ],
    },
    WCChange2: {
      group: "Estado",
      type: CheckType.ExpressionCheck,
      label: "Variação de fundo de maneio — Estado",
      description:
        "O financiamento no Estado parece ter crescido de forma significativa",
      expression: new GreaterThan(
        new Divide(
          engine.Ratios.ChangeInState.formula,
          new Subtract(
            new Previous(
              engine.StatementTree.Assets.Current.StateAndOtherPublicEntitities
            ),
            new Previous(
              engine.StatementTree.Liabilities.Current.StateAndOtherPublicEntitities
            )
          )
        ),
        0.2
      ),
      companyValue: {
        formula: new Divide(
          engine.Ratios.ChangeInState.formula,
          new Subtract(
            new Previous(
              engine.StatementTree.Assets.Current.StateAndOtherPublicEntitities
            ),
            new Previous(
              engine.StatementTree.Liabilities.Current.StateAndOtherPublicEntitities
            )
          )
        ),
      },
      thresholdValue: 0.2,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Se as dívidas ao AT / SS estão a acumular-se pode estar penalizar as contas da empresa com multas e incorrer em responsabilidades que podem ser crime. É urgente encontrar um plano para a regularização.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Note que há Bancos que não aceitam reformulações / restruturações das dívidas se a empresa estiver incumprimento face ao Estado.",
        },
      ],
    },
    DebtService: {
      group: "Financiamento",
      type: CheckType.ExpressionCheck,
      label: "Ineficiência financeira ou sobrecustos financeiros",
      description:
        "Os custos financeiros foram superiores à variação dos financiamento",
      expression: new GreaterThan(
        new Divide(
          engine.Ratios.FinancialIncome.formula,
          engine.Ratios.ChangeInLoans.formula
        ),
        0.2
      ),
      companyValue: {
        formula: new Divide(
          engine.Ratios.FinancialIncome.formula,
          engine.Ratios.ChangeInLoans.formula
        ),
      },
      thresholdValue: 0.2,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Verifique se está a amortizar a dívida de forma lenta ou se os custos financeiros (juros, comissões) estão elevados face às práticas de mercado",
        },
      ],
    },
    FinanceInefficiency: {
      group: "Financiamento",
      type: CheckType.ExpressionCheck,
      label: "Ineficiência financeira ou sobrecustos financeiros",
      description:
        "O valor de comissões e outros gastos de financiamento (excluindo juros) parece ser excessivo.",
      expression: new GreaterThan(
        new Divide(
          engine.Ratios.OtherFinancingCosts.formula,
          new Subtract(0, engine.StatementTree.ProfitAndLoss.InterestExpense)
        ),
        0.1
      ),
      companyValue: {
        formula: new Divide(
          engine.Ratios.OtherFinancingCosts.formula,
          new Subtract(0, engine.StatementTree.ProfitAndLoss.InterestExpense)
        ),
      },
      thresholdValue: 0.1,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Verifique que produtos financeiros estão a gerar comissões e outros sobrecustos, e se os está utilizar correctamente (ex: muitas notas de crédito sobre facturas descontas na factoring), para depois falar com a Banca sobre posíveis alternativas",
        },
        {
          target: RecomendationType.Company,
          description:
            "Mencione à Banca os sobrecustos financeiros idenficados e solicite propostas alternativas.",
        },
      ],
    },
    FinanceInefficiency2: {
      group: "Financiamento",
      type: CheckType.ExpressionCheck,
      label: "Ineficiência financeira ou sobrecustos financeiros",
      description:
        "O valor da taxa all-in (taxa de juros mais comissões e outros gastos de financiamento) parece ser excessivo.",
      expression: new GreaterThan(engine.Ratios.FinancialAllIn.formula, 0.03),
      companyValue: engine.Ratios.FinancialAllIn,
      thresholdValue: 0.03,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Avalie quanto poderia poupar se a taxa normalizasse para média do sector e como excesso pode ser utilizado para amortizar mais rapidamente a dívida, financiar fundo de maneio e/ou reforçar autonomia financeira da empresa.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Se tem endividamento para amortizar, explique à Banca (sobretudo a concorrentes) que pode estar disponível para encurtar prazos de amortização se juros baixarem",
        },
      ],
    },
    FinanceInefficiency3: {
      group: "Financiamento",
      type: CheckType.ExpressionCheck,
      label: "Ineficiência financeira ou sobrecustos financeiros",
      description: "O valor da taxa all-in é superior ao valor médio do sector",
      expression: new GreaterThan(
        engine.Ratios.FinancialAllIn.formula,
        new Ref(Fields.AllIn, "bdp")
      ),
      companyValue: engine.Ratios.FinancialAllIn,
      benchmarkValue: new Ref(Fields.AllIn, "bdp"),
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Avalie quanto poderia poupar se a taxa normalizasse para média do sector e como excesso pode ser utilizado para amortizar mais rapidamente a dívida, financiar fundo de maneio e/ou reforçar autonomia financeira da empresa.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Se tem endividamento para amortizar, explique à Banca (sobretudo a concorrentes) que pode estar disponível para encurtar prazos de amortização se juros baixarem",
        },
      ],
    },
    FinancialRobustness: {
      group: "Sustentabilidade Financeira",
      type: CheckType.ExpressionCheck,
      label: "Autonomia financeira baixa",
      description: "Autonomia financeira parece ser baixa",
      expression: new LessThan(engine.Ratios.FinancialAutonomy.formula, 0.2),
      companyValue: engine.Ratios.FinancialAutonomy,
      thresholdValue: 0.2,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Se tiver suprimentos realizados pelos sócios, considere transformá-los em capital para que a Banca veja de forma mais evidence o compromisso dos sócios para com o futuro da empresa.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Se tiver suprimentos realizados pelos sócios (não incluidos no capital), faça essa nota à Banca para mostrar que autonomia 'real' é maior e dar evidências do compromisso dos sócios para com o sucesso da empresa.",
        },
      ],
      importance: 1,
    },
    FinancialRobustness2: {
      group: "Endividamento excessivo",
      type: CheckType.ExpressionCheck,
      label: "Dívida líquida sobre EBITDA",
      description:
        "O montante em dívida pode ser percebido com excessivo face ao resultado operacional gerado pela empresa",
      expression: new Not(
        new And(
          new GreaterThan(engine.StatementTree.ProfitAndLoss.EBITDA, 0),
          new LessThan(engine.Ratios.NetDebtOverEBITDA.formula, 3)
        )
      ),
      companyValue: engine.Ratios.NetDebtOverEBITDA,
      format: "x",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Se não fez um investimento recente com recurso à dívida, considere fazer um plano de restruturação da dívida financeira, por exemplo, para alargar o prazo de amortizações e manter folga para o financiamento de fundo-de-maneio.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Evite financiar novos projectos apenas com dívida para não colocar a empresa numa situação de elevada fragilidade — em que qualquer percalço pode criar uma espiral negativa.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Se fez um investimento recente, junte uma nota sobre o investimento sempre que partilhar informação (contas, balancetes) com a Banca. Essa nota servirá para Banca entender o nível de endividamento.",
        },
      ],
      importance: 1,
    },
    FinancialRobustness3: {
      group: "Sustentabilidade Financeira",
      type: CheckType.ExpressionCheck,
      label: "Autonomia financeira mais baixa do que a do sector",
      description: "Autonomia financeira mais baixa do que a do sector",
      expression: new LessThan(
        engine.Ratios.FinancialAutonomy.formula,
        new Ref(Fields.FinancialAutonomy, "bdp")
      ),
      companyValue: engine.Ratios.FinancialAutonomy,
      benchmarkValue: new Ref(Fields.FinancialAutonomy, "bdp"),
      format: "percentage",
    },
    FinancialRobustness4: {
      group: "Endividamento excessivo",
      type: CheckType.ExpressionCheck,
      label: "Dívida líquida sobre EBITDA",
      description: "Dívida sobre EBITDA pior que o do sector",
      expression: new Or(
        new GreaterThan(
          engine.Ratios.NetDebtOverEBITDA.formula,
          new Ref(Fields.TotalFinancingOverEBITDA, "bdp")
        ),
        new And(
          new And(
            new GreaterThan(engine.Ratios.NetDebt.formula, 0),
            new LessThan(engine.Ratios.NetDebtOverEBITDA.formula, 0)
          ),
          new LessThan(
            engine.Ratios.NetDebtOverEBITDA.formula,
            new Ref(Fields.TotalFinancingOverEBITDA, "bdp")
          )
        )
      ),
      companyValue: engine.Ratios.NetDebtOverEBITDA,
      benchmarkValue: new Ref(Fields.TotalFinancingOverEBITDA, "bdp"),
      format: "x",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Se tiver suprimentos realizados pelos sócios, considere transformá-los em capital para que a Banca veja de forma mais evidence o compromisso dos sócios para com o futuro da empresa.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Se tiver suprimentos realizados pelos sócios (não incluidos no capital), faça essa nota à Banca para mostrar que autonomia 'real' é maior e dar evidências do compromisso dos sócios para com o sucesso da empresa.",
        },
      ],
    },
    FinancialRobustness5: {
      group: "Análise de Fragilidade",
      type: CheckType.ExpressionCheck,
      label: "Dívida líquida sobre EBITDA (cenário de queda)",
      description: "Uma queda das vendas reduz substancialamente o EBITDA",
      expression: new LessThan(
        new Divide(
          engine.Ratios.EBITDAScenario.formula,
          engine.StatementTree.ProfitAndLoss.EBITDA
        ),
        0.25
      ),
      companyValue: engine.Ratios.EBITDAScenario.formula,
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Evite retirar dividendos ou realizar investimentos que requeiram capital significativo, seja financiado por capitais próprios ou por dívida.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Considere re-avaliar os custos fixos de forma a reduzir custos que não estão a contribuir de forma signifativa para o negócio.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Considere rescalonar o plano de amortizações à banca para melhorar margem de segurança do capital.",
        },
      ],
      importance: 1,
    },
    FinancialRobustness6: {
      group: "Análise de Fragilidade",
      type: CheckType.ExpressionCheck,
      label: "Dívida líquida sobre EBITDA (cenário de queda)",
      description:
        "Uma queda das vendas pode levar a empresa a ficar numa situação de fragilidade",
      expression: new Or(
        new And(
          new GreaterThan(engine.Ratios.EBITDAScenario.formula, 0),
          new GreaterThan(engine.Ratios.NetDebtOverEBITDAScenario.formula, 4)
        ),
        new LessThan(engine.Ratios.EBITDAScenario.formula, 0)
      ),
      companyValue: engine.Ratios.NetDebtOverEBITDAScenario.formula,
      format: "x",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Evite retirar dividendos ou realizar investimentos que requeiram capital significativo, seja financiado por capitais próprios ou por dívida.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Considere re-avaliar os custos fixos de forma a reduzir custos que não estão a contribuir de forma signifativa para o negócio.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Considere rescalonar o plano de amortizações à banca para melhorar margem de segurança do capital.",
        },
      ],
      importance: 1,
    },
    FinancialRobustness7: {
      group: "Análise de Fragilidade",
      type: CheckType.ExpressionCheck,
      label: "Meses",
      description:
        "O capital disponível chega para financiar 3 meses de salários ou 3 meses de despesas (salários, FSEs, outros gastos).",
      expression: new And(
        new GreaterThan(engine.Ratios.PersonnelExpense.formula, 0),
        new Or(
          new LessThan(engine.Ratios.PersonnelRunway.formula, 3),
          new LessThan(engine.Ratios.PersonnelRunway2.formula, 3),
          new LessThan(engine.Ratios.CombinedRunway.formula, 3),
          new LessThan(engine.Ratios.CombinedRunway2.formula, 3)
        )
      ),
      format: "x",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Evite retirar dividendos ou realizar investimentos que requeiram capital significativo, seja financiado por capitais próprios ou por dívida.",
        },
        {
          target: RecomendationType.Company,
          description:
            "Considere re-avaliar os custos fixos de forma a reduzir custos que não estão a contribuir de forma signifativa para o negócio.",
        },
        {
          target: RecomendationType.Bank,
          description:
            "Considere rescalonar o plano de amortizações à banca para melhorar margem de segurança do capital.",
        },
      ],
    },
    Return: {
      group: "Rentabilidade",
      type: CheckType.ExpressionCheck,
      label: "Retorno",
      description: "O retorno do negócio parece ser baixo.",
      expression: new Or(
        new LessThan(
          partial ? engine.Ratios.SynthROA.formula : engine.Ratios.ROA.formula,
          0.05
        ),
        new Or(
          new LessThan(
            partial
              ? engine.Ratios.SynthROCE.formula
              : engine.Ratios.ROCE.formula,
            0.05
          ),
          new LessThan(
            partial
              ? engine.Ratios.SynthROIC.formula
              : engine.Ratios.ROIC.formula,
            0.05
          )
        )
      ),
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "O utilize o simulador de potencial de optimização na secção de Planeamento para em que áreas pode ter maior impacto de esforço de melhoria do desempenho da empresa",
        },
        {
          target: RecomendationType.Company,
          description:
            "Se tiver EBITDA positivo e capital empatado em imóveis (ex: armazéns, escritórios), considere separar os imóveis numa entidade jurídica específica para o negócio imobiliário. Esta poderá ser uma forma de captar capital adicional, caso o imóvel não esteja hipotecado.",
        },
      ],
    },
    Return1: {
      group: "Rentabilidade",
      type: CheckType.ExpressionCheck,
      label: "Retorno",
      description: "O ROA está abaixo do sector",
      expression: new LessThan(
        partial ? engine.Ratios.SynthROA.formula : engine.Ratios.ROA.formula,
        new Ref(Fields.ROA, "bdp")
      ),
      companyValue: partial ? engine.Ratios.SynthROA : engine.Ratios.ROA,
      benchmarkValue: new Ref(Fields.ROA, "bdp"),
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "O utilize o simulador de potencial de optimização na secção de Planeamento para em que áreas pode ter maior impacto de esforço de melhoria do desempenho da empresa",
        },
        {
          target: RecomendationType.Company,
          description:
            "Se tiver EBITDA positivo e capital empatado em imóveis (ex: armazéns, escritórios), considere separar os imóveis numa entidade jurídica específica para o negócio imobiliário. Esta poderá ser uma forma de captar capital adicional, caso o imóvel não esteja hipotecado.",
        },
      ],
    },
    Return2: {
      group: "Rentabilidade",
      type: CheckType.ExpressionCheck,
      label: "Retorno",
      description: "O ROCI está abaixo do sector",
      expression: new LessThan(
        partial ? engine.Ratios.SynthROIC.formula : engine.Ratios.ROIC.formula,
        new Ref(Fields.ROCI, "bdp")
      ),
      companyValue: partial ? engine.Ratios.SynthROIC : engine.Ratios.ROIC,
      benchmarkValue: new Ref(Fields.ROCI, "bdp"),
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "O utilize o simulador de potencial de optimização na secção de Planeamento para em que áreas pode ter maior impacto de esforço de melhoria do desempenho da empresa",
        },
        {
          target: RecomendationType.Company,
          description:
            "Se tiver EBITDA positivo e capital empatado em imóveis (ex: armazéns, escritórios), considere separar os imóveis numa entidade jurídica específica para o negócio imobiliário. Esta poderá ser uma forma de captar capital adicional, caso o imóvel não esteja hipotecado.",
        },
      ],
    },
    ZScoreWarning: {
      group: "Sustentabilidade Financeira",
      type: CheckType.ExpressionCheck,
      label: "Aviso Z-Score",
      description:
        "Z-Score entre 1.23 e 2.9 aponta para situação de potencial fragilidade",
      expression: new And(
        new GreaterThan(engine.Ratios.ZScore.formula, 1.23),
        new LessThan(engine.Ratios.ZScore.formula, 2.9)
      ),
      companyValue: engine.Ratios.ZScore,
      format: "x",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Veja as recomendações abaixo com especial atenção — a empresa parece estar em zona de fragilidade",
        },
      ],
      importance: -1,
    },
    ZScoreDanger: {
      group: "Sustentabilidade Financeira",
      type: CheckType.ExpressionCheck,
      label: "Aviso Z-Score",
      description:
        "Z-Score abaixo de 1.23 aponta para zona de elevada fragilidade ",
      expression: new LessThan(engine.Ratios.ZScore.formula, 1.23),
      companyValue: engine.Ratios.ZScore,
      format: "x",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Veja as recomendações abaixo com especial atenção — parece estar em situação de elevada fragilidade",
        },
      ],
      importance: -1,
    },
    UltraBearInvestmentNetDebtOverEBITDA: {
      group: "Novos investimentos",
      type: CheckType.ExpressionCheck,
      label: "Impacto de investimento cenário ultra bear",
      description:
        "O investimento pode reduzir a sua capacidade de servir a dívida.",
      expression: new Or(
        new LessThan(
          engine.Ratios.InvestmentScenarioUltraBearNetDebtOverEbitda.formula,
          0
        ),
        new GreaterThan(
          engine.Ratios.InvestmentScenarioUltraBearNetDebtOverEbitda.formula,
          4
        )
      ),
      companyValue: engine.Ratios.InvestmentScenarioUltraBearNetDebtOverEbitda,
      format: "x",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Considere alternativas ao financiamento bancário para limitar a probabilidade de implosão ",
        },
        {
          target: RecomendationType.Bank,
          description:
            "A Banca poderá não aprovar o financiamento com estes tipo de indicadores.",
        },
      ],
    },
    UltraBearInvestmentNetDebtOverFinancialAutonomy: {
      group: "Novos investimentos",
      type: CheckType.ExpressionCheck,
      label: "Impacto de investimento cenário ultra bear",
      description:
        "O investimento pode reduzir a sua autonomia financeiros para níveis perigosos",
      expression: new LessThan(
        engine.Ratios.InvestmentScenarioUltraBearFinancialAutonomy.formula,
        0.3
      ),
      companyValue: engine.Ratios.InvestmentScenarioUltraBearFinancialAutonomy,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Considere alternativas ao financiamento bancário para limitar a probabilidade de implosão ",
        },
        {
          target: RecomendationType.Bank,
          description:
            "A Banca poderá não aprovar o financiamento com estes tipo de indicadores.",
        },
      ],
    },
    CustomerRevenueCoverage: {
      group: "Erro no SAFT",
      type: CheckType.ExpressionCheck,
      label: "Retorno",
      description:
        "Menos de 50% dos proveitos estão a ser detalhados por cliente",
      expression: new LessThan(
        new Divide(
          new Ref("CustomerRankingRevenue", "input"),
          engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
        ),
        0.5
      ),
      companyValue: {
        formula: new Divide(
          new Ref("CustomerRankingRevenue", "input"),
          engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
        ),
      },
      thresholdValue: 0.5,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Company,
          description:
            "Utilize os ficheiros SAFT de facturação para calcular a sua concentração de clientes e pensar em planos de diversificação de risco.",
        },

        {
          target: RecomendationType.Bank,
          description:
            "Prepara-se para dar indicação detalhada da facturação dos principais clientes e/ou decomposição da conta Clientes por cliente à Banca. Sem este detalhe, a Banca irá assumir que um factor de maior risco a qualquer operação com a sua empresa.",
        },
      ],
    },
    SupplierExpenseCoverage: {
      group: "Erro no SAFT",
      type: CheckType.ExpressionCheck,
      label: "Retorno",
      description:
        "Menos de 50% dos gastos estão a ser detalhados por fornecedor",
      expression: new LessThan(
        new Divide(
          new Ref("SupplierRankingExpenses", "input"),
          new Add(
            engine.StatementTree.ProfitAndLoss.ExternalServices,
            new Ref("Purchases", "input")
          )
        ),
        0.5
      ),
      companyValue: {
        formula: new Divide(
          new Ref("SupplierRankingExpenses", "input"),
          new Add(
            engine.StatementTree.ProfitAndLoss.ExternalServices,
            new Ref("Purchases", "input")
          )
        ),
      },
      thresholdValue: 0.5,
      format: "percentage",
      recommendations: [
        {
          target: RecomendationType.Bank,
          description:
            "Utilize os dados de fornecedores do e-fatura para avaliar a sua concentração de fornecedores. Se estiver muito concentrado em poucos fornecedores, pode estar na hora de procurar mais.",
        },
      ],
    },
  };
};

export const sectionChecksForEngine = (engine, configs) => {
  const checks = checksForEngine(engine, configs);

  const critical = Object.values(checks)
    .filter((check) => check.importance <= 2)
    .sort((a, b) => a.importance - b.importance);

  const profitAndLossStatement = [
    checks.CMVMC,
    checks.MaterialsCheck,
    checks.DepreciatioCheck,
  ];

  const balanceSheetStatement = [
    checks.Balanced,
    checks.ZeroAssets,
    checks.ZeroCapitalAndLiabilities,
    checks.NãoZeradas8,
    checks.NãoZeradas62,
    checks.NãoZeradas63,
    checks.Clients,
    checks.Inventory,
    checks.CurrentLoans,
    checks.NonCurrentLoans,
  ];

  const moaf = [
    checks.SilentDeath,
    checks.SilentDeath3,
    checks.SilentDeath2,
    checks.GrowingPains,
    checks.GrowingPains2,
    checks.RedAllOver,
  ];

  const cashFromResults = [checks.CashFromResults, checks.CashFromResults2];

  const profitability = [
    checks.GrossMargin,
    checks.GrossMargin1,
    checks.GrossMargin2,
    checks.Margin3,
    checks.Margin4,
    checks.SubScale,
    checks.SubScale2,
  ];

  const profitabilityDetails = [
    checks.RevenueGrowth,
    checks.ExternalServices,
    checks.ExtendedMargin,
    checks.Personnel,
    checks.Personnel2,
    checks.Productivity1,
    checks.Productivity2,
  ];

  const workingCapital = [checks.WC0, checks.WC1, checks.WC2];

  const simpleWorkingCapital = [
    checks.WCDetails0,
    checks.WCDetails1,
    checks.WCDetails2,
    checks.WCDetails3,
  ];

  const otherWorkingCapital = [checks.WCDetails4, checks.WCDetails5];

  const workingCapitalDays = [
    checks.DSO1,
    checks.DSO2,
    checks.DSO3,
    checks.DSO4,
    checks.DII1,
    checks.DII2,
    checks.DII3,
    checks.DII4,
    checks.DPO1,
    checks.DPO2,
    checks.DPO3,
    checks.DPO4,
    checks.DPO5,
    checks.DPO6,
    checks.DPO7,
  ];
  const workingCapitalChanges = [
    checks.GrowingPains2,
    checks.WCChange1,
    checks.WC3,
    checks.WC4,
  ];

  const simpleWorkingCapitalChangeDetails = [
    checks.Clients1,
    checks.Clients2,
    checks.Inventory1,
    checks.Inventory2,
    checks.Providers1,
    checks.Providers2,
  ];

  const otherWorkingCapitalChangeDetails = [checks.WCChange2];

  const debtService = [checks.DebtService];

  const financeEfficiency = [
    checks.FinanceInefficiency,
    checks.FinanceInefficiency2,
    checks.FinanceInefficiency3,
  ];
  const robustness = [
    checks.FinancialRobustness,
    checks.FinancialRobustness3,
    checks.FinancialRobustness2,
    checks.FinancialRobustness4,
    checks.ZScoreWarning,
    checks.ZScoreDanger,
  ];

  const robustnessScenarios = [
    checks.FinancialRobustness5,
    checks.FinancialRobustness6,
  ];

  const runways = [
    checks.FinancialRobustness5,
    checks.FinancialRobustness6,
    checks.FinancialRobustness7,
  ];

  const investmentScenarios = [
    checks.UltraBearInvestmentNetDebtOverEBITDA,
    checks.UltraBearInvestmentNetDebtOverFinancialAutonomy,
  ];

  const returns = [checks.Return, checks.Return1, checks.Return2];

  const customers = [checks.CustomerRevenueCoverage];
  const suppliers = [checks.SupplierExpenseCoverage];

  const scores = [checks.ZScoreWarning, checks.ZScoreDanger];

  return {
    critical,
    profitAndLossStatement,
    balanceSheetStatement,
    moaf,
    cashFromResults,
    profitability,
    profitabilityDetails,
    workingCapital,
    simpleWorkingCapital,
    otherWorkingCapitalChangeDetails,
    otherWorkingCapital,
    workingCapitalDays,
    workingCapitalChanges,
    simpleWorkingCapitalChangeDetails,
    debtService,
    financeEfficiency,
    robustness,
    robustnessScenarios,
    runways,
    investmentScenarios,
    returns,
    customers,
    suppliers,
  };
};
