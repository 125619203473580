/**
 *
 * @param {string} dateStr
 * @returns an object with the month (1-12) and calendar year (ex: 2020)
 */
export const getMonthYear = (dateStr) => {
  const _date = new Date(dateStr);
  return {
    month: _date.getMonth() + 1,
    year: _date.getFullYear(),
  };
};

/**
 *
 * @param {object} monthYear
 * @returns the next month in the month year format
 */
export const getNextMonthYear = ({ month, year }) => ({
  month: month < 12 ? month + 1 : 1,
  year: month < 12 ? year : year + 1,
});

/**
 *
 * @param {[[]]} matrix
 * @returns the min and max dates in array of date arrays
 */
export const getMinMax = (matrix = [[]]) => {
  const _all = matrix.flatMap((row) => row.map((d) => d && new Date(d)));
  const min = Math.min(..._all);
  const max = Math.max(..._all);
  return {
    min: min && new Date(min),
    max: max && new Date(max),
  };
};

export const isSameMonthYear = (a, b) =>
  a && b && a.month === b.month && a.year === b.year;

export const isValidDate = (d) => d && d.getDate && d.getDate();

/**
 *
 * @param {string} start
 * @param {string} end
 * @returns a list of month-year object that include the start and end dates
 */
export const generateMonthYears = (start, end) => {
  if (!isValidDate(start) || !isValidDate(end)) return undefined;
  const _start = getMonthYear(start);
  const _end = getMonthYear(end);
  var _list = [_start];
  var _current = _start;
  while (!isSameMonthYear(_current, _end)) {
    _current = getNextMonthYear(_current);
    _list.push(_current);
  }
  return _list;
};
