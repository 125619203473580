import { memo, useEffect, useState } from "react";
import { Card, Select, LoadingScreen } from "./UI";
import { runChecks } from "./check-engine";
import { sectionChecksForEngine, RecomendationType } from "./check-defs";
import { Fields } from "./bdp";
import { isNotFullYear } from "./saft";
import { WorkerSuspense } from "./Worker";

const ViewOptions = [
  { label: "Recomendações Empresa", value: "companyRecomendations" },
  { label: "Recomendações Banca", value: "bankRecomendations" },
];

export const Summary = ({
  processed,
  statements,
  engine,
  engines,
  years,
  sectorData,
}) => {
  console.log({ statements });

  const [viewMode, setViewMode] = useState(ViewOptions[0].value);
  const [selectedYear, setSelectedYear] = useState(years && years[0]);
  const [processing, setProcessing] = useState(true);
  const [state, setState] = useState();
  const [disclose, setDisclose] = useState(true);

  const selectedIndex = years.indexOf(selectedYear);
  const selectedProcessed = processed && processed[selectedIndex];
  const selectedStatement = statements && statements[selectedIndex];
  const selectedEngine = engines && engines[selectedIndex];
  const args = [selectedProcessed, selectedStatement, selectedEngine];

  const toggleDislose = (_) => setDisclose(!disclose);
  // useEffect(() => {
  //   setProcessing(true);
  //   const selectedIndex = years.indexOf(selectedYear);
  //   const selectedProcessed = processed && processed[selectedIndex];
  //   const sectionChecks = sectionChecksForEngine(engine, {
  //     partial: isNotFullYear(selectedProcessed) || statementHasNoTaxOrNetIncome(selectedIndex, engines[selectedIndex]),
  //   });

  //   const criticalChecks = sectionChecks.critical;
  //   const sectionCheckGroups = [
  //     ...new Set(criticalChecks.map((check) => check.group)),
  //   ];
  //   const groupedSectionChecks = sectionCheckGroups.map((group) =>
  //     criticalChecks.filter((_s) => _s.group === group)
  //   );

  //   console.log({ sectionChecks, groupedSectionChecks, sectionCheckGroups });
  //   setState({ sectionChecks, groupedSectionChecks, sectionCheckGroups });
  //   setProcessing(false);
  // }, [engine, years, selectedYear, processed]);

  // useEffect(() => {
  //   console.log({ viewMode });
  // }, [viewMode]);

  return (
    <Card className="my-2">
      <SectionIntro className="mb-2">
        Esta secção resume as principais recomendações que resultam do
        diagnóstico financeiro da empresa.
      </SectionIntro>
      <WorkerSuspense
        fallback={<LoadingScreen />}
        workerPath="Summary.worker.js"
        workerFn="summaryData"
        workerArgs={args}
      >
        {({ sectionChecks, groupedSectionChecks, sectionCheckGroups }) => <>
          <div className="float-end">
            <span className="small text-muted me-2">
              Importante. Este sumário serve para alertas à gestão e não
              substitui integralmente a consulta de um profisional.
            </span>
            <button
              className="btn btn-sm btn-primary me-2"
              onClick={toggleDislose}
            >
              {!disclose ? "Mostrar detalhes" : "Esconder detalhes"}
            </button>
          </div>
          <h5>
            Sumário — Recomendações Críticas{" "}
            <Select
              className="ms-2 small"
              options={ViewOptions}
              selected={viewMode}
              onChange={setViewMode}
            ></Select>
          </h5>

          <>
            <GroupedRecomendationSummaryWithDisclosable
              groupLabels={sectionCheckGroups}
              groupChecks={groupedSectionChecks}
              processed={processed}
              statements={statements}
              engine={engine}
              db={sectorData.db}
              labels={sectorData.labels}
              company={{ [Fields.FTEs]: sectorData.fte }}
              type={
                viewMode === "companyRecomendations"
                  ? RecomendationType.Company
                  : RecomendationType.Bank
              }
              disclose={disclose}
            ></GroupedRecomendationSummaryWithDisclosable>
            <hr />
            {false && (
              <RecomendationSummaryWithDisclosable
                header={" "}
                className="my-3"
                checks={sectionChecks.critical}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
                disclose={disclose}
              ></RecomendationSummaryWithDisclosable>
            )}
          </>
        </>}
      </WorkerSuspense>
    </Card>
  )
}

const GroupedRecomendationSummaryWithDisclosable = ({
  groupChecks,
  groupLabels,
  processed,
  statements,
  engine,
  className,
  db,
  labels,
  company,
  type = RecomendationType.Company,
  disclose,
}) => (
  <div>
    {groupLabels &&
      groupLabels.map((groupLabel, offset) => (
        <RecomendationSummaryWithDisclosable
          header={<h5>{groupLabel}</h5>}
          className={className || "my-3"}
          checks={groupChecks[offset]}
          processed={processed}
          statements={statements}
          engine={engine}
          db={db}
          labels={labels}
          company={company}
          type={type}
          disclose={disclose}
        />
      ))}
  </div>
);

const RecomendationSummaryWithDisclosable = ({
  checks,
  processed,
  statements,
  engine,
  header,
  className,
  db,
  labels,
  company,
  type = RecomendationType.Company,
  disclose,
}) => {
  const checksReadyToBePerformed =
    checks && checks.filter((check) => !check.benchmarkValue || (db && labels));
  const results =
    checksReadyToBePerformed &&
    runChecks(
      checksReadyToBePerformed,
      processed,
      statements,
      engine,
      db,
      labels,
      company
    );
  console.log({ results });
  const trueResults =
    results &&
    results.filter(
      (result) => result && result.values && result.values[0] === true
    );
  const applicableRecommendations = trueResults
    .filter((result) => result.recommendations)
    .flatMap((result) =>
      result.recommendations
        .filter((r) => r.target === type)
        .map((r) => r.description)
    );
  const recommendationDescriptions = trueResults && [
    ...new Set(applicableRecommendations),
  ];
  const recommendationToAlertMap =
    trueResults &&
    recommendationDescriptions.map((desc) =>
      trueResults
        .filter((res) =>
          res.recommendations.map((_r) => _r.description).includes(desc)
        )
        .map((result) => result.description)
    );

  return (
    <div
      className={[
        "bg-info bg-opacity-10 rounded px-2 py-2",
        className || "",
      ].join(" ")}
    >
      {header || <b>Alertas</b>}
      <div className="d-flex flex-column mt-2">
        {recommendationDescriptions &&
          recommendationDescriptions.length > 0 &&
          recommendationDescriptions.map((description, offset) => (
            <RecommendationItemWithDisclosable
              key={offset}
              description={description}
              checkDescriptions={recommendationToAlertMap[offset]}
              disclose={disclose}
            />
          ))}
        {recommendationDescriptions &&
          recommendationDescriptions.length === 0 && (
            <div className="text-muted">Sem recomendações relevantes</div>
          )}
      </div>
    </div>
  );
};

const RecommendationItemWithDisclosable = memo(({
  description,
  checkDescriptions,
  disclose = true,
}) => {
  return (
    <div
      className={`d-flex flex-column ${disclose ? "mb-4 mt-2" : "mb-2 mt-1"}`}
    >
      <div className="d-flex flex-row mb-1">
        <div className="me-2">
          <i className="bi bi-info-square-fill text-primary"></i>
        </div>
        <div>{description} </div>
      </div>
      {disclose && (
        <div>
          {checkDescriptions &&
            checkDescriptions.map((desc) => (
              <div className="ms-4">
                <i className="bi bi-exclamation-triangle-fill text-danger me-2"></i>
                <span className="text-muted">{desc}</span>
              </div>
            ))}
        </div>
      )}
    </div>
  );
});

const SectionIntro = ({ children, className }) => (
  <div
    className={`bg-light small text-secondary px-2 py-2 rounded mb-4 ${className || ""
      }`}
  >
    {children}
  </div>
);

const statementHasNoTaxOrNetIncome = (statement, engine) => {
  const symbols = [engine.StatementTree.ProfitAndLoss.Taxes, engine.StatementTree.Capital.NetIncome];
  const noTaxOrNetIncome = symbols.some(symbol => symbolHasNoSummaries(statement[symbol]));
  return noTaxOrNetIncome
}

const symbolHasNoSummaries = (symbol) => Object.keys(symbol || {}).filter(key => key && !key.includes('__summary')).length === 0;