export const StatementTree = {
  Assets: {
    Total: Symbol("Ativo total"),
    NonCurrent: {
      Total: Symbol("Ativo não corrente"),
      CreditsAndOtherNonCurrentAssets: Symbol(
        "Créditos e outros ativos não correntes"
      ),
      FinancialInvestments: Symbol("Investimentos financeiros"),
      FixedAssets: Symbol("Ativos fixos tangíveis"),
      IntagibleAssets: Symbol("Ativos intangíveis"),
    },
    Current: {
      Total: Symbol("Ativo corrente"),
      OtherCurrentAssets: Symbol("Outros ativos correntes"),
      OtherCurrentAssets2: Symbol("Outros ativos correntes (A2)"),
      Deferrals: Symbol("Diferimentos (balanço ativo)"),
      StateAndOtherPublicEntities: Symbol(
        "Estado e outros entes públicos (balanço ativo)"
      ),
      UnrealizedButSubscribedCapital: Symbol(
        "Capital subscrito e não realizado"
      ),
      Inventory: Symbol("Inventários"),
      Clients: Symbol("Clientes"),
      Cash: Symbol("Caixa e depósitos bancários"),
    },
  },
  LiabilitiesAndCapital: {
    Total: Symbol("Total do capital próprio e do passivo"),
  },
  Liabilities: {
    Total: Symbol("Total do passivo"),
    NonCurrent: {
      Total: Symbol("Passivo não corrente"),
      OtherNonCurrentPayables: Symbol("Outras dívidas a pagar"),
      Loans: Symbol("Financiamentos obtidos"),
      Provisions: Symbol("Provisões"),
    },
    Current: {
      Total: Symbol("Passivo corrente"),
      Loans: Symbol("Financiamentos Obtidos"),
      OtherCurrentPayables: Symbol("Outros passivos correntes"),
      OtherCurrentPayables2: Symbol("Outros passivos correntes (A2)"),
      Deferrals: Symbol("Diferimentos (balanço passivo)"),
      StateAndOtherPublicEntities: Symbol(
        "Estado e outros entes públicos (balanço passivo)"
      ),
      Providers: Symbol("Fornecedores"),
    },
  },
  Capital: {
    Total: Symbol("Capital Próprio"),
    SubscribedCapital: Symbol("Capital Subscrito"),
    CapitalChanges: Symbol(
      "Ajustamentos / Outras variações no capital próprio"
    ),
    OtherCapitalInstruments: Symbol("Outros instrumentos de capital próprio"),
    LegalReserves: Symbol("Reservas legais"),
    OtherReserves: Symbol("Outras reservas"),
    PastResults: Symbol("Resultados transitados"),
    NetProfit: Symbol("Resultado líquido do período (demonstração e balanço)"),
    AntecipatedDividends: Symbol("Dividendos antecipados"),
  },
  ProfitAndLoss: {
    SalesAndServicesRendered: Symbol("Vendas e serviços prestados"),
    FinishedGoodsChanges: Symbol("Variação nos inventários da produção"),
    OwnWork: Symbol("Trabalhos para a própria entidade"),
    Subsidies: Symbol("Subsídios à exploração"),
    Materials: Symbol(
      "Custo das mercadorias vendidas e das matérias consumidas"
    ),
    ExternalServices: Symbol("Fornecimentos e serviços externos"),
    Personnel: Symbol("Gastos com o pessoal"),
    Impairment: Symbol("Imparidades (Perdas/Reversões)"),
    Provisions: Symbol("Provisões (aumentos/reduções)"),
    OtherGains: Symbol("Outros rendimentos"),
    OtherExpenses: Symbol("Outros gastos"),
    EBITDA: Symbol("EBITDA"),
    DandA: Symbol("Gastos / reversões de depreciação e de amortização"),
    OperatingIncome: Symbol("Resultado Operacional"),
    InterestIncome: Symbol("Juros e rendimentos similares obtidos"),
    InterestExpense: Symbol("Juros e gastos similares suportados"),
    EarningsBeforeTaxes: Symbol("Resultado antes de impostos"),
    Taxes: Symbol("Imposto sobre o rendimento do período"),
  },
};

export const BalanceSheetSymbols = [
  StatementTree.Assets.Total,
  ...Object.values(StatementTree.Assets.NonCurrent),
  ...Object.values(StatementTree.Assets.Current),
  StatementTree.LiabilitiesAndCapital.Total,
  ...Object.values(StatementTree.Capital),
  StatementTree.Liabilities.Total,
  ...Object.values(StatementTree.Liabilities.NonCurrent),
  ...Object.values(StatementTree.Liabilities.Current),
];

export const BalanceSheetTotals = [
  StatementTree.Assets.Total,
  StatementTree.Assets.NonCurrent.Total,
  StatementTree.Assets.Current.Total,
  StatementTree.Liabilities.Total,
  StatementTree.Liabilities.NonCurrent.Total,
  StatementTree.Liabilities.Current.Total,
  StatementTree.LiabilitiesAndCapital.Total,
  StatementTree.Capital.Total,
];

export const UncollapsableBalanceSheetItems = [...BalanceSheetTotals];

export const isBalanceSheetSymbol = (symbol) =>
  BalanceSheetSymbols.includes(symbol);

export const isBalanceSheetTotal = (symbol) => {
  return BalanceSheetTotals.includes(symbol);
};

export const ProfitAndLossSymbols = [
  ...Object.values(StatementTree.ProfitAndLoss),
  StatementTree.Capital.NetProfit,
];

export const ProfitAndLossTotals = [
  StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  StatementTree.ProfitAndLoss.EBITDA,
  StatementTree.ProfitAndLoss.OperatingIncome,
  StatementTree.ProfitAndLoss.EarningsBeforeTaxes,
  StatementTree.Capital.NetProfit,
];

export const UncollapsableProfitAndLossItems = [
  ...ProfitAndLossTotals,
  StatementTree.ProfitAndLoss.Taxes,
  StatementTree.ProfitAndLoss.InterestExpense,
]

export const isProfitAndLossSymbol = (symbol) =>
  ProfitAndLossSymbols.includes(symbol);

export const isProfitAndLossTotal = (symbol) => {
  return ProfitAndLossTotals.includes(symbol);
};

export const isUncollapsable = (symbol) => [
  ...UncollapsableBalanceSheetItems, 
  ...UncollapsableProfitAndLossItems
].includes(symbol);

export const StatementOrdering = {
  Assets: [
    StatementTree.Assets.Total,
    // Non current assets
    StatementTree.Assets.NonCurrent.Total,
    StatementTree.Assets.NonCurrent.CreditsAndOtherNonCurrentAssets,
    StatementTree.Assets.NonCurrent.FinancialInvestments,
    StatementTree.Assets.NonCurrent.FixedAssets,
    StatementTree.Assets.NonCurrent.IntagibleAssets,
    // Current assets
    StatementTree.Assets.Current.Total,
    StatementTree.Assets.Current.OtherCurrentAssets,
    StatementTree.Assets.Current.OtherCurrentAssets2,
    StatementTree.Assets.Current.Deferrals,
    StatementTree.Assets.Current.StateAndOtherPublicEntities,
    StatementTree.Assets.Current.UnrealizedButSubscribedCapital,
    StatementTree.Assets.Current.Inventory,
    StatementTree.Assets.Current.Clients,
    StatementTree.Assets.Current.Cash,
  ],
  LiabilitiesAndCapital: [StatementTree.LiabilitiesAndCapital.Total],
  Liabilities: [
    StatementTree.Liabilities.Total,
    // Non current liabilities
    StatementTree.Liabilities.NonCurrent.Total,
    StatementTree.Liabilities.NonCurrent.OtherNonCurrentPayables,
    StatementTree.Liabilities.NonCurrent.Loans,
    StatementTree.Liabilities.NonCurrent.Provisions,
    // Current liabilities
    StatementTree.Liabilities.Current.Total,
    StatementTree.Liabilities.Current.Loans,
    StatementTree.Liabilities.Current.OtherCurrentPayables,
    StatementTree.Liabilities.Current.OtherCurrentPayables2,
    StatementTree.Liabilities.Current.Deferrals,
    StatementTree.Liabilities.Current.StateAndOtherPublicEntities,
    StatementTree.Liabilities.Current.Providers,
  ],
  Capital: [
    StatementTree.Capital.Total,
    StatementTree.Capital.SubscribedCapital,
    StatementTree.Capital.CapitalChanges,
    StatementTree.Capital.OtherCapitalInstruments,
    StatementTree.Capital.LegalReserves,
    StatementTree.Capital.OtherReserves,
    StatementTree.Capital.PastResults,
    StatementTree.Capital.NetProfit,
    StatementTree.Capital.AntecipatedDividends,
  ],
  ProfitAndLoss: [
    StatementTree.ProfitAndLoss.SalesAndServicesRendered,
    StatementTree.ProfitAndLoss.FinishedGoodsChanges,
    StatementTree.ProfitAndLoss.OwnWork,
    StatementTree.ProfitAndLoss.Subsidies,
    StatementTree.ProfitAndLoss.Materials,
    StatementTree.ProfitAndLoss.ExternalServices,
    StatementTree.ProfitAndLoss.Personnel,
    StatementTree.ProfitAndLoss.Impairment,
    StatementTree.ProfitAndLoss.Provisions,
    StatementTree.ProfitAndLoss.OtherExpenses,
    StatementTree.ProfitAndLoss.OtherGains,
    StatementTree.ProfitAndLoss.EBITDA,
    StatementTree.ProfitAndLoss.DandA,
    StatementTree.ProfitAndLoss.OperatingIncome,
    StatementTree.ProfitAndLoss.InterestIncome,
    StatementTree.ProfitAndLoss.InterestExpense,
    StatementTree.ProfitAndLoss.EarningsBeforeTaxes,
    StatementTree.ProfitAndLoss.Taxes,
    StatementTree.Capital.NetProfit,
  ],
};

export const TaxonomyRules = [
  {
    taxonomyCode: "1",
    sncSvatCode: ["11"],
    description: "Caixa",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Cash,
    creditField: "",
  },
  {
    taxonomyCode: "2",
    sncSvatCode: ["12"],
    description: "Depósitos à ordem",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Cash,
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "3",
    sncSvatCode: ["13"],
    description: "Outros depósitos bancários",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Cash,
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "4",
    sncSvatCode: ["14"],
    description: "Outros",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables,
  },
  {
    taxonomyCode: "5",
    sncSvatCode: ["211"],
    description: "Clientes - Clientes c/c",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables2,
  },
  {
    taxonomyCode: "6",
    sncSvatCode: ["212"],
    description: "Clientes - Clientes - títulos a receber",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables2,
  },
  {
    taxonomyCode: "7",
    sncSvatCode: ["213", "214", "215", "216", "217"],
    description: "Clientes - Outros Clientes",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables2,
  },
  {
    taxonomyCode: "8",
    sncSvatCode: ["218"],
    description: "Clientes - Adiantamentos de clientes",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables,
  },
  {
    taxonomyCode: "9",
    sncSvatCode: ["219"],
    description: "Clientes - Perdas por imparidade acumuladas - Clientes c/c",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "10",
    sncSvatCode: ["219"],
    description:
      "Clientes - Perdas por imparidade acumuladas - Clientes - títulos a receber",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "11",
    sncSvatCode: ["219"],
    description:
      "Clientes - Perdas por imparidade acumuladas - Outros Clientes",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Clients,
    creditField: "",
  },
  {
    taxonomyCode: "12",
    sncSvatCode: ["221"],
    description: "Fornecedores - Fornecedores c/c",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets2,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "13",
    sncSvatCode: ["222"],
    description: "Fornecedores - Fornecedores - títulos a pagar",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets2,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "14",
    sncSvatCode: ["223", "224", "226", "227"],
    description: "Fornecedores - Outros fornecedores",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets2,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "15",
    sncSvatCode: ["225"],
    description: "Fornecedores - Faturas em receção e conferência",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets2,
    creditField: StatementTree.Liabilities.Current.Providers,
  },
  {
    taxonomyCode: "16",
    sncSvatCode: ["228"],
    description: "Fornecedores - Adiantamentos a fornecedores",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "17",
    sncSvatCode: ["229"],
    description: "Fornecedores - Perdas por imparidade acumuladas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "18",
    sncSvatCode: ["2311"],
    description: "Pessoal - Remunerações a pagar - Aos Órgãos sociais",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables,
  },
  {
    taxonomyCode: "19",
    sncSvatCode: ["2312"],
    description: "Pessoal - Remunerações a pagar - Ao pessoal",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables,
  },
  {
    taxonomyCode: "20",
    sncSvatCode: ["2321"],
    description: "Pessoal - Adiantamentos - Aos Órgãos sociais",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "21",
    sncSvatCode: ["2322"],
    description: "Pessoal - Adiantamentos - Ao pessoal",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "22",
    sncSvatCode: ["2381"],
    description:
      "Pessoal - Outras operações - Com os Órgãos sociais - (CORRENTE)",
    includeAccounts: ["233", "234", "235", "236"],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables,
  },
  {
    taxonomyCode: "23",
    sncSvatCode: ["2381"],
    description:
      "Pessoal - Outras operações - Com os Órgãos sociais - (NÃO CORRENTE)",
    includeAccounts: ["233", "234", "235", "236"],
    classType: "APNC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.NonCurrent.CreditsAndOtherNonCurrentAssets,
    creditField: StatementTree.Liabilities.NonCurrent.OtherNonCurrentPayables,
  },
  {
    taxonomyCode: "24",
    sncSvatCode: ["2382"],
    description: "Pessoal - Outras operações - Com o pessoal - (CORRENTE)",
    includeAccounts: ["233", "234", "235", "236"],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables,
  },
  {
    taxonomyCode: "25",
    sncSvatCode: ["2382"],
    description: "Pessoal - Outras operações - Com o pessoal - (NÃO CORRENTE)",
    includeAccounts: ["233", "234", "235", "236"],
    classType: "APNC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.NonCurrent.CreditsAndOtherNonCurrentAssets,
    creditField: StatementTree.Liabilities.NonCurrent.OtherNonCurrentPayables,
  },
  {
    taxonomyCode: "26",
    sncSvatCode: ["239"],
    description:
      "Pessoal - Perdas por imparidade acumuladas - Adiantamentos - Aos Órgãos sociais",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "27",
    sncSvatCode: ["239"],
    description:
      "Pessoal - Perdas por imparidade acumuladas - Adiantamentos - Ao pessoal",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "28",
    sncSvatCode: ["239"],
    description:
      "Pessoal - Perdas por imparidade acumuladas - Outras operações - Aos Órgãos sociais (CORRENTE)",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "29",
    sncSvatCode: ["239"],
    description:
      "Pessoal - Perdas por imparidade acumuladas - Outras operações - Aos Órgãos sociais (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.CreditsAndOtherNonCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "30",
    sncSvatCode: ["239"],
    description:
      "Pessoal - Perdas por imparidade acumuladas - Outras operações - Ao pessoal (CORRENTE)",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "31",
    sncSvatCode: ["239"],
    description:
      "Pessoal - Perdas por imparidade acumuladas - Outras operações - Ao pessoal (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.CreditsAndOtherNonCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "32",
    sncSvatCode: ["241"],
    description: "Estado e outros entes públicos - Imposto sobre o rendimento",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntities,
    creditField: StatementTree.Liabilities.Current.StateAndOtherPublicEntities,
  },
  {
    taxonomyCode: "33",
    sncSvatCode: ["242"],
    description:
      "Estado e outros entes públicos - Retenção de impostos sobre rendimentos",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.StateAndOtherPublicEntities,
  },
  {
    taxonomyCode: "34",
    sncSvatCode: ["2431"],
    description:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA � Suportado",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntities,
    creditField: "",
  },
  {
    taxonomyCode: "35",
    sncSvatCode: ["2432"],
    description:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA - Dedutível",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntities,
    creditField: "",
  },
  {
    taxonomyCode: "36",
    sncSvatCode: ["2433"],
    description:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA - Liquidado",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.StateAndOtherPublicEntities,
  },
  {
    taxonomyCode: "37",
    sncSvatCode: ["2434"],
    description:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA - Regularizações",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntities,
    creditField: StatementTree.Liabilities.Current.StateAndOtherPublicEntities,
  },
  {
    taxonomyCode: "38",
    sncSvatCode: ["2435"],
    description:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA - Apuramento",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntities,
    creditField: StatementTree.Liabilities.Current.StateAndOtherPublicEntities,
  },
  {
    taxonomyCode: "39",
    sncSvatCode: ["2436"],
    description:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA - A pagar",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.StateAndOtherPublicEntities,
  },
  {
    taxonomyCode: "40",
    sncSvatCode: ["2437"],
    description:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA - A recuperar",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntities,
    creditField: "",
  },
  {
    taxonomyCode: "41",
    sncSvatCode: ["2438"],
    description:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA - Reembolsos pedidos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntities,
    creditField: "",
  },
  {
    taxonomyCode: "42",
    sncSvatCode: ["2439"],
    description:
      "Estado e outros entes públicos - Imposto sobre o valor acrescentado (IVA) - IVA - Liquidações oficiosas",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntities,
    creditField: StatementTree.Liabilities.Current.StateAndOtherPublicEntities,
  },
  {
    taxonomyCode: "43",
    sncSvatCode: ["244"],
    description: "Estado e outros entes públicos - Outros impostos",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntities,
    creditField: StatementTree.Liabilities.Current.StateAndOtherPublicEntities,
  },
  {
    taxonomyCode: "44",
    sncSvatCode: ["245"],
    description:
      "Estado e outros entes públicos - Contribuições para a Segurança Social",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntities,
    creditField: StatementTree.Liabilities.Current.StateAndOtherPublicEntities,
  },
  {
    taxonomyCode: "45",
    sncSvatCode: ["246"],
    description:
      "Estado e outros entes públicos - Tributos das autarquias locais",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntities,
    creditField: StatementTree.Liabilities.Current.StateAndOtherPublicEntities,
  },
  {
    taxonomyCode: "46",
    sncSvatCode: ["248"],
    description: "Estado e outros entes públicos - Outras tributações",
    includeAccounts: ["247"],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.StateAndOtherPublicEntities,
    creditField: StatementTree.Liabilities.Current.StateAndOtherPublicEntities,
  },
  {
    taxonomyCode: "47",
    sncSvatCode: ["2511"],
    description:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Empréstimos banc�rios - (CORRENTE)",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "48",
    sncSvatCode: ["2511"],
    description:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Empréstimos bancários - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Loans,
  },
  {
    taxonomyCode: "49",
    sncSvatCode: ["2512"],
    description:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Descobertos bancários - (CORRENTE)",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "50",
    sncSvatCode: ["2512"],
    description:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Descobertos bancários - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Loans,
  },
  {
    taxonomyCode: "51",
    sncSvatCode: ["2513"],
    description:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Locações financeiras - (CORRENTE)",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "52",
    sncSvatCode: ["2513"],
    description:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Locações financeiras - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Loans,
  },
  {
    taxonomyCode: "53",
    sncSvatCode: ["2514", "2515", "2516", "2517", "2518", "2519"],
    description:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Outros financiamentos - (CORRENTE)",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "54",
    sncSvatCode: ["2514", "2515", "2516", "2517", "2518", "2519"],
    description:
      "Financiamentos obtidos - Instituições de crédito e sociedades financeiras - Outros financiamentos - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Loans,
  },
  {
    taxonomyCode: "55",
    sncSvatCode: ["258"],
    description: "Financiamentos obtidos - Outros financiadores - (CORRENTE)",
    includeAccounts: ["255", "256", "257"],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Loans,
  },
  {
    taxonomyCode: "56",
    sncSvatCode: ["258"],
    description:
      "Financiamentos obtidos - Outros financiadores - (NÃO CORRENTE)",
    includeAccounts: ["255", "256", "257"],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Loans,
  },
  {
    taxonomyCode: "57",
    sncSvatCode: ["262"],
    description: "Acionistas/sócios - Quotas não liberadas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.UnrealizedButSubscribedCapital,
    creditField: "",
  },
  {
    taxonomyCode: "58",
    sncSvatCode: ["263"],
    description: "Acionistas/sócios - Adiantamentos por conta de lucros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "59",
    sncSvatCode: ["264"],
    description: "Acionistas/sócios - Resultados atribuídos",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables,
  },
  {
    taxonomyCode: "60",
    sncSvatCode: ["265"],
    description: "Acionistas/sócios - Lucros disponíveis",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables,
  },
  {
    taxonomyCode: "61",
    sncSvatCode: ["268"],
    description: "Acionistas/sócios - Outras operações - (CORRENTE)",
    includeAccounts: ["267"],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables,
  },
  {
    taxonomyCode: "62",
    sncSvatCode: ["268"],
    description: "Acionistas/sócios - Outras operações - (NÃO CORRENTE)",
    includeAccounts: ["267"],
    classType: "APNC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.NonCurrent.CreditsAndOtherNonCurrentAssets,
    creditField: StatementTree.Liabilities.NonCurrent.OtherNonCurrentPayables,
  },
  {
    taxonomyCode: "63",
    sncSvatCode: ["269"],
    description:
      "Acionistas/sócios - Perdas por imparidade acumuladas - Quotas não liberadas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.UnrealizedButSubscribedCapital,
    creditField: "",
  },
  {
    taxonomyCode: "64",
    sncSvatCode: ["269"],
    description:
      "Acionistas/sócios - Perdas por imparidade acumuladas - Adiantamentos por conta de lucros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "65",
    sncSvatCode: ["269"],
    description:
      "Acionistas/sócios - Perdas por imparidade acumuladas - Resultados atribuídos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "66",
    sncSvatCode: ["269"],
    description:
      "Acionistas/sócios - Perdas por imparidade acumuladas - Lucros disponíveis",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "67",
    sncSvatCode: ["269"],
    description:
      "Acionistas/sócios - Perdas por imparidade acumuladas - Outras operações (CORRENTE)",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "68",
    sncSvatCode: ["269"],
    description:
      "Acionistas/sócios - Perdas por imparidade acumuladas - Outras operações (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.CreditsAndOtherNonCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "69",
    sncSvatCode: ["2711"],
    description:
      "Outras contas a receber e a pagar - Fornecedores de investimentos - Fornecedores de investimentos � contas gerais - (CORRENTE)",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets2,
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables,
  },
  {
    taxonomyCode: "70",
    sncSvatCode: ["2711"],
    description:
      "Outras contas a receber e a pagar - Fornecedores de investimentos - Fornecedores de investimentos � contas gerais - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "APNC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.NonCurrent.OtherCurrentPayables2,
    creditField: StatementTree.Liabilities.NonCurrent.OtherNonCurrentPayables,
  },
  {
    taxonomyCode: "71",
    sncSvatCode: ["2712"],
    description:
      "Outras contas a receber e a pagar - Fornecedores de investimentos - Facturas em recepçãoo e confer�ncia - (CORRENTE)",
    includeAccounts: [],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets2,
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables,
  },
  {
    taxonomyCode: "72",
    sncSvatCode: ["2712"],
    description:
      "Outras contas a receber e a pagar - Fornecedores de investimentos - Facturas em recepçãoo e conferência - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "APNC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.NonCurrent.OtherCurrentPayables2,
    creditField: StatementTree.Liabilities.NonCurrent.OtherNonCurrentPayables,
  },
  {
    taxonomyCode: "73",
    sncSvatCode: ["2713"],
    description:
      "Outras contas a receber e a pagar - Fornecedores de investimentos - Adiantamentos a fornecedores de investimentos - (CORRENTE)",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "74",
    sncSvatCode: ["2713"],
    description:
      "Outras contas a receber e a pagar - Fornecedores de investimentos - Adiantamentos a fornecedores de investimentos - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.CreditsAndOtherNonCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "75",
    sncSvatCode: ["2721"],
    description:
      "Outras contas a receber e a pagar - Devedores e credores por acréscimos (periodização económica)* - Devedores por acréscimos de rendimentos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "76",
    sncSvatCode: ["2722"],
    description:
      "Outras contas a receber e a pagar - Devedores e credores por acréscimos (periodização económica)* - Credores por acréscimos de gastos",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables,
  },
  {
    taxonomyCode: "77",
    sncSvatCode: ["276"],
    description:
      "Outras contas a receber e a pagar - Adiantamentos por conta de vendas",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables,
  },
  {
    taxonomyCode: "78",
    sncSvatCode: ["278"],
    description:
      "Outras contas a receber e a pagar - Outros devedores e credores - (CORRENTE)",
    includeAccounts: ["277"],
    classType: "APC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: StatementTree.Liabilities.Current.OtherCurrentPayables,
  },
  {
    taxonomyCode: "79",
    sncSvatCode: ["278"],
    description:
      "Outras contas a receber e a pagar - Outros devedores e credores - (NÃO CORRENTE)",
    includeAccounts: ["277"],
    classType: "APNC",
    expectedBalance: "S2C",
    debitField: StatementTree.Assets.NonCurrent.CreditsAndOtherNonCurrentAssets,
    creditField: StatementTree.Liabilities.NonCurrent.OtherNonCurrentPayables,
  },
  {
    taxonomyCode: "80",
    sncSvatCode: ["279"],
    description:
      "Outras contas a receber e a pagar - Perdas por imparidade acumuladas - Fornecedores de investimentos - Adiantamentos a fornecedores de investimentos (CORRENTE)",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "81",
    sncSvatCode: ["279"],
    description:
      "Outras contas a receber e a pagar - Perdas por imparidade acumuladas - Fornecedores de investimentos - Adiantamentos a fornecedores de investimentos (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.CreditsAndOtherNonCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "82",
    sncSvatCode: ["279"],
    description:
      "Outras contas a receber e a pagar - Perdas por imparidade acumuladas - Devedores e credores por acréscimos (periodização económica)* - Devedores por acréscimos de rendimentos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "83",
    sncSvatCode: ["279"],
    description:
      "Outras contas a receber e a pagar - Perdas por imparidade acumuladas - Outros devedores e credores - (CORRENTE)",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.OtherCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "84",
    sncSvatCode: ["279"],
    description:
      "Outras contas a receber e a pagar - Perdas por imparidade acumuladas - Outros devedores e credores - (NÃO CORRENTE)",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.CreditsAndOtherNonCurrentAssets,
    creditField: "",
  },
  {
    taxonomyCode: "85",
    sncSvatCode: ["281"],
    description: "Diferimentos - Gastos a reconhecer",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Deferrals,
    creditField: "",
  },
  {
    taxonomyCode: "86",
    sncSvatCode: ["282"],
    description: "Diferimentos - Rendimentos a reconhecer",
    includeAccounts: [],
    classType: "PC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.Current.Deferrals,
  },
  {
    taxonomyCode: "87",
    sncSvatCode: ["291"],
    description: "Provisões - Impostos",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Provisions,
  },
  {
    taxonomyCode: "88",
    sncSvatCode: ["292"],
    description: "Provisões - Garantias a clientes",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Provisions,
  },
  {
    taxonomyCode: "89",
    sncSvatCode: ["293"],
    description: "Provisões - Processos judiciais em curso",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Provisions,
  },
  {
    taxonomyCode: "90",
    sncSvatCode: ["294"],
    description: "Provisões - Acidentes de trabalho e doenças profissionais",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Provisions,
  },
  {
    taxonomyCode: "91",
    sncSvatCode: ["298"],
    description: "Provisões - Outras provisões",
    includeAccounts: [],
    classType: "PNC",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Liabilities.NonCurrent.Provisions,
  },
  {
    taxonomyCode: "92",
    sncSvatCode: ["311"],
    description: "Compras - Mercadorias",
    includeAccounts: ["314", "315", "316"],
    classType: "",
    expectedBalance: "Dc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "93",
    sncSvatCode: ["312"],
    description: "Compras - Matérias-primas, subsidiárias e de consumo",
    includeAccounts: ["314", "315", "316"],
    classType: "",
    expectedBalance: "Dc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "94",
    sncSvatCode: ["317"],
    description: "Compras - Devoluções de compras - Mercadorias",
    includeAccounts: ["314", "315", "316"],
    classType: "",
    expectedBalance: "Cc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "95",
    sncSvatCode: ["317"],
    description:
      "Compras - Devoluções de compras - Matérias-primas, subsidiárias e de consumo",
    includeAccounts: ["314", "315", "316"],
    classType: "",
    expectedBalance: "Cc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "96",
    sncSvatCode: ["318"],
    description: "Compras - Descontos e abatimentos em compras - Mercadorias",
    includeAccounts: ["314", "315", "316"],
    classType: "",
    expectedBalance: "Cc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "97",
    sncSvatCode: ["318"],
    description:
      "Compras - Descontos e abatimentos em compras - Matérias-primas, subsidiárias e de consumo",
    includeAccounts: ["314", "315", "316"],
    classType: "",
    expectedBalance: "Cc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "98",
    sncSvatCode: ["321", "322", "323", "324", "327", "328"],
    description: "Mercadorias - Mercadorias",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "99",
    sncSvatCode: ["325"],
    description: "Mercadorias - Mercadorias em trânsito",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "100",
    sncSvatCode: ["326"],
    description: "Mercadorias - Mercadorias em poder de terceiros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "101",
    sncSvatCode: ["329"],
    description: "Mercadorias - Perdas por imparidade acumuladas - Mercadorias",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "102",
    sncSvatCode: ["329"],
    description:
      "Mercadorias - Perdas por imparidade acumuladas - Mercadorias em trânsito",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "103",
    sncSvatCode: ["329"],
    description:
      "Mercadorias - Perdas por imparidade acumuladas - Mercadorias em poder de terceiros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "104",
    sncSvatCode: ["331"],
    description: "Matérias-primas, subsidiárias e de consumo - Matérias-primas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "105",
    sncSvatCode: ["332"],
    description:
      "Matérias-primas, subsidiárias e de consumo - Matérias subsidiárias",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "106",
    sncSvatCode: ["333"],
    description: "Matérias-primas, subsidiárias e de consumo - Embalagens",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "107",
    sncSvatCode: ["334"],
    description:
      "Matérias-primas, subsidiárias e de consumo - Materiais diversos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "108",
    sncSvatCode: ["335"],
    description:
      "Matérias-primas, subsidiárias e de consumo - Matérias em trânsito",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "109",
    sncSvatCode: ["336", "337", "338"],
    description:
      "Matérias-primas, subsidiárias e de consumo - Outras matérias-primas, subsidiárias e de consumo",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "110",
    sncSvatCode: ["339"],
    description:
      "Matérias-primas, subsidiárias e de consumo - Perdas por imparidade acumuladas - Matérias-primas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "111",
    sncSvatCode: ["339"],
    description:
      "Matérias-primas, subsidiárias e de consumo - Perdas por imparidade acumuladas - Matérias subsidiárias",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "112",
    sncSvatCode: ["339"],
    description:
      "Matérias-primas, subsidiárias e de consumo - Perdas por imparidade acumuladas - Embalagens",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "113",
    sncSvatCode: ["339"],
    description:
      "Matérias-primas, subsidiárias e de consumo - Perdas por imparidade acumuladas - Materiais diversos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "114",
    sncSvatCode: ["339"],
    description:
      "Matérias-primas, subsidiárias e de consumo - Perdas por imparidade acumuladas - Matérias em trânsito",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "115",
    sncSvatCode: ["339"],
    description:
      "Matérias-primas, subsidiárias e de consumo - Perdas por imparidade acumuladas - Outras matérias-primas, subsidiárias e de consumo",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "116",
    sncSvatCode: ["341", "342", "343", "344", "345", "347", "348"],
    description:
      "Produtos acabados e intermédios - Produtos acabados e intermédios",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "117",
    sncSvatCode: ["346"],
    description:
      "Produtos acabados e intermédios - Produtos em poder de terceiros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "118",
    sncSvatCode: ["349"],
    description:
      "Produtos acabados e intermédios - Perdas por imparidade acumuladas - Produtos acabados e intermédios",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "119",
    sncSvatCode: ["349"],
    description:
      "Produtos acabados e intermédios - Perdas por imparidade acumuladas - Produtos em poder de terceiros",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "120",
    sncSvatCode: ["351"],
    description: "Subprodutos, desperdícios, resíduos e refugos - Subprodutos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "121",
    sncSvatCode: ["352"],
    description:
      "Subprodutos, desperdícios, resíduos e refugos - Desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "122",
    sncSvatCode: ["353", "354", "355", "356", "357", "358"],
    description:
      "Subprodutos, desperdícios, resíduos e refugos - Outros subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "123",
    sncSvatCode: ["359"],
    description:
      "Subprodutos, desperdícios, resíduos e refugos - Perdas por imparidade acumuladas - Subprodutos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "124",
    sncSvatCode: ["359"],
    description:
      "Subprodutos, desperdícios, resíduos e refugos - Perdas por imparidade acumuladas - Desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "125",
    sncSvatCode: ["359"],
    description:
      "Subprodutos, desperdícios, resíduos e refugos - Perdas por imparidade acumuladas - Outros subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "126",
    sncSvatCode: ["361", "362", "363", "364", "365", "366", "367", "368"],
    description:
      "Produtos e trabalhos em curso - Produtos e trabalhos em curso",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "127",
    sncSvatCode: ["369"],
    description:
      "Produtos e trabalhos em curso - Perdas por imparidade acumuladas",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "128",
    sncSvatCode: ["382"],
    description: "Reclassificação e regularização de inventários - Mercadorias",
    includeAccounts: [],
    classType: "",
    expectedBalance: "Sc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "129",
    sncSvatCode: ["383"],
    description:
      "Reclassificação e regularização de inventários - Matérias-primas, subsidiárias e de consumo",
    includeAccounts: [],
    classType: "",
    expectedBalance: "Sc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "130",
    sncSvatCode: ["384"],
    description:
      "Reclassificação e regularização de inventários - Produtos acabados e intermédios",
    includeAccounts: [],
    classType: "",
    expectedBalance: "Sc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "131",
    sncSvatCode: ["385"],
    description:
      "Reclassificação e regularização de inventários - Subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "",
    expectedBalance: "Sc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "132",
    sncSvatCode: ["386"],
    description:
      "Reclassificação e regularização de inventários - Produtos e trabalhos em curso",
    includeAccounts: [],
    classType: "",
    expectedBalance: "Sc",
    debitField: "",
    creditField: "",
  },
  {
    taxonomyCode: "133",
    sncSvatCode: ["39"],
    description: "Adiantamentos por conta de compras - Mercadorias",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "134",
    sncSvatCode: ["39"],
    description:
      "Adiantamentos por conta de compras - Matérias-primas, subsidiárias e de consumo",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "135",
    sncSvatCode: ["39"],
    description:
      "Adiantamentos por conta de compras - Produtos acabados e intermédios",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "136",
    sncSvatCode: ["39"],
    description:
      "Adiantamentos por conta de compras - Subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "137",
    sncSvatCode: ["39"],
    description:
      "Adiantamentos por conta de compras - Produtos e trabalhos em curso",
    includeAccounts: [],
    classType: "AC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.Current.Inventory,
    creditField: "",
  },
  {
    taxonomyCode: "138",
    sncSvatCode: ["4141"],
    description:
      "Investimentos financeiros - Investimentos noutras empresas - Participações de capital",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "139",
    sncSvatCode: ["4142"],
    description:
      "Investimentos financeiros - Investimentos noutras empresas - Empréstimos concedidos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "140",
    sncSvatCode: ["4143", "4144", "4145", "4146", "4147", "4148", "4149"],
    description:
      "Investimentos financeiros - Investimentos noutras empresas - Outros investimentos financeiros",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "141",
    sncSvatCode: ["419"],
    description:
      "Investimentos financeiros - Perdas por imparidade acumuladas - Investimentos noutras empresas - Participações de capital",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "142",
    sncSvatCode: ["419"],
    description:
      "Investimentos financeiros - Perdas por imparidade acumuladas - Investimentos noutras empresas - Empréstimos concedidos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "143",
    sncSvatCode: ["419"],
    description:
      "Investimentos financeiros - Perdas por imparidade acumuladas - Investimentos noutras empresas - Outros investimentos financeiros",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "144",
    sncSvatCode: ["431"],
    description: "Ativos fixos tangíveis - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "145",
    sncSvatCode: ["432"],
    description: "Ativos fixos tangíveis - Edifícios e outras construções",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "146",
    sncSvatCode: ["433"],
    description: "Ativos fixos tangíveis - Equipamento básico",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "147",
    sncSvatCode: ["434"],
    description: "Ativos fixos tangíveis - Equipamento de transporte",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "148",
    sncSvatCode: ["435"],
    description: "Ativos fixos tangíveis - Equipamento administrativo",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "149",
    sncSvatCode: ["436"],
    description: "Ativos fixos tangíveis - Equipamentos biológicos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "150",
    sncSvatCode: ["437"],
    description: "Ativos fixos tangíveis - Outros ativos fixos tangíveis",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "151",
    sncSvatCode: ["438"],
    description:
      "Ativos fixos tangíveis - Depreciações acumuladas - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "152",
    sncSvatCode: ["438"],
    description:
      "Ativos fixos tangíveis - Depreciações acumuladas - Edifícios e outras construções",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "153",
    sncSvatCode: ["438"],
    description:
      "Ativos fixos tangíveis - Depreciações acumuladas - Equipamento básico",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "154",
    sncSvatCode: ["438"],
    description:
      "Ativos fixos tangíveis - Depreciações acumuladas - Equipamento de transporte",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "155",
    sncSvatCode: ["438"],
    description:
      "Ativos fixos tangíveis - Depreciações acumuladas - Equipamento administrativo",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "156",
    sncSvatCode: ["438"],
    description:
      "Ativos fixos tangíveis - Depreciações acumuladas - Equipamentos biológicos",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "157",
    sncSvatCode: ["438"],
    description:
      "Ativos fixos tangíveis - Depreciações acumuladas - Outros ativos fixos tangíveis",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "158",
    sncSvatCode: ["441"],
    description: "Ativos intangíveis - Goodwill",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.IntagibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "159",
    sncSvatCode: ["442"],
    description: "Ativos intangíveis - Projetos de desenvolvimento",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.IntagibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "160",
    sncSvatCode: ["443"],
    description: "Ativos intangíveis - Programas de computador",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.IntagibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "161",
    sncSvatCode: ["444"],
    description: "Ativos intangíveis - Propriedade industrial",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.IntagibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "162",
    sncSvatCode: ["446"],
    description: "Ativos intangíveis - Outros ativos intangíveis",
    includeAccounts: ["445", "447"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.IntagibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "163",
    sncSvatCode: ["448"],
    description: "Ativos intangíveis - Amortizações acumuladas - Goodwill",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.IntagibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "164",
    sncSvatCode: ["448"],
    description:
      "Ativos intangíveis - Amortizações acumuladas - Projetos de desenvolvimento",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.IntagibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "165",
    sncSvatCode: ["448"],
    description:
      "Ativos intangíveis - Amortizações acumuladas - Programas de computador",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.IntagibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "166",
    sncSvatCode: ["448"],
    description:
      "Ativos intangíveis - Amortizações acumuladas - Propriedade industrial",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.IntagibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "167",
    sncSvatCode: ["448"],
    description:
      "Ativos intangíveis - Amortizações acumuladas - Outros ativos intangíveis",
    includeAccounts: [],
    classType: "ANC",
    expectedBalance: "C",
    debitField: StatementTree.Assets.NonCurrent.IntagibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "168",
    sncSvatCode: ["451"],
    description: "Investimentos em curso - Investimentos financeiros em curso",
    includeAccounts: ["456", "457", "458"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "169",
    sncSvatCode: ["453"],
    description: "Investimentos em curso - Ativos fixos tangíveis em curso",
    includeAccounts: ["456", "457", "458"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "170",
    sncSvatCode: ["454"],
    description: "Investimentos em curso - Ativos intangíveis em curso",
    includeAccounts: ["456", "457", "458"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.IntagibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "171",
    sncSvatCode: ["455"],
    description:
      "Investimentos em curso - Adiantamentos por conta de investimentos - Investimentos financeiros em curso",
    includeAccounts: ["456", "457", "458"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FinancialInvestments,
    creditField: "",
  },
  {
    taxonomyCode: "172",
    sncSvatCode: ["455"],
    description:
      "Investimentos em curso - Adiantamentos por conta de investimentos - Ativos fixos tangíveis em curso",
    includeAccounts: ["456", "457", "458"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.FixedAssets,
    creditField: "",
  },
  {
    taxonomyCode: "173",
    sncSvatCode: ["455"],
    description:
      "Investimentos em curso - Adiantamentos por conta de investimentos - Ativos intangíveis em curso",
    includeAccounts: ["456", "457", "458"],
    classType: "ANC",
    expectedBalance: "D",
    debitField: StatementTree.Assets.NonCurrent.IntagibleAssets,
    creditField: "",
  },
  {
    taxonomyCode: "174",
    sncSvatCode: ["51"],
    description: "Capital subscrito",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.SubscribedCapital,
  },
  {
    taxonomyCode: "175",
    sncSvatCode: ["521"],
    description: "A��es (quotas) próprias - Valor nominal",
    includeAccounts: ["523", "524", "525", "526", "527", "528", "529"],
    classType: "C",
    expectedBalance: "D",
    debitField: "",
    creditField: StatementTree.Capital.CapitalChanges,
  },
  {
    taxonomyCode: "176",
    sncSvatCode: ["522"],
    description: "A��es (quotas) próprias - Descontos e pr�mios",
    includeAccounts: ["523", "524", "525", "526", "527", "528", "529"],
    classType: "C",
    expectedBalance: "S1C",
    debitField: "",
    creditField: StatementTree.Capital.CapitalChanges,
  },
  {
    taxonomyCode: "177",
    sncSvatCode: ["53"],
    description: "Outros instrumentos de capital próprio*",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.OtherCapitalInstruments,
  },
  {
    taxonomyCode: "178",
    sncSvatCode: ["54"],
    description: "Pr�mios de emiss�o",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.CapitalChanges,
  },
  {
    taxonomyCode: "179",
    sncSvatCode: ["551"],
    description: "Reservas - Reservas legais",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.LegalReserves,
  },
  {
    taxonomyCode: "180",
    sncSvatCode: ["552"],
    description: "Reservas - Outras reservas",
    includeAccounts: ["553", "554", "555", "556", "557", "558", "559"],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.OtherReserves,
  },
  {
    taxonomyCode: "181",
    sncSvatCode: ["56"],
    description: "Resultados transitados",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "S1C",
    debitField: "",
    creditField: StatementTree.Capital.PastResults,
  },
  {
    taxonomyCode: "182",
    sncSvatCode: ["581"],
    description:
      "Excedentes de revaloriza��o de ativos fixos tangíveis * - Reavalia��es decorrentes de diplomas legais",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.CapitalChanges,
  },
  {
    taxonomyCode: "183",
    sncSvatCode: ["589"],
    description:
      "Excedentes de revaloriza��o de ativos fixos tangíveis * - Outros excedentes",
    includeAccounts: ["582", "583", "584", "585", "586", "587", "588"],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.CapitalChanges,
  },
  {
    taxonomyCode: "184",
    sncSvatCode: ["5931"],
    description:
      "Outras variações no capital próprio - Subsídios - Subsídios atribuídos",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.CapitalChanges,
  },
  {
    taxonomyCode: "185",
    sncSvatCode: ["5932"],
    description:
      "Outras variações no capital próprio - Subsídios - Ajustamentos em subsídios",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "D",
    debitField: "",
    creditField: StatementTree.Capital.CapitalChanges,
  },
  {
    taxonomyCode: "186",
    sncSvatCode: ["594"],
    description: "Outras variações no capital próprio - Doa��es",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "C",
    debitField: "",
    creditField: StatementTree.Capital.CapitalChanges,
  },
  {
    taxonomyCode: "187",
    sncSvatCode: ["599"],
    description: "Outras variações no capital próprio - Outras",
    includeAccounts: ["595", "596", "597", "598"],
    classType: "C",
    expectedBalance: "S1C",
    debitField: "",
    creditField: StatementTree.Capital.CapitalChanges,
  },
  {
    taxonomyCode: "188",
    sncSvatCode: ["611"],
    description:
      "Custo das mercadorias vendidas e das matérias consumidas - Mercadorias",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Sa1C",
    debitField: StatementTree.ProfitAndLoss.Materials,
    creditField: "",
  },
  {
    taxonomyCode: "189",
    sncSvatCode: ["612"],
    description:
      "Custo das mercadorias vendidas e das matérias consumidas - Matérias-primas, subsidiárias e de consumo",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Sa1C",
    debitField: StatementTree.ProfitAndLoss.Materials,
    creditField: "",
  },
  {
    taxonomyCode: "190",
    sncSvatCode: ["621"],
    description: "Fornecimentos e serviços externos - Subcontratos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "191",
    sncSvatCode: ["622"],
    description: "Fornecimentos e serviços externos - Serviços especializados",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "192",
    sncSvatCode: ["623"],
    description: "Fornecimentos e serviços externos - Materiais",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "193",
    sncSvatCode: ["624"],
    description: "Fornecimentos e serviços externos - Energia e fluidos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "194",
    sncSvatCode: ["625"],
    description:
      "Fornecimentos e serviços externos - Desloca��es, estadas e transportes",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "195",
    sncSvatCode: ["626"],
    description: "Fornecimentos e serviços externos - Serviços diversos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.ExternalServices,
    creditField: "",
  },
  {
    taxonomyCode: "196",
    sncSvatCode: ["631"],
    description: "Gastos com o pessoal - Remunera��es dos �rg�os sociais",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "197",
    sncSvatCode: ["632"],
    description: "Gastos com o pessoal - Remunera��es do pessoal",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "198",
    sncSvatCode: ["634"],
    description: "Gastos com o pessoal - Indemniza��es",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "199",
    sncSvatCode: ["635"],
    description: "Gastos com o pessoal - Encargos sobre remunera��es",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "200",
    sncSvatCode: ["636"],
    description:
      "Gastos com o pessoal - Seguros de acidentes no trabalho e doen�as profissionais",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "201",
    sncSvatCode: ["637"],
    description: "Gastos com o pessoal - Gastos de a��o social",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "202",
    sncSvatCode: ["638"],
    description: "Gastos com o pessoal - Outros gastos com o pessoal",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Personnel,
    creditField: "",
  },
  {
    taxonomyCode: "203",
    sncSvatCode: ["642"],
    description:
      "Gastos de depreciação e de amortização - Ativos fixos tangíveis - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "204",
    sncSvatCode: ["642"],
    description:
      "Gastos de depreciação e de amortização - Ativos fixos tangíveis - Edifícios e outras construções",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "205",
    sncSvatCode: ["642"],
    description:
      "Gastos de depreciação e de amortização - Ativos fixos tangíveis - Equipamento básico",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "206",
    sncSvatCode: ["642"],
    description:
      "Gastos de depreciação e de amortização - Ativos fixos tangíveis - Equipamento de transporte",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "207",
    sncSvatCode: ["642"],
    description:
      "Gastos de depreciação e de amortização - Ativos fixos tangíveis - Equipamento administrativo",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "208",
    sncSvatCode: ["642"],
    description:
      "Gastos de depreciação e de amortização - Ativos fixos tangíveis - Equipamentos biológicos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "209",
    sncSvatCode: ["642"],
    description:
      "Gastos de depreciação e de amortização - Ativos fixos tangíveis - Outros ativos fixos tangíveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "210",
    sncSvatCode: ["643"],
    description:
      "Gastos de depreciação e de amortização - Ativos intangíveis - Goodwill",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "211",
    sncSvatCode: ["643"],
    description:
      "Gastos de depreciação e de amortização - Ativos intangíveis - Projetos de desenvolvimento",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "212",
    sncSvatCode: ["643"],
    description:
      "Gastos de depreciação e de amortização - Ativos intangíveis - Programas de computador",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "213",
    sncSvatCode: ["643"],
    description:
      "Gastos de depreciação e de amortização - Ativos intangíveis - Propriedade industrial",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "214",
    sncSvatCode: ["643"],
    description:
      "Gastos de depreciação e de amortização - Ativos intangíveis - Outros ativos intangíveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "215",
    sncSvatCode: ["6511", "6512", "652", "653"],
    description: "Perdas por imparidade - Instrumentos financeiros",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Sa1C",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "216",
    sncSvatCode: ["6511"],
    description: "Perdas por imparidade - Em dívidas a receber - Clientes",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "217",
    sncSvatCode: ["6512"],
    description:
      "Perdas por imparidade - Em dívidas a receber - Outros devedores",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "218",
    sncSvatCode: ["652"],
    description: "Perdas por imparidade - Em inventários - Mercadorias",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "219",
    sncSvatCode: ["652"],
    description:
      "Perdas por imparidade - Em inventários - Matérias-primas, subsidiárias e de consumo",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "220",
    sncSvatCode: ["652"],
    description:
      "Perdas por imparidade - Em inventários - Produtos acabados e intermédios",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "221",
    sncSvatCode: ["652"],
    description:
      "Perdas por imparidade - Em inventários - Subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "222",
    sncSvatCode: ["652"],
    description:
      "Perdas por imparidade - Em inventários - Produtos e trabalhos em curso",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "223",
    sncSvatCode: ["653"],
    description:
      "Perdas por imparidade - Em investimentos financeiros - Participações de capital",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "224",
    sncSvatCode: ["653"],
    description:
      "Perdas por imparidade - Em investimentos financeiros - Empréstimos concedidos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "225",
    sncSvatCode: ["653"],
    description:
      "Perdas por imparidade - Em investimentos financeiros - Outros investimentos financeiros",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "226",
    sncSvatCode: ["653"],
    description:
      "Perdas por imparidade - Em investimentos financeiros - Goodwill",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "227",
    sncSvatCode: ["671"],
    description: "Provisões do período - Impostos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "228",
    sncSvatCode: ["672"],
    description: "Provisões do período - Garantias a clientes",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "229",
    sncSvatCode: ["673"],
    description: "Provisões do período - Processos judiciais em curso",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "230",
    sncSvatCode: ["674"],
    description:
      "Provisões do período - Acidentes no trabalho e doen�as profissionais",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "231",
    sncSvatCode: ["678"],
    description: "Provisões do período - Outras provisões",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "232",
    sncSvatCode: ["6811"],
    description: "Outros gastos - Impostos - Impostos diretos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "233",
    sncSvatCode: ["6812"],
    description: "Outros gastos - Impostos - Impostos indiretos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "234",
    sncSvatCode: ["6813"],
    description: "Outros gastos - Impostos - Taxas",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "235",
    sncSvatCode: ["682"],
    description: "Outros gastos - Descontos de pronto pagamento concedidos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "236",
    sncSvatCode: ["683"],
    description: "Outros gastos - Dívidas incobr�veis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "237",
    sncSvatCode: ["6841"],
    description: "Outros gastos - Perdas em inventários - Sinistros",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "238",
    sncSvatCode: ["6842"],
    description: "Outros gastos - Perdas em inventários - Quebras",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "239",
    sncSvatCode: ["6848"],
    description: "Outros gastos - Perdas em inventários - Outras perdas",
    includeAccounts: ["6843", "6844", "6845", "6846", "6847"],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "240",
    sncSvatCode: ["6861"],
    description:
      "Outros gastos - Gastos nos restantes investimentos financeros - Cobertura de preju�zos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "241",
    sncSvatCode: ["6862"],
    description:
      "Outros gastos - Gastos nos restantes investimentos financeros - Alienações",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "242",
    sncSvatCode: ["6863"],
    description:
      "Outros gastos - Gastos nos restantes investimentos financeros - Diferen�as de c�mbio desfavor�veis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "243",
    sncSvatCode: ["6868"],
    description:
      "Outros gastos - Gastos nos restantes investimentos financeros - Outros gastos",
    includeAccounts: ["6864", "6865", "6866", "6867"],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "244",
    sncSvatCode: ["6871"],
    description:
      "Outros gastos - Gastos em investimentos não financeiros - Alienações",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "245",
    sncSvatCode: ["6872"],
    description:
      "Outros gastos - Gastos em investimentos não financeiros - Sinistros",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "246",
    sncSvatCode: ["6873"],
    description:
      "Outros gastos - Gastos em investimentos não financeiros - Abates",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "247",
    sncSvatCode: ["6878"],
    description:
      "Outros gastos - Gastos em investimentos não financeiros - Outras gastos",
    includeAccounts: ["6875", "6876", "6877"],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "248",
    sncSvatCode: ["6881"],
    description:
      "Outros gastos - Outros - Correções relativas a períodos anteriores",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "249",
    sncSvatCode: ["6882"],
    description: "Outros gastos - Outros - Donativos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "250",
    sncSvatCode: ["6883"],
    description: "Outros gastos - Outros - Quotiza��es",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "251",
    sncSvatCode: ["6884"],
    description: "Outros gastos - Outros - Ofertas e amostras de inventários",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "252",
    sncSvatCode: ["6885"],
    description:
      "Outros gastos - Outros - Insufici�ncia da estimativa para impostos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "253",
    sncSvatCode: ["6887"],
    description: "Outros gastos - Outros - Diferen�as de c�mbio desfavor�veis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "254",
    sncSvatCode: ["6888"],
    description: "Outros gastos - Outros - Outros não especificados",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.OtherExpenses,
    creditField: "",
  },
  {
    taxonomyCode: "255",
    sncSvatCode: ["6911"],
    description:
      "Gastos de financiamento - Juros suportados - Juros de financiamentos obtidos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InterestExpense,
    creditField: "",
  },
  {
    taxonomyCode: "256",
    sncSvatCode: ["6918"],
    description: "Gastos de financiamento - Juros suportados - Outros juros",
    includeAccounts: ["6912", "6913", "6914", "6915", "6916", "6917"],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InterestExpense,
    creditField: "",
  },
  {
    taxonomyCode: "257",
    sncSvatCode: ["6921"],
    description:
      "Gastos de financiamento - Diferen�as de c�mbio desfavor�veis - Relativas a financiamentos obtidos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InterestExpense,
    creditField: "",
  },
  {
    taxonomyCode: "258",
    sncSvatCode: ["6928"],
    description:
      "Gastos de financiamento - Diferen�as de c�mbio desfavor�veis - Outras",
    includeAccounts: ["6922", "6923", "6924", "6925", "6926", "6927"],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InterestExpense,
    creditField: "",
  },
  {
    taxonomyCode: "259",
    sncSvatCode: ["6981"],
    description:
      "Gastos de financiamento - Outros gastos de financiamento - Relativos a financiamentos obtidos",
    includeAccounts: [
      "693",
      "694",
      "695",
      "696",
      "697",
      "6982",
      "6983",
      "6984",
      "6985",
      "6986",
      "6987",
    ],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InterestExpense,
    creditField: "",
  },
  {
    taxonomyCode: "260",
    sncSvatCode: ["6988"],
    description:
      "Gastos de financiamento - Outros gastos de financiamento - Outros",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.InterestExpense,
    creditField: "",
  },
  {
    taxonomyCode: "261",
    sncSvatCode: ["711"],
    description: "Vendas - Mercadorias",
    includeAccounts: ["715"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "262",
    sncSvatCode: ["712"],
    description: "Vendas - Produtos acabados e intermédios",
    includeAccounts: ["715"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "263",
    sncSvatCode: ["713"],
    description: "Vendas - Subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: ["715"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "264",
    sncSvatCode: ["716"],
    description: "Vendas - IVA das vendas com imposto incluído",
    includeAccounts: ["715"],
    classType: "RG",
    expectedBalance: "Sa1C",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "265",
    sncSvatCode: ["717"],
    description: "Vendas - Devoluções de vendas",
    includeAccounts: ["715"],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "266",
    sncSvatCode: ["718"],
    description: "Vendas - Descontos e abatimentos em vendas",
    includeAccounts: ["715"],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "267",
    sncSvatCode: ["721"],
    description: "Prestações de serviços - Serviço A",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "268",
    sncSvatCode: ["722"],
    description: "Prestações de serviços - Serviço B",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "269",
    sncSvatCode: ["723", "724", "727"],
    description: "Prestações de serviços - Outros serviços",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "270",
    sncSvatCode: ["725"],
    description: "Prestações de serviços - Serviços secundários",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "271",
    sncSvatCode: ["726"],
    description:
      "Prestações de serviços - IVA dos serviços com imposto incluído",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Sa1C",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "272",
    sncSvatCode: ["728"],
    description: "Prestações de serviços - Descontos e abatimentos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Da",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
  },
  {
    taxonomyCode: "273",
    sncSvatCode: ["731"],
    description:
      "Variações nos inventários da produção - Produtos acabados e intermédios",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Sa1C",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FinishedGoodsChanges,
  },
  {
    taxonomyCode: "274",
    sncSvatCode: ["732"],
    description:
      "Variações nos inventários da produção - Subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Sa1C",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FinishedGoodsChanges,
  },
  {
    taxonomyCode: "275",
    sncSvatCode: ["733"],
    description:
      "Variações nos inventários da produção - Produtos e trabalhos em curso",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Sa1C",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.FinishedGoodsChanges,
  },
  {
    taxonomyCode: "276",
    sncSvatCode: ["741"],
    description: "Trabalhos para a própria entidade - Ativos fixos tangíveis",
    includeAccounts: ["745", "746", "747", "748", "749"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OwnWork,
  },
  {
    taxonomyCode: "277",
    sncSvatCode: ["742"],
    description: "Trabalhos para a própria entidade - Ativos intangíveis",
    includeAccounts: ["745", "746", "747", "748", "749"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OwnWork,
  },
  {
    taxonomyCode: "278",
    sncSvatCode: ["744"],
    description:
      "Trabalhos para a própria entidade - Ativos por gastos diferidos",
    includeAccounts: ["745", "746", "747", "748", "749"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OwnWork,
  },
  {
    taxonomyCode: "279",
    sncSvatCode: ["751"],
    description: "Subsídios à exploração - Subsídios das entidades públicas",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.Subsidies,
  },
  {
    taxonomyCode: "280",
    sncSvatCode: ["752"],
    description: "Subsídios à exploração - Subsídios de outras entidades",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.Subsidies,
  },
  {
    taxonomyCode: "281",
    sncSvatCode: ["7612"],
    description:
      "Reversões - De depreciações e de amortizações - Ativos fixos tangíveis - Terrenos e recursos naturais",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "282",
    sncSvatCode: ["7612"],
    description:
      "Reversões - De depreciações e de amortizações - Ativos fixos tangíveis - Edifícios e outras construções",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "283",
    sncSvatCode: ["7612"],
    description:
      "Reversões - De depreciações e de amortizações - Ativos fixos tangíveis - Equipamento básico",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "284",
    sncSvatCode: ["7612"],
    description:
      "Reversões - De depreciações e de amortizações - Ativos fixos tangíveis - Equipamento de transporte",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "285",
    sncSvatCode: ["7612"],
    description:
      "Reversões - De depreciações e de amortizações - Ativos fixos tangíveis - Equipamento administrativo",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "286",
    sncSvatCode: ["7612"],
    description:
      "Reversões - De depreciações e de amortizações - Ativos fixos tangíveis - Equipamentos biológicos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "287",
    sncSvatCode: ["7612"],
    description:
      "Reversões - De depreciações e de amortizações - Ativos fixos tangíveis - Outros ativos fixos tangíveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "288",
    sncSvatCode: ["7613"],
    description:
      "Reversões - De depreciações e de amortizações - Ativos intangíveis - Goodwill",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "289",
    sncSvatCode: ["7613"],
    description:
      "Reversões - De depreciações e de amortizações - Ativos intangíveis - Projetos de desenvolvimento",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "290",
    sncSvatCode: ["7613"],
    description:
      "Reversões - De depreciações e de amortizações - Ativos intangíveis - Programas de computador",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "291",
    sncSvatCode: ["7613"],
    description:
      "Reversões - De depreciações e de amortizações - Ativos intangíveis - Propriedade industrial",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "292",
    sncSvatCode: ["7613"],
    description:
      "Reversões - De depreciações e de amortizações - Ativos intangíveis - Outros ativos intangíveis",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.DandA,
    creditField: "",
  },
  {
    taxonomyCode: "293",
    sncSvatCode: ["76211"],
    description:
      "Reversões - De perdas por imparidade - Em dívidas a receber - Clientes",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "294",
    sncSvatCode: ["76212"],
    description:
      "Reversões - De perdas por imparidade - Em dívidas a receber - Outros devedores",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "295",
    sncSvatCode: ["7622"],
    description:
      "Reversões - De perdas por imparidade - Em inventários - Mercadorias",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "296",
    sncSvatCode: ["7622"],
    description:
      "Reversões - De perdas por imparidade - Em inventários - Matérias-primas, subsidiárias e de consumo",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "297",
    sncSvatCode: ["7622"],
    description:
      "Reversões - De perdas por imparidade - Em inventários - Produtos acabados e intermédios",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "298",
    sncSvatCode: ["7622"],
    description:
      "Reversões - De perdas por imparidade - Em inventários - Subprodutos, desperdícios, resíduos e refugos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "299",
    sncSvatCode: ["7622"],
    description:
      "Reversões - De perdas por imparidade - Em inventários - Produtos e trabalhos em curso",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "300",
    sncSvatCode: ["7623"],
    description:
      "Reversões - De perdas por imparidade - Em investimentos financeiros - Participações de capital",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "301",
    sncSvatCode: ["7623"],
    description:
      "Reversões - De perdas por imparidade - Em investimentos financeiros - Empréstimos concedidos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "302",
    sncSvatCode: ["7623"],
    description:
      "Reversões - De perdas por imparidade - Em investimentos financeiros - Outros investimentos financeiros",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Impairment,
    creditField: "",
  },
  {
    taxonomyCode: "303",
    sncSvatCode: ["7631"],
    description: "Reversões - De provisões - Impostos",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "304",
    sncSvatCode: ["7632"],
    description: "Reversões - De provisões - Garantias a clientes",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "305",
    sncSvatCode: ["7633"],
    description: "Reversões - De provisões - Processos judiciais em curso",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "306",
    sncSvatCode: ["7634"],
    description:
      "Reversões - De provisões - Acidentes no trabalho e doenças profissionais",
    includeAccounts: [],
    classType: "GR",
    expectedBalance: "Ca",
    debitField: StatementTree.ProfitAndLoss.Provisions,
    creditField: "",
  },
  {
    taxonomyCode: "307",
    sncSvatCode: ["781"],
    description: "Outros rendimentos - Rendimentos suplementares",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "308",
    sncSvatCode: ["782"],
    description: "Outros rendimentos - Descontos de pronto pagamento obtidos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "309",
    sncSvatCode: ["783"],
    description: "Outros rendimentos - Recuperação de dívidas a receber",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "310",
    sncSvatCode: ["7841"],
    description: "Outros rendimentos - Ganhos em inventários - Sinistros",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "311",
    sncSvatCode: ["7842"],
    description: "Outros rendimentos - Ganhos em inventários - Sobras",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "312",
    sncSvatCode: ["7848"],
    description: "Outros rendimentos - Ganhos em inventários - Outros ganhos",
    includeAccounts: ["7843", "7844", "7845", "7846", "7847"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "313",
    sncSvatCode: ["7861"],
    description:
      "Outros rendimentos - Rendimentos nos restantes ativos financeiros - Diferenças de câmbio favoráveis",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "314",
    sncSvatCode: ["7862"],
    description:
      "Outros rendimentos - Rendimentos nos restantes ativos financeiros - Alienações",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "315",
    sncSvatCode: ["7868"],
    description:
      "Outros rendimentos - Rendimentos nos restantes ativos financeiros - Outros rendimentos",
    includeAccounts: ["7863", "7864", "7865", "7866", "7867"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "316",
    sncSvatCode: ["7871"],
    description:
      "Outros rendimentos - Rendimentos em investimentos não financeiros - Alienações",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "317",
    sncSvatCode: ["7872"],
    description:
      "Outros rendimentos - Rendimentos em investimentos não financeiros - Sinistros",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "318",
    sncSvatCode: ["7878"],
    description:
      "Outros rendimentos - Rendimentos em investimentos não financeiros - Outros rendimentos",
    includeAccounts: ["7874", "7875", "7876", "7877"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "319",
    sncSvatCode: ["7881"],
    description:
      "Outros rendimentos - Outros - Correções relativas a períodos anteriores",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "320",
    sncSvatCode: ["7882"],
    description:
      "Outros rendimentos - Outros - Excesso da estimativa para impostos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "321",
    sncSvatCode: ["7883"],
    description:
      "Outros rendimentos - Outros - Imputação de subsídios para investimentos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "322",
    sncSvatCode: ["7885"],
    description: "Outros rendimentos - Outros - Restituição de impostos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "323",
    sncSvatCode: ["7887"],
    description:
      "Outros rendimentos - Outros - Diferenças de câmbio favoráveis",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "324",
    sncSvatCode: ["7888"],
    description: "Outros rendimentos - Outros - Outros não especificados",
    includeAccounts: ["7886"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "325",
    sncSvatCode: ["7911"],
    description:
      "Juros, dividendos e outros rendimentos similares - Juros obtidos - De depósitos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "326",
    sncSvatCode: ["7915"],
    description:
      "Juros, dividendos e outros rendimentos similares - Juros obtidos - De financiamentos obtidos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.InterestIncome,
  },
  {
    taxonomyCode: "327",
    sncSvatCode: ["7918"],
    description:
      "Juros, dividendos e outros rendimentos similares - Juros obtidos - Outros",
    includeAccounts: ["7916", "7917"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "328",
    sncSvatCode: ["792"],
    description:
      "Juros, dividendos e outros rendimentos similares - Dividendos obtidos",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "329",
    sncSvatCode: ["793"],
    description:
      "Juros, dividendos e outros rendimentos similares - Diferenças de câmbio favoráveis",
    includeAccounts: [],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.InterestIncome,
  },
  {
    taxonomyCode: "330",
    sncSvatCode: ["798"],
    description:
      "Juros, dividendos e outros rendimentos similares - Outros rendimentos similares",
    includeAccounts: ["794", "795", "796", "797"],
    classType: "RG",
    expectedBalance: "Ca",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.OtherGains,
  },
  {
    taxonomyCode: "331",
    sncSvatCode: ["811"],
    description: "Resultado líquido do período - Resultado antes de impostos",
    includeAccounts: [
      "8123",
      "8124",
      "8125",
      "8126",
      " 8127",
      "8128",
      "8129",
      "813",
      " 814",
      "815",
      "816",
      "817",
      "819",
      " 82",
      "83",
      "84",
      "85",
      "86",
      "87",
      "88",
    ],
    classType: "RG",
    expectedBalance: "Sa1C",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.EarningsBeforeTaxes,
  },
  {
    taxonomyCode: "332",
    sncSvatCode: ["8121"],
    description:
      "Resultado líquido do período - Imposto sobre o rendimento do período - Imposto estimado para o período",
    includeAccounts: [
      "8123",
      "8124",
      "8125",
      "8126",
      " 8127",
      "8128",
      "8129",
      "813",
      " 814",
      "815",
      "816",
      "817",
      "819",
      " 82",
      "83",
      "84",
      "85",
      "86",
      "87",
      "88",
    ],
    classType: "GR",
    expectedBalance: "Da",
    debitField: StatementTree.ProfitAndLoss.Taxes,
    creditField: "",
  },
  {
    taxonomyCode: "333",
    sncSvatCode: ["818"],
    description: "Resultado líquido do período - Resultado líquido",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "S1C",
    debitField: "",
    creditField: StatementTree.Capital.NetProfit,
  },
  {
    taxonomyCode: "334",
    sncSvatCode: ["89"],
    description: "Dividendos antecipados",
    includeAccounts: [],
    classType: "C",
    expectedBalance: "D",
    debitField: "",
    creditField: StatementTree.ProfitAndLoss.AntecipatedDividends,
  },
];
