import { useEffect, useState } from "react";
import { Card, Select, LoadingScreen } from "./UI";
import { runChecks } from "./check-engine";
import { sectionChecksForEngine, RecomendationType } from "./check-defs";
import { Fields } from "./bdp";
import { isNotFullYear } from "./saft";

const ViewOptions = [
  { label: "Recomendações Empresa", value: "companyRecomendations" },
  { label: "Recomendações Banca", value: "bankRecomendations" },
];

export const Recommendations = ({
  processed,
  statements,
  engine,
  years,
  sectorData,
}) => {
  console.log({ statements });

  const [viewMode, setViewMode] = useState(ViewOptions[0].value);
  const [selectedYear, setSelectedYear] = useState(years && years[0]);
  const [processing, setProcessing] = useState(true);
  const [state, setState] = useState();

  useEffect(() => {
    setProcessing(true);
    const selectedIndex = years.indexOf(selectedYear);
    const selectedProcessed = processed && processed[selectedIndex];
    const sectionChecks = sectionChecksForEngine(engine, {
      partial: isNotFullYear(selectedProcessed),
    });
    console.log({ sectionChecks });
    setState({ sectionChecks });
    setProcessing(false);
  }, [engine, years, selectedYear, processed]);

  useEffect(() => {
    console.log({ viewMode });
  }, [viewMode]);

  return (
    <>
      {!state && processing && (
        <Card className="my-2">
          <LoadingScreen></LoadingScreen>
        </Card>
      )}
      {state && !processing && (
        <>
          <Card className="my-2">
            <h5>Sumário — Recomendações Críticas</h5>
            <>
              <RecomendationSummaryWithDisclosable
                header={" "}
                className="my-3"
                checks={state.sectionChecks.critical}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummaryWithDisclosable>
            </>
          </Card>
          <Card className="my-2">
            <div className="float-end">
              <div className="float-end">
                <Select
                  options={years}
                  selected={selectedYear}
                  onChange={setSelectedYear}
                ></Select>
              </div>
              <Select
                options={ViewOptions}
                selected={viewMode}
                onChange={setViewMode}
              ></Select>
            </div>
            <h5>Demostrações</h5>
            <>
              <RecomendationSummary
                className="my-3"
                header={<b>Demonstração de resultados</b>}
                checks={state.sectionChecks.profitAndLossStatement}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummary>
              <RecomendationSummary
                className="my-3"
                header={<b>Balanço</b>}
                checks={state.sectionChecks.balanceSheetStatement}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummary>
            </>
          </Card>
          <Card className="my-2">
            <h5>Análise Financeira</h5>
            <>
              <RecomendationSummary
                className="my-3"
                header={<b>MOAF</b>}
                checks={state.sectionChecks.moaf}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummary>
              <RecomendationSummary
                className="my-3"
                header={<b>Fluxo de resultados</b>}
                checks={state.sectionChecks.cashFromResults}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummary>
              <RecomendationSummary
                className="my-3"
                header={<b>Lucrabilidade - sumário</b>}
                checks={state.sectionChecks.profitability}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummary>
              <RecomendationSummary
                className="my-3"
                header={<b>Lucrabilidade - detalhe</b>}
                checks={state.sectionChecks.profitabilityDetails}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummary>
              <RecomendationSummary
                className="my-3"
                header={<b>Fundo de maneio - sumário</b>}
                checks={state.sectionChecks.workingCapital}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummary>
              <RecomendationSummary
                className="my-3"
                header={<b>Fundo de maneio - simples</b>}
                checks={state.sectionChecks.simpleWorkingCapital}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
              >
                {" "}
                type=
                {viewMode === "companyRecomendations"
                  ? RecomendationType.Company
                  : RecomendationType.Bank}
              </RecomendationSummary>
              <RecomendationSummary
                className="my-3"
                header={<b>Fundo de maneio - outro</b>}
                checks={state.sectionChecks.otherWorkingCapital}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
              >
                {" "}
                type=
                {viewMode === "companyRecomendations"
                  ? RecomendationType.Company
                  : RecomendationType.Bank}
              </RecomendationSummary>
              <RecomendationSummary
                className="my-3"
                header={<b>PMR, Dias de stocks e PMP</b>}
                checks={state.sectionChecks.workingCapitalDays}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummary>
              <RecomendationSummary
                className="my-3"
                header={<b>Variação de Fundo de Maneio</b>}
                checks={state.sectionChecks.workingCapitalChanges}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummary>
              <RecomendationSummary
                className="my-3"
                header={
                  <b>
                    Variação de Fundo de Maneio — clientes, inventários e
                    fornecedores
                  </b>
                }
                checks={state.sectionChecks.simpleWorkingCapitalChangeDetails}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummary>
              <RecomendationSummary
                className="my-3"
                header={<b>Variação de Fundo de Maneio - Estados e outros</b>}
                checks={state.sectionChecks.otherWorkingCapitalChangeDetails}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummary>
              <RecomendationSummary
                className="my-3"
                header={<b>Serviço da Dívida</b>}
                checks={state.sectionChecks.debtService}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummary>
              <RecomendationSummary
                className="my-3"
                header={<b>Taxa all-in e sobrecustos financeiros</b>}
                checks={state.sectionChecks.financeEfficiency}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummary>
              <RecomendationSummary
                className="my-3"
                header={
                  <b>Robustez financeira e capacidade de servir dívida</b>
                }
                checks={state.sectionChecks.robustness}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummary>
              <RecomendationSummary
                className="my-3"
                header={<b>Rentabilidade</b>}
                checks={state.sectionChecks.returns}
                processed={processed}
                statements={statements}
                engine={engine}
                db={sectorData.db}
                labels={sectorData.labels}
                company={{ [Fields.FTEs]: sectorData.fte }}
                type={
                  viewMode === "companyRecomendations"
                    ? RecomendationType.Company
                    : RecomendationType.Bank
                }
              ></RecomendationSummary>
            </>
          </Card>
        </>
      )}
    </>
  );
};

const RecomendationSummary = ({
  checks,
  processed,
  statements,
  engine,
  header,
  className,
  db,
  labels,
  company,
  type = RecomendationType.Company,
}) => {
  const checksReadyToBePerformed =
    checks && checks.filter((check) => !check.benchmarkValue || (db && labels));
  const results =
    checksReadyToBePerformed &&
    runChecks(
      checksReadyToBePerformed,
      processed,
      statements,
      engine,
      db,
      labels,
      company
    );
  console.log({ results });
  const trueResults =
    results &&
    results.filter(
      (result) => result && result.values && result.values[0] === true
    );
  const recommendationDescriptions = trueResults && [
    ...new Set(
      trueResults
        .filter((result) => result.recommendations)
        .flatMap((result) =>
          result.recommendations
            .filter((r) => r.target === type)
            .map((r) => r.description)
        )
    ),
  ];

  return (
    <div
      className={[
        "bg-info bg-opacity-10 rounded px-2 py-2",
        className || "",
      ].join(" ")}
    >
      {header || <b>Alertas</b>}
      <div className="d-flex flex-column mt-2">
        {recommendationDescriptions &&
          recommendationDescriptions.length > 0 &&
          recommendationDescriptions.map((description, offset) => (
            <RecommendationItem key={offset} description={description} />
          ))}
        {recommendationDescriptions &&
          recommendationDescriptions.length === 0 && (
            <div className="text-muted">Sem recomendações relevantes</div>
          )}
      </div>
    </div>
  );
};

const RecommendationItem = ({ description }) => (
  <div className="d-flex flex-row mb-2">
    <div className="me-2">
      <i className="bi bi-info-square-fill text-primary"></i>
    </div>
    <div>{description} </div>
  </div>
);

const RecomendationSummaryWithDisclosable = ({
  checks,
  processed,
  statements,
  engine,
  header,
  className,
  db,
  labels,
  company,
  type = RecomendationType.Company,
}) => {
  const checksReadyToBePerformed =
    checks && checks.filter((check) => !check.benchmarkValue || (db && labels));
  const results =
    checksReadyToBePerformed &&
    runChecks(
      checksReadyToBePerformed,
      processed,
      statements,
      engine,
      db,
      labels,
      company
    );
  console.log({ results });
  const trueResults =
    results &&
    results.filter(
      (result) => result && result.values && result.values[0] === true
    );
  const applicableRecommendations = trueResults
    .filter((result) => result.recommendations)
    .flatMap((result) =>
      result.recommendations
        .filter((r) => r.target === type)
        .map((r) => r.description)
    );
  const recommendationDescriptions = trueResults && [
    ...new Set(applicableRecommendations),
  ];
  const recommendationToAlertMap =
    trueResults &&
    recommendationDescriptions.map((desc) =>
      trueResults
        .filter((res) =>
          res.recommendations.map((_r) => _r.description).includes(desc)
        )
        .map((result) => result.description)
    );

  return (
    <div
      className={[
        "bg-info bg-opacity-10 rounded px-2 py-2",
        className || "",
      ].join(" ")}
    >
      {header || <b>Alertas</b>}
      <div className="d-flex flex-column mt-2">
        {recommendationDescriptions &&
          recommendationDescriptions.length > 0 &&
          recommendationDescriptions.map((description, offset) => (
            <RecommendationItemWithDisclosable
              key={offset}
              description={description}
              checkDescriptions={recommendationToAlertMap[offset]}
            />
          ))}
        {recommendationDescriptions &&
          recommendationDescriptions.length === 0 && (
            <div className="text-muted">Sem recomendações relevantes</div>
          )}
      </div>
    </div>
  );
};

const RecommendationItemWithDisclosable = ({
  description,
  checkDescriptions,
}) => {
  const [dislosed, setDisclosed] = useState(true);

  return (
    <div className="d-flex flex-column mb-3">
      <div className="d-flex flex-row mb-1">
        <div className="me-2">
          <i className="bi bi-info-square-fill text-primary"></i>
        </div>
        <div>{description} </div>
      </div>
      <div>
        {checkDescriptions &&
          checkDescriptions.map((desc) => (
            <div className="ms-4">
              <i className="bi bi-exclamation-triangle-fill text-danger me-2"></i>
              {desc}
            </div>
          ))}
      </div>
    </div>
  );
};
