import React from "react";
import { Card } from "./UI";
import {
  absoluteGrowth,
  costAbsoluteGrowth,
  costRelativeGrowth,
  Divide,
  invert,
  relativeGrowth,
} from "./math";

export const ExecutiveSummaryTab = ({ statements, engine }) => {
  const _statements = statements.slice(0, 2);

  const revenue = engine.calcEuro(
    engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered,
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const revenueDelta = engine.calcEuro(
    absoluteGrowth(engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered),
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const revenueGrowth = engine.calcPercentage(
    relativeGrowth(engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered),
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const grossProfit = engine.calcEuro(
    engine.Ratios.GrossProfit.formula,
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const grossProfitDelta = engine.calcEuro(
    absoluteGrowth(engine.Ratios.GrossProfit.formula),
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const grossProfitGrowth = engine.calcEuro(
    absoluteGrowth(engine.Ratios.GrossProfit.formula),
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const grossMargin = engine.calcPercentage(
    engine.Ratios.GrossMargin.formula,
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const grossMarginDelta = engine.calcPercentage(
    absoluteGrowth(engine.Ratios.GrossMargin.formula),
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );

  const fse = engine.calcEuro(
    invert(engine.StatementTree.ProfitAndLoss.ExternalServices),
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const fseDelta = engine.calcEuro(
    costAbsoluteGrowth(engine.StatementTree.ProfitAndLoss.ExternalServices),
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const fseGrowth = engine.calcPercentage(
    costRelativeGrowth(engine.StatementTree.ProfitAndLoss.ExternalServices),
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const fseOfRevenue = engine.calcPercentage(
    new Divide(
      invert(engine.StatementTree.ProfitAndLoss.ExternalServices),
      engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
    ),
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const fseOfRevenueDelta = engine.calcPercentage(
    new Divide(
      invert(engine.StatementTree.ProfitAndLoss.ExternalServices),
      engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
    ),
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const personnel = engine.calcEuro(
    invert(engine.StatementTree.ProfitAndLoss.Personnel),
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const personnelDelta = engine.calcEuro(
    costAbsoluteGrowth(engine.StatementTree.ProfitAndLoss.Personnel),
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const personnelGrowth = engine.calcPercentage(
    costRelativeGrowth(engine.StatementTree.ProfitAndLoss.Personnel),
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const personnelOfRevenue = engine.calcPercentage(
    new Divide(
      invert(engine.StatementTree.ProfitAndLoss.Personnel),
      engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
    ),
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );
  const personnelOfRevenueDelta = engine.calcPercentage(
    new Divide(
      invert(engine.StatementTree.ProfitAndLoss.Personnel),
      engine.StatementTree.ProfitAndLoss.SalesAndServicesRendered
    ),
    _statements[0].report,
    _statements[1] ? _statements[1].report : statements[0].previousReport
  );

  return (
    <Card className="my-2">
      <p>
        O <b>Volume de Negócios</b> (VN) é de {revenue}, uma variação de{" "}
        {revenueDelta} ({revenueGrowth}).
      </p>
      <p>
        A <b>Margem Bruta</b> é de {grossProfit} ({grossMargin} do VN), uma
        variação de {grossProfitDelta} ({grossProfitGrowth}) ou{" "}
        {grossMarginDelta}.
      </p>

      <p>
        O custo com <b>Fornecimentos de Serviços Externos</b> é de {fse} (
        {fseOfRevenue} dos VN), uma variação de {fseDelta} ({fseGrowth}) e de{" "}
        {fseOfRevenueDelta} do VN.
      </p>
      <p>
        O custo com <b>Gastos com Pessoal</b> é de {personnel} (
        {personnelOfRevenue} do VN), uma variação de {personnelDelta} (
        {personnelGrowth}) e de {personnelOfRevenueDelta} do VN.
      </p>
    </Card>
  );
};
