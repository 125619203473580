import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  Badge,
  Spinner,
  CopyToClipBoard,
  Scrollable,
  LoadingScreen,
  Select,
} from "./UI";
import { LabelValueListTable, LabelValueListTable2, monthLabelsForYear } from "./Table";
import { asEuro } from "./euro";
import { runChecks } from "./check-engine";
import { sectionChecksForEngine } from "./check-defs";
import ClipboardJS from "clipboard";
import {
  DateField,
  ExternalServiceAccounts,
  groupByLabels,
  isNotFullYear,
} from "./saft";
import { WorkerSuspense } from "./Worker";
import { BalanceAnalysisTab } from "./BalanceAnalysis";

export const StatementsTab = ({
  isAdmin,
  anonymous,
  years,
  balanceSheetYears,
  processed,
  statements,
  // engine,
  engines,
  refEngine,
}) => {
  new ClipboardJS(".bi-clipboard");
  const [dateField, setDateField] = useState(DateField.TransactionDate);
  const [selectedYear, setSelectedYear] = useState(years && years[0]);
  const [selectedSubAccountPrefix, setSelectedSubAccountPrefix] = useState(ExternalServiceAccounts[0].prefix);
  const selectedYearIndex = years.indexOf(selectedYear);
  const selectedProcessed = processed && processed[selectedYearIndex];
  const selectedStatement = statements && statements[selectedYearIndex];
  const selectedEngine = engines && engines[selectedYearIndex];
  const firstEngine = engines && engines[0];
  const isPartialArray = processed.map((p, o) => ({ partial: isNotFullYear(p) || statementHasNoTaxOrNetIncome(statements[o], engines[o]) }));
  const sectionCheckArgs = [selectedProcessed, selectedStatement, selectedEngine];
  const extendedArgs = [selectedEngine, refEngine, isPartialArray, statements, engines];
  const shortArgs = [processed, dateField];
  const shortYearArgs = [processed, years];
  const standardArgs = [processed, statements, engines];

  const yearLabels = years.map((_year, offset) => (
    <span>
      {_year}
      {isNotFullYear(processed[offset]) ? (
        <sup>{monthAcronym(new Date(processed[offset].header.endDate))}</sup>
      ) : (
        ""
      )}
    </span>
  ));

  const balanceSheetYearLabels = balanceSheetYears.map((_year, offset) => (
    <span>
      {_year}
      {isNotFullYear(
        processed[offset === balanceSheetYears.length - 1 ? offset - 1 : offset]
      ) ? (
        <sup>
          {monthAcronym(
            new Date(
              processed[offset === balanceSheetYears.length - 1 ? offset - 1 : offset].header.endDate
            )
          )}
        </sup>
      ) : (
        ""
      )}
    </span>
  ));


  const hiddenRowForData = ({ values, label, formula }) => !firstEngine.isUncollapsable(formula) &&
    (values || []).every((_val) => !_val || _val === "€ 0.00");

  return (
    <WorkerSuspense
      fallback={<LoadingScreen />}
      workerPath="Statements.worker.js"
      workerFn="sectionChecks"
      workerArgs={sectionCheckArgs}
    >
      {(sectionChecks) => <><Card className="my-2">
        <h3>Demonstração de resultados</h3>
        <CheckListSummary
          checks={sectionChecks.profitAndLossStatement}
          processed={processed}
          statements={statements}
          engine={selectedEngine}
          years={years}
          selectedYear={selectedYear}
          onYearChange={setSelectedYear}
        ></CheckListSummary>
        <WorkerSuspense
          fallback={<LoadingScreen />}
          workerPath="Statements.worker.js"
          workerFn="pnl"
          workerArgs={extendedArgs}
        >
          {(pnl) => <Scrollable>
            <LabelValueListTable
              id="dr"
              className="small"
              headers={[
                <CopyToClipBoard target="#dr"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={pnl}
              totals={pnl.map((i) => i.total)}
              hideRowsFn={hiddenRowForData}
            ></LabelValueListTable>
          </Scrollable>}
        </WorkerSuspense>
      </Card>


        <Card className="my-2">
          <CheckListSummary
            checks={sectionChecks.balanceSheetStatement}
            processed={processed}
            statements={statements}
            engine={selectedEngine}
            years={years}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
          ></CheckListSummary>
          <h3>Balanço</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="bs"
            workerArgs={extendedArgs}
          >
            {(bs) => <Scrollable>
              <LabelValueListTable
                id="bs"
                className="small"
                headers={[
                  <CopyToClipBoard target="#bs"></CopyToClipBoard>,
                  ...balanceSheetYearLabels,
                ]}
                data={bs}
                sliceEnd={bs && bs.length}
                totals={bs.map((i) => i.total)}
                hideRowsFn={hiddenRowForData}
              ></LabelValueListTable>
            </Scrollable>}
          </WorkerSuspense>
        </Card>


        <Card className="my-2">
          <h3>Detalhe de Volume de Negócios</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="revenueByNature"
            workerArgs={shortArgs}
          >
            {(revenueByNature) => <LabelValueListTable
              id="revenueDetails"
              className="small"
              headers={[
                <CopyToClipBoard target="#revenueDetails"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={groupByLabels(revenueByNature, "label", "total")}
            ></LabelValueListTable>}
          </WorkerSuspense>
        </Card>

        <Card className="my-2">
          <h3>Volume de Negócios por cliente</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="customerRanking"
            workerArgs={shortYearArgs}
          >
            {(customerRanking) => <LabelValueListTable2
              id="customerDetails"
              anonymous={anonymous}
              className="small"
              headers={[
                <CopyToClipBoard target="#customerDetails"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={customerRanking}
            ></LabelValueListTable2>}
          </WorkerSuspense>
        </Card>

        <Card className="my-2">
          <h3>Compras por fornecedor</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="supplierPurchasesRanking"
            workerArgs={shortYearArgs}
          >
            {(supplierPurchasesRanking) => <LabelValueListTable
              id="purchaseDetails"
              className="small"
              anonymous={anonymous}
              headers={[
                <CopyToClipBoard target="#purchaseDetails"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={supplierPurchasesRanking}
            ></LabelValueListTable>}
          </WorkerSuspense>
        </Card>

        <Card className="my-2">
          <h3>FSE por fornecedor</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="supplierServicesRanking"
            workerArgs={shortYearArgs}
          >
            {(supplierServicesRanking) => <LabelValueListTable2
              id="serviceDetails"
              anonymous={anonymous}
              className="small"
              headers={[
                <CopyToClipBoard target="#serviceDetails"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={supplierServicesRanking}
              hideRowsFn={hiddenRowForData}
            ></LabelValueListTable2>}
          </WorkerSuspense>
        </Card>

        <Card className="my-2">
          <h3>FSE por naturezas</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="externalServiceByNature"
            workerArgs={shortArgs}
          >
            {(externalServiceByNature) => <LabelValueListTable
              id="fseDetails"
              className="small"
              headers={[
                <CopyToClipBoard target="#fseDetails"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={groupByLabels(
                externalServiceByNature,
                "label",
                "total"
              )}
            ></LabelValueListTable>}
          </WorkerSuspense>
        </Card>

        {isAdmin && <Card className="my-2">
          <div className="float-end">
            <Select
              options={ExternalServiceAccounts.map(({ prefix, label }) => ({ value: prefix, label }))}
              selected={selectedSubAccountPrefix}
              onChange={setSelectedSubAccountPrefix}
            ></Select>
          </div>
          <h3>FSE por fornecedor <small className="text-muted small">por subcontas</small></h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="supplierServicesRankingForSubAccountPrefix"
            workerArgs={[...shortYearArgs, selectedSubAccountPrefix]}
            reloadable={true}
          >
            {(supplierServicesSubAccountRanking) => <LabelValueListTable2
              id="serviceSubAccountDetails"
              anonymous={anonymous}
              className="small"
              headers={[
                <CopyToClipBoard target="#serviceSubAccountDetails"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={supplierServicesSubAccountRanking}
              hideRowsFn={hiddenRowForData}
            ></LabelValueListTable2>}
          </WorkerSuspense>
        </Card>}

        <Card className="my-2">
          <h3>Detalhe de Gastos com Pessoal</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="personnelCostBySubAccounts"
            workerArgs={shortArgs}
          >
            {(personnelCostBySubAccounts) => <LabelValueListTable
              id="personnelDetails"
              className="small"
              headers={[
                <CopyToClipBoard target="#personnelDetails"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={groupByLabels(
                personnelCostBySubAccounts,
                "label",
                "total"
              )}
            ></LabelValueListTable>}
          </WorkerSuspense>
        </Card>

        <Card className="my-2">
          <h3>Detalhe de Depreciações</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="depreciationCostBySubAccounts"
            workerArgs={shortArgs}
          >
            {(depreciationCostBySubAccounts) => <LabelValueListTable
              id="depreciationDetails"
              className="small"
              headers={[
                <CopyToClipBoard target="#depreciationDetails"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={groupByLabels(
                depreciationCostBySubAccounts,
                "label",
                "total"
              )}
            ></LabelValueListTable>}
          </WorkerSuspense>
        </Card>

        <Card className="my-2">
          <h3>Detalhe de Outros Gastos</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="depreciationCostBySubAccounts"
            workerArgs={shortArgs}
          >
            {(otherCostBySubAccounts) => <LabelValueListTable
              id="otherCostDetails"
              className="small"
              headers={[
                <CopyToClipBoard target="#otherCostDetails"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={groupByLabels(
                otherCostBySubAccounts,
                "label",
                "total"
              )}
            ></LabelValueListTable>}
          </WorkerSuspense>
        </Card>

        <Card className="my-2">
          <h3>Financiamentos Obtidos</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="financingDetails"
            workerArgs={extendedArgs}
          >
            {(financingDetails) => <LabelValueListTable
              id="financingDetails"
              className="small"
              headers={[
                <CopyToClipBoard target="#financingDetails"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={financingDetails}
              totals={financingDetails.map((i) => i.total)}
              hideRowsFn={hiddenRowForData}
            ></LabelValueListTable>}
          </WorkerSuspense>
        </Card>

        <Card className="my-2">
          <h3>Detalhe de Custos de Financiamento</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="financingCostsBySubAccounts"
            workerArgs={shortArgs}
          >
            {(financingCostsBySubAccounts) => <LabelValueListTable
              id="financingCostDetails"
              className="small"
              headers={[
                <CopyToClipBoard target="#financingCostDetails"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={groupByLabels(
                financingCostsBySubAccounts,
                "label",
                "total"
              )}
            ></LabelValueListTable>}
          </WorkerSuspense>
        </Card>

        {isAdmin && <Card className="my-2">
          <h3>Outras contas a receber <small className="text-muted"> correntes e não correntes</small></h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="otherReceivables"
            workerArgs={standardArgs}
          >
            {(otherReceivables) => <LabelValueListTable
              id="otherReceivables"
              className="small"
              headers={[
                <CopyToClipBoard target="#otherReceivables"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={otherReceivables}
            ></LabelValueListTable>}
          </WorkerSuspense>
        </Card>}

        {isAdmin && <Card className="my-2">
          <h3>Outras contas a pagar <small className="text-muted"> correntes e não correntes</small></h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="otherPayables"
            workerArgs={standardArgs}
          >
            {(otherPayables) => <LabelValueListTable
              id="otherPayables"
              className="small"
              headers={[
                <CopyToClipBoard target="#otherReceivables"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={otherPayables}
            ></LabelValueListTable>}
          </WorkerSuspense>
        </Card>}

        {isAdmin && <Card className="my-2">
          <h3>Saldos de Clientes</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="clients"
            workerArgs={standardArgs}
          >
            {(clients) => <LabelValueListTable2
              id="clients"
              className="small"
              headers={[
                <CopyToClipBoard target="#clients"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={clients}
            ></LabelValueListTable2>}
          </WorkerSuspense>
        </Card>}

        {isAdmin && <Card className="my-2">
          <h3>Saldos de Fornecedores</h3>
          <WorkerSuspense
            fallback={<LoadingScreen />}
            workerPath="Statements.worker.js"
            workerFn="providers"
            workerArgs={standardArgs}
          >
            {(providers) => <LabelValueListTable2
              id="providers"
              className="small"
              headers={[
                <CopyToClipBoard target="#providers"></CopyToClipBoard>,
                ...yearLabels,
              ]}
              data={providers}
            ></LabelValueListTable2>}
          </WorkerSuspense>
        </Card>}

        {isAdmin && <BalanceAnalysisTab
          engines={engines}
          years={years}
          processed={processed}
        ></BalanceAnalysisTab>}
      </>}
    </WorkerSuspense >
  )
};

const CheckListSummary = ({
  checks,
  processed,
  statements,
  engine,
  years,
  selectedYear,
  onYearChange,
}) => {
  const results = checks && runChecks(checks, processed, statements, engine);
  console.log({ results });
  const trueResults = results.filter(
    (result) => result && result.values && result.values[0] === true
  );
  return (
    <div className="bg-warning bg-opacity-25 rounded px-2 py-1">
      <div className="float-end">
        <Select
          options={years}
          selected={selectedYear}
          onChange={onYearChange}
        ></Select>
      </div>
      <b>Alertas</b>
      <div className="d-flex flex-column">
        {results &&
          trueResults.length > 0 &&
          trueResults.map((result, offset) => (
            <CheckResult key={offset} description={result.description} />
          ))}
        {results && trueResults.length === 0 && (
          <div className="text-muted">Sem alertas relevantes</div>
        )}
      </div>
    </div>
  );
};

const CheckResult = ({ description }) => (
  <div>
    <i className="bi bi-exclamation-triangle-fill text-danger me-2"></i>
    {description}
  </div>
);

const monthAcronym = (date) => {
  const month = date && date.getMonth() + 1;
  const Acronynms = {
    undefined: "",
    1: "Jan",
    2: "Fev",
    3: "Mar",
    4: "Abr",
    5: "Mai",
    6: "Jun",
    7: "Jul",
    8: "Ago",
    9: "Set",
    10: "Out",
    11: "Nov",
    12: "Dec",
  };
  return Acronynms[month];
};

const statementHasNoTaxOrNetIncome = (statement, engine) => {
  const symbols = [engine.StatementTree.ProfitAndLoss.Taxes, engine.StatementTree.Capital.NetIncome];
  const noTaxOrNetIncome = symbols.some(symbol => symbolHasNoSummaries(statement[symbol]));
  return noTaxOrNetIncome
}

const symbolHasNoSummaries = (symbol) => Object.keys(symbol || {}).filter(key => key && !key.includes('__summary')).length === 0;