import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";

export const FileDrop = ({ onFilesDrop, loading, loaded, loadingScreen }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onFilesDrop(acceptedFiles);
    },
    [onFilesDrop]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="w-100">
      <div className="card" {...getRootProps()}>
        <div className="card-body text-center">
          <input {...getInputProps()} />

          {loading && !loadingScreen && (
            <p>
              <span className="alert alert-info">A carregar ...</span>
            </p>
          )}
          {loading && loadingScreen}
          {loaded && (
            <p>
              <span className="alert alert-success">Acabou de carregar.</span>
            </p>
          )}
          {isDragActive ? (
            <p>Arrastar os ficheiros para aqui ...</p>
          ) : (
            !loading && (
              <>
                <p>
                  <b>Arraste</b> os ficheiros para aqui para <b>carregar</b>
                </p>
                <p>
                  ou <b>clique aqui</b> para seleccionar ficheiros.
                </p>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Reads texts files and processes after reading if a processing function is provided
 * @param {function} processFn
 * @returns
 */
export const useTextFiles = (processFn = () => { }, filterFn = (i) => true) => {
  const [files, setFiles] = useState();
  const [contents, setContents] = useState();
  const [processed, setProcessed] = useState();
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [removing, setRemoving] = useState(false);

  useEffect(() => {
    if (!files) return;
    if (files.length === 0) {
      setLoading(false);
      setLoaded(false);
      setFiles(undefined);
      setContents(undefined);
      setProcessed(undefined);
      return;
    }
    setLoading(true);
    setLoaded(false);
    // var _status = status;
    // const _setStatus = (offset, phase, state) => {
    //   _status = { ..._status, [offset]: { [phase]: state } };
    //   setStatus(_status);
    // };
    if (files) {
      readFilesSeq(
        files
        // (file, offset) => _setStatus(offset, "reading", "started"),
        // (file, offset) => _setStatus(offset, "reading", "ended")
      ).then(setContents);
    }
  }, [files]);

  useEffect(() => {
    if (!contents) return;
    if (contents.length === 0) return;
    // var _status = status;
    // const _setStatus = (offset, phase, state) => {
    //   _status = { ..._status, [offset]: { [phase]: state } };
    //   setStatus(_status);
    // };
    if (contents && processFn) {
      processContentsSeq(
        contents,
        processFn,
        undefined, // (file, offset) => _setStatus(offset, "processing", "started"),
        undefined, // (file, offset) => _setStatus(offset, "processing", "ended"),
        filterFn
      )
        .then(setProcessed)
        .then((_) => {
          setLoading(false);
          setLoaded(true);
          setRemoving(false);
        });
    }
  }, [contents]);



  const removeFileAtOffset = (offset) => {
    setRemoving(true);
    setFiles(files.filter((f, i) => i !== offset));
  };

  return {
    setFiles,
    files,
    removeFileAtOffset,
    contents,
    processed,
    status,
    loading,
    loaded,
    removing,
  };
};

/**
 * Reads texts files and processes after reading if a processing function is provided
 * @param {function} processFn
 * @returns
 */
export const useTextFilesFast = (processFn = () => { }, filterFn = (i) => true) => {
  const [files, setFiles] = useState();
  const [processed, setProcessed] = useState();
  const [removing, setRemoving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!files) {
      setProcessed(undefined);
      return
    };
    if (files.length === 0) {
      setLoading(false);
      setLoaded(false);
      setFiles(undefined);
      setProcessed(undefined);
      return;
    }
    setLoading(true);
    setLoaded(false);
    readAndProcessFiles(files, processFn, filterFn).then(setProcessed);
  }, [files])


  useEffect(() => {
    if (loading && processed) {
      setLoading(false)
      setLoaded(true)
    }
  }, [processed, loading])

  useEffect(() => { console.log(processed) }, [processed]);

  const removeFileAtOffset = (offset) => {
    setRemoving(true);
    setFiles(files.filter((f, i) => i !== offset));
  };

  return {
    setFiles,
    files,
    removeFileAtOffset,
    processed,
    loading,
    loaded,
    removing,
  };
};
/**
 * Reads texts files and processes after reading if a processing function is provided
 * @param {function} processFn
 * @returns
 */
export const useTextFilesWorker2 = (workerPath, workerFn, filterFn = (i) => true) => {
  const { results, processArgs, isError } = useWorkerArray(workerPath, workerFn, filterFn);
  // const [processed, setProcessed] = useState();
  // const [removing, setRemoving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const loadFiles = (files) => {
    if (!files) {
      processArgs(undefined);
      return
    };
    if (files.length === 0) {
      setLoading(false);
      setLoaded(false);
      processArgs(undefined);
      return;
    }
    setLoading(true);
    setLoaded(false);
    processArgs(files)
  }

  useEffect(() => {
    if (loading && results) {
      setLoading(false)
      setLoaded(true)
    }
  }, [results, loading])

  useEffect(() => { console.log({ results }) }, [results]);


  return {
    loadFiles,
    results,
    isError,
    loading,
    loaded,
  };
};

/**
 * Reads texts files and processes after reading if a processing function is provided
 * @param {function} processFn
 * @returns
 */
export const useTextFilesWorker = (workerPath, workerFn, filterFn = (i) => true) => {
  const { results, processArgs } = useWorkerArray(workerPath, workerFn, filterFn);
  const [files, setFiles] = useState();
  // const [processed, setProcessed] = useState();
  const [removing, setRemoving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!files) {
      processArgs(undefined);
      return
    };
    if (files.length === 0) {
      setLoading(false);
      setLoaded(false);
      setFiles(undefined);
      processArgs(undefined);
      return;
    }
    setLoading(true);
    setLoaded(false);
    processArgs(files)
  }, [files])


  useEffect(() => {
    if (loading && results) {
      setLoading(false)
      setLoaded(true)
    }
  }, [results, loading])

  useEffect(() => { console.log(results) }, [results]);


  const removeFileAtOffset = (offset) => {
    setRemoving(true);
    setFiles(files.filter((f, i) => i !== offset));
  };

  return {
    setFiles,
    files,
    removeFileAtOffset,
    results,
    loading,
    loaded,
    removing,
  };
};

const readSAFT = (file) => readAsText(file, "Windows-1252");

const readAsText = (file, encoding) => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = function () {
      resolve(fr.result);
    };
    fr.readAsText(file, encoding);
  });
};

const readFilesSeq = (files, beforeEachFn, afterEachFn) =>
  processItemsSeq(files, readSAFT, beforeEachFn, afterEachFn);

const processContentsSeq = (
  contents,
  processFn,
  beforeEachFn,
  afterEachFn,
  filterFn
) => processItemsSeq(contents, processFn, beforeEachFn, afterEachFn, filterFn);

const processItemsSeq = async (
  items,
  processFn,
  beforeEachFn,
  afterEachFn,
  filterFn = (i) => true
) => {
  // const tasks = items.map((item, _offset) =>
  //   Promise.resolve(processFn(item, _offset))
  // );
  var results = [];
  var offset = 0;
  for (const item of items) {
    beforeEachFn && beforeEachFn(items[offset], offset, items);
    console.log("started item " + offset);
    const result = await Promise.resolve(processFn(item, offset));
    // console.log("processItemSeq", { item, result });
    results.push(result);
    console.log("ended item " + offset);
    afterEachFn && afterEachFn(items[offset], offset, items);
    offset = offset + 1;
  }
  const filteredResults = filterFn ? results.filter(filterFn) : results;
  return filteredResults;
};
async function readAndProcessFiles(files, processFn, filterFn) {
  return files && files.length > 0 ? await readFilesSeq(
    files
  ).then(contents => processContentsSeq(
    contents,
    processFn,
    undefined,
    undefined,
    filterFn
  )) : undefined;
}


export const useWorkerArray = (workerPath, workerFn, filterFn) => {
  const [isError, setError] = useState();
  const [results, setResults] = useState(undefined);

  const createWorker = createWorkerFactory(() => import('./' + workerPath));
  const worker = useWorker(createWorker);

  const filterResults = (array) => array.filter(filterFn);
  const checkResults = (reference, actual) => {
    const errorExists = reference && actual && actual.length < reference.length
    console.log({ errorExists }, actual.length, reference.length)
    setError(errorExists);
    return actual;
  }

  const processArgs = (...args) => {
    setError(false);
    if (args === undefined) {
      setResults(undefined);
    } else if (args[0] === undefined) {
      setResults(undefined);
    } else {
      const array = args[0];
      const otherArgs = array.slice(1);
      const workers = array.map((item, offset) => {
        return worker[workerFn](item, offset, ...otherArgs).then((result) => {
          console.log("finished", item);
          return result;
        });
      });

      console.log({ workers });

      Promise.all(workers)
        .then(filterResults)
        .then((actual) => checkResults(workers, actual))
        .then(setResults)
        .then((_) => console.log("finished"));
    }
  }

  return { results, processArgs, isError };
};