import {
  Add,
  Divide,
  Multiply,
  Previous,
  Subtract,
  Within,
  Ref,
  EqualTo,
  GreaterThan,
  LessThan,
  Not,
  And,
  Or,
  If,
  StringEqualTo,
  PreviousWithin,
} from "./math";
import { asEuro, asPreciseEuro } from "./euro";
import { isPnLSymbol, mapSNCtoMicro } from "./ratios-defs-mapping";

export const PreviousBalanceType = {
  Closing: Symbol("closingBalance"),
  Opening: Symbol("openingBalance"),
};

export const calcMultiEngineConfigurableRatioListMultiYear = (
  configurableRatioListKey,
  engines,
  refEngine,
  configs,
  _statements,
  sourceMaps,
  descending = true,
  isBalanceSheet = false
) => {
  const uniqueEngineCount = [...new Set(engines)].length;
  const thereAreMultipleEngines = uniqueEngineCount > 1;

  const configuredRatioLists = configs.map((config, offset) => {
    const engine = engines[offset];
    const _key =
      configurableRatioListKey +
      (thereAreMultipleEngines && engine !== refEngine ? "Expanded" : "");
    const configurableRatioListFn =
      engine.Ratios[_key] || engine.Ratios[configurableRatioListKey];
    return configurableRatioListFn(config);
  });

  console.log({
    configuredRatioLists,
  });

  const configuredRatiosForYears = configuredRatioLists[0].map(
    (_, ratioOffset) =>
      configuredRatioLists.map((ratioList) => ratioList[ratioOffset])
  );

  // console.log({ configuredRatiosForYears });

  return configuredRatiosForYears.map((rArray) => {
    const refRatio = rArray[0];
    return {
      ...refRatio,
      values: _statements.flatMap((_statement, offset) =>
        descending
          ? offset < _statements.length - 1
            ? calcRatio(
              rArray[offset],
              _statement.report,
              _statement.previousReport,
              _statements[offset + 1].report,
              PreviousBalanceType.Opening,
              sourceMaps,
              PreviousBalanceType.Closing
            )
            : !isBalanceSheet
              ? calcRatio(
                rArray[offset],
                _statement.report,
                _statement.previousReport,
                undefined,
                PreviousBalanceType.Opening,
                sourceMaps,
                PreviousBalanceType.Closing
              )
              : [
                // isBalanceSheet === true
                calcRatio(
                  rArray[offset],
                  _statement.report,
                  _statement.previousReport,
                  undefined,
                  PreviousBalanceType.Opening,
                  sourceMaps,
                  PreviousBalanceType.Closing
                ),
                calcRatio(
                  rArray[offset],
                  _statement.previousReport,
                  _statement.previousReport,
                  undefined,
                  PreviousBalanceType.Opening,
                  sourceMaps,
                  PreviousBalanceType.Opening
                ),
              ]
          : offset === 0
            ? !isBalanceSheet
              ? calcRatio(
                rArray[offset],
                _statement.report,
                _statement.previousReport,
                undefined,
                PreviousBalanceType.Opening,
                sourceMaps,
                PreviousBalanceType.Closing
              )
              : [
                // isBalanceSheet === true
                calcRatio(
                  rArray[offset],
                  _statement.previousReport,
                  _statement.previousReport,
                  undefined,
                  PreviousBalanceType.Opening,
                  sourceMaps,
                  PreviousBalanceType.Opening
                ),
                calcRatio(
                  rArray[offset],
                  _statement.report,
                  _statement.previousReport,
                  undefined,
                  PreviousBalanceType.Opening,
                  sourceMaps,
                  PreviousBalanceType.Closing
                ),
              ]
            : calcRatio(
              rArray[offset],
              _statement.report,
              _statement.previousReport,
              _statements[offset - 1].report,
              PreviousBalanceType.Opening,
              sourceMaps,
              PreviousBalanceType.Closing
            )
      ),
      rawValues: _statements.flatMap((_statement, offset) =>
        descending
          ? offset < _statements.length - 1
            ? calcRawRatio(
              rArray[offset],
              _statement.report,
              _statement.previousReport,
              _statements[offset + 1].report,
              PreviousBalanceType.Opening,
              sourceMaps,
              PreviousBalanceType.Closing
            )
            : !isBalanceSheet
              ? calcRawRatio(
                rArray[offset],
                _statement.report,
                _statement.previousReport,
                undefined,
                PreviousBalanceType.Opening,
                sourceMaps,
                PreviousBalanceType.Closing
              )
              : [
                // isBalanceSheet === true
                calcRawRatio(
                  rArray[offset],
                  _statement.report,
                  _statement.previousReport,
                  undefined,
                  PreviousBalanceType.Opening,
                  sourceMaps,
                  PreviousBalanceType.Closing
                ),
                calcRawRatio(
                  rArray[offset],
                  _statement.previousReport,
                  _statement.previousReport,
                  undefined,
                  PreviousBalanceType.Opening,
                  sourceMaps,
                  PreviousBalanceType.Opening
                ),
              ]
          : offset === 0
            ? !isBalanceSheet
              ? calcRawRatio(
                rArray[offset],
                _statement.report,
                _statement.previousReport,
                undefined,
                PreviousBalanceType.Opening,
                sourceMaps,
                PreviousBalanceType.Closing
              )
              : [
                // isBalanceSheet === true
                calcRawRatio(
                  rArray[offset],
                  _statement.previousReport,
                  _statement.previousReport,
                  undefined,
                  PreviousBalanceType.Opening,
                  sourceMaps,
                  PreviousBalanceType.Opening
                ),
                calcRawRatio(
                  rArray[offset],
                  _statement.report,
                  _statement.previousReport,
                  undefined,
                  PreviousBalanceType.Opening,
                  sourceMaps,
                  PreviousBalanceType.Closing
                ),
              ]
            : calcRawRatio(
              rArray[offset],
              _statement.report,
              _statement.previousReport,
              _statements[offset - 1].report,
              PreviousBalanceType.Opening,
              sourceMaps,
              PreviousBalanceType.Closing
            )
      ),
    };
  });
};

export const calcRatioListMultiYear = (
  ratios = [],
  _statements,
  sourceMaps,
  descending = true,
  isBalanceSheet = false
) =>
  ratios &&
  ratios.map((_ratio) =>
    calcRatioMultiYear(
      _ratio,
      _statements,
      sourceMaps,
      descending,
      isBalanceSheet
    )
  );

export const calcRatioMultiYear = (
  ratio,
  _statements,
  sourceMaps,
  descending = true,
  isBalanceSheet = false
) => ({
  ...ratio,
  values: _statements.flatMap((_statement, offset) =>
    descending
      ? offset < _statements.length - 1
        ? calcRatio(
          ratio,
          _statement.report,
          _statement.previousReport,
          _statements[offset + 1].report,
          PreviousBalanceType.Opening,
          sourceMaps,
          PreviousBalanceType.Closing
        )
        : !isBalanceSheet
          ? calcRatio(
            ratio,
            _statement.report,
            _statement.previousReport,
            undefined,
            PreviousBalanceType.Opening,
            sourceMaps,
            PreviousBalanceType.Closing
          )
          : [
            // isBalanceSheet === true
            calcRatio(
              ratio,
              _statement.report,
              _statement.previousReport,
              undefined,
              PreviousBalanceType.Opening,
              sourceMaps,
              PreviousBalanceType.Closing
            ),
            calcRatio(
              ratio,
              _statement.previousReport,
              _statement.previousReport,
              undefined,
              PreviousBalanceType.Opening,
              sourceMaps,
              PreviousBalanceType.Opening
            ),
          ]
      : offset === 0
        ? !isBalanceSheet
          ? calcRatio(
            ratio,
            _statement.report,
            _statement.previousReport,
            undefined,
            PreviousBalanceType.Opening,
            sourceMaps,
            PreviousBalanceType.Closing
          )
          : [
            // isBalanceSheet === true
            calcRatio(
              ratio,
              _statement.previousReport,
              _statement.previousReport,
              undefined,
              PreviousBalanceType.Opening,
              sourceMaps,
              PreviousBalanceType.Opening
            ),
            calcRatio(
              ratio,
              _statement.report,
              _statement.previousReport,
              undefined,
              PreviousBalanceType.Opening,
              sourceMaps,
              PreviousBalanceType.Closing
            ),
          ]
        : calcRatio(
          ratio,
          _statement.report,
          _statement.previousReport,
          _statements[offset - 1].report,
          PreviousBalanceType.Opening,
          sourceMaps,
          PreviousBalanceType.Closing
        )
  ),
  rawValues: _statements.flatMap((_statement, offset) =>
    descending
      ? offset < _statements.length - 1
        ? calcRawRatio(
          ratio,
          _statement.report,
          _statement.previousReport,
          _statements[offset + 1].report,
          PreviousBalanceType.Opening,
          sourceMaps,
          PreviousBalanceType.Closing
        )
        : !isBalanceSheet
          ? calcRawRatio(
            ratio,
            _statement.report,
            _statement.previousReport,
            undefined,
            PreviousBalanceType.Opening,
            sourceMaps,
            PreviousBalanceType.Closing
          )
          : [
            // isBalanceSheet === true
            calcRawRatio(
              ratio,
              _statement.report,
              _statement.previousReport,
              undefined,
              PreviousBalanceType.Opening,
              sourceMaps,
              PreviousBalanceType.Closing
            ),
            calcRawRatio(
              ratio,
              _statement.previousReport,
              _statement.previousReport,
              undefined,
              PreviousBalanceType.Opening,
              sourceMaps,
              PreviousBalanceType.Opening
            ),
          ]
      : offset === 0
        ? !isBalanceSheet
          ? calcRawRatio(
            ratio,
            _statement.report,
            _statement.previousReport,
            undefined,
            PreviousBalanceType.Opening,
            sourceMaps,
            PreviousBalanceType.Closing
          )
          : [
            // isBalanceSheet === true
            calcRawRatio(
              ratio,
              _statement.previousReport,
              _statement.previousReport,
              undefined,
              PreviousBalanceType.Opening,
              sourceMaps,
              PreviousBalanceType.Opening
            ),
            calcRawRatio(
              ratio,
              _statement.report,
              _statement.previousReport,
              undefined,
              PreviousBalanceType.Opening,
              sourceMaps,
              PreviousBalanceType.Closing
            ),
          ]
        : calcRawRatio(
          ratio,
          _statement.report,
          _statement.previousReport,
          _statements[offset - 1].report,
          PreviousBalanceType.Opening,
          sourceMaps,
          PreviousBalanceType.Closing
        )
  ),
});

export const calcRatioList = (
  ratios = [],
  symbols,
  previousSymbols,
  previousReportSymbols,
  previousBalanceType = PreviousBalanceType.Opening,
  sourceMaps
) =>
  ratios &&
  ratios.map((i) => ({
    ...i,
    value: calcRatio(
      i,
      symbols,
      previousSymbols,
      previousReportSymbols,
      previousBalanceType,
      sourceMaps
    ),
    rawValue: calcRawRatio(
      i,
      symbols,
      previousSymbols,
      previousReportSymbols,
      previousBalanceType,
      sourceMaps
    ),
  }));

/** Calculates ratio with formating */
export const calcRatio = (
  ratio,
  symbols,
  previousSymbols,
  previousReportSymbols,
  previousBalanceType = PreviousBalanceType.Closing,
  sourceMaps,
  currentBalanceType = PreviousBalanceType.Closing
) => {
  // console.log({ ratio });
  const result = metaCalc(
    ratio.formula,
    symbols,
    previousSymbols,
    previousReportSymbols,
    previousBalanceType,
    sourceMaps,
    currentBalanceType
  );
  if (ratio.format === "percentage") {
    return `${(asPreciseEuro(result).value * 100).toFixed(2)}%`;
  } else if (ratio.format === "days") {
    return `${Math.round(asEuro(result).value)} dias`;
  } else if (ratio.format === "x") {
    return `${asEuro(result).value.toFixed(1)} x`;
  } else {
    // console.log("non-ratio", ratio, sourceMaps);
    return asEuro(result).format();
  }
};

/** Formats unformatted ratio value */
export const formatValue = (rawValue, format) => {
  switch (format) {
    case "percentage":
      return `${(rawValue * 100).toFixed(2)} %`;
    case "days":
      return `${Math.round(rawValue)} dias`;
    case "x":
      return `${Math.round(rawValue)} x`;
    default:
      return asEuro(rawValue).format();
  }
};

/** Calculates ratio without formating */
export const calcRawRatio = (
  ratio,
  symbols,
  previousSymbols,
  previousReportSymbols,
  previousBalanceType = PreviousBalanceType.Closing,
  sourceMaps,
  currentBalanceType = PreviousBalanceType.Closing
) => {
  const result = metaCalc(
    ratio.formula,
    symbols,
    previousSymbols,
    previousReportSymbols,
    previousBalanceType,
    sourceMaps,
    currentBalanceType
  );
  if (ratio.format === "percentage") {
    return asPreciseEuro(result).value;
  } else if (ratio.format === "days") {
    return asEuro(result).value;
  } else if (ratio.format === "x") {
    return asEuro(result).value;
  } else {
    // console.log("raw", ratio, sourceMaps);
    return asEuro(result).value;
  }
};

export const calcPercentage = (
  formula,
  symbols,
  previousSymbols,
  previousReportSymbols,
  previousBalanceType = PreviousBalanceType.Closing,
  sourceMaps,
  currentBalanceType = PreviousBalanceType.Closing
) =>
  calcRatio(
    { formula, format: "percentage" },
    symbols,
    previousSymbols,
    previousReportSymbols,
    previousBalanceType,
    sourceMaps,
    currentBalanceType
  );

export const calcEuro = (
  formula,
  symbols,
  previousSymbols,
  previousReportSymbols,
  previousBalanceType = PreviousBalanceType.Closing,
  sourceMaps,
  currentBalanceType = PreviousBalanceType.Closing
) =>
  calcRatio(
    { formula },
    symbols,
    previousSymbols,
    previousReportSymbols,
    previousBalanceType,
    sourceMaps,
    currentBalanceType
  );

export const calcDays = (
  formula,
  symbols,
  previousSymbols,
  previousReportSymbols,
  previousBalanceType = PreviousBalanceType.Closing,
  sourceMaps,
  currentBalanceType = PreviousBalanceType.Closing
) =>
  calcRatio(
    { formula, format: "days" },
    symbols,
    previousSymbols,
    previousReportSymbols,
    previousBalanceType,
    sourceMaps,
    currentBalanceType
  );

export const calcX = (
  formula,
  symbols,
  previousSymbols,
  previousReportSymbols,
  previousBalanceType = PreviousBalanceType.Closing,
  sourceMaps,
  currentBalanceType = PreviousBalanceType.Closing
) =>
  calcRatio(
    { formula, format: "x" },
    symbols,
    previousSymbols,
    previousReportSymbols,
    previousBalanceType,
    sourceMaps,
    currentBalanceType
  );

export const metaCalc = (
  formula,
  symbols,
  previousSymbols,
  previousReportSymbols,
  previousBalanceType = PreviousBalanceType.Closing,
  sourceMap,
  currentBalanceType = PreviousBalanceType.Closing
) => {
  if (typeof exp === "boolean") {
    return formula;
  } else if (formula instanceof LessThan) {
    const _lhs = asPreciseEuro(
      metaCalc(
        formula.lhs,
        symbols,
        previousSymbols,
        previousReportSymbols,
        previousBalanceType,
        sourceMap,
        currentBalanceType
      )
    ).value;
    const _rhs = asPreciseEuro(
      metaCalc(
        formula.rhs,
        symbols,
        previousSymbols,
        previousReportSymbols,
        previousBalanceType,
        sourceMap,
        currentBalanceType
      )
    ).value;
    return _lhs < _rhs;
  } else if (formula instanceof GreaterThan) {
    const _lhs = asPreciseEuro(
      metaCalc(
        formula.lhs,
        symbols,
        previousSymbols,
        previousReportSymbols,
        previousBalanceType,
        sourceMap,
        currentBalanceType
      )
    ).value;
    const _rhs = asPreciseEuro(
      metaCalc(
        formula.rhs,
        symbols,
        previousSymbols,
        previousReportSymbols,
        previousBalanceType,
        sourceMap,
        currentBalanceType
      )
    ).value;
    // console.log(_lhs, ">", _rhs);
    return _lhs > _rhs;
  } else if (formula instanceof EqualTo) {
    const _lhs = asPreciseEuro(
      metaCalc(
        formula.lhs,
        symbols,
        previousSymbols,
        previousReportSymbols,
        previousBalanceType,
        sourceMap,
        currentBalanceType
      )
    ).value;
    const _rhs = asPreciseEuro(
      metaCalc(
        formula.rhs,
        symbols,
        previousSymbols,
        previousReportSymbols,
        previousBalanceType,
        sourceMap,
        currentBalanceType
      )
    ).value;
    // console.log(_lhs, "=", _rhs);
    return _lhs === _rhs;
  } else if (formula instanceof And) {
    return (
      metaCalc(
        formula.lhs,
        symbols,
        previousSymbols,
        previousReportSymbols,
        previousBalanceType,
        sourceMap,
        currentBalanceType
      ) &&
      metaCalc(
        formula.rhs,
        symbols,
        previousSymbols,
        previousReportSymbols,
        previousBalanceType,
        sourceMap,
        currentBalanceType
      )
    );
  } else if (formula instanceof Or) {
    return (
      metaCalc(
        formula.lhs,
        symbols,
        previousSymbols,
        previousReportSymbols,
        previousBalanceType,
        sourceMap,
        currentBalanceType
      ) ||
      metaCalc(
        formula.rhs,
        symbols,
        previousSymbols,
        previousReportSymbols,
        previousBalanceType,
        sourceMap,
        currentBalanceType
      )
    );
  } else if (formula instanceof Not) {
    return !metaCalc(
      formula.arg,
      symbols,
      previousSymbols,
      previousReportSymbols,
      previousBalanceType,
      sourceMap,
      currentBalanceType
    );
  } else if (formula instanceof If) {
    const conditionIsTrue = metaCalc(
      formula.condition,
      symbols,
      previousSymbols,
      previousReportSymbols,
      previousBalanceType,
      sourceMap,
      currentBalanceType
    );
    const branch = conditionIsTrue ? formula.ifTrue : formula.ifFalse;
    const result = metaCalc(
      branch,
      symbols,
      previousSymbols,
      previousReportSymbols,
      previousBalanceType,
      sourceMap,
      currentBalanceType
    );
    return result;
  } else if (formula instanceof StringEqualTo) {
    const _lhs = metaCalc(
      formula.condition,
      symbols,
      previousSymbols,
      previousReportSymbols,
      previousBalanceType,
      sourceMap,
      currentBalanceType
    );
    const _rhs = metaCalc(
      formula.condition,
      symbols,
      previousSymbols,
      previousReportSymbols,
      previousBalanceType,
      sourceMap,
      currentBalanceType
    );
    return _lhs === _rhs;
  } else if (typeof formula === "string") {
    return formula;
  } else if (typeof formula === "number") {
    return formula;
  } else if (formula instanceof Ref) {
    if (formula.source === "bdp" && sourceMap && sourceMap.labels) {
      const fieldIndex = sourceMap.labels.indexOf(formula.key);
      const value = sourceMap[formula.source].filter(
        (item) => item && item.l === fieldIndex
      )[0];
      return (value && value.v) || 0;
    } else if (formula.source === "input") {
      // console.log(">>>>", formula, sourceMap);
      const value =
        sourceMap &&
        sourceMap[formula.source] &&
        sourceMap[formula.source][formula.key];
      return value;
    } else {
      console.warn(
        `Unknown <<<SOURCE>>>: '${formula.source}' with key '${formula.key}'`
      );
      return undefined;
    }
  } else if (typeof formula === "symbol") {
    if (!symbols[formula])
      console.warn(`${formula.description} is missing in 'current' report`);
    return (
      (symbols[formula] &&
        symbols[formula].__summary[currentBalanceType.description]) ||
      0
    );
  } else if (formula instanceof Previous) {
    // console.log({ formula, previousSymbols });
    const symbolExistsInPreviousSymbols =
      formula &&
      formula.symbol &&
      previousSymbols &&
      previousSymbols[formula.symbol];
    const symbolExistsInPreviousReport =
      formula &&
      formula.symbol &&
      previousReportSymbols &&
      previousReportSymbols[formula.symbol];
    const transformedSymbol =
      formula && formula.symbol && mapSNCtoMicro[formula.symbol];
    const transformedSymbolExistsInPreviousReport =
      transformedSymbol &&
      previousReportSymbols &&
      previousReportSymbols[transformedSymbol];
    var _symbol, _symbolMap, _balanceType;

    if (isPnLSymbol(formula.symbol)) {
      _symbol = symbolExistsInPreviousReport
        ? formula.symbol
        : transformedSymbolExistsInPreviousReport
          ? transformedSymbol
          : undefined;
      _symbolMap = _symbol && previousReportSymbols;
      _balanceType = PreviousBalanceType.Closing;
      // console.info({ symbol: formula.symbol, _symbol, _symbolMap, symbols });
    } else {
      // for BalanceSheet symbols
      _symbol = formula.symbol;
      _symbolMap = previousSymbols;
      _balanceType = PreviousBalanceType.Opening;
    }

    // isPnLSymbol(formula.symbol) &&
    //   console.info({
    //     symbolExists,
    //     symbol: formula.symbol,
    //     transformedSymbol,
    //     transformedSymbolExists,
    //     _symbolMap,
    //     _balanceType,
    //   });
    if (!_symbol && !_symbolMap) {
      console.warn(
        `${formula && formula.symbol && formula.symbol.toString()
        } is missing in 'previous' report`,
        formula
      );
      return 0;
    } else
      return (
        (_symbolMap &&
          _symbol &&
          _symbolMap[_symbol] &&
          _symbolMap[_symbol].__summary &&
          _symbolMap[_symbol].__summary[_balanceType.description]) ||
        0
      );
  } else if (formula instanceof Within) {
    // TODO: it may need some additional checking to distinguish
    // Income Statements vs Balance Statement accounts
    const subAccounts =
      symbols[formula.symbol] &&
      Object.values(symbols[formula.symbol]).filter(
        (item) => item.accountId && item.accountId.includes(formula.prefix)
      );
    return (
      subAccounts &&
      subAccounts
        .reduce(
          (total, item) =>
            total.add(asPreciseEuro(item.calculated.closingBalance)),
          asPreciseEuro(0)
        )
        .format()
    );
  } else if (formula instanceof PreviousWithin) {
    // TODO: it may need some additional checking to distinguish
    // Income Statements vs Balance Statement accounts
    const symbolExistsInPreviousSymbols =
      formula &&
      formula.symbol &&
      previousSymbols &&
      previousSymbols[formula.symbol];
    const symbolExistsInPreviousReport =
      formula &&
      formula.symbol &&
      previousReportSymbols &&
      previousReportSymbols[formula.symbol];
    const transformedSymbol =
      formula && formula.symbol && mapSNCtoMicro[formula.symbol];
    const transformedSymbolExistsInPreviousReport =
      transformedSymbol &&
      previousReportSymbols &&
      previousReportSymbols[transformedSymbol];
    var _symbol, _symbolMap, _balanceType;

    if (isPnLSymbol(formula.symbol)) {
      _symbol = symbolExistsInPreviousReport
        ? formula.symbol
        : transformedSymbolExistsInPreviousReport
          ? transformedSymbol
          : undefined;
      _symbolMap = _symbol && previousReportSymbols;
      _balanceType = PreviousBalanceType.Closing;
      // console.info({ symbol: formula.symbol, _symbol, _symbolMap, symbols });
    } else {
      // for BalanceSheet symbols
      _symbol = formula.symbol;
      _symbolMap = previousSymbols;
      _balanceType = PreviousBalanceType.Opening;
    }

    if (!_symbol && !_symbolMap) {
      return 0;
    } else {
      const subAccounts =
        _symbolMap[_symbol] &&
        Object.values(_symbolMap[_symbol]).filter(
          (item) => item.accountId && item.accountId.includes(formula.prefix)
        );
      // console.log({subAccounts, formula, _symbol, _symbolMap})
      return (
        subAccounts &&
        subAccounts
          .reduce(
            (total, item) =>
              total.add(asPreciseEuro(item.calculated[_balanceType.description])),
            asPreciseEuro(0)
          )
          .format() || 0
      );
    }
  } else if (formula instanceof Add) {
    return asPreciseEuro(
      metaCalc(
        formula.lhs,
        symbols,
        previousSymbols,
        previousReportSymbols,
        previousBalanceType,
        sourceMap,
        currentBalanceType
      )
    )
      .add(
        asPreciseEuro(
          metaCalc(
            formula.rhs,
            symbols,
            previousSymbols,
            previousReportSymbols,
            previousBalanceType,
            sourceMap,
            currentBalanceType
          )
        )
      )
      .format();
  } else if (formula instanceof Subtract) {
    return asPreciseEuro(
      metaCalc(
        formula.lhs,
        symbols,
        previousSymbols,
        previousReportSymbols,
        previousBalanceType,
        sourceMap,
        currentBalanceType
      )
    )
      .subtract(
        asPreciseEuro(
          metaCalc(
            formula.rhs,
            symbols,
            previousSymbols,
            previousReportSymbols,
            previousBalanceType,
            sourceMap,
            currentBalanceType
          )
        )
      )
      .format();
  } else if (formula instanceof Multiply) {
    return asPreciseEuro(
      metaCalc(
        formula.lhs,
        symbols,
        previousSymbols,
        previousReportSymbols,
        previousBalanceType,
        sourceMap,
        currentBalanceType
      )
    )
      .multiply(
        asPreciseEuro(
          metaCalc(
            formula.rhs,
            symbols,
            previousSymbols,
            previousReportSymbols,
            previousBalanceType,
            sourceMap,
            currentBalanceType
          )
        )
      )
      .format();
  } else if (formula instanceof Divide) {
    const _lhs = metaCalc(
      formula.lhs,
      symbols,
      previousSymbols,
      previousReportSymbols,
      previousBalanceType,
      sourceMap,
      currentBalanceType
    );
    const _rhs = metaCalc(
      formula.rhs,
      symbols,
      previousSymbols,
      previousReportSymbols,
      previousBalanceType,
      sourceMap,
      currentBalanceType
    );
    return asPreciseEuro(_lhs).divide(asPreciseEuro(_rhs)).format();
  } else {
    console.warn("unexpected operation", formula);
    return;
  }
};

// export const calc = (
//   formula,
//   symbols,
//   previousSymbols,
//   previousBalanceType = PreviousBalanceType.Closing,
//   sourceMap
// ) => {
//   if (typeof formula === "number") {
//     return formula;
//   } else if (formula instanceof Ref) {
//     if (formula.source === "bdp") {
//       const fieldIndex = sourceMap.labels.indexOf(formula.key);
//       const value = sourceMap[formula.source].filter(
//         (item) => item && item.l === fieldIndex
//       )[0];
//       return (value && value.v) || 0;
//     } else if (formula.source === "input") {
//       // console.log(">>>>", formula, sourceMap);
//       const value =
//         sourceMap &&
//         sourceMap[formula.source] &&
//         sourceMap[formula.source][formula.key];
//       return value;
//     } else {
//       console.warn(
//         `Unknown <<<SOURCE>>>: '${formula.source}' with key '${formula.key}'`
//       );
//       return undefined;
//     }
//   } else if (typeof formula === "symbol") {
//     if (!symbols[formula])
//       console.warn(`${formula.description} is missing in 'current' report`);
//     return (symbols[formula] && symbols[formula].__summary.closingBalance) || 0;
//   } else if (formula instanceof Previous) {
//     // console.log({ formula });
//     if (!previousSymbols[formula.symbol])
//       console.warn(
//         `${
//           formula.symbol && formula.symbol.toString()
//         } is missing in 'previous' report`,
//         formula
//       );
//     return (
//       (previousSymbols[formula.symbol] &&
//         previousSymbols[formula.symbol].__summary[
//           previousBalanceType.description
//         ]) ||
//       0
//     );
//   } else if (formula instanceof Within) {
//     // TODO: it may need some additional checking to distinguish
//     // Income Statements vs Balance Statement accounts
//     const subAccounts =
//       symbols[formula.symbol] &&
//       Object.values(symbols[formula.symbol]).filter(
//         (item) => item.accountId && item.accountId.includes(formula.prefix)
//       );
//     return (
//       subAccounts &&
//       subAccounts
//         .reduce(
//           (total, item) =>
//             total.add(asPreciseEuro(item.calculated.closingBalance)),
//           asPreciseEuro(0)
//         )
//         .format()
//     );
//   } else if (formula instanceof Add) {
//     return asPreciseEuro(
//       calc(
//         formula.lhs,
//         symbols,
//         previousSymbols,
//         previousBalanceType,
//         sourceMap
//       )
//     )
//       .add(
//         asPreciseEuro(
//           calc(
//             formula.rhs,
//             symbols,
//             previousSymbols,
//             previousBalanceType,
//             sourceMap
//           )
//         )
//       )
//       .format();
//   } else if (formula instanceof Subtract) {
//     return asPreciseEuro(
//       calc(
//         formula.lhs,
//         symbols,
//         previousSymbols,
//         previousBalanceType,
//         sourceMap
//       )
//     )
//       .subtract(
//         asPreciseEuro(
//           calc(
//             formula.rhs,
//             symbols,
//             previousSymbols,
//             previousBalanceType,
//             sourceMap
//           )
//         )
//       )
//       .format();
//   } else if (formula instanceof Multiply) {
//     return asPreciseEuro(
//       calc(
//         formula.lhs,
//         symbols,
//         previousSymbols,
//         previousBalanceType,
//         sourceMap
//       )
//     )
//       .multiply(
//         asPreciseEuro(
//           calc(
//             formula.rhs,
//             symbols,
//             previousSymbols,
//             previousBalanceType,
//             sourceMap
//           )
//         )
//       )
//       .format();
//   } else if (formula instanceof Divide) {
//     const _lhs = calc(
//       formula.lhs,
//       symbols,
//       previousSymbols,
//       previousBalanceType,
//       sourceMap
//     );
//     const _rhs = calc(
//       formula.rhs,
//       symbols,
//       previousSymbols,
//       previousBalanceType,
//       sourceMap
//     );
//     return asPreciseEuro(_lhs).divide(asPreciseEuro(_rhs)).format();
//   } else {
//     console.warn("unexpected operation", formula);
//     return;
//   }
// };

//** Dedicated engine for calculating monthly MOAF */
// export const calc2 = (formula, report) => {
//   if (typeof formula === "number") {
//     return formula;
//   } else if (formula instanceof Ref) {
//     console.warn(`'Ref' is not implemented yet`);
//     return undefined;
//   } else if (typeof formula === "symbol") {
//     if (!report[formula])
//       console.warn(`${formula.description} is missing in 'current' report`);
//     // return (report[formula] && report[formula]._endBalance) || 0;
//     return (
//       asEuro(0)
//         .subtract(report[formula] && report[formula]._total)
//         .format() || 0
//     );
//   } else if (formula instanceof Previous) {
//     // return (report[formula] && report[formula]._startBalance) || 0;
//     return 0;
//   } else if (formula instanceof Within) {
//     // TODO: it may need some additional checking to distinguish
//     // Income Statements vs Balance Statement accounts
//     console.warn(`'Within' is not implemented yet`);
//   } else if (formula instanceof Add) {
//     return asPreciseEuro(calc2(formula.lhs, report))
//       .add(asPreciseEuro(calc2(formula.rhs, report)))
//       .format();
//   } else if (formula instanceof Subtract) {
//     return asPreciseEuro(calc2(formula.lhs, report))
//       .subtract(asPreciseEuro(calc2(formula.rhs, report)))
//       .format();
//   } else if (formula instanceof Multiply) {
//     return asPreciseEuro(calc2(formula.lhs, report))
//       .multiply(asPreciseEuro(calc2(formula.rhs, report)))
//       .format();
//   } else if (formula instanceof Divide) {
//     return asPreciseEuro(calc2(formula.lhs, report))
//       .divide(asPreciseEuro(calc2(formula.rhs, report)))
//       .format();
//   } else {
//     console.warn("unexpected operation", formula);
//     return;
//   }
// };

/** Calculates ratio for monthtly MOAF with formating */
// export const calcRatio2 = (ratio, report) => {
//   // console.log({ ratio });
//   if (ratio.format === "percentage") {
//     return `${(asPreciseEuro(calc2(ratio.formula, report)).value * 100).toFixed(
//       2
//     )}%`;
//   } else if (ratio.format === "days") {
//     return `${Math.round(asEuro(calc2(ratio.formula, report)).value)} dias`;
//   } else if (ratio.format === "x") {
//     return `${Math.round(asEuro(calc2(ratio.formula, report)).value)} x`;
//   } else {
//     // console.log("non-ratio", ratio, sourceMaps);
//     return asEuro(calc2(ratio.formula, report)).format();
//   }
// };

// export const calcRatio2MultiPeriod = (ratio, reports = []) =>
//   reports && {
//     ...ratio,
//     values: reports.map((report) => calcRatio2(ratio, report)),
//   };

// export const calcRatio2ListMultiPeriod = (ratios = [], reports = []) =>
//   ratios &&
//   ratios
//     .map((ratio) => calcRatio2MultiPeriod(ratio, reports))
//     .map((r) => ({
//       ...r,
//       valueTota: r.values.reduce(
//         (total, value) => asEuro(total).add(asEuro(value)).format(),
//         0
//       ),
//     }));
