import {
  Add,
  addList,
  Divide,
  Multiply,
  Previous,
  Subtract,
  averageWithPrevious,
  invert,
  changeIn,
  percentageChangeIn,
  Within,
  Ref,
  addAllFormulas,
  If,
  GreaterThan,
  max,
  min,
  EqualTo,
  StringEqualTo,
  PreviousWithin,
} from "./math";
import { StatementTree } from "./normalentitities";

const fromSymbol = (item) => ({ formula: item, label: item.description });

const previousFromSymbol = (item) => ({
  formula: new Previous(item),
  label: item.description + " (período anterior)",
});

const addAll = (items = []) =>
  items
    .map((i) => i.formula)
    .slice(1)
    .reduce((t, i) => new Add(t, i), items[0]);

const quick = (formula, label) => {
  return { formula, label };
};

const delta = (item) => ({
  label: "∆ " + item.description,
  formula: changeIn(item),
});

const invertDelta = (item) => ({
  label: "∆ " + item.description,
  formula: invert(changeIn(item)),
});

const growth = (item) => ({
  label: "∆% " + item.description,
  formula: percentageChangeIn(item),
  format: "percentage",
});

const appendWithText = (item, text) => ({
  ...item,
  label: item.label + " " + text,
});

const total = (item) => ({ ...item, total: true });
const subTotal = (item) => ({ ...item, subTotal: true });
const grandTotal = (item) => ({ ...item, grandTotal: true });
const percentageOf = (item1, item2) => ({
  label: `${item1.description} % de ${item2.description}`,
  formula: new Divide(item1, item2),
  format: "percentage",
});
const italic = (item) => ({ ...item, italic: true });

export const Revenue = {
  label: "Volume de negócios",
  formula: StatementTree.ProfitAndLoss.SalesAndServicesRendered,
};

const modeIsPartial = new StringEqualTo(new Ref("mode", "params"), "partial");

export const RevenueDelta = {
  label: "∆ Volume de negócios",
  formula: changeIn(StatementTree.ProfitAndLoss.SalesAndServicesRendered),
};

export const RevenueGrowth = {
  label: "∆% Volume de Negócios",
  formula: percentageChangeIn(
    StatementTree.ProfitAndLoss.SalesAndServicesRendered
  ),
  format: "percentage",
};

export const Cogs = {
  label: "CMVMC",
  formula: StatementTree.ProfitAndLoss.Materials,
};

export const CogsDelta = {
  label: "∆ CMVMC",
  formula: changeIn(StatementTree.ProfitAndLoss.Materials),
};

export const CogsGrowth = {
  label: "∆% CMVMC",
  formula: percentageChangeIn(StatementTree.ProfitAndLoss.Materials),
  format: "percentage",
};

export const CogsOverRevenue = {
  label: "CMVMC %",
  formula: new Divide(
    invert(StatementTree.ProfitAndLoss.Materials),
    StatementTree.ProfitAndLoss.SalesAndServicesRendered
  ),
  format: "percentage",
};

export const CogsOverRevenuePercentageDelta = {
  label: "∆pp CMVMC",
  formula: new Subtract(
    new Divide(
      invert(StatementTree.ProfitAndLoss.Materials),
      StatementTree.ProfitAndLoss.SalesAndServicesRendered
    ),
    new Divide(
      invert(new Previous(StatementTree.ProfitAndLoss.Materials)),
      new Previous(StatementTree.ProfitAndLoss.SalesAndServicesRendered)
    )
  ),
  format: "percentage",
};

export const GrossProfit = {
  label: "Margem Bruta",
  formula: new Add(
    StatementTree.ProfitAndLoss.SalesAndServicesRendered,
    StatementTree.ProfitAndLoss.Materials
  ),
};

export const GrossMargin = {
  label: "Margem Bruta %",
  formula: new Divide(
    GrossProfit.formula,
    StatementTree.ProfitAndLoss.SalesAndServicesRendered
  ),
  format: "percentage",
};

export const GrossProfitDelta = {
  label: "∆ Margem Bruta",
  formula: new Subtract(
    new Add(
      StatementTree.ProfitAndLoss.SalesAndServicesRendered,
      StatementTree.ProfitAndLoss.Materials
    ),
    new Add(
      new Previous(StatementTree.ProfitAndLoss.SalesAndServicesRendered),
      new Previous(StatementTree.ProfitAndLoss.Materials)
    )
  ),
};

export const GrossProfitGrowth = {
  label: "∆% Margem Bruta",
  formula: new Divide(
    new Subtract(
      new Add(
        StatementTree.ProfitAndLoss.SalesAndServicesRendered,
        StatementTree.ProfitAndLoss.Materials
      ),
      new Add(
        new Previous(StatementTree.ProfitAndLoss.SalesAndServicesRendered),
        new Previous(StatementTree.ProfitAndLoss.Materials)
      )
    ),
    new Add(
      new Previous(StatementTree.ProfitAndLoss.SalesAndServicesRendered),
      new Previous(StatementTree.ProfitAndLoss.Materials)
    )
  ),
  format: "percentage",
};

export const GrossProfitPercentageDelta = {
  label: "∆pp Margem Bruta",
  formula: new Subtract(
    new Divide(
      GrossProfit.formula,
      StatementTree.ProfitAndLoss.SalesAndServicesRendered
    ),
    new Divide(
      new Add(
        new Previous(StatementTree.ProfitAndLoss.SalesAndServicesRendered),
        new Previous(StatementTree.ProfitAndLoss.Materials)
      ),
      new Previous(StatementTree.ProfitAndLoss.SalesAndServicesRendered)
    )
  ),
  format: "percentage",
};

export const ExternalServices = {
  label: "FSE",
  formula: StatementTree.ProfitAndLoss.ExternalServices,
};

export const ExternalServicesDelta = {
  label: "∆ FSE",
  formula: changeIn(StatementTree.ProfitAndLoss.ExternalServices),
};

export const ExternalServicesGrowth = {
  label: "∆% FSE",
  formula: percentageChangeIn(StatementTree.ProfitAndLoss.ExternalServices),
  format: "percentage",
};

export const ExternalServicesOverRevenue = {
  label: "FSE %",
  formula: new Subtract(
    0,
    new Divide(
      StatementTree.ProfitAndLoss.ExternalServices,
      StatementTree.ProfitAndLoss.SalesAndServicesRendered
    )
  ),
  format: "percentage",
};

export const ExternalServicesOverRevenuePercentageDelta = {
  label: "∆pp FSE",
  formula: new Subtract(
    invert(
      new Divide(
        StatementTree.ProfitAndLoss.ExternalServices,
        StatementTree.ProfitAndLoss.SalesAndServicesRendered
      )
    ),
    invert(
      new Divide(
        new Previous(StatementTree.ProfitAndLoss.ExternalServices),
        new Previous(StatementTree.ProfitAndLoss.SalesAndServicesRendered)
      )
    )
  ),
  format: "percentage",
};

export const ExtendedGrossProfit = {
  label: "Margem Bruta Alargada (incluindo FSEs)",
  formula: new Add(
    StatementTree.ProfitAndLoss.SalesAndServicesRendered,
    new Add(
      StatementTree.ProfitAndLoss.Materials,
      StatementTree.ProfitAndLoss.ExternalServices
    )
  ),
};

export const ExtendedGrossMargin = {
  label: "Margem Bruta Alargada (incluindo FSEs) %",
  formula: new Divide(
    ExtendedGrossProfit.formula,
    StatementTree.ProfitAndLoss.SalesAndServicesRendered
  ),
  format: "percentage",
};

export const ExtendedGrossProfitGrowth = {
  label: "∆% Margem Bruta Alargada (incluindo FSE)",
  formula: new Divide(
    new Subtract(
      new Add(
        StatementTree.ProfitAndLoss.SalesAndServicesRendered,
        new Add(
          StatementTree.ProfitAndLoss.Materials,
          StatementTree.ProfitAndLoss.ExternalServices
        )
      ),
      new Add(
        new Previous(StatementTree.ProfitAndLoss.SalesAndServicesRendered),
        new Add(
          new Previous(StatementTree.ProfitAndLoss.Materials),
          new Previous(StatementTree.ProfitAndLoss.ExternalServices)
        )
      )
    ),
    new Add(
      new Previous(StatementTree.ProfitAndLoss.SalesAndServicesRendered),
      new Add(
        new Previous(StatementTree.ProfitAndLoss.Materials),
        new Previous(StatementTree.ProfitAndLoss.ExternalServices)
      )
    )
  ),
  format: "percentage",
};

export const ExtendedGrossProfitPercentageDelta = {
  label: "∆pp Margem Bruta Alargada (incluindo FSE)",
  formula: new Subtract(
    new Divide(
      ExtendedGrossProfit.formula,
      StatementTree.ProfitAndLoss.SalesAndServicesRendered
    ),
    new Divide(
      new Add(
        new Previous(StatementTree.ProfitAndLoss.SalesAndServicesRendered),
        new Add(
          new Previous(StatementTree.ProfitAndLoss.Materials),
          new Previous(StatementTree.ProfitAndLoss.ExternalServices)
        )
      ),
      new Previous(StatementTree.ProfitAndLoss.SalesAndServicesRendered)
    )
  ),
  format: "percentage",
};

export const PersonnelExpense = {
  label: "Gastos de Pessoal",
  formula: StatementTree.ProfitAndLoss.Personnel,
};

export const PersonnelExpenseDelta = {
  label: "∆ Gastos de Pessoal",
  formula: changeIn(StatementTree.ProfitAndLoss.Personnel),
};

export const PersonnelExpenseGrowth = {
  label: "∆% Gastos de Pessoal",
  formula: percentageChangeIn(StatementTree.ProfitAndLoss.Personnel),
  format: "percentage",
};

export const PersonnelExpenseOverRevenue = {
  label: "Gastos de Pessoal %",
  formula: new Subtract(
    0,
    new Divide(
      StatementTree.ProfitAndLoss.Personnel,
      StatementTree.ProfitAndLoss.SalesAndServicesRendered
    )
  ),
  format: "percentage",
};

export const PersonnelExpenseOverRevenuePercentageDelta = {
  label: "∆pp Gastos de Pessoal",
  formula: new Subtract(
    invert(
      new Divide(
        StatementTree.ProfitAndLoss.Personnel,
        StatementTree.ProfitAndLoss.SalesAndServicesRendered
      )
    ),
    invert(
      new Divide(
        new Previous(StatementTree.ProfitAndLoss.Personnel),
        new Previous(StatementTree.ProfitAndLoss.SalesAndServicesRendered)
      )
    )
  ),
  format: "percentage",
};

export const DepreciationDelta = {
  label: "∆ Depreciações",
  formula: changeIn(StatementTree.ProfitAndLoss.DandA),
};

export const DepreciationGrowth = {
  label: "∆% Depreciações",
  formula: percentageChangeIn(StatementTree.ProfitAndLoss.DandA),
  format: "percentage",
};

export const DepreciationOverRevenue = {
  label: "Depreciações %",
  formula: invert(
    new Divide(
      StatementTree.ProfitAndLoss.DandA,
      StatementTree.ProfitAndLoss.SalesAndServicesRendered
    )
  ),
  format: "percentage",
};

export const DepreciationOverRevenuePercentageDelta = {
  label: "∆pp Depreciações",
  formula: new Subtract(
    invert(
      new Divide(
        StatementTree.ProfitAndLoss.DandA,
        StatementTree.ProfitAndLoss.SalesAndServicesRendered
      )
    ),
    invert(
      new Divide(
        new Previous(StatementTree.ProfitAndLoss.DandA),
        new Previous(StatementTree.ProfitAndLoss.SalesAndServicesRendered)
      )
    )
  ),
  format: "percentage",
};

export const OperatingIncome = {
  label: "Resultado Operacional",
  formula: new Add(
    StatementTree.ProfitAndLoss.EBITDA,
    StatementTree.ProfitAndLoss.DandA
  ),
};

export const OperatingMargin = {
  label: "Margem Operacional",
  formula: new Divide(
    OperatingIncome.formula,
    StatementTree.ProfitAndLoss.SalesAndServicesRendered
  ),
  format: "percentage",
};

export const EBITDAMargin = {
  label: "Margem EBITDA",
  formula: new Divide(
    StatementTree.ProfitAndLoss.EBITDA,
    StatementTree.ProfitAndLoss.SalesAndServicesRendered
  ),
  format: "percentage",
};

export const EarningsBeforeTaxes = {
  label: "Resultado antes de Impostos",
  formula: new Add(StatementTree.ProfitAndLoss.EarningsBeforeTaxes, 0),
};

export const SynthEarningsBeforeTaxes = {
  label: "Resultado antes de Impostos (calculado)",
  formula: max(
    StatementTree.ProfitAndLoss.EarningsBeforeTaxes,
    addList(
      StatementTree.ProfitAndLoss.SalesAndServicesRendered,
      StatementTree.ProfitAndLoss.Subsidies,
      StatementTree.ProfitAndLoss.GainsAndLossesInAssociates,
      StatementTree.ProfitAndLoss.ChangeInProductionInventory,
      StatementTree.ProfitAndLoss.OwnWork,
      StatementTree.ProfitAndLoss.Materials,
      StatementTree.ProfitAndLoss.ExternalServices,
      StatementTree.ProfitAndLoss.Personnel,
      StatementTree.ProfitAndLoss.InventoryImpairments,
      StatementTree.ProfitAndLoss.ReceivableImpairments,
      StatementTree.ProfitAndLoss.Provisions,
      StatementTree.ProfitAndLoss.FairValueChanges,
      StatementTree.ProfitAndLoss.NonDandAInvestmentImpairments,
      StatementTree.ProfitAndLoss.OtherGains,
      StatementTree.ProfitAndLoss.OtherExpenses,
      StatementTree.ProfitAndLoss.DandA,
      StatementTree.ProfitAndLoss.InterestIncome,
      StatementTree.ProfitAndLoss.InterestExpense
    )
  ),
};

export const SynthTaxes = {
  label: "Imposto (calculado)",
  formula: min(
    StatementTree.ProfitAndLoss.Taxes,
    invert(new Multiply(SynthEarningsBeforeTaxes.formula, 0.21))
  ),
};

export const NetMargin = {
  label: "Margem Líquida",
  formula: new Divide(
    StatementTree.Capital.NetProfit,
    StatementTree.ProfitAndLoss.SalesAndServicesRendered
  ),
  format: "percentage",
};

export const SynthNetProfit = {
  label: "Resultado líquido (calculado)",
  formula: addList(
    StatementTree.ProfitAndLoss.SalesAndServicesRendered,
    StatementTree.ProfitAndLoss.Subsidies,
    StatementTree.ProfitAndLoss.GainsAndLossesInAssociates,
    StatementTree.ProfitAndLoss.ChangeInProductionInventory,
    StatementTree.ProfitAndLoss.OwnWork,
    StatementTree.ProfitAndLoss.Materials,
    StatementTree.ProfitAndLoss.ExternalServices,
    StatementTree.ProfitAndLoss.Personnel,
    StatementTree.ProfitAndLoss.InventoryImpairments,
    StatementTree.ProfitAndLoss.ReceivableImpairments,
    StatementTree.ProfitAndLoss.Provisions,
    StatementTree.ProfitAndLoss.FairValueChanges,
    StatementTree.ProfitAndLoss.NonDandAInvestmentImpairments,
    StatementTree.ProfitAndLoss.OtherGains,
    StatementTree.ProfitAndLoss.OtherExpenses,
    StatementTree.ProfitAndLoss.DandA,
    StatementTree.ProfitAndLoss.InterestIncome,
    StatementTree.ProfitAndLoss.InterestExpense,
    StatementTree.ProfitAndLoss.Taxes // SynthTaxes.formula
  ),
};

export const SynthNetMargin = {
  label: "Margem Líquida (calculado)",
  formula: new Divide(
    SynthNetProfit.formula,
    StatementTree.ProfitAndLoss.SalesAndServicesRendered
  ),
  format: "percentage",
};

export const EbitdaToGrossProfit = {
  label: "EBITDA / Margem Bruta",
  formula: new Divide(StatementTree.ProfitAndLoss.EBITDA, GrossProfit.formula),
  format: "percentage",
};

export const OperatingIncomeToGrossProfit = {
  label: "Resultado Operacional / Margem Bruta",
  formula: new Divide(OperatingIncome.formula, GrossProfit.formula),
  format: "percentage",
};

export const EarningsBeforeTaxesToOperatingIncome = {
  label: "RAI / Resultado Operacional",
  formula: new Divide(
    StatementTree.ProfitAndLoss.EarningsBeforeTaxes,
    StatementTree.ProfitAndLoss.OperatingIncome
  ),
  format: "percentage",
};

export const Assets = {
  label: "Ativo total",
  formula: StatementTree.Assets.Total,
};

export const DSO = {
  label: "Dias de Clientes",
  formula: new Multiply(
    365,
    new Divide(
      StatementTree.Assets.Current.Clients,
      StatementTree.ProfitAndLoss.SalesAndServicesRendered
    )
  ),
  format: "days",
};

export const DPO = {
  label: "Dias de Fornecedores",
  formula: new Multiply(
    365,
    new Divide(
      StatementTree.Liabilities.Current.Providers,
      new Subtract(
        0,
        new Add(
          StatementTree.ProfitAndLoss.Materials,
          StatementTree.ProfitAndLoss.ExternalServices
        )
      )
    )
  ),
  format: "days",
};

export const DII = {
  label: "Dias de Inventários",
  formula: new Multiply(
    365,
    new Divide(
      StatementTree.Assets.Current.Inventory,
      new Subtract(0, StatementTree.ProfitAndLoss.Materials)
    )
  ),
  format: "days",
};

export const SimpleWorkingCapital = {
  label: "Fundo de Maneio (simples)",
  formula: new Subtract(
    addList(
      StatementTree.Assets.Current.Clients,
      StatementTree.Assets.Current.Inventory,
      StatementTree.Assets.Current.Deferrals
    ),
    addList(
      StatementTree.Liabilities.Current.Providers,
      StatementTree.Liabilities.Current.Deferrals,
      StatementTree.Liabilities.Current.AdvancementsFromClients,
      StatementTree.Liabilities.Current.AdvancementsFromClients2
    )
  ),
};

export const OtherWorkingCapital = {
  label: "Outro Fundo de Maneio",
  formula: new Subtract(
    addList(
      StatementTree.Assets.Current.OtherReceivables,
      StatementTree.Assets.Current.OtherReceivables2,
      StatementTree.Assets.Current.OtherFinancialAssets,
      StatementTree.Assets.Current.StateAndOtherPublicEntitities
    ),
    addList(
      StatementTree.Liabilities.Current.StateAndOtherPublicEntitities,
      StatementTree.Liabilities.Current.OtherPayables,
      StatementTree.Liabilities.Current.OtherFinantialLiabilities,
      StatementTree.Liabilities.Current.TradeableLiabilities
    )
  ),
};

export const WorkingCapital = {
  label: "Fundo de maneio",
  formula: new Subtract(
    StatementTree.Assets.Current.Total,
    StatementTree.Liabilities.Current.Total
  ),
};

export const OtherCurrentItems = {
  label: "Outro fundo de maneio",
  formula: new Subtract(WorkingCapital.formula, SimpleWorkingCapital.formula),
};

export const CashFromResults = {
  label: "Fluxo de resultados",
  formula: new Subtract(
    StatementTree.Capital.NetProfit,
    addList(
      StatementTree.ProfitAndLoss.InterestExpense,
      StatementTree.ProfitAndLoss.InterestIncome,
      StatementTree.ProfitAndLoss.DandA,
      StatementTree.ProfitAndLoss.Provisions
    )
  ),
};

export const SynthCashFromResults = {
  label: "Fluxo de resultados (calculado)",
  formula: new Subtract(
    SynthNetProfit.formula,
    addList(
      StatementTree.ProfitAndLoss.InterestExpense,
      StatementTree.ProfitAndLoss.InterestIncome,
      StatementTree.ProfitAndLoss.DandA,
      StatementTree.ProfitAndLoss.Provisions
    )
  ),
};

export const CashFromResultsMonthly = {
  label: "Fluxo de resultados",
  formula: new Subtract(
    SynthNetProfit.formula,
    addList(
      StatementTree.ProfitAndLoss.InterestExpense,
      StatementTree.ProfitAndLoss.InterestIncome,
      StatementTree.ProfitAndLoss.DandA,
      StatementTree.ProfitAndLoss.Provisions
    )
  ),
};

export const ChangeInClients = {
  label: "∆ Clientes",
  formula: new Subtract(
    StatementTree.Assets.Current.Clients,
    new Previous(StatementTree.Assets.Current.Clients)
  ),
};

export const ChangeInInventory = {
  label: "∆ Inventários",
  formula: new Subtract(
    StatementTree.Assets.Current.Inventory,
    new Previous(StatementTree.Assets.Current.Inventory)
  ),
};

export const ChangeInProviders = {
  label: "∆ Fornecedores",
  formula: new Subtract(
    StatementTree.Liabilities.Current.Providers,
    new Previous(StatementTree.Liabilities.Current.Providers)
  ),
};

export const ChangeInClientAdvancements = {
  label: "∆ Adiantamentos de Clientes",
  formula: new Subtract(
    new Add(
      StatementTree.Liabilities.Current.AdvancementsFromClients,
      StatementTree.Liabilities.Current.AdvancementsFromClients2
    ),
    new Add(
      new Previous(StatementTree.Liabilities.Current.AdvancementsFromClients),
      new Previous(StatementTree.Liabilities.Current.AdvancementsFromClients2)
    )
  ),
};

export const ChangeInChangeInDeferrals = {
  label: "∆ Diferimentos",
  formula: new Subtract(
    new Subtract(
      StatementTree.Assets.Current.Deferrals,
      StatementTree.Liabilities.Current.Deferrals
    ),
    new Subtract(
      new Previous(StatementTree.Assets.Current.Deferrals),
      new Previous(StatementTree.Liabilities.Current.Deferrals)
    )
  ),
};

export const ChangeInCurrentAssets = {
  label: "∆ Ativo Corrente",
  formula: new Subtract(
    StatementTree.Assets.Current.Total,
    new Previous(StatementTree.Assets.Current.Total)
  ),
};

export const ChangeInCurrentLiabilities = {
  label: "∆ Passivo Corrente",
  formula: new Subtract(
    StatementTree.Liabilities.Current.Total,
    new Previous(StatementTree.Liabilities.Current.Total)
  ),
};

export const ChangeInCurrentItems = {
  label: "∆ Fundo de Maneio",
  formula: new Subtract(
    ChangeInCurrentAssets.formula,
    ChangeInCurrentLiabilities.formula
  ),
};

export const ChangeInSimpleWorkingCapital = {
  label: "∆ Fundo de Maneio (simples)",
  formula: new Subtract(
    addList(
      ChangeInClients.formula,
      ChangeInInventory.formula,
      ChangeInChangeInDeferrals.formula
    ),
    addList(ChangeInProviders.formula, ChangeInClientAdvancements.formula)
  ),
};

export const ChangeInState = {
  label: "∆ Estado e outros entes públicos",
  formula: new Subtract(
    new Subtract(
      StatementTree.Assets.Current.StateAndOtherPublicEntitities,
      StatementTree.Liabilities.Current.StateAndOtherPublicEntitities
    ),
    new Subtract(
      new Previous(StatementTree.Assets.Current.StateAndOtherPublicEntitities),
      new Previous(
        StatementTree.Liabilities.Current.StateAndOtherPublicEntitities
      )
    )
  ),
};

export const ChangeInOtherWorkingCapital = {
  label: "∆ Fundo de Maneio (outros)",
  formula: new Subtract(
    OtherWorkingCapital.formula,
    new Subtract(
      new Add(
        addList(
          new Previous(
            StatementTree.Assets.Current.StateAndOtherPublicEntitities
          ),
          new Previous(StatementTree.Assets.Current.OtherReceivables),
          new Previous(StatementTree.Assets.Current.OtherReceivables2),
          new Previous(StatementTree.Assets.Current.OtherFinancialAssets)
        )
      ),
      addList(
        new Previous(
          StatementTree.Liabilities.Current.StateAndOtherPublicEntitities
        ),
        new Previous(StatementTree.Liabilities.Current.OtherPayables),
        new Previous(
          StatementTree.Liabilities.Current.OtherFinantialLiabilities
        ),
        new Previous(StatementTree.Liabilities.Current.TradeableLiabilities)
      )
    )
  ),
};

export const ChangeInWorkingCapital = {
  label: "∆ Fundo de maneio (simples + outros",
  formula: new Add(
    ChangeInSimpleWorkingCapital.formula,
    ChangeInOtherWorkingCapital.formula
  ),
};

// Deprecated
export const ChangeInOtherCurrentItems = {
  label: "∆ Fundo de Maneio (outros)",
  formula: new Subtract(
    ChangeInCurrentItems.formula,
    ChangeInSimpleWorkingCapital.formula
  ),
};

export const OperatingCashflow = {
  label: "Fluxo operacional",
  formula: new Subtract(
    CashFromResults.formula,
    new Add(
      ChangeInSimpleWorkingCapital.formula,
      ChangeInOtherWorkingCapital.formula
    )
  ),
};

export const SynthOperatingCashflow = {
  label: "Fluxo operacional",
  formula: new Subtract(
    SynthCashFromResults.formula,
    new Add(
      ChangeInSimpleWorkingCapital.formula,
      ChangeInOtherWorkingCapital.formula
    )
  ),
};

export const OperatingCashflowMonthly = {
  label: "Fluxo operacional",
  formula: new Subtract(
    CashFromResultsMonthly.formula,
    new Add(
      ChangeInSimpleWorkingCapital.formula,
      ChangeInOtherWorkingCapital.formula
    )
  ),
};

export const CAPEX = {
  label: "CAPEX / Alienações",
  formula: new Subtract(
    changeIn(StatementTree.Assets.NonCurrent.FixedAssets),
    StatementTree.ProfitAndLoss.DandA
  ),
};

export const FinancialInvestments = {
  label: "Outros investimentos / desinvestimentos ",
  formula: addAllFormulas([
    changeIn(StatementTree.Assets.NonCurrent.FinancialInvestments),
    changeIn(StatementTree.Assets.NonCurrent.OtherFinancialInvestments),
    changeIn(StatementTree.Assets.NonCurrent.OtherReceivables),
    changeIn(StatementTree.Assets.NonCurrent.OtherReceivables2),
    changeIn(StatementTree.Assets.NonCurrent.IntangibleAssets),
    changeIn(StatementTree.Assets.NonCurrent.Goodwill),
    changeIn(StatementTree.Assets.NonCurrent.InvestmentProperties),
    changeIn(StatementTree.Assets.NonCurrent.BiologicalAssets),
    changeIn(StatementTree.Assets.NonCurrent.DeferredTaxAssets),
    invert(changeIn(StatementTree.Liabilities.NonCurrent.OtherPayables)),
  ]),
};

export const FinancialIncome = {
  label: "Resultado Financeiro",
  formula: new Add(
    StatementTree.ProfitAndLoss.InterestIncome,
    StatementTree.ProfitAndLoss.InterestExpense
  ),
};

export const ChangeInCurrentLoans = {
  label: "∆ Financiamentos Obtidos (correntes)",
  formula: new Subtract(
    StatementTree.Liabilities.Current.Loans,
    new Previous(StatementTree.Liabilities.Current.Loans)
  ),
};

export const ChangeInNonCurrentLoans = {
  label: "∆ Financiamentos Obtidos (não correntes)",
  formula: new Subtract(
    StatementTree.Liabilities.NonCurrent.Loans,
    new Previous(StatementTree.Liabilities.NonCurrent.Loans)
  ),
};

export const Loans = {
  label: "Financiamentos Obtidos (total)",
  formula: new Add(
    StatementTree.Liabilities.NonCurrent.Loans,
    StatementTree.Liabilities.Current.Loans
  ),
};

export const AverageLoans = {
  label: "Financiamentos Obtidos (média)",
  formula: new Add(
    averageWithPrevious(StatementTree.Liabilities.NonCurrent.Loans),
    averageWithPrevious(StatementTree.Liabilities.Current.Loans)
  ),
};

export const ChangeInLoans = {
  label: "∆ Financiamentos Obtidos (total)",
  formula: new Add(
    ChangeInNonCurrentLoans.formula,
    ChangeInCurrentLoans.formula
  ),
};

export const BankCredit = {
  label: 'Crédito bancário',
  formula: invert(new Add(
    new Within(StatementTree.Liabilities.NonCurrent.Loans, '251'),
    new Within(StatementTree.Liabilities.Current.Loans, '251')
  )),
}

export const ChangeInBankCredit = {
  label: '∆ Crédito Bancário',
  formula: new Subtract(
    BankCredit.formula,
    invert(new Add(
      new PreviousWithin(StatementTree.Liabilities.NonCurrent.Loans, '251'),
      new PreviousWithin(StatementTree.Liabilities.Current.Loans, '251')
    )))
}

export const BankLoans = {
  label: 'Empréstimos',
  formula: invert(new Add(
    new Within(StatementTree.Liabilities.NonCurrent.Loans, '2511'),
    new Within(StatementTree.Liabilities.Current.Loans, '2511')
  )),
}

export const BankOverdrafts = {
  label: 'Descobertos',
  formula: invert(new Add(
    new Within(StatementTree.Liabilities.NonCurrent.Loans, '2512'),
    new Within(StatementTree.Liabilities.Current.Loans, '2512')
  )),
}

export const Leases = {
  label: 'Locações',
  formula: invert(new Add(
    new Within(StatementTree.Liabilities.NonCurrent.Loans, '2513'),
    new Within(StatementTree.Liabilities.Current.Loans, '2513')
  )),
}

export const Bonds = {
  label: 'Obrigações',
  formula: invert(new Add(
    new Within(StatementTree.Liabilities.NonCurrent.Loans, '252'),
    new Within(StatementTree.Liabilities.Current.Loans, '252')
  )),
};

export const ChangeInBonds = {
  label: '∆ Obrigações',
  formula: new Subtract(
    Bonds.formula,
    invert(new Add(
      new PreviousWithin(StatementTree.Liabilities.NonCurrent.Loans, '252'),
      new PreviousWithin(StatementTree.Liabilities.Current.Loans, '252')
    ))),
};

export const ChangeInOtherDebt = {
  label: '∆ Outros financiamentos',
  formula: new Subtract(
    ChangeInLoans.formula,
    new Add(
      ChangeInBankCredit.formula,
      ChangeInBonds.formula
    )
  )
};

export const OtherDebt = {
  label: 'Outros financiamentos',
  formula: new Subtract(
    Loans.formula,
    new Add(
      BankCredit.formula,
      Bonds.formula
    )
  ),
};



export const Dividends = {
  label: "Dividendos e devolução de suprimentos",
  formula: addAllFormulas([
    invert(changeIn(StatementTree.Capital.SubscribedCapital)),
    invert(changeIn(StatementTree.Capital.OtherReserves)),
    invert(changeIn(StatementTree.Capital.LegalReserves)),
    invert(changeIn(StatementTree.Capital.PastResults)),
    invert(changeIn(StatementTree.Capital.RevaluationSurplus)),
    invert(changeIn(StatementTree.Capital.OwnShare)),
    invert(changeIn(StatementTree.Capital.OtherCapitalInstruments)),
    invert(changeIn(StatementTree.Capital.AntecipatedDividends)),
    new Previous(StatementTree.Capital.NetProfit),
  ]),
};

export const DividendsMonthly = {
  label: "Dividendos (calculados)",
  formula: addAllFormulas([
    invert(changeIn(StatementTree.Capital.LegalReserves)),
    invert(changeIn(StatementTree.Capital.OtherReserves)),
    invert(changeIn(StatementTree.Capital.PastResults)),
    invert(changeIn(StatementTree.Capital.OtherCapitalInstruments)),
    invert(changeIn(StatementTree.Capital.NetProfit)),
  ]),
};

export const DebtService = {
  label: "Serviço da dívida",
  formula: new Add(FinancialIncome.formula, ChangeInLoans.formula),
};

export const AvailableCashflow = {
  label: "Fluxo disponível",
  formula: addAllFormulas([
    OperatingCashflow.formula,
    invert(FinancialInvestments.formula),
    invert(CAPEX.formula),
  ]),
};
export const SynthAvailableCashflow = {
  label: "Fluxo disponível",
  formula: addAllFormulas([
    OperatingCashflow.formula,
    invert(FinancialInvestments.formula),
    invert(CAPEX.formula),
  ]),
};

export const AvailableCashflowMonthly = {
  label: "Fluxo disponível",
  formula: addAllFormulas([
    OperatingCashflowMonthly.formula,
    invert(FinancialInvestments.formula),
    invert(CAPEX.formula),
  ]),
};

export const CashChangeInMOAF = {
  label: "∆ Caixa (MOAF)",
  formula: addAllFormulas([
    OperatingCashflow.formula,
    invert(FinancialInvestments.formula),
    invert(CAPEX.formula),
    FinancialIncome.formula,
    ChangeInLoans.formula,
    invert(Dividends.formula),
  ]),
};

export const SynthCashChangeInMOAF = {
  label: "∆ Caixa (MOAF) (calculado)",
  formula: addAllFormulas([
    new Subtract(
      SynthCashFromResults.formula,
      new Add(
        ChangeInSimpleWorkingCapital.formula,
        ChangeInOtherWorkingCapital.formula
      )
    ),
    invert(FinancialInvestments.formula),
    invert(CAPEX.formula),
    FinancialIncome.formula,
    ChangeInLoans.formula,
    invert(Dividends.formula),
  ]),
};

export const CashChangeInMOAFMonthly = {
  label: "∆ Caixa (MOAF) mensal",
  formula: addAllFormulas([
    OperatingCashflowMonthly.formula,
    invert(FinancialInvestments.formula),
    invert(CAPEX.formula),
    FinancialIncome.formula,
    ChangeInLoans.formula,
    invert(DividendsMonthly.formula),
  ]),
};

export const ChangeInCash = {
  label: "∆ Caixa (Balanço)",
  formula: new Subtract(
    StatementTree.Assets.Current.Cash,
    new Previous(StatementTree.Assets.Current.Cash)
  ),
};

export const ChangeInCashMonhtly = {
  label: "∆ Caixa",
  formula: invert(changeIn(StatementTree.Assets.Current.Cash)),
};

export const FinancialAllIn = {
  label: "All in",
  formula: new Divide(
    invert(StatementTree.ProfitAndLoss.InterestExpense),
    averageWithPrevious(Loans.formula)
  ),
  format: "percentage",
};

export const NetDebt = {
  label: "Dívida Financeira Líquida",
  formula: new If(
    new GreaterThan(Loans.formula, StatementTree.Assets.Current.Cash),
    new Subtract(Loans.formula, StatementTree.Assets.Current.Cash),
    0
  ),
};

export const NetDebtOverEBITDA = {
  label: "Dívida Líquida sobre EBITDA",
  formula: new If(
    new GreaterThan(NetDebt.formula, 0),
    new Divide(NetDebt.formula, StatementTree.ProfitAndLoss.EBITDA),
    0
  ),
  format: "x",
};

export const FinancialAutonomy = {
  label: "Autonomia Financeira",
  formula: new Divide(StatementTree.Capital.Total, StatementTree.Assets.Total),
  format: "percentage",
};

export const PersonnelRunway = {
  label: "Meses de salários (caixa)",
  formula: new Multiply(
    new Divide(
      StatementTree.Assets.Current.Cash,
      invert(PersonnelExpense.formula)
    ),
    12
  ),
  format: "x",
};

export const PersonnelRunway2 = {
  label: "Meses de salários (caixa + fundo de maneio)",
  formula: new Multiply(
    new Divide(
      new Add(StatementTree.Assets.Current.Cash, SimpleWorkingCapital.formula),
      invert(PersonnelExpense.formula)
    ),
    12
  ),
  format: "x",
};

export const CombinedRunway = {
  label: "Meses de salários + FSE (caixa)",
  formula: new Multiply(
    new Divide(
      StatementTree.Assets.Current.Cash,
      invert(
        addList(
          PersonnelExpense.formula,
          ExternalServices.formula,
          StatementTree.ProfitAndLoss.OtherExpenses
        )
      )
    ),
    12
  ),
  format: "x",
};

export const CombinedRunway2 = {
  label: "Meses de salários + FSE (caixa + fundo de maneio)",
  formula: new Multiply(
    new Divide(
      new Add(StatementTree.Assets.Current.Cash, SimpleWorkingCapital.formula),
      invert(
        addList(
          PersonnelExpense.formula,
          ExternalServices.formula,
          StatementTree.ProfitAndLoss.OtherExpenses
        )
      )
    ),
    12
  ),
  format: "x",
};

export const RevenueMultiplyScenario = {
  label: "Volume de negócios",
  formula: new Multiply(Revenue.formula, new Ref("revenueMultiply", "input")),
};

export const CogsMultiplyScenario = {
  label: "",
  formula: new Multiply(Cogs.formula, new Ref("cogsMultiply", "input")),
};

export const ExternalServicesMultiplyScenario = {
  label: "",
  formula: new Multiply(
    ExternalServices.formula,
    new Ref("externalChangesMultiply", "input")
  ),
};

export const PersonnelScenario = {
  label: "",
  formula: new Multiply(
    PersonnelExpense.formula,
    new Ref("personnelMultiply", "input")
  ),
};

export const RevenueAddScenario = {
  label: "Volume de negócios",
  formula: new Add(Revenue.formula, new Ref("revenueAdd", "input")),
};

export const CogsAddScenario = {
  label: "",
  formula: new Add(Cogs.formula, new Ref("cogsAdd", "input")),
};

export const ExternalServicesAddScenario = {
  label: "",
  formula: new Add(
    ExternalServices.formula,
    new Ref("externalChangesAdd", "input")
  ),
};

export const PersonnelAddScenario = {
  label: "",
  formula: new Add(PersonnelExpense.formula, new Ref("personnelAdd", "input")),
};

export const EBITDAScenario = {
  label: "EBITDA — cenário de queda",
  formula: addList(
    RevenueMultiplyScenario.formula,
    CogsMultiplyScenario.formula,
    ExternalServices.formula,
    PersonnelExpense.formula,
    StatementTree.ProfitAndLoss.OtherGains,
    StatementTree.ProfitAndLoss.OtherExpenses
  ),
};

export const EBITDAScenarioDelta = {
  label: "∆ EBITDA",
  formula: new Subtract(
    EBITDAScenario.formula,
    StatementTree.ProfitAndLoss.EBITDA
  ),
};

export const NetDebtOverEBITDAScenario = {
  label: "Dívida líquida sobre EBITDA — cenário de queda",
  formula: new If(
    new GreaterThan(NetDebt.formula, 0),
    new Divide(NetDebt.formula, EBITDAScenario.formula),
    0
  ),
  format: "x",
};

export const NetDebtOverEBITDAScenarioDelta = {
  label: "∆ Dívida líquida sobre EBITDA",
  formula: new Subtract(
    NetDebtOverEBITDAScenario.formula,
    NetDebtOverEBITDA.formula
  ),
  format: "x",
};

export const ROA = {
  label: "ROA",
  formula: new Divide(
    StatementTree.Capital.NetProfit,
    averageWithPrevious(StatementTree.Capital.Total)
  ),
  format: "percentage",
};

export const SynthROA = {
  label: "ROA (calculado)",
  formula: new Divide(
    SynthNetProfit.formula,
    averageWithPrevious(StatementTree.Capital.Total)
  ),
  format: "percentage",
};

export const ROIC = {
  label: "ROIC",
  formula: new Divide(
    StatementTree.Capital.NetProfit,
    new Add(Loans.formula, averageWithPrevious(StatementTree.Capital.Total))
  ),
  format: "percentage",
};

export const SynthROIC = {
  label: "ROIC (calculado)",
  formula: new Divide(
    SynthNetProfit.formula,
    new Add(Loans.formula, averageWithPrevious(StatementTree.Capital.Total))
  ),
  format: "percentage",
};

export const ROCE = {
  label: "ROCE",
  formula: new Divide(
    StatementTree.Capital.NetProfit,
    new Add(
      SimpleWorkingCapital.formula,
      averageWithPrevious(StatementTree.Assets.NonCurrent.FixedAssets)
    )
  ),
  format: "percentage",
};

export const SynthROCE = {
  label: "ROCE (calculado)",
  formula: new Divide(
    SynthNetProfit.formula,
    new Add(
      SimpleWorkingCapital.formula,
      averageWithPrevious(StatementTree.Assets.NonCurrent.FixedAssets)
    )
  ),
  format: "percentage",
};

const PowerOfOneVolume = {
  label: "Incrementar volumes",
  formula: new Multiply(
    new Multiply(
      StatementTree.ProfitAndLoss.SalesAndServicesRendered,
      new Ref("improvement", "input")
    ),
    GrossMargin.formula
  ),
};

const PowerOfOnePrice = {
  label: "Incrementar preços",
  formula: new Multiply(
    StatementTree.ProfitAndLoss.SalesAndServicesRendered,
    new Ref("improvement", "input")
  ),
};

const PowerOfOneCOGS = {
  label: "Renegociar materiais primas",
  formula: invert(
    new Multiply(
      StatementTree.ProfitAndLoss.Materials,
      new Ref("improvement", "input")
    )
  ),
};

const PowerOfOneExternalServices = {
  label: "Renegociar FSEs",
  formula: invert(
    new Multiply(
      StatementTree.ProfitAndLoss.ExternalServices,
      new Ref("improvement", "input")
    )
  ),
};

const PowerOfOnePersonnel = {
  label: "Optimizar Gastos de Pessoal",
  formula: invert(
    new Multiply(
      StatementTree.ProfitAndLoss.Personnel,
      new Ref("improvement", "input")
    )
  ),
};

const PowerOfOneInterestExpense = {
  label: "Baixar juros",
  formula: new Multiply(
    invert(StatementTree.ProfitAndLoss.InterestExpense),
    new Ref("improvement", "input")
  ),
};

const PowerOfOneList1Total = quick(
  addAll([
    PowerOfOneVolume,
    PowerOfOnePrice,
    PowerOfOneCOGS,
    PowerOfOneExternalServices,
    PowerOfOnePersonnel,
    PowerOfOneInterestExpense,
  ]),
  "Lucro adicional"
);

const PowerOfOneList1TotalImpact = {
  label: "Crescimento do lucro",
  formula: new Divide(
    PowerOfOneList1Total.formula,
    EarningsBeforeTaxes.formula
  ),
  format: "percentage",
};

const PowerOfOneDSO = {
  label: "Encurtar clientes",
  formula: new Multiply(
    StatementTree.Assets.Current.Clients,
    new Ref("improvement", "input")
  ),
};

const PowerOfOneDII = {
  label: "Encurtar inventários",
  formula: new Multiply(
    StatementTree.Assets.Current.Inventory,
    new Ref("improvement", "input")
  ),
};

const PowerOfOneDPO = {
  label: "Alargar fornecedores",
  formula: new Multiply(
    StatementTree.Liabilities.Current.Providers,
    new Ref("improvement", "input")
  ),
};

const PowerOfOneList2Total = quick(
  addAll([PowerOfOneDSO, PowerOfOneDII, PowerOfOneDPO]),
  "Capital liberto"
);

const PowerOfOneList2TotalImpact = {
  label: "Redução do fundo de maneio",
  formula: new Divide(
    PowerOfOneList2Total.formula,
    SimpleWorkingCapital.formula
  ),
  format: "percentage",
};

export const WorkingCapitalDetails = ({ partial }) => [
  SimpleWorkingCapital,
  OtherWorkingCapital,
  total(
    quick(
      addList(SimpleWorkingCapital.formula, OtherWorkingCapital.formula),
      "Total de Fundo de Maneio"
    )
  ),
];

export const SimpleWorkingCapitalDetails = ({ partial }) => [
  fromSymbol(StatementTree.Assets.Current.Clients),
  growth(StatementTree.Assets.Current.Clients),
  fromSymbol(StatementTree.Assets.Current.Inventory),
  growth(StatementTree.Assets.Current.Inventory),
  fromSymbol(StatementTree.Liabilities.Current.Providers),
  growth(StatementTree.Liabilities.Current.Providers),
  fromSymbol(StatementTree.Liabilities.Current.AdvancementsFromClients),
  fromSymbol(StatementTree.Liabilities.Current.AdvancementsFromClients2),
  total(SimpleWorkingCapital),
];

export const OtherWorkingCapitalDetails = ({ partial }) => [
  fromSymbol(StatementTree.Assets.Current.OtherReceivables),
  fromSymbol(StatementTree.Assets.Current.OtherReceivables2),
  fromSymbol(StatementTree.Assets.Current.StateAndOtherPublicEntitities),
  fromSymbol(StatementTree.Assets.Current.Deferrals),
  fromSymbol(StatementTree.Liabilities.Current.StateAndOtherPublicEntitities),
  fromSymbol(StatementTree.Liabilities.Current.OtherPayables),
  fromSymbol(StatementTree.Liabilities.Current.Deferrals),
  total(OtherWorkingCapital),
];

export const WorkingCapitalDaysDetails = ({ partial }) => [DSO, DII, DPO];

export const Profitability = ({ partial }) => [
  GrossMargin,
  ExtendedGrossMargin,
  EBITDAMargin,
  partial ? SynthNetMargin : NetMargin,
];

export const OperatingLeverage = ({ partial }) => [
  EbitdaToGrossProfit,
  OperatingIncomeToGrossProfit,
  EarningsBeforeTaxesToOperatingIncome,
];

export const Returnability = ({ partial }) => [
  partial ? SynthROA : ROA,
  partial ? SynthROIC : ROIC,
  partial ? SynthROCE : ROCE,
];

export const Financing = ({ partial }) => [
  Loans,
  fromSymbol(StatementTree.Assets.Current.Cash),
  NetDebt,
  fromSymbol(StatementTree.ProfitAndLoss.EBITDA),
  total(NetDebtOverEBITDA),
  fromSymbol(StatementTree.Capital.Total),
  total(FinancialAutonomy),
  ZScore,
];

export const RobustnessScenarios = ({ partial }) => [
  fromSymbol(StatementTree.ProfitAndLoss.EBITDA),
  total(EBITDAScenario),
  EBITDAScenarioDelta,
  NetDebtOverEBITDA,
  total(NetDebtOverEBITDAScenario),
  NetDebtOverEBITDAScenarioDelta,
];

export const Runways = ({ partial }) => [
  PersonnelRunway,
  PersonnelRunway2,
  CombinedRunway,
  CombinedRunway2,
];

export const MOAF = ({ partial }) => [
  total(partial ? SynthCashFromResults : CashFromResults),
  ChangeInSimpleWorkingCapital,
  ChangeInOtherWorkingCapital,
  total(partial ? SynthOperatingCashflow : OperatingCashflow),
  CAPEX,
  FinancialInvestments,
  total(partial ? SynthAvailableCashflow : AvailableCashflow),
  FinancialIncome,
  ChangeInLoans,
  Dividends,
  total(partial ? SynthCashChangeInMOAF : CashChangeInMOAF),
];

export const MOAFMonthly = ({ partial }) => [
  total(partial ? SynthCashFromResults : CashFromResults),
  ChangeInSimpleWorkingCapital,
  ChangeInOtherWorkingCapital,
  total(partial ? SynthOperatingCashflow : OperatingCashflow),
  CAPEX,
  FinancialInvestments,
  total(partial ? SynthAvailableCashflow : AvailableCashflow),
  FinancialIncome,
  ChangeInLoans,
  DividendsMonthly,
  total(partial ? SynthCashChangeInMOAF : CashChangeInMOAF),
  ChangeInCash,
];

export const CashFromResultsDetails = ({ partial }) => [
  partial ? SynthNetProfit : fromSymbol(StatementTree.Capital.NetProfit),
  fromSymbol(StatementTree.ProfitAndLoss.InterestExpense),
  fromSymbol(StatementTree.ProfitAndLoss.InterestIncome),
  fromSymbol(StatementTree.ProfitAndLoss.DandA),
  fromSymbol(StatementTree.ProfitAndLoss.Provisions),
  total(SynthCashFromResults),
];

export const CashFromResultsDetailsMonthly = ({ partial }) => [
  partial ? SynthNetProfit : fromSymbol(StatementTree.Capital.NetProfit),
  fromSymbol(StatementTree.ProfitAndLoss.InterestExpense),
  fromSymbol(StatementTree.ProfitAndLoss.InterestIncome),
  fromSymbol(StatementTree.ProfitAndLoss.DandA),
  fromSymbol(StatementTree.ProfitAndLoss.Provisions),
  total(CashFromResultsMonthly),
];

export const WorkingCapitalChangeDetails = ({ partial }) => [
  ChangeInSimpleWorkingCapital,
  ChangeInOtherWorkingCapital,
  total(
    quick(
      addList(
        ChangeInSimpleWorkingCapital.formula,
        ChangeInOtherWorkingCapital.formula
      ),
      "∆ Fundo de Maneio (total)"
    )
  ),
  // ChangeInOtherCurrentItems,
  // ChangeInCurrentItems,
];

export const SimpleWorkingCapitalChangeDetails = ({ partial }) => [
  ChangeInClients,
  ChangeInInventory,
  ChangeInProviders,
  total(ChangeInSimpleWorkingCapital),
];

export const OtherWorkingCapitalChangeDetails = ({ partial }) => [
  delta(StatementTree.Assets.Current.OtherReceivables),
  delta(StatementTree.Assets.Current.OtherReceivables2),
  delta(StatementTree.Assets.Current.StateAndOtherPublicEntitities),
  delta(StatementTree.Assets.Current.Deferrals),
  delta(StatementTree.Liabilities.Current.StateAndOtherPublicEntitities),
  delta(StatementTree.Liabilities.Current.OtherPayables),
  delta(StatementTree.Liabilities.Current.Deferrals),
  total(ChangeInOtherWorkingCapital),
];

export const CAPEXDetails = ({ partial }) => [
  delta(StatementTree.Assets.NonCurrent.FixedAssets),
  fromSymbol(StatementTree.ProfitAndLoss.DandA),
  total(CAPEX),
];

export const FinancialInvestmentDetails = ({ partial }) => [
  delta(StatementTree.Assets.NonCurrent.FinancialInvestments),
  delta(StatementTree.Assets.NonCurrent.OtherFinancialInvestments),
  delta(StatementTree.Assets.NonCurrent.OtherReceivables),
  delta(StatementTree.Assets.NonCurrent.OtherReceivables2),
  delta(StatementTree.Assets.NonCurrent.IntangibleAssets),
  delta(StatementTree.Assets.NonCurrent.Goodwill),
  delta(StatementTree.Assets.NonCurrent.InvestmentProperties),
  delta(StatementTree.Assets.NonCurrent.BiologicalAssets),
  invertDelta(StatementTree.Liabilities.NonCurrent.OtherPayables),
  total(FinancialInvestments),
];


export const DebtDetails = ({ partial }) => [
  BankCredit,
  BankLoans,
  BankOverdrafts,
  Leases,
  OtherDebt,
  total(Loans)
];

export const DebtChangeDetails = ({ partial }) => [
  ChangeInBankCredit,
  ChangeInBonds,
  ChangeInOtherDebt,
  total(ChangeInLoans),
];


export const DebtServiceDetails = ({ partial }) => [
  fromSymbol(StatementTree.ProfitAndLoss.InterestIncome),
  fromSymbol(StatementTree.ProfitAndLoss.InterestExpense),
  total(FinancialIncome),
  ChangeInNonCurrentLoans,
  ChangeInCurrentLoans,
  total(ChangeInLoans),
  total(DebtService),
];

export const DividendDetails = ({ partial }) => [
  invertDelta(StatementTree.Capital.OtherReserves),
  invertDelta(StatementTree.Capital.LegalReserves),
  invertDelta(StatementTree.Capital.PastResults),
  invertDelta(StatementTree.Capital.OtherCapitalInstruments),
  previousFromSymbol(StatementTree.Capital.NetProfit),
  total(Dividends),
];

export const CashDetails = ({ partial }) => [
  previousFromSymbol(StatementTree.Assets.Current.Cash),
  fromSymbol(StatementTree.Assets.Current.Cash),
  total(ChangeInCash),
];

export const FinancingCosts = {
  label: "Custos de financiamento",
  formula: invert(StatementTree.ProfitAndLoss.InterestExpense),
};

export const FinancingAllInDetails = ({ partial }) => [
  AverageLoans,
  FinancingCosts,
  total(FinancialAllIn),
];

export const OtherFinancingCosts = {
  label: "Outros gastos de financiamento",
  formula: new Within(StatementTree.ProfitAndLoss.InterestExpense, "698"),
};

export const OtherFinancingCostsRatio = {
  label: "Outros gastos de financiamento / Custos de financiamento",
  formula: new Divide(OtherFinancingCosts.formula, FinancingCosts.formula),
  format: "percentage",
};

export const FinancialDiscountsGiven = {
  label: "Descontos PP cedidos",
  formula: invert(new Within(StatementTree.ProfitAndLoss.OtherExpenses, "682")),
};

export const FinancialDiscountsObtained = {
  label: "Descontos PP obtidos",
  formula: invert(new Within(StatementTree.ProfitAndLoss.OtherGains, "782")),
};

export const NetFinancialDiscounts = {
  label: "Descontos PP líquidos",
  formula: new Add(
    FinancialDiscountsObtained.formula,
    FinancialDiscountsGiven.formula
  ),
};

export const ExtendedFinancialCosts = {
  label: "Custos financeiros totais",
  formula: new Add(
    StatementTree.ProfitAndLoss.InterestExpense,
    NetFinancialDiscounts.formula
  ),
};

export const ExtendedFinancialAllIn = {
  label: "All in — incluindos descontos PP líquidos",
  formula: new Divide(
    invert(ExtendedFinancialCosts.formula),
    averageWithPrevious(Loans.formula)
  ),
  format: "percentage",
};

export const ZScore = {
  label: "Z-score",
  format: "x",
  formula: addAllFormulas([
    new Multiply(
      0.72,
      new Divide(WorkingCapital.formula, StatementTree.Assets.Total)
    ),
    new Multiply(
      0.89,
      new Divide(
        addAllFormulas([
          StatementTree.Capital.PastResults,
          StatementTree.Capital.LegalReserves,
          StatementTree.Capital.OtherReserves,
          StatementTree.OtherCapitalInstruments,
        ]),
        StatementTree.Assets.Total
      )
    ),
    new Multiply(
      3.1,
      new Divide(OperatingIncome.formula, StatementTree.Assets.Total)
    ),
    new Multiply(
      0.42,
      new Divide(StatementTree.Capital.Total, StatementTree.Liabilities.Total)
    ),
    new Multiply(
      0.998,
      new Divide(
        StatementTree.ProfitAndLoss.SalesAndServicesRendered,
        StatementTree.Assets.Total
      )
    ),
  ]),
};

export const InvestmentScenarioUltraBearEBITDA = {
  label: "EBITDA após investimento",
  formula: new Subtract(
    StatementTree.ProfitAndLoss.EBITDA,
    addAllFormulas([
      new Ref("externalServices", "input"),
      new Ref("personnel", "input"),
    ])
  ),
};

export const InvestmentScenarioUltraBearNetDebt = {
  label: "Dívida líquida após investimento",
  formula: new If(
    new GreaterThan(
      new Add(
        Loans.formula,
        new Ref("loanAmount", "input"),
        StatementTree.Assets.Current.Cash
      )
    ),
    new Add(
      new Subtract(Loans.formula, StatementTree.Assets.Current.Cash),
      new Ref("loanAmount", "input")
    )
  ),
};

export const InvestmentScenarioUltraBearNetDebtOverEbitda = {
  label: "Dívida líquida sobre EBITDA após investimento",
  format: "x",
  formula: new If(
    new GreaterThan(InvestmentScenarioUltraBearNetDebt.formula, 0),
    new Divide(
      InvestmentScenarioUltraBearNetDebt.formula,
      InvestmentScenarioUltraBearEBITDA.formula
    ),
    0
  ),
};

export const InvestmentScenarioUltraBearFinancialAutonomy = {
  label: "Autonomia Financeira após investimento",
  format: "percentage",
  formula: new Divide(
    new Add(
      StatementTree.Capital.Total,
      new Subtract(
        new Ref("investment", "input"),
        new Ref("loanAmount", "input")
      )
    ),
    new Add(StatementTree.Assets.Total, new Ref("investment", "input"))
  ),
};

export const InvestmentScenarioUltraBear = ({ partial }) => [
  quick(StatementTree.ProfitAndLoss.EBITDA, "EBITDA antes do investimento"),
  appendWithText(NetDebt, "antes do investimento"),
  total(appendWithText(NetDebtOverEBITDA, "antes do investimento")),
  total(appendWithText(FinancialAutonomy, "antes do investimento")),
  InvestmentScenarioUltraBearEBITDA,
  InvestmentScenarioUltraBearNetDebt,
  total(InvestmentScenarioUltraBearNetDebtOverEbitda),
  total(InvestmentScenarioUltraBearFinancialAutonomy),
];

export const FinancingCostDetails = ({ partial }) => [
  FinancingCosts,
  OtherFinancingCosts,
  total(OtherFinancingCostsRatio),
];

export const ExtendedFinancialCostDetails = ({ partial }) => [
  FinancialDiscountsObtained,
  FinancialDiscountsGiven,
  total(NetFinancialDiscounts),
  FinancingCosts,
  total(ExtendedFinancialCosts),
  AverageLoans,
  total(ExtendedFinancialAllIn),
];

export const PowerOfOneList = ({ partial }) => [
  PowerOfOneVolume,
  PowerOfOnePrice,
  PowerOfOneCOGS,
  PowerOfOneExternalServices,
  PowerOfOneInterestExpense,
  PowerOfOnePersonnel,
  total(PowerOfOneList1Total),
  total(PowerOfOneList1TotalImpact),
];

export const PowerOfOneList2 = ({ partial }) => [
  PowerOfOneDSO,
  PowerOfOneDII,
  PowerOfOneDPO,
  total(PowerOfOneList2Total),
  total(PowerOfOneList2TotalImpact),
];

export const IncomeFunnel = ({ partial }) => [
  fromSymbol(StatementTree.ProfitAndLoss.SalesAndServicesRendered),
  GrossProfit,
  ExtendedGrossProfit,
  fromSymbol(StatementTree.ProfitAndLoss.EBITDA),
  OperatingIncome,
  partial ? SynthEarningsBeforeTaxes : EarningsBeforeTaxes,
  partial ? SynthNetProfit : fromSymbol(StatementTree.Capital.NetProfit),
];

export const ProfitAndLoss = ({ partial }) => [
  total(fromSymbol(StatementTree.ProfitAndLoss.SalesAndServicesRendered)),
  fromSymbol(StatementTree.ProfitAndLoss.Subsidies),
  fromSymbol(StatementTree.ProfitAndLoss.GainsAndLossesInAssociates),
  fromSymbol(StatementTree.ProfitAndLoss.ChangeInProductionInventory),
  fromSymbol(StatementTree.ProfitAndLoss.OwnWork),
  fromSymbol(StatementTree.ProfitAndLoss.Materials),
  fromSymbol(StatementTree.ProfitAndLoss.ExternalServices),
  fromSymbol(StatementTree.ProfitAndLoss.Personnel),
  fromSymbol(StatementTree.ProfitAndLoss.InventoryImpairments),
  fromSymbol(StatementTree.ProfitAndLoss.ReceivableImpairments),
  fromSymbol(StatementTree.ProfitAndLoss.Provisions),
  fromSymbol(StatementTree.ProfitAndLoss.FairValueChanges),
  fromSymbol(StatementTree.ProfitAndLoss.NonDandAInvestmentImpairments),
  fromSymbol(StatementTree.ProfitAndLoss.OtherGains),
  fromSymbol(StatementTree.ProfitAndLoss.OtherExpenses),
  total(fromSymbol(StatementTree.ProfitAndLoss.EBITDA)),
  fromSymbol(StatementTree.ProfitAndLoss.DandA),
  total(fromSymbol(StatementTree.ProfitAndLoss.OperatingIncome)),
  fromSymbol(StatementTree.ProfitAndLoss.InterestIncome),
  fromSymbol(StatementTree.ProfitAndLoss.InterestExpense),
  total(partial ? SynthEarningsBeforeTaxes : EarningsBeforeTaxes),
  partial ? SynthTaxes : fromSymbol(StatementTree.ProfitAndLoss.Taxes),
  total(partial ? SynthNetProfit : fromSymbol(StatementTree.Capital.NetProfit)),
];

export const ProfitAndLossOneYear = ({ partial }) => [
  fromSymbol(StatementTree.ProfitAndLoss.SalesAndServicesRendered),
  // RevenueDelta,
  // RevenueGrowth,
  fromSymbol(StatementTree.ProfitAndLoss.Materials),
  // CogsDelta,
  // CogsGrowth,
  CogsOverRevenue,
  // CogsOverRevenuePercentageDelta,
  GrossProfit,
  // GrossProfitDelta,
  // GrossProfitGrowth,
  GrossMargin,
  // GrossProfitPercentageDelta,
  fromSymbol(StatementTree.ProfitAndLoss.ExternalServices),
  // ExternalServicesDelta,
  // ExternalServicesGrowth,
  ExternalServicesOverRevenue,
  // ExternalServicesOverRevenuePercentageDelta,
  ExtendedGrossProfit,
  ExtendedGrossMargin,
  // ExtendedGrossProfitGrowth,
  // ExtendedGrossProfitPercentageDelta,
  fromSymbol(StatementTree.ProfitAndLoss.Personnel),
  // PersonnelExpenseDelta,
  // PersonnelExpenseGrowth,
  PersonnelExpenseOverRevenue,
  // PersonnelExpenseOverRevenuePercentageDelta,
  fromSymbol(StatementTree.ProfitAndLoss.DandA),
  // DepreciationDelta,
  // DepreciationGrowth,
  DepreciationOverRevenue,
  // DepreciationOverRevenuePercentageDelta,
];

export const ProfitAndLossMultipleYear = ({ partial }) => [
  total(fromSymbol(StatementTree.ProfitAndLoss.SalesAndServicesRendered)),
  RevenueDelta,
  RevenueGrowth,
  total(fromSymbol(StatementTree.ProfitAndLoss.Materials)),
  CogsDelta,
  CogsGrowth,
  CogsOverRevenue,
  CogsOverRevenuePercentageDelta,
  total(GrossProfit),
  GrossProfitDelta,
  GrossProfitGrowth,
  GrossMargin,
  GrossProfitPercentageDelta,
  total(fromSymbol(StatementTree.ProfitAndLoss.ExternalServices)),
  ExternalServicesDelta,
  ExternalServicesGrowth,
  ExternalServicesOverRevenue,
  ExternalServicesOverRevenuePercentageDelta,
  total(ExtendedGrossProfit),
  ExtendedGrossMargin,
  ExtendedGrossProfitGrowth,
  ExtendedGrossProfitPercentageDelta,
  total(fromSymbol(StatementTree.ProfitAndLoss.Personnel)),
  PersonnelExpenseDelta,
  PersonnelExpenseGrowth,
  PersonnelExpenseOverRevenue,
  PersonnelExpenseOverRevenuePercentageDelta,
  total(fromSymbol(StatementTree.ProfitAndLoss.DandA)),
  DepreciationDelta,
  DepreciationGrowth,
  DepreciationOverRevenue,
  DepreciationOverRevenuePercentageDelta,
];

export const BalanceSheet = ({ partial }) => [
  total(fromSymbol(StatementTree.Assets.Total)),
  total(fromSymbol(StatementTree.Assets.NonCurrent.Total)),
  fromSymbol(StatementTree.Assets.NonCurrent.FixedAssets),
  fromSymbol(StatementTree.Assets.NonCurrent.InvestmentProperties),
  fromSymbol(StatementTree.Assets.NonCurrent.IntangibleAssets),
  fromSymbol(StatementTree.Assets.NonCurrent.FinancialInvestments),
  fromSymbol(StatementTree.Assets.NonCurrent.BiologicalAssets),
  fromSymbol(StatementTree.Assets.NonCurrent.FinantialInterests),
  fromSymbol(StatementTree.Assets.NonCurrent.Goodwill),
  fromSymbol(StatementTree.Assets.NonCurrent.OtherFinancialInvestments),
  fromSymbol(StatementTree.Assets.NonCurrent.OtherReceivables),
  fromSymbol(StatementTree.Assets.NonCurrent.OtherReceivables2),
  fromSymbol(StatementTree.Assets.NonCurrent.DeferredTaxAssets),

  total(fromSymbol(StatementTree.Assets.Current.Total)),
  fromSymbol(StatementTree.Assets.Current.TradeableFinantialAssets),
  fromSymbol(StatementTree.Assets.Current.OtherFinancialAssets),
  fromSymbol(StatementTree.Assets.Current.OtherReceivables2),
  fromSymbol(StatementTree.Assets.Current.UnrealizedButSubscribedCapital),
  fromSymbol(StatementTree.Assets.Current.BiologicalAssets),

  fromSymbol(StatementTree.Assets.Current.NonCurrentAssetsHoldForSales),
  fromSymbol(StatementTree.Assets.Current.Shareholders),
  fromSymbol(StatementTree.Assets.Current.OtherReceivables),
  fromSymbol(StatementTree.Assets.Current.Deferrals),
  fromSymbol(StatementTree.Assets.Current.Clients),
  fromSymbol(StatementTree.Assets.Current.Inventory),
  fromSymbol(StatementTree.Assets.Current.StateAndOtherPublicEntitities),
  fromSymbol(StatementTree.Assets.Current.Cash),

  /// LiabilitiesAndCapital

  total(fromSymbol(StatementTree.LiabilitiesAndCapital.Total)),

  /// Capital
  total(fromSymbol(StatementTree.Capital.Total)),
  fromSymbol(StatementTree.Capital.SubscribedCapital),
  fromSymbol(StatementTree.Capital.OwnShare),
  fromSymbol(StatementTree.Capital.OtherCapitalInstruments),
  fromSymbol(StatementTree.Capital.LegalReserves),
  fromSymbol(StatementTree.Capital.RevaluationSurplus),
  fromSymbol(StatementTree.Capital.OtherCapitalChanges),
  fromSymbol(StatementTree.Capital.IssuingPremia),
  fromSymbol(StatementTree.Capital.OtherReserves),
  fromSymbol(StatementTree.Capital.PastResults),
  fromSymbol(StatementTree.Capital.AntecipatedDividends),
  partial ? SynthNetProfit : fromSymbol(StatementTree.Capital.NetProfit),

  /// Liabilities

  total(fromSymbol(StatementTree.Liabilities.Total)),

  total(fromSymbol(StatementTree.Liabilities.NonCurrent.Total)),
  fromSymbol(StatementTree.Liabilities.NonCurrent.Provisions),
  fromSymbol(StatementTree.Liabilities.NonCurrent.Loans),
  fromSymbol(StatementTree.Liabilities.NonCurrent.OtherPayables),
  fromSymbol(StatementTree.Liabilities.NonCurrent.PersonnelBenefits),
  fromSymbol(StatementTree.Liabilities.NonCurrent.DeferredTaxLiabilities),

  total(fromSymbol(StatementTree.Liabilities.Current.Total)),
  fromSymbol(StatementTree.Liabilities.Current.TradeableLiabilities),
  fromSymbol(StatementTree.Liabilities.Current.OtherFinantialLiabilities),
  fromSymbol(StatementTree.Liabilities.Current.AdvancementsFromClients2),
  fromSymbol(StatementTree.Liabilities.Current.AdvancementsFromClients),
  fromSymbol(StatementTree.Liabilities.Current.Deferrals),
  fromSymbol(
    StatementTree.Liabilities.Current.NonCurrentLiabilitiesHeldForSales
  ),
  fromSymbol(StatementTree.Liabilities.Current.Providers),
  fromSymbol(StatementTree.Liabilities.Current.StateAndOtherPublicEntitities),
  fromSymbol(StatementTree.Liabilities.Current.Shareholders),
  fromSymbol(StatementTree.Liabilities.Current.Loans),
  fromSymbol(StatementTree.Liabilities.Current.OtherPayables),
];
