import { callCloudFunction } from "./firebase";

export const getInitialBDPDataForNif = (nif, dim) => {
  var caes = [];
  return callCloudFunction("caeAPI", {
    nif,
  })
    .then((data) => {
      caes = data;
      if (!data || data.length === 0) return;
      const firstCae = data[0];
      return firstCae;
    })
    .then((firstCae) => {
      return callCloudFunction("sectorData", { cae: firstCae, dim });
    })
    .then((data) => ({ ...data, caes }));
};

export const getBDPDataForCaeAndDim = (cae, dim) =>
  callCloudFunction("sectorData", { cae, dim });
