import React, { useEffect, useState } from "react";
import logo from "./icon-left-font-monochrome-black.svg";
import { useUser, Login, useFirestoreDoc } from "./firebase";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";
import { ImportPage, SAFTValidationSummary } from "./ImportPage";
import { useSAFTXMLFile, useSAFTCXMLFiles, useSAFTCXMLFiles2 } from "./import";
import { useLogger } from "./messaging";
import { Button, Popover, PopoverBody } from "reactstrap";
import { getTaxonomyReference } from "./saft";
import { Icon, MailTo } from "./UI";

const App = () => {
  const [demoMode, setDemoMode] = useState(true);
  const [loggedLogIn, setLoggedLogin] = useState(false);
  const { logEvent, logs, getLogs, LogEvent } = useLogger();

  const { user, login, isLoggingIn, errorLoggingIn, logout } = useUser();
  const { data: userRole, setDocPath: setUserUid } = useFirestoreDoc("roles");
  const [requestedRole, setRequestedRole] = useState(false);
  const tabHeaders = ["Importação", "Validação", "Configuração", "Calendário"];
  const startTab = tabHeaders[0];
  const [selectedTab, setSelectedTab] = useState(startTab);

  // const {
  //   file: xmlFile,
  //   data: xmlData,
  //   loadFromXMLArrayBuffer,
  //   report,
  //   resetSaftXmlFile,
  // } = useSAFTXMLFile();

  const {
    loadFiles,
    processed,
    isError,
    status,
    loading,
    loaded,
  } = useSAFTCXMLFiles2();
  // var file = files && files[0];
  // var data = processed && processed[0];

  // -- BEGIN TEST

  // useEffect(() => {
  //   fetchFile(SAFTC)
  //     .then((saftXml) => xmljs.xml2js(saftXml, { compact: true }))
  //     .then((saft) => console.log(saft));
  // }, []);

  // -- END OF TEST ONLY

  // Ensure logging
  useEffect(() => {
    const logLogin = (user) => logEvent({ user, eventType: LogEvent.Login });
    user && !loggedLogIn && logLogin(user).then(setLoggedLogin);
  }, [user, logEvent, getLogs, LogEvent, loggedLogIn]);

  useEffect(() => {
    if (user && !requestedRole && !userRole) {
      setRequestedRole(true);
      setUserUid(user.uid);
    }
  }, [user, userRole, requestedRole, setRequestedRole, setUserUid]);

  const handleFilesDrop = (files) => {
    console.log("file loaded", files);
    const FileTypes = { TSV: "text/tab-separated-values", XML: "text/xml" };
    if (files.every((f) => f.type === FileTypes.XML)) {
      return loadFiles(files); // loadFromXMLArrayBuffer(file, buffer);
    } else {
      console.warn("unhandled file type", files);
    }
  };

  const outOfScopeCompanyIdDetected =
    loaded &&
    userRole &&
    processed
      .map((p) => p.header.companyId)
      .some(
        (id) =>
          userRole.companyIds &&
          userRole.companyIds.length > 0 &&
          !userRole.companyIds.includes(id)
      );
  console.log({ outOfScopeCompanyIdDetected });

  if (outOfScopeCompanyIdDetected) {
    setTimeout(() => loadFiles([]), 5000);
  }

  const resetFiles = (_) => {
    loadFiles([])
  };
  const signInWithCredentials = (credentials) => login(credentials.email, credentials.password);
  const toggleDemoMode = (_) => setDemoMode(!demoMode);
  return (
    <AppContainer>
      <TopBar
        left={<Logo logo={logo}></Logo>}
        middle={
          // <Tabs
          //   showTabs={user}
          //   // headers={tabHeaders}
          //   selected={selectedTab}
          //   onClick={(value) => setSelectedTab(value)}
          // ></Tabs>

          processed && (
            <CompanyBar
              anonymous={demoMode}
              processed={processed}
              // onFileResetClick={removeFileAtOffset}
              onResetAllClick={resetFiles}
            />
          )
        }
        right={
          <div className="d-flex flex-row">
            {user && (
              <Username
                pre={
                  <>
                    {user && (
                      <span className="me-2">
                        <HelpdeskButton user={user} userRole={userRole} />
                      </span>
                    )}
                    {user && userRole && userRole.admin && (
                      <>
                        <span className="me-2">
                          <Badge
                            className=""
                            onClick={toggleDemoMode}
                          >
                            <Icon
                              icon={demoMode ? "eye-slash-fill" : "eye-slash"}
                            />
                          </Badge>
                        </span>
                        <span className="me-2">
                          <Badge className="">admin</Badge>
                        </span>
                      </>
                    )}
                    {user &&
                      userRole &&
                      userRole.companyIds &&
                      userRole.companyIds.length > 0 && (
                        <span className="me-2">
                          {userRole.companyIds &&
                            userRole.companyIds.map((id) => (
                              <Badge className="black-black">{id}</Badge>
                            ))}
                        </span>
                      )}
                  </>
                }
                username={user && user.email}
                logout={logout}
              ></Username>
            )}
          </div>
        }
      ></TopBar>
      <PageContainer className="h-100">
        {!user && (
          <div className="d-flex w-100 h-100 align-items-center mx-4">
            <div className="w-50 d-flex flex-column align-items-center">
              <CoverSection></CoverSection>
            </div>
            <Login
              className="w-50 align-items-center"
              disabled={isLoggingIn}
              onSubmit={signInWithCredentials
              }
              error={errorLoggingIn}
            ></Login>
          </div>
        )}
        {user && (
          <>
            {selectedTab === "Importação" && !outOfScopeCompanyIdDetected && (
              <ImportPage
                isAdmin={userRole && userRole.admin}
                anonymous={demoMode}
                status={status}
                loading={loading}
                loaded={loaded}
                isError={isError}
                // removing={removing}
                // files={files}
                processed={processed}
                // file={files && files[0]}
                onFilesDrop={handleFilesDrop}
                importData={processed && processed[0]}
                // onFileResetClick={removeFileAtOffset}
                onResetAllClick={resetFiles}
              ></ImportPage>
            )}
            {selectedTab === "Importação" &&
              outOfScopeCompanyIdDetected &&
              userRole && (
                <div className="mt-4">
                  <div className="alert alert-danger">
                    Incluíu SAFTs de outras empresas para as quais não tem
                    utilização. Tem autorização para ver{" "}
                    {userRole.companyIds &&
                      userRole.companyIds.map((id) => (
                        <Badge className="black-black">{id}</Badge>
                      ))}
                  </div>
                </div>
              )}
            {!(processed && processed[0]) && selectedTab !== "Importação" && (
              <NoFile></NoFile>
            )}
          </>
        )}
      </PageContainer>
    </AppContainer>
  );
};

// const fetchFile = (fileUrl) => fetch(fileUrl).then((body) => body.text());

const AppContainer = ({ children }) => (
  <div className="d-flex flex-column vh-100 px-1 bg-light overflow-hidden">
    {children}
  </div>
);

const PageContainer = ({ children, className }) => (
  <div className="flex-fill overflow-scroll">
    <div className={`d-flex flex-column ${className || ""}`}>{children}</div>
  </div>
);

const NoFile = () => (
  <div className="d-flex w-100 justify-content-center py-4">
    <div className="alert alert-warning">Nenhum ficheiro importado</div>
  </div>
);

const TopBar = ({ left, middle, right }) => (
  <div className="d-flex w-100 justify-content-between align-items-center">
    <div>{left}</div>
    <div className="ml-3 flex-fill mr-3">{middle}</div>
    <div>{right}</div>
  </div>
);

const Logo = ({ logo }) =>
  logo && (
    <div className="flex-fill my-1 py-1 px-2 mr-2">
      <img src={logo} alt="logo" style={{ height: "1rem" }}></img>
    </div>
  );

const Username = ({ username, logout, pre }) => (
  <div className="d-flex flex-row">
    {pre && <div>{pre}</div>}
    <div className="">
      <Badge className="black-black">
        {username}{" "}
        <span onClick={logout} className="clickable">
          &times;
        </span>
      </Badge>
    </div>
    {/* <div onClick={logout}>
      <button type="button" className="btn-close"></button>
    </div> */}
  </div>
);

const Badge = ({ children, className, pill, onClick, ...others }) => (
  <span
    className={`badge ${className || "black-accent text-white"} ${onClick ? "clickable" : ""
      } ${pill ? "rounded-pill" : ""}`}
    onClick={onClick}
    {...others}
  >
    {children}
  </span>
);

const CompanyBar = ({
  anonymous,
  files,
  processed,
  // onFileResetClick,
  onResetAllClick,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  return (
    processed &&
    processed.length > 0 && (
      <div className="text-center">
        <span className="me-2">
          <Badge pill={true}>
            <span style={{ filter: anonymous && "blur(3px)" }}>
              {[...new Set(processed.map((p) => p.header.companyName))][0]}{" "}
            </span>
            <span className="clickable" onClick={onResetAllClick}>
              &times;
            </span>
          </Badge>
        </span>
        {processed.map((p, offset) => (
          <span className="me-2">
            <Badge key={p.header.fiscalYear} pill={true}>
              {p.header.fiscalYear}
              {!isDecember31st(new Date(p.header.endDate)) && (
                <span className="text-light">
                  <sup>{monthAcronym(new Date(p.header.endDate))}</sup>
                </span>
              )}{" "}
              {/*<span
                className="clickable"
                onClick={onFileResetClick.bind(this, offset)}
              >
                &times;
              </span>*/}
            </Badge>
          </span>
        ))}
        <Badge pill={true} id="PopoverA" title="Ver detalhes" onClick={toggle}>
          <i className="bi bi-eye"></i>
        </Badge>
        <Popover
          placement="bottom"
          isOpen={popoverOpen}
          target="PopoverA"
          toggle={toggle}
          className="popover-container"
        >
          {/* <PopoverHeader>XXX</PopoverHeader> */}
          <PopoverBody>
            <div className="w-100">
              {processed &&
                processed.map((p, offset) => (
                  <SAFTValidationSummary
                    key={offset}
                    className="my-1"
                    // file={file}
                    data={p}
                    taxonomy={
                      p &&
                      getTaxonomyReference(p.xmlJson)
                    }
                  // onCloseClick={onFileResetClick.bind(this, offset)}
                  ></SAFTValidationSummary>
                ))}
            </div>
          </PopoverBody>
        </Popover>
      </div>
    )
  );
};

const isDecember31st = (date) => {
  const dayOfMonth = date && date.getDate();
  const month = date && date.getMonth() + 1;
  return dayOfMonth === 31 && month === 12;
};

const monthAcronym = (date) => {
  const month = date && date.getMonth() + 1;
  const Acronynms = {
    undefined: "",
    1: "Jan",
    2: "Fev",
    3: "Mar",
    4: "Abr",
    5: "Mai",
    6: "Jun",
    7: "Jul",
    8: "Ago",
    9: "Set",
    10: "Out",
    11: "Nov",
    12: "Dec",
  };
  return Acronynms[month];
};

const CoverSection = ({ className }) => (
  <div className={`d-flex flex-column ${className || ""}`}>
    <div className="mb-4">
      <span className="display-4">
        O seu controller financeiro a qualquer momento
      </span>
    </div>
    <CoverBullet
      className="mb-3"
      icon="bi-lightning-charge-fill text-primary fs-2"
      text={
        <span>
          <b>Super-rápido</b> de usar!
        </span>
      }
      subtext="Basta arrastar o seu ficheiro SAFT-C e tem resultados imediatos."
    ></CoverBullet>
    <CoverBullet
      className="mb-3"
      icon="bi-exclamation-octagon-fill text-danger fs-2"
      text={
        <span>
          <b>Antecipe riscos</b> para evitar dificuldades financeiras.
        </span>
      }
      subtext="Mostramos os alertas críticos e recomendações para os resolver."
    ></CoverBullet>
    <CoverBullet
      className="mb-3"
      icon="bi-bank2 text-info fs-2"
      text={
        <span>
          Sugestões para <b>melhor interação com Banca</b>.
        </span>
      }
      subtext="Identificamos potenciais questões que a Banca lhe pode fazer."
    ></CoverBullet>
    <CoverBullet
      className="mb-3"
      icon="bi-graph-up-arrow text-success fs-2"
      text={
        <span>
          Ideias para <b>melhorar rentabilidade</b> do negócio.
        </span>
      }
      subtext="E damos-lhe um simulador rápido de impacto nas contas."
    ></CoverBullet>
    <div className="mt-4">
      <a
        className="btn btn-primary"
        href="mailto:controller@racios.consulting?subject=Pedido%20de%20acesso&body=Boa%20tarde%2C%0A%0Asolicito%20pedido%20de%20acesso%20para%20a%20minha%20empresa.%0A%0ANIF%3A%20%5Bpreencher%5D%0AEmail%3A%20%5Bpreencher%5D%0A%0AObrigado%2C%0A"
      >
        Pedir acesso
      </a>
    </div>
  </div>
);

const CoverBullet = ({ text, subtext, icon, className }) => (
  <div className={`d-flex flex-row align-items-center ${className || ""}`}>
    {icon && <i className={`bi ${icon} me-3`}></i>}
    <div className="align-middle">
      {text && <div className="align-middle">{text}</div>}
      {subtext && <div className="small text-muted">{subtext}</div>}
    </div>
  </div>
);

const HelpdeskButton = ({ user, userRole }) => {
  return (
    <Badge className="bg-primary text-light">
      <MailTo
        className="bg-primary text-light text-decoration-none"
        tooltip="Clique aqui para solicitar apoio ou colocar uma questão via email"
        emailTo="controller@racios.consulting"
        emailCc={user.email}
        subject="Pedido de apoio / questão"
        body={`Olá, este é uma solicitação sobre:\n\n\n[Inserir aqui o seu tema]${user &&
          user.email &&
          `\n\n\nDados adicionais:\n\t- Utilizador: ${user.email}`
          }${userRole && userRole.companyIds && userRole.companyIds.length > 0
            ? `\n\t- NIFs:${userRole.companyIds.join(", ")}`
            : ""
          }
      \n\nObrigado`}
      >
        <i className="bi bi-question-circle-fill me-1"></i>
        Ajuda
      </MailTo>
    </Badge>
  );
};

export default App;
