import { useState, useEffect } from "react";
import { XMLtoJson, parseSAFTJson, groupItemsByAccountId } from "./saft";
import { useTextFiles, useTextFilesFast, useTextFilesWorker, useTextFilesWorker2 } from "./FileDrop";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
// eslint-disable-next-line import/no-webpack-loader-syntax
// import SaftWorker from "worker-loader!./saft.worker";

const FILTER_PREFIXES = []; // for testing only

// Excludes transactions from the periods listed below
// TODO: It will need to be dynamically determined
const EXCLUDE_PERIODS = [14, 15];

// It will include excluded periods just for the prefixes indicated in EXCEPTION_PREFIXES
// "818" is NetProfit
const EXCEPTION_PREFIXES = ["818"];

export const useSAFTCXMLFiles = () => {
  const {
    setFiles,
    files,
    removeFileAtOffset,
    results: processed,
    loading,
    loaded,
    removing,
  } = useTextFilesWorker('import.worker.js', 'readAndprocessSAFTXML', filterSAFTFiles) // useTextFilesFast(processSAFTXML, filterSAFTFiles);

  return {
    setFiles,
    files,
    removeFileAtOffset,
    processed,
    loading,
    loaded,
    removing,
  };
};

export const useSAFTCXMLFiles2 = () => {
  const {
    loadFiles,
    results: processed,
    isError,
    loading,
    loaded,
  } = useTextFilesWorker2('import.worker.js', 'readAndprocessSAFTXML', filterSAFTFiles) // useTextFilesFast(processSAFTXML, filterSAFTFiles);

  return {
    loadFiles,
    processed,
    isError,
    loading,
    loaded,
  };
};

const getReduceXmlJson = (xmlJson) => {
  const { AuditFile } = xmlJson
  const { Header, MasterFiles } = AuditFile;
  const { Customer, Supplier, GeneralLedgerAccounts } = MasterFiles;
  const { TaxonomyReference } = GeneralLedgerAccounts;

  return {
    AuditFile: {
      Header,
      MasterFiles: {
        Customer, Supplier, GeneralLedgerAccounts: { TaxonomyReference }
      }
    }
  }
}

export const processSAFTXML = (saftText, offset) =>
  Promise.resolve(saftText)
    .then(XMLtoJson)
    .then((xmlJson) => ({
      offset,
      xmlJson: getReduceXmlJson(xmlJson),
      ...parseSAFTJson(
        xmlJson,
        FILTER_PREFIXES,
        EXCLUDE_PERIODS,
        EXCEPTION_PREFIXES
      ),
    }))
    .then(
      ({
        offset,
        missingCriticalInformation,
        xmlJson,
        header,
        codeSummaries,
        transactionsGroupedByAccounts,
      }) => ({
        offset,
        missingCriticalInformation,
        xmlJson,
        header,
        summaries: codeSummaries,
        summaryIndex: codeSummaries && groupItemsByAccountId(codeSummaries),
        transactionIndex: transactionsGroupedByAccounts,
      })
    );

export const filterSAFTFiles = ({ missingCriticalInformation }) =>
  !missingCriticalInformation;

export const ErrorChecking = {
  yearsAreDifferent: (fileHeaders) =>
    [...new Set(fileHeaders.map((fileHeader) => fileHeader.fiscalYear))]
      .length < fileHeaders.length,
};

/** Loads and parse SAFT-C PT files */
export const useSAFTXMLFile = () => {
  const [file, setFile] = useState();
  const [data, setData] = useState();

  const FILTER_PREFIXES = []; // for testing only

  const resetSaftXmlFile = () => {
    setFile();
    setData();
  };

  const loadFromXMLArrayBuffer = (file, arrayBuffer) =>
    Promise.resolve(arrayBufferToText(arrayBuffer))
      .then(XMLtoJson)
      .then((xmlJson) => ({
        xmlJson,
        ...parseSAFTJson(
          xmlJson,
          FILTER_PREFIXES,
          EXCLUDE_PERIODS,
          EXCEPTION_PREFIXES
        ),
      }))
      .then(
        ({ xmlJson, header, codeSummaries, transactionsGroupedByAccounts }) => {
          setFile(file);
          setData({
            xmlJson,
            header,
            summaries: codeSummaries,
            summaryIndex: groupItemsByAccountId(codeSummaries),
            transactionIndex: transactionsGroupedByAccounts,
          });
        }
      );

  // const saft = XMLtoJson(xml);
  // const { codeSummaries } = parseSAFTJson(saft);

  return {
    file,
    loadFromXMLArrayBuffer,
    data,
    resetSaftXmlFile,
  };
};

/**
 * It does not use an array buffer but a binary string.
 * For now, it seems to be detecting encoding
 * @param {*} arrayBuffer
 * @returns
 */
export const arrayBufferToText = (arrayBuffer) => {
  // console.log(arrayBuffer);
  // console.log(jschardet.detect(arrayBuffer));
  // const _text = new TextDecoder("utf-8").decode(arrayBuffer);
  return arrayBuffer;
};

export const MimeType = {
  "application/xml": "XML",
  "text/xml": "XML",
  "text/csv": "CSV",
  "text/tsv": "TSV",
};



